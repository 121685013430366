import { makeStyles } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import React from "react";

const useStyle = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "white",
  },
}));

export const NotificationSnakBar = ({
  openSnakbar,
  setOpenSnakbar,
  snakMessage,
  error,
}) => {
  const classes = useStyle();
  return (
    <>
      <Snackbar
        open={openSnakbar}
        autoHideDuration={2000}
        message={snakMessage}
        onClose={() => setOpenSnakbar(false)}
        ContentProps={{
          classes: { root: error ? classes.error : classes.success },
        }}
      />
    </>
  );
};
