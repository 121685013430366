import { Avatar, Box, Grid, Link, Typography, useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from "@material-ui/icons/Notifications";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apis } from "../../apis/apis";
import { askForPermissionToReceiveNotifications } from "../../push-notification";
import {
  getAccountStatus,
  getUnreadNotifications,
} from "../../redux-reducer/centrifuge/centrifugeSlice";
import { getUser, logout } from "../../services/authService";
import NotificationList from "./NotificationList";
import PopoverDesktop from "./PopoverDesktop";
import MobileNotificationPopover from "./MobileNotificationPopover";
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from "react-router-dom";
import { getAvatar, getUsername, profileStatus } from "../../utils";
import { useTranslation } from 'react-i18next';
import { getProfileType } from "../../redux-reducer/profileType/profileTypeSlice" 
import MobileMenuDrawer from "./MobileMenuDrawer";
import { LoadingDisplay } from "../common-ui-fit/LoadingDisplay";

export default function NavigationBar({profileDetails}) { 
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [popoverElement, setpopoverElement] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [notificationOpened, setNotificationOpened] = useState("false");
  const menuId = "menu-desktop";
  const mobileMenuId = "menu-mobile";
  const history = useHistory();
  const profile = useSelector(getProfileType);

  const [drawerAnchor, setDrawerAnchor] = useState(false);

  const [numberOfUnreadNotifications, setNumberOfUnreadNotifications] = useState(0);

  const [status, setStatus] = useState({
    hasNextPage: true,
    isNextPageLoading: false,
    items: [],
    totalNumberOfItems: -1,
  });

  const [rerender, setRerender] = useState(false);
  const accountStatus = useSelector(getAccountStatus);

  const unreadNotificationsFromRealtime = useSelector(getUnreadNotifications);

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if(accountStatus && accountStatus.type == profileStatus.AccountDeactivated)
      logout()
  }, [accountStatus]);

  useEffect(async () => {
    const response = await apis.getUnreadNotifications();
    setNumberOfUnreadNotifications(response.data.notifications);
  }, [notificationOpened]);

  useEffect(() => {
    if (unreadNotificationsFromRealtime) {
      setNumberOfUnreadNotifications(unreadNotificationsFromRealtime);
    }
  }, [unreadNotificationsFromRealtime]);

  useEffect(() => {
    askForPermissionToReceiveNotifications();
  }, []);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogOut = () => {
    logout();
  };

  const showNotification = async (event) => {
    setpopoverElement(event.currentTarget);
    if (getUser().email) {
      let response = await apis.getTotalNotifications();
      if (response.status === 200) {
        setStatus({
          ...status,
          totalNumberOfItems: response.data.notifications,
        });
      }
    }
  };

  const loadNextPage = async (startIndex, endIndex) => {
    setStatus({ ...status, isNextPageLoading: true });
    const currentPageNotifications = await apis.getNotificationContent(
      startIndex
    );
    setStatus({
      ...status,
      hasNextPage: status.items.length < status.totalNumberOfItems,
      isNextPageLoading: false,
      items: [...status.items].concat(currentPageNotifications.data),
    });
  };

  const closeNotification = () => {
    setpopoverElement(null);
  };

  const openNotificationList = Boolean(popoverElement);

  const markAllAsReadAction = async () => {
    const response = await apis.updateReadNotifications();
    setNotificationOpened(!notificationOpened);
    closeNotification();
  };

  const avatarContent = getAvatar(profileDetails, profile);
  const username = getUsername(profileDetails, profile);

  const toggleDrawer = () => {
    setDrawerAnchor(!drawerAnchor);
  }

  const renderNotificationList = (
    <Popover
      open={openNotificationList}
      anchorEl={popoverElement}
      onClose={closeNotification}
      onClick={markAllAsReadAction}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Grid
        container
        direction="column"
        justify="flex-start"
        style={{ paddingTop: "0.2rem" }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ paddingLeft: "8px" }}
        >
          <Grid item>
            <Typography variant="h5">{t("navbar.notification")}</Typography>
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item onClick={markAllAsReadAction}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Typography variant="h6" className={classes.cursor}>
                  {t("navbar.mark-as-read")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton>
                  <CheckCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {status.totalNumberOfItems != -1 ? (
            <NotificationList
              hasNextPage={status.hasNextPage}
              isNextPageLoading={status.isNextPageLoading}
              items={status.items}
              loadNextPage={loadNextPage}
              closeNotification={closeNotification}
              rerender={rerender}
              setRerender={setRerender}
            />
          ) : (
            <h6>{t("navbar.loading")}</h6>
          )}
        </Grid>
      </Grid>
    </Popover>
  );

  if(profileDetails)
  return (
    <>
      <div className={classes.grow}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              href="/"
            >
              <img
                src="/images/cedarxneo-logo/cedarxneo-white-logo.png"
                className={classes.navBarIconSettings}
                alt="cedar-x-neo-logo"
              />
            </IconButton>

            <div className={classes.grow} />

            <div className={classes.sectionDesktop}>
              <IconButton
                aria-label="home"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
                href='/home'
              >
                <HomeIcon />
              </IconButton>

              <IconButton
                aria-label="show new notifications"
                color="inherit"
                onClick={showNotification}
              >
                <Badge
                  badgeContent={numberOfUnreadNotifications}
                  color="secondary"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              
              <IconButton
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar className={classes.small}>{avatarContent}</Avatar>
                <Typography variant="body2" style={{marginLeft: ".5rem"}}>{username}</Typography>
              </IconButton>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-haspopup="true"
                onClick={toggleDrawer}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        {/*Notification Popover mobile*/ }
        <MobileNotificationPopover
          menuId={mobileMenuId}
          loading={loading}
          handleMobileMenuClose={handleMenuClose}
          handleLogOut={handleLogOut}
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          status={status}
        />

        {/** Menu Popover Desktop */}
        <PopoverDesktop
          menuId={menuId}
          handleMenuClose={handleMenuClose}
          anchorEl={anchorEl}
          handleLogOut={handleLogOut}
          isMenuOpen={isMenuOpen}
          classes={classes}
          avatarContent={avatarContent}
          profileDetails={profileDetails}
        />

        <MobileMenuDrawer 
          drawerAnchor={drawerAnchor}
          toggleDrawer={() => toggleDrawer}
          classes={classes}
          avatarContent={avatarContent}
          handleLogOut={handleLogOut}
          profileDetails={profileDetails}
        />

        {renderNotificationList}
      </div>
    </>
  );
  return <LoadingDisplay />
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  inputRoot: {
    color: "inherit",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  navBarIconSettings: {
    width: "11rem",
  },
  cursor: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(238, 238, 238, 0.42)",
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: "1rem",
    backgroundColor: theme.palette.secondary.main
  },
  avatarCustomized: {
    backgroundColor: theme.palette.secondary.main
  },
  mobileMenuItem: {
    color: theme.palette.white.main,
    justifyContent: "center"
  },
  desktopMenuItem: {
    color: theme.palette.black.main,
    justifyContent: "center"
  }
}));
