import React from "react";
import { useSelector } from "react-redux";
import { LoadingDisplay } from "../components/common-ui-fit/LoadingDisplay";
import { getProfileType } from "../redux-reducer/profileType/profileTypeSlice" 
import { profileType } from "../utils";
import OrganizationProfile from "../components/profilepage/organization/profile";
import BusinessProfile from "../components/profilepage/business/profile";
import IndividualProfile from "../components/profilepage/individual/profile";

export default function Profile() {
  const profile = useSelector(getProfileType);

  if(!profile)
    return <LoadingDisplay />

  if(profile == profileType.Organization)
    return <OrganizationProfile /> 
  
  if(profile == profileType.Business)
    return <BusinessProfile />
  
    if(profile == profileType.Individual)
    return <IndividualProfile /> 
}
