import {createSlice} from "@reduxjs/toolkit";

export const profileTypeSlice = createSlice({
    name: "profileType",
    initialState: {
        profileType: null
    },
    reducers: {
        updateProfileType: (state, action) => {
            state.profileType = action.payload;
        },
    }
});

export const { updateProfileType } = profileTypeSlice.actions;

export const getProfileType = (state) => state.profileType.profileType;

export default profileTypeSlice.reducer;
