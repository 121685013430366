import {
  Avatar,
    Box,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
  } from "@material-ui/core";
  import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
  import { useTheme } from "@material-ui/styles";
  import React, { useEffect } from "react";
  import { useSelector } from "react-redux";
  import { getUnreadNotifications } from "../../../redux-reducer/centrifuge/centrifugeSlice";
  import { getAvatar, isArabic, profileStatus, toArabicDigits } from "../../../utils";
  import { isAccountApproved } from "../../../utils";
  import { useTranslation } from 'react-i18next';
  
  import { formatDate, parseServerDate } from "../../../utils";
import { getProfileType } from "../../../redux-reducer/profileType/profileTypeSlice";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";
  
  const BusinessSummary = ({ businessDetails }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const unreadNotificationsFromRealtime = useSelector(getUnreadNotifications);
  
    var created_on = formatDate(parseServerDate(businessDetails.created_on));

    const type = useSelector(getProfileType);
    
    if(type && businessDetails)
      return (
        <Grid container direction="colum" md={12} xs={12} justify="center" style={{textAlign: "center"}} >
          <Grid item>
            <Box className={classes.iconSettings}>
              <Avatar className={classes.avatarCustomized}>{getAvatar(businessDetails, type)}</Avatar>           
            </Box>
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Box mt={2} />
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Typography variant="h6">{t("hi-label")} {businessDetails.name}</Typography>
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Box mt={2} />
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Typography variant="h6" style={{ opacity: 0.5, color: "#2e2f30",}}>
              {t("homepage.profile-summary.profile-status")}
            </Typography>
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Grid container direction="row" justify="center" style={{alignItems: "center"}}>
              <Grid item>
                <FiberManualRecordIcon
                  className={
                    isAccountApproved(businessDetails)
                      ? classes.approvedVerificationStatusSettings
                      : classes.disapproveVerificationStatusSettings
                  }
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {
                    isAccountApproved(businessDetails)
                      ? t("profile-status.verified")
                      : t("profile-status.pending")
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Box mt={2} />
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Typography variant="h6" style={{ opacity: 0.5, color: "#2e2f30",}}>
              {t("homepage.profile-summary.member")}
            </Typography>
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Typography variant="body2">{isArabic() ? toArabicDigits(created_on) : created_on}</Typography>
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Box mt={2} />
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Typography variant="h6" style={{ opacity: 0.5, color: "#2e2f30",}}>
              {t("homepage.profile-summary.orders")}
            </Typography>
          </Grid>
    
          <Grid item md={12} xs={12}>
            <Typography variant="body2">{businessDetails.total_orders}</Typography>
          </Grid>
    
        </Grid>
      );
    return <LoadingDisplay />
  };
  
  const useStyles = makeStyles((theme) => ({
    homepageSettings: {
      backgroundColor: "#f6f6f6",
      [theme.breakpoints.up("md")]: {
        paddingLeft: "6rem",
        paddingRight: "6rem",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
  
      paddingTop: "3rem",
    },
    itemSettings: {
      borderRadius: "7px",
      boxShadow: "0 0 16px 0 rgba(92, 92, 92, 0.06)",
      border: "solid 1px #f6f6f6",
      backgroundColor: "white",
      [theme.breakpoints.up("md")]: {
        paddingLeft: "3rem",
        paddingRight: "3rem",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      paddingTop: "1.6rem",
      paddingBottom: "1.6rem",
    },
    iconSettings: {
      backgroundColor: "rgba(37, 218, 197, 0.1)",
      padding: "0.8rem",
      borderRadius: "50%",
    },
    approvedVerificationStatusSettings: {
      color: theme.palette.success.main,
      fontSize: "12px",
    },
    disapproveVerificationStatusSettings: {
      color: "orange",
      fontSize: "12px",
    },
    profileBoxSettings: {
      opacity: 0.5,
      color: "#2e2f30",
    },
    avatarCustomized: {
      height: "7.2rem",
      width: "7.2rem",
      border: "solid 1px",
      borderColor: theme.palette.secondary.main,
      backgroundColor: "rgba(37, 218, 197, 0.3)",
      fontSize: "3rem",
      fontWeight: 500,
    },
    orderTable: {
      [theme.breakpoints.down("md")]: {
        width: 380,
      },
    },
  }));
  
  export default BusinessSummary;
  