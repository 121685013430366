import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmationDialog({
  title,
  content,
  leftButtonTitle,
  rightButtonTitle,
  onClickLeftButton,
  onClickRightButton,
  open,
  close,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickLeftButton} color="primary">
            {leftButtonTitle}
          </Button>
          <Button onClick={onClickRightButton} color="primary" autoFocus>
            {rightButtonTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
