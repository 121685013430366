import i18n from "../../../../i18nextConf";
import Identification from "../Identification";
import Activity from "../Activity";
import FinancialSituation from "../FinancialSituation";
import OwnershipStructure from "../OwnershipStructure";
import Representatives from "../Representatives";
import ContactDetails from "../ContactDetails";
import CompanyVerification from "../CompanyVerification";

export const fields = {
    formId: 'organizationStepper',
    formField: {
        organizationName: {
            name: 'name',
            label: i18n.t("company-identification-step.name-label"),
            requiredErrorMsg: i18n.t("validation.required.organization-name"),
            matchesErrorMsg: i18n.t("validation.matches.name")
        },
        registrationCountries: {
            name: 'place_and_country_of_registration_id',
            label: i18n.t("company-identification-step.country-label"),
            requiredErrorMsg: i18n.t("validation.required.registration-countries"),
        },
        registrationDate: {
            name: 'date_of_registration',
            label: i18n.t("company-identification-step.date-label"),
            requiredErrorMsg: i18n.t("validation.required.registration-date")
        },
        occupations: {
            name: 'occupations',
            label: i18n.t("activity-step.occupation-label"),
            requiredErrorMsg: i18n.t("validation.required.occupations")
        },
        employees: {
            name: 'number_of_employees',
            label: i18n.t("activity-step.employees-label"),
            requiredErrorMsg: i18n.t("validation.required.employees")
        },
        interactionCountries: {
            name: 'countries_of_business_interactions',
            label: i18n.t("activity-step.countries-label"),
            requiredErrorMsg: i18n.t("validation.required.interaction-countries")
        },
        activity: {
            name: 'detailed_activity',
            label: i18n.t("activity-step.activity-label"),
            requiredErrorMsg: i18n.t("validation.required.detailed-activity")
        },
        volume: {
            name: 'expected_annual_volume_IN_OUT_in_USD',
            label: i18n.t("financial-step.volume-label"),
            requiredErrorMsg: i18n.t("validation.required.volume")
        },
        sales: {
            name: 'annual_sales_in_USD',
            label: i18n.t("financial-step.sales-label"),
            requiredErrorMsg: i18n.t("validation.required.sales")
        },
        information: {
            name: 'financial_situation_additional_information',
            label: i18n.t("financial-step.information-label"),
            requiredErrorMsg: i18n.t("validation.required.information")
        },
        beneficialOwnership: {
            name: 'beneficial_right_ownership_id',
            label: i18n.t("ownership-step.beneficial-label"),
            requiredErrorMsg: i18n.t("validation.required.beneficial-ownership")
        },
        beneficialParties: {
            name: 'beneficial_right_parties',
            label: i18n.t("ownership-step.beneficial-parties-label"),
            requiredErrorMsg: i18n.t("validation.required.beneficial-parties")
        },
        owners: {
            name: 'owners',
            label: i18n.t("ownership-step.owner-label"),
            type: {
                name: 'type',
                options: {
                    individual: {
                        name: 'individual',
                        label: i18n.t("ownership-step.type.individual")
                    },
                    entity: {
                        name: 'entity',
                        label: i18n.t("ownership-step.type.entity")
                    }
                },
                requiredErrorMsg: i18n.t("validation.required.owner-type")
            },
            percentage: {
                name: 'percentage_of_ownership',
                label: i18n.t("ownership-step.percentage-label"),
                requiredErrorMsg: i18n.t("validation.required.owner-percentage")
            },
            firstName: {
                name: 'first_name',
                label: i18n.t("labels.first-name"),
                requiredErrorMsg: i18n.t("validation.required.first-name"),
                matchesErrorMsg: i18n.t("validation.matches.name")
            },
            fatherName: {
                name: 'father_name',
                label: i18n.t("labels.father-name"),
                requiredErrorMsg: i18n.t("validation.required.father-name"),
                matchesErrorMsg: i18n.t("validation.matches.name")
            },
            lastName: {
                name: 'last_name',
                label: i18n.t("labels.last-name"),
                requiredErrorMsg: i18n.t("validation.required.last-name"),
                matchesErrorMsg: i18n.t("validation.matches.name")
            },
            doBirth: {
                name: 'date_of_birth',
                label: i18n.t("labels.dob"),
                requiredErrorMsg: i18n.t("validation.required.dob")
            },
            nationality: {
                name: 'nationality',
                label: i18n.t("labels.nationality"),
                requiredErrorMsg: i18n.t("validation.required.nationality")
            },
            residence: {
                name: 'residence',
                label: i18n.t("labels.residence"),
                requiredErrorMsg: i18n.t("validation.required.residence")
            },
            entityName: {
                name: 'name',
                label: i18n.t("ownership-step.entity-name-label"),
                requiredErrorMsg: i18n.t("validation.required.owner-entity-name"),
                matchesErrorMsg: i18n.t("validation.matches.name")
            },
            legalForm: {
                name: 'legal_form_id',
                label: i18n.t("ownership-step.legal-form-label"),
                requiredErrorMsg: i18n.t("validation.required.owner-legal-form"),
            },
            shareholders: {
                name: 'shareholders_names',
                label: i18n.t("ownership-step.shareholders-name-label"),
                requiredErrorMsg: i18n.t("validation.required.owner-shareholders"),
            },
            representatives: {
                name: 'legal_representatives_names',
                label: i18n.t("ownership-step.representatives-name-label"),
                requiredErrorMsg: i18n.t("validation.required.owner-representatives"),
            }, 
            documentType: {
                name: 'document_type',
                label: i18n.t("kyc-documents.type-label"),
                requiredErrorMsg: i18n.t("validation.required.document-type")
            },
            documentNumber: {
                name: 'document_number',
                label: i18n.t("kyc-documents.number-label"),
                requiredErrorMsg: i18n.t("validation.required.document-number"),
                passportMatchesErrorMsg: i18n.t("validation.matches.passport-number"),
                idMatchesErrorMsg: i18n.t("validation.matches.id-number")
            },
            documentDate: {
                name: 'document_expiry_date',
                label: i18n.t("kyc-documents.date-label"),
                requiredErrorMsg: i18n.t("validation.required.document-expiry-date")
            }         
        },
        representatives: {
            name: 'representatives',
            label: i18n.t("representatives-step.representative-label"),
            contact: {
                name: 'is_main_contact',
                label: i18n.t("representatives-step.contact-label"),
                requiredErrorMsg: i18n.t("validation.required.representative-contact"),
            },
            firstName: {
                name: 'first_name',
                label: i18n.t("labels.first-name"),
                requiredErrorMsg: i18n.t("validation.required.first-name"),
                matchesErrorMsg: i18n.t("validation.matches.name")
            },
            fatherName: {
                name: 'father_name',
                label: i18n.t("labels.father-name"),
                requiredErrorMsg: i18n.t("validation.required.father-name"),
                matchesErrorMsg: i18n.t("validation.matches.name")
            },
            lastName: {
                name: 'last_name',
                label: i18n.t("labels.last-name"),
                requiredErrorMsg: i18n.t("validation.required.last-name"),
                matchesErrorMsg: i18n.t("validation.matches.name")
            },
            doBirth: {
                name: 'date_of_birth',
                label: i18n.t("labels.dob"),
                requiredErrorMsg: i18n.t("validation.required.dob"),
                ageErrorMsg: i18n.t("validation.age")
            },
            nationality: {
                name: 'nationality',
                label: i18n.t("labels.nationality"),
                requiredErrorMsg: i18n.t("validation.required.nationality")
            },
            residence: {
                name: 'residence',
                label: i18n.t("labels.residence"),
                requiredErrorMsg: i18n.t("validation.required.residence")
            },
            isRepresentative: {
                name: 'is_representative',
                label: i18n.t("representatives-step.contact-person-label")
            },
            documentType: {
                name: 'document_type',
                label: i18n.t("kyc-documents.type-label"),
                requiredErrorMsg: i18n.t("validation.required.document-type")
            },
            documentNumber: {
                name: 'document_number',
                label: i18n.t("kyc-documents.number-label"),
                requiredErrorMsg: i18n.t("validation.required.document-number"),
                passportMatchesErrorMsg: i18n.t("validation.matches.passport-number"),
                idMatchesErrorMsg: i18n.t("validation.matches.id-number")
            },
            documentDate: {
                name: 'document_expiry_date',
                label: i18n.t("kyc-documents.date-label"),
                requiredErrorMsg: i18n.t("validation.required.document-expiry-date")
            }
        },
        officeCountry: {
            name: 'head_office_country_id',
            label: i18n.t("contact-details-step.country-label"),
            requiredErrorMsg: i18n.t("validation.required.office-country") 
        },
        officeAddress: {
            name: 'head_office_address',
            label: i18n.t("contact-details-step.address-label"),
            requiredErrorMsg: i18n.t("validation.required.office-address")
        },
        phone: {
            name: 'telephone',
            label: i18n.t("contact-details-step.phone-label"),
            requiredErrorMsg: i18n.t("validation.required.phone"),
            matchesErrorMsg: i18n.t("validation.matches.phone"),
            lengthErrorMsg: i18n.t("validation.phone-length")
        },
        email: {
            name: 'email',
            label: i18n.t("contact-details-step.email-label"),
            requiredErrorMsg: i18n.t("validation.required.email"),
            matchesErrorMsg: i18n.t("validation.matches.email")
        },
        website: {
            name: 'website',
            label: i18n.t("contact-details-step.website-label"),
            requiredErrorMsg: i18n.t("validation.required.website"),
            matchesErrorMsg: i18n.t("validation.matches.website")
        },
        signature: {
            name: 'signature',
            label: i18n.t("validation.required.signature"),
            requiredErrorMsg: i18n.t("validation.required.signature")
        },
        signatureDate: {
            name: 'signature_date',
            label: i18n.t("validation.required.signature-date"),
            requiredErrorMsg: i18n.t("validation.required.signature-date")
        },
        documents: {
            name: 'documents',
        },
    }
};

export   const steps = [
    {
        id: 0,
        label: i18n.t("stepper.kyb.step1"),
        fields: [fields.formField.organizationName.name, fields.formField.registrationCountries.name, fields.formField.registrationDate.name]
    },

    {
        id: 1,
        label: i18n.t("stepper.kyb.step2"),
        fields: [fields.formField.occupations.name, fields.formField.employees.name, fields.formField.interactionCountries.name,fields.formField.activity.name]
    },

    {
        id: 2,
        label: i18n.t("stepper.kyb.step3"),
        fields: [fields.formField.volume.name, fields.formField.sales.name, fields.formField.information.name]
    },

    {
        id: 3,
        label: i18n.t("stepper.kyb.step4"),
        fields: [fields.formField.beneficialOwnership.name, fields.formField.beneficialParties.name, fields.formField.owners.name]
    },

    {
        id: 4,
        label: i18n.t("stepper.kyb.step5"),
        fields: [fields.formField.representatives.name]
    },

    {
        id: 5,
        label: i18n.t("stepper.kyb.step6"),
        fields: [fields.formField.officeCountry.name, fields.formField.officeAddress.name, fields.formField.phone.name, fields.formField.email.name, fields.formField.website.name]
    },

    {
        id: 6,
        label: i18n.t("stepper.kyb.step7"),
        fields: [fields.formField.documents.name]
    }
];

export const stepsContent = (formInputs, formikProps) => {
    return (
        [           
            <Identification {...formInputs} formikProps = {formikProps} />,
            <Activity {...formInputs} formikProps = {formikProps} />,
            <FinancialSituation {...formInputs} formikProps = {formikProps} />,
            <OwnershipStructure {...formInputs} formikProps = {formikProps} />,
            <Representatives {...formInputs} formikProps = {formikProps} />,
            <ContactDetails {...formInputs} formikProps = {formikProps} />,
            <CompanyVerification {...formInputs} formikProps = {formikProps} />,
        ]
    )
}

  