import {createSlice} from "@reduxjs/toolkit";

export const individualSlice = createSlice({
  name: "individualInfo",
  initialState: {
    individualDetails: null,
    loaded: true
  },
  reducers: {
    updateIndividualDetails: (state, action) => {
      state.individualDetails = action.payload;
      state.loaded = true;
    },
    reloadIndividualDetails: (state) => {
      state.loaded = false;
    },
  }
});

export const { updateIndividualDetails, reloadIndividualDetails } = individualSlice.actions;

export const getIndividualDetails = (state) => state.individualInfo.individualDetails;

export const getIndividualLoaded = (state) => state.individualInfo.loaded;

export default individualSlice.reducer;
