import { Box, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { httpGet } from "../../httpcommon";

function CurrencySelector({ className, onChange, value, disabled }) {
  const [currencyList, setCurrencyList] = useState("");

  const getCurrencies = async () => {
    let res = await httpGet("/get_all_currencies");
    if (res.status == 200) {
      setCurrencyList(res.data);
    }
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  if (currencyList)
    return (
      <>
        <Select
          variant="outlined"
          fullWidth
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          className={className}
          onChange={onChange}
          value={value}
          disabled={disabled}
        >
          {currencyList.map((data) => {
            return (
              <MenuItem value={data.ticker}>
                <Box
                  style={{ height: "1rem" }}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <img src={data.flag_url} />
                  <p
                    style={{
                      marginLeft: "4px",
                      fontWeight: 600,
                    }}
                  >
                    {data.ticker}
                  </p>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </>
    );
  return <></>;
}

export default CurrencySelector;
