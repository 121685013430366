import { httpGet, httpPost, httpPut } from "../httpcommon";
import { getUser } from "../services/authService";

export const organizationApis = {
  getOrganizationInformation: async function (profileId) {
    try {
      return await httpGet("/organization-information/" + profileId);
    } catch (error) {
      console.log(error.message);
    }
  },

  register: async function (id, activationkey, data) {
    try {
      return await httpPost("/fill-organization-information/" + id + "/" + activationkey, data);
    } catch (error) {
      console.log(error.message);
    }
  },

  edit: async function (masked_id, data) {
    try {
      return await httpPut("/organization/" + masked_id, data);
    } catch (error) {
      console.log(error.message);
    }
  },

  confirmDeposit: async function(masked_id, data) {
    try {
      return await httpPut("/order-deposite-verification/" + masked_id, data);
    } catch (error) {
      console.log(error.message);
    }
  }
};