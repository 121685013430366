import decodeJwt from "jwt-decode";
import { httpDelete } from "../httpcommon";
const TOKEN_KEY = "token";

export function getApiToken() {
  const token = localStorage.getItem(TOKEN_KEY);
  return !token ? null : token;
}

export function getUser() {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    return null;
  }
  try {
    const decodedToken = decodeJwt(token);
    return {
      email: decodedToken.email,
      organization_id: decodedToken.organization_id ? decodedToken.organization_id : null,
      onboarded: decodedToken.onboarded,
      c_token: decodedToken.c_token,
      profile_id: decodedToken.profile_id,
      profile_type: decodedToken.profile_type
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function setUser({ token }) {
  localStorage.setItem(TOKEN_KEY, token);
}

export async function logout() {
  let res = await httpDelete("/delete-fcm-token");
  localStorage.removeItem(TOKEN_KEY);
  window.location.reload();
}
