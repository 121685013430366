import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import commonStyles from "../../../commonStyles";
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Input, Radio, RadioGroup, Tooltip } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from "react-redux";
import { getCountries, getLegalForms, getOwnershipBeneficialRights } from "../../../redux-reducer/data/dataSlice";
import { countryToFlag, isArabic, hasFieldError, isEditStepper, getFieldError, individualDocuments } from "../../../utils";
import HelpIcon from '@material-ui/icons/Help';
import CustomSlider from "../../common-components/CustomSlider";
import DatePicker from "../../common-components/DatePicker";
import { FieldArray } from "formik";
import Snackbar from "@material-ui/core/Snackbar";
import CorporateIdentificationDocument from "../../common-components/CorporateIdentificationDocument";

const useStyles = makeStyles((theme) => ({
    avatarCustomized: {
        height: "7.2rem",
        width: "7.2rem",
        border: "solid 1px",
        borderColor: theme.palette.secondary.main,
        borderRadius: "3px",
    },
    dropzone: {
        fontSize: "0.8rem",
        marginTop: "1.8rem",
    },

    input: {
        marginTop: "1rem"
    },

    inputContainer: {
        alignItems: "center"
    },

    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    deleteIcon: {
        fontSize: '1rem'
    },

    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    
    ...commonStyles,
}));

export default function OwnershipStructure(props) {
    const formikProps = props.formikProps;

    const {
        formField: {
            beneficialOwnership,
            beneficialParties,
            owners
        }
    } = props;   
    const classes = useStyles();
    const { t } = useTranslation();

    const countries = useSelector(getCountries);

    const beneficialRight = useSelector(getOwnershipBeneficialRights);

    const legalForms = useSelector(getLegalForms);

    const [percentages, setPercentage] = useState(props.organizationDetails.owners.map((owner) => owner.percentage_of_ownership));

    const isMinorityOrThird = (id) => {
        for(var i=0; i < beneficialRight.length; i++){
            if(id == beneficialRight[i].id && (beneficialRight[i].field == "Third Parties" || beneficialRight[i].field == "Minority Shareholders")){          
                return true;
            }
        }
        return false;
    }
    
    const handleBenificialChange = (event) => {
        if(!isMinorityOrThird(event.target.value))
            formikProps.setFieldValue(beneficialParties.name, null)
        
        formikProps.setFieldValue(event.target.name, event.target.value)
        props.setEdit(true);
    }
    
    const handlePercentageChange = (index) => (event, newValue) => {
        var newPercentages = percentages;
        
        newPercentages[index] = newValue;
        
        setPercentage(newPercentages);

        formikProps.setFieldValue(`[${owners.name}][${index}][${owners.percentage.name}]`, newValue)

        props.setEdit(true);
    }

    console.log("ben: ", formikProps.values)

    const handleInputPercentageChange = (index) => (event) => {
        var newPercentage = event.target.value

        if(newPercentage < 0.1)
            newPercentage = 0.1
        if(newPercentage > 100)
            newPercentage = 100

        var newPercentages = percentages;

        newPercentages[index] = newPercentage;

        setPercentage(newPercentages);

        formikProps.setFieldValue(event.target.name, newPercentage)

        props.setEdit(true);
    }

    const handleTypeChange = (index, replace) => (event) => {
        if(event.target.value == owners.type.options.individual.name){
             replace(index, {
                [owners.type.name]: owners.type.options.individual.name,
                [owners.firstName.name]: null,
                [owners.lastName.name]: null,
                [owners.fatherName.name]: null,
                [owners.doBirth.name]: null,
                [owners.percentage.name]: 0.1,
                [owners.nationality.name]: [],
                [owners.residence.name]: [],
                [owners.documentType.name]: individualDocuments.NationalID.name,
                [owners.documentNumber.name]: "",
                [owners.documentDate.name]: null,
                document_content: {
                    [individualDocuments.IdFrontSide.name]: null,
                    [individualDocuments.IdBackSide.name]: null
                },
            })
        }

        if(event.target.value == owners.type.options.entity.name){
            replace(index, {
                [owners.type.name]: owners.type.options.entity.name,
                [owners.entityName.name]: null,
                [owners.legalForm.name]: null,
                [owners.shareholders.name]: [
                    {
                        [owners.type.name]: owners.type.options.individual.name,
                        [owners.firstName.name]: '',
                        [owners.lastName.name]: '',
                    }
                ],
                [owners.representatives.name]: [
                    {
                        [owners.firstName.name]: null,
                        [owners.lastName.name]: null,
                    }
                ],
                [owners.percentage.name]: 0.1
            })
        }

        var newPercentages = percentages;

        newPercentages[index] = 0.1;

        setPercentage(newPercentages);

        props.setEdit(true);
    }

    const handleAddOwner = (push) => {
        push(
            {
                [owners.type.name]: owners.type.options.individual.name,
                [owners.firstName.name]: null,
                [owners.lastName.name]: null,
                [owners.fatherName.name]: null,
                [owners.doBirth.name]: null,
                [owners.percentage.name]: 0.1,
                [owners.nationality.name]: [],
                [owners.residence.name]: [], 
                [owners.documentType.name]: individualDocuments.NationalID.name,
                [owners.documentNumber.name]: "",
                [owners.documentDate.name]: null,
                document_content: {
                    [individualDocuments.IdFrontSide.name]: null,
                    [individualDocuments.IdBackSide.name]: null
                },
            }
        )

        var newPercentages = percentages;

        newPercentages.push(0.1);

        setPercentage(newPercentages);

        props.setEdit(true);
    };

    const handleRemoveOwner = (index, remove) => {
        remove(index);

        var newPercentages = percentages;

        newPercentages.push(0.1);

        setPercentage(newPercentages);

        props.setEdit(true);
    };

    const handleEntityShareholderTypeChange = (index, replace) => (event) => {
        if(event.target.value == owners.type.options.individual.name){
             replace(index, {
                [owners.type.name]: owners.type.options.individual.name,
                [owners.firstName.name]: '',
                [owners.lastName.name]: ''
            })
        }

        if(event.target.value == owners.type.options.entity.name){
            replace(index, {
                [owners.type.name]: owners.type.options.entity.name,
                [owners.entityName.name]: ''
            })
        }

        props.setEdit(true);
    }

    const handleDocumentTypeChange = (replace, index) => (e) => {   
        let document_content = {
            [individualDocuments.IdFrontSide.name]: null,
            [individualDocuments.IdBackSide.name]: null
        }
        if(e.target.value == individualDocuments.Passport.name){
            document_content = {
                [individualDocuments.Passport.name]: null,
            }     
        }
        replace(index, {
            ...formikProps.values[owners.name][index],
            [owners.documentType.name]: e.target.value,
            [owners.documentNumber.name]: "",
            [owners.documentDate.name]: null,
            document_content: document_content 
        });

        props.setEdit(true);
    }

    return (
        <React.Fragment>
            <Grid container md={12} xs={12} direction="column">
                <Grid item md={12} xs={12}>
                    <Typography variant="h4" style={{ lineHeight: 1.33, textAlign: "center" }}>
                        {t("stepper.kyb.step4")}
                    </Typography>
                </Grid>

                {
                    isEditStepper() && hasFieldError(props.organizationDetails, owners.name) &&
                    <React.Fragment>
                        <Box mt={5} /> 
                        <Grid item md={12} xs={12}>
                            <Typography variant="body2" color="error" style={{ textAlign: "center" }}>
                                {getFieldError(props.organizationDetails, owners.name)}
                            </Typography>
                        </Grid>
                    </React.Fragment>
                    
                }
                
                <Box mt={5} /> 

                {               
                    <React.Fragment>
                        <Grid item md={12} xs={12}>
                            <FormControl 
                                required 
                                component="fieldset"
                                error={(formikProps.touched[beneficialOwnership.name] && Boolean(formikProps.errors[beneficialOwnership.name])) || (isEditStepper() && formikProps.values[beneficialOwnership.name] == props.organizationDetails[beneficialOwnership.name] && hasFieldError(props.organizationDetails, beneficialOwnership.name))}
                            >
                                <Grid container direction="row" md={12} xs={12} className={classes.inputContainer}>
                                    <Grid item>
                                        <FormLabel component="legend">
                                            {beneficialOwnership.label}
                                        </FormLabel>
                                    </Grid>
                                    <Box mr={1} />
                                    <Grid item>
                                        <Tooltip title={t("ownership-step.beneficial-desc")}>
                                            <HelpIcon color="secondary" fontSize= "small" />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Box mt={1} />
                                <RadioGroup 
                                    row 
                                    name={beneficialOwnership.name}
                                    value={formikProps.values[beneficialOwnership.name]} 
                                    onChange={handleBenificialChange} 
                                    onBlur={formikProps.handleBlur(beneficialOwnership.name)} 
                                >
                                    {
                                        beneficialRight.map((right, index) => {
                                            return (
                                                <FormControlLabel
                                                    value={right.id} 
                                                    control={<Radio checked={formikProps.values[beneficialOwnership.name] == right.id}/>}
                                                    label={isArabic() ? right.field_arabic : right.field}
                                                /> 
                                            )
                                        })                                         
                                    }
                                </RadioGroup>
                                <FormHelperText>{(formikProps.touched[beneficialOwnership.name] && formikProps.errors[beneficialOwnership.name]) || (formikProps.values[beneficialOwnership.name] == props.organizationDetails[beneficialOwnership.name] && getFieldError(props.organizationDetails, beneficialOwnership.name))}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {
                            formikProps.values[beneficialOwnership.name]  &&
                                isMinorityOrThird(formikProps.values[beneficialOwnership.name]) &&
                                <Grid item>
                                    <Box mt={2} />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        name={beneficialParties.name}
                                        label={beneficialParties.label}
                                        value={formikProps.values[beneficialParties.name]}
                                        onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                        onBlur={formikProps.handleBlur(beneficialParties.name)} 
                                        error={(formikProps.touched[beneficialParties.name] && Boolean(formikProps.errors[beneficialParties.name])) || (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))}
                                        helperText={(formikProps.touched[beneficialParties.name] && formikProps.errors[beneficialParties.name]) || (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))}
                                    />
                                </Grid>  
                        }
                    </React.Fragment>
                }

                <Box mt={3} /> 

                <FieldArray
                    name={owners.name}
                    render={({ remove, push, replace }) => (
                        <React.Fragment>
                            {
                                formikProps.values[owners.name].map((owner, index) => {
                                    if(owner[owners.type.name] === owners.type.options.individual.name)
                                        return (
                                            <React.Fragment>
                                                <Grid container direction="row" spacing={1} alignItems="center" style={{justifyContent: "space-between"}}>
                                                    <Grid item style={{display: "inline-flex", alignItems: "center"}}>
                                                        <Typography variant="h5">
                                                            {owners.label} #{index + 1}
                                                        </Typography>
                                                        {index != 0  &&
                                                            <IconButton onClick={() => handleRemoveOwner(index, remove)}>
                                                                <DeleteIcon color="error" className={classes.deleteIcon}/>
                                                            </IconButton>
                                                        }
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControl required component="fieldset">
                                                            <Box mt={1} />
                                                            <RadioGroup row value={owner[owners.type.name]} onChange={handleTypeChange(index, replace)} name={owners.type.name}>
                                                                <FormControlLabel
                                                                    value={owners.type.options.individual.name}
                                                                    control={<Radio 
                                                                        required 
                                                                        checked={owner[owners.type.name] === owners.type.options.individual.name}/>}
                                                                    label={owners.type.options.individual.label}
                                                                /> 
                                                                <FormControlLabel
                                                                    value={owners.type.options.entity.name}
                                                                    control={<Radio 
                                                                        required
                                                                        checked={owner[owners.type.name] === owners.type.options.entity.name}/>}
                                                                    label={owners.type.options.entity.label}
                                                                /> 
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>       
                            
                                                <Grid container direction="row" md={12} xs={12} >
                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <Typography variant="body2" gutterBottom>{owners.percentage.label}</Typography>
                                                        <Grid container direction="row" md={12} xs={12}>
                                                            <Grid item md={9} xs={9}>
                                                                <CustomSlider                           
                                                                    min={0.1} 
                                                                    max={100} 
                                                                    step={0.1}  
                                                                    value={owner[owners.percentage.name]} 
                                                                    onChange={handlePercentageChange(index)}
                                                                    fullWidth
                                                                />
                                                            </Grid>

                                                            <Grid item md={1} xs={1} /> 

                                                            <Grid item md={2} xs={2}> 
                                                                <Input
                                                                    fullWidth
                                                                    value={owner[owners.percentage.name]}
                                                                    onChange={handleInputPercentageChange(index)}
                                                                    name={`[${owners.name}][${index}][${owners.percentage.name}]`}
                                                                    inputProps={{
                                                                        step: 0.1,
                                                                        min: 0.1,
                                                                        max: 100,
                                                                        type: 'number'
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            (
                                                                (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.percentage.name] && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && Boolean(formikProps.errors[owners.name][index][owners.percentage.name])) 
                                                                //|| (isEditStepper() && formikProps.values[beneficialOwnership.name] == props.organizationDetails[beneficialOwnership.name] && hasFieldError(props.organizationDetails, beneficialOwnership.name))
                                                            )
                                                            &&
                                                            <Grid item md={12} xs={12}>
                                                                <Typography variant="caption" color="error">
                                                                    {
                                                                        formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.percentage.name]  && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && formikProps.errors[owners.name][index][owners.percentage.name]
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>  

                                                    <Grid item md={7} xs={12} /> 

                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            name={`[${owners.name}][${index}][${owners.firstName.name}]`}
                                                            label={owners.firstName.label}
                                                            value={owner[owners.firstName.name]}
                                                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                            onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.firstName.name}]`)} 
                                                            error={
                                                                (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.firstName.name] && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && Boolean(formikProps.errors[owners.name][index][owners.firstName.name])) 
                                                                //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                            helperText={
                                                                (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.firstName.name]  && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && formikProps.errors[owners.name][index][owners.firstName.name]) 
                                                                //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                        />
                                                    </Grid>
                                    
                                                    <Grid item md={2} xs={12} />
                                    
                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            name={`[${owners.name}][${index}][${owners.lastName.name}]`}
                                                            label={owners.lastName.label}
                                                            value={owner[owners.lastName.name]}
                                                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                            onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.lastName.name}]`)} 
                                                            error={
                                                                (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.lastName.name] && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && Boolean(formikProps.errors[owners.name][index][owners.lastName.name])) 
                                                                //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                            helperText={
                                                                (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.lastName.name]  && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && formikProps.errors[owners.name][index][owners.lastName.name]) 
                                                                //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                        />
                                                    </Grid>
                                                    
                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            name={`[${owners.name}][${index}][${owners.fatherName.name}]`}
                                                            label={owners.fatherName.label}
                                                            value={owner[owners.fatherName.name]}
                                                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                            onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.fatherName.name}]`)} 
                                                            error={
                                                                (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.fatherName.name] && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && Boolean(formikProps.errors[owners.name][index][owners.fatherName.name])) 
                                                                //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                            helperText={
                                                                (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.fatherName.name]  && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && formikProps.errors[owners.name][index][owners.fatherName.name]) 
                                                                //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                        />
                                                    </Grid>
                                    
                                                    <Grid item md={2} xs={12} />
                                    
                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <Autocomplete
                                                            multiple
                                                            filterSelectedOptions
                                                            options={countries}
                                                            value={props.getOptionById(countries, owner[owners.residence.name])}
                                                            onChange={props.setMultipleOptionsAttribute(formikProps.setFieldValue, `[${owners.name}][${index}][${owners.residence.name}]`)}
                                                            getOptionLabel={
                                                                (option) => isArabic() ? countryToFlag(option.code) + " " + option.name_arabic : countryToFlag(option.code) + " " + option.name 
                                                            }
                                                            renderOption={(option) => (
                                                                <React.Fragment>
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                        style={{marginRight: '1rem'}}
                                                                    />
                                                                    {isArabic() ? option.name_arabic : option.name} 
                                                                </React.Fragment>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    name={`[${owners.name}][${index}][${owners.residence.name}]`}
                                                                    label={owners.residence.label} 
                                                                    onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.residence.name}]`)} 
                                                                    error={
                                                                        (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.residence.name] && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && Boolean(formikProps.errors[owners.name][index][owners.residence.name])) 
                                                                        //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                                    }
                                                                    helperText={
                                                                        (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.residence.name]  && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && formikProps.errors[owners.name][index][owners.residence.name]) 
                                                                        //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        
                                                    </Grid>

                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <Autocomplete
                                                            multiple
                                                            options={countries}
                                                            filterSelectedOptions
                                                            value={props.getOptionById(countries, owner[owners.nationality.name])}
                                                            onChange={props.setMultipleOptionsAttribute(formikProps.setFieldValue, `[${owners.name}][${index}][${owners.nationality.name}]`)}
                                                            getOptionLabel={
                                                                (option) => isArabic() ? countryToFlag(option.code) + " " + option.nationality_arabic : countryToFlag(option.code) + " " + option.nationality_en 
                                                            }
                                                            renderOption={(option) => (
                                                                <React.Fragment>
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                        style={{marginRight: '1rem'}}
                                                                    />
                                                                    {isArabic() ? option.nationality_arabic : option.nationality_en} 
                                                                </React.Fragment>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    name={`[${owners.name}][${index}][${owners.nationality.name}]`}
                                                                    label={owners.nationality.label}
                                                                    onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.nationality.name}]`)} 
                                                                    error={
                                                                        (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.nationality.name] && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && Boolean(formikProps.errors[owners.name][index][owners.nationality.name])) 
                                                                        //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                                    }
                                                                    helperText={
                                                                        (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.nationality.name]  && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && formikProps.errors[owners.name][index][owners.nationality.name]) 
                                                                        //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item md={2} xs={12} />
                                    
                                                    <Grid container direction="row" md={5} xs={12} className={classes.input}>
                                                        <Grid item md={12} xs={12}>
                                                            <Typography variant="h6">{owners.doBirth.label}</Typography>
                                                        </Grid>
                                                        <DatePicker
                                                            value={owner[owners.doBirth.name]}
                                                            handleDateChange={value => { formikProps.setFieldValue(`[${owners.name}][${index}][${owners.doBirth.name}]`, value); props.setEdit(true) }}
                                                            InputProps={{ 
                                                                readOnly: true,
                                                                name: `[${owners.name}][${index}][${owners.doBirth.name}]`,
                                                                error:
                                                                    (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.doBirth.name] && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && Boolean(formikProps.errors[owners.name][index][owners.doBirth.name])), 
                                                                    //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                                
                                                                helperText:
                                                                    (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.doBirth.name]  && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && formikProps.errors[owners.name][index][owners.doBirth.name]), 
                                                                    //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                            }}
                                                            disableFuture={true}
                                                            maxDate={new Date()}
                                                        />
                                                    </Grid> 

                                                    <CorporateIdentificationDocument
                                                        field={owner}
                                                        arrayField={owners}
                                                        index={index}
                                                        classes={classes}
                                                        formikProps={formikProps}
                                                        onChange={props.setOrganizationAttribute(formikProps.setFieldValue)}
                                                        setEdit={props.setEdit}
                                                        handleDocumentTypeChange={handleDocumentTypeChange(replace, index)}
                                                        otherProps={props}
                                                    />

                                                </Grid> 
                                                <Box mt={2} />
                                            </React.Fragment>
                                        )

                                    if(owner[owners.type.name] === owners.type.options.entity.name)
                                        return (
                                            <React.Fragment>
                                                <Grid container direction="row" spacing={1} alignItems="center" style={{justifyContent: "space-between"}}>
                                                    <Grid item style={{display: "inline-flex", alignItems: "center"}}>
                                                        <Typography variant="h5">
                                                            {owners.label} #{index + 1}
                                                        </Typography>
                                                        {index != 0  &&
                                                            <IconButton onClick={() => remove(index)}>
                                                                <DeleteIcon color="error" className={classes.deleteIcon}/>
                                                            </IconButton>
                                                        }
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControl required component="fieldset">
                                                            <Box mt={1} />
                                                            <RadioGroup row value={owner[owners.type.name]} onChange={handleTypeChange(index, replace)} name={owners.type.name}>
                                                                <FormControlLabel
                                                                    value={owners.type.options.individual.name}
                                                                    control={<Radio 
                                                                        required 
                                                                        checked={owner[owners.type.name] === owners.type.options.individual.name}/>}
                                                                    label={owners.type.options.individual.label}
                                                                /> 
                                                                <FormControlLabel
                                                                    value={owners.type.options.entity.name}
                                                                    control={<Radio 
                                                                        required 
                                                                        checked={owner[owners.type.name] === owners.type.options.entity.name}/>}
                                                                    label={owners.type.options.entity.label}
                                                                /> 
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>      
                            
                                                <Grid container direction="row" md={12} xs={12} >
                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <Typography variant="body2" gutterBottom>{owners.percentage.label}</Typography>
                                                        <Grid container direction="row" md={12} xs={12}>
                                                            <Grid item md={9} xs={9}>
                                                                <CustomSlider                           
                                                                    min={0.1} 
                                                                    max={100} 
                                                                    step={0.1}  
                                                                    value={owner[owners.percentage.name]} 
                                                                    onChange={handlePercentageChange(index)}
                                                                    fullWidth
                                                                />
                                                            </Grid>

                                                            <Grid item md={1} xs={1} /> 

                                                            <Grid item md={2} xs={2}> 
                                                                <Input
                                                                    fullWidth
                                                                    value={owner[owners.percentage.name]}
                                                                    onChange={handleInputPercentageChange(index)}
                                                                    name={`[${owners.name}][${index}][${owners.percentage.name}]`}
                                                                    inputProps={{
                                                                        step: 0.1,
                                                                        min: 0.1,
                                                                        max: 100,
                                                                        type: 'number'
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            (
                                                                (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.percentage.name] && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && Boolean(formikProps.errors[owners.name][index][owners.percentage.name])) 
                                                                //|| (isEditStepper() && formikProps.values[beneficialOwnership.name] == props.organizationDetails[beneficialOwnership.name] && hasFieldError(props.organizationDetails, beneficialOwnership.name))
                                                            )
                                                            &&
                                                            <Grid item md={12} xs={12}>
                                                                <Typography variant="caption" color="error">
                                                                    {
                                                                        formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.percentage.name]  && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && formikProps.errors[owners.name][index][owners.percentage.name]
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>  

                                                    <Grid item md={7} xs={12} /> 

                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            name={`[${owners.name}][${index}][${owners.entityName.name}]`}
                                                            label={owners.entityName.label}
                                                            value={owner[owners.entityName.name]}
                                                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                            onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.entityName.name}]`)} 
                                                            error={
                                                                (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.entityName.name] && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && Boolean(formikProps.errors[owners.name][index][owners.entityName.name])) 
                                                                //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                            helperText={
                                                                (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.entityName.name]  && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && formikProps.errors[owners.name][index][owners.entityName.name]) 
                                                                //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                            } 
                                                        />
                                                    </Grid>
                                    
                                                    <Grid item md={2} xs={12} />                
                                                    
                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <Autocomplete
                                                            options={legalForms}
                                                            getOptionLabel={(option) => isArabic() ? option.name_arabic : option.name}
                                                            onChange={props.setSingleOptionAttribute(formikProps.setFieldValue, `[${owners.name}][${index}][${owners.legalForm.name}]`)}
                                                            value={legalForms.find((legalForm) => legalForm.id === owner[owners.legalForm.name])}
                                                            renderOption={(option) => isArabic() ? option.name_arabic : option.name}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    name={`[${owners.name}][${index}][${owners.legalForm.name}]`}
                                                                    label={owners.legalForm.label}
                                                                    onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.legalForm.name}]`)} 
                                                                    error={
                                                                        (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.legalForm.name] && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && Boolean(formikProps.errors[owners.name][index][owners.legalForm.name])) 
                                                                        //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                                    }
                                                                    helperText={
                                                                        (formikProps.touched[owners.name] && formikProps.touched[owners.name][index] && formikProps.touched[owners.name][index][owners.legalForm.name]  && formikProps.errors[owners.name] && formikProps.errors[owners.name][index] && formikProps.errors[owners.name][index][owners.legalForm.name]) 
                                                                        //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                                    } 
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                    
                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <FieldArray 
                                                            name={`[${owners.name}][${index}][${owners.shareholders.name}]`}
                                                            render={({ remove, push, replace }) => (
                                                                <React.Fragment>
                                                                    <Grid container md={12} xs={12} direction="row" style={{display: "inline-flex", alignItems: "center"}}>
                                                                        <Typography variant="h6">{owners.shareholders.label}</Typography>
                                                                        <IconButton 
                                                                            onClick={() => push({
                                                                                [owners.type.name]: owners.type.options.individual.name, 
                                                                                [owners.firstName.name]: '', 
                                                                                [owners.lastName.name]: ''
                                                                            })}
                                                                        >
                                                                            <AddIcon color="primary"/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                    {
                                                                        owner[owners.shareholders.name].map((shareholder, idx) => {
                                                                            return (
                                                                            <React.Fragment>
                                                                                <Box mt={1} />
                                                                                <Grid container md={12} xs={12} direction="row" style={{display: "inline-flex", alignItems: "center"}}>
                                                                                    <Typography variant="body2">
                                                                                        #{idx + 1}
                                                                                    </Typography>
                                                                                    {idx != 0  &&
                                                                                        <IconButton onClick={() => remove(idx)}>
                                                                                            <DeleteIcon color="error" className={classes.deleteIcon}/>
                                                                                        </IconButton>
                                                                                    }
                                                                                </Grid>

                                                                                {
                                                                                    (shareholder[owners.type.name] === owners.type.options.individual.name) && 
                                                                                    (
                                                                                    <Grid container md={12} xs={12} direction="row" >
                                                                                        <Grid item md={12} xs={12} className={classes.input}>
                                                                                            <FormControl required component="fieldset">
                                                                                                <Box mt={1} />
                                                                                                <RadioGroup row value={shareholder[owners.type.name]} onChange={handleEntityShareholderTypeChange(idx, replace)} name={owners.type.name}>
                                                                                                    <FormControlLabel
                                                                                                        value={owners.type.options.individual.name}
                                                                                                        control={<Radio 
                                                                                                            required 
                                                                                                            checked={shareholder[owners.type.name] === owners.type.options.individual.name}/>}
                                                                                                        label={owners.type.options.individual.label}
                                                                                                    /> 
                                                                                                    <FormControlLabel
                                                                                                        value={owners.type.options.entity.name}
                                                                                                        control={<Radio 
                                                                                                            required 
                                                                                                            checked={shareholder[owners.type.name] === owners.type.options.entity.name}/>}
                                                                                                        label={owners.type.options.entity.label}
                                                                                                    /> 
                                                                                                </RadioGroup>
                                                                                            </FormControl>
                                                                                        </Grid>
                                                                                        <Grid item md={6} xs={12} className={classes.input} style={{paddingRight: ".5rem"}}>
                                                                                            <TextField
                                                                                                variant="outlined"
                                                                                                fullWidth
                                                                                                name={`[${owners.name}][${index}][${owners.shareholders.name}][${idx}][${owners.firstName.name}]`}
                                                                                                label={owners.firstName.label}
                                                                                                value={shareholder[owners.firstName.name]}
                                                                                                onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                                                                onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.shareholders.name}][${idx}][${owners.firstName.name}]`)} 
                                                                                                error={
                                                                                                    (formikProps.touched[owners.name] && 
                                                                                                    formikProps.touched[owners.name][index] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx][owners.firstName.name] && 
                                                                                                    formikProps.errors[owners.name] && 
                                                                                                    formikProps.errors[owners.name][index] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    Boolean(formikProps.errors[owners.name][index][owners.shareholders.name][idx][owners.firstName.name])) 
                                                                                                    //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                                                                }
                                                                                                helperText={
                                                                                                    (formikProps.touched[owners.name] && 
                                                                                                    formikProps.touched[owners.name][index] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx][owners.firstName.name] && 
                                                                                                    formikProps.errors[owners.name] && 
                                                                                                    formikProps.errors[owners.name][index] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name]) && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name][idx][owners.firstName.name]
                                                                                                    //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                                                                } 
                                                                                            />
                                                                                        </Grid>

                                                                                        <Grid item md={6} xs={12} className={classes.input} style={{paddingLeft: ".5rem"}}>
                                                                                            <TextField
                                                                                                variant="outlined"
                                                                                                fullWidth
                                                                                                name={`[${owners.name}][${index}][${owners.shareholders.name}][${idx}][${owners.lastName.name}]`}
                                                                                                label={owners.lastName.label}
                                                                                                value={shareholder[owners.lastName.name]}
                                                                                                onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                                                                onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.shareholders.name}][${idx}][${owners.lastName.name}]`)} 
                                                                                                error={
                                                                                                    (formikProps.touched[owners.name] && 
                                                                                                    formikProps.touched[owners.name][index] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx][owners.lastName.name] && 
                                                                                                    formikProps.errors[owners.name] && 
                                                                                                    formikProps.errors[owners.name][index] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    Boolean(formikProps.errors[owners.name][index][owners.shareholders.name][idx][owners.lastName.name])) 
                                                                                                    //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                                                                }
                                                                                                helperText={
                                                                                                    (formikProps.touched[owners.name] && 
                                                                                                    formikProps.touched[owners.name][index] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx][owners.lastName.name] && 
                                                                                                    formikProps.errors[owners.name] && 
                                                                                                    formikProps.errors[owners.name][index] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name]) && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name][idx][owners.lastName.name]
                                                                                                    //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                                                                } 
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    )
                                                                                }


                                                                                {
                                                                                    (shareholder[owners.type.name] === owners.type.options.entity.name) && 
                                                                                    (
                                                                                    <Grid container md={12} xs={12}>
                                                                                        <Grid item md={12} xs={12} className={classes.input}>
                                                                                            <FormControl required component="fieldset">
                                                                                                <Box mt={1} />
                                                                                                <RadioGroup row value={shareholder[owners.type.name]} onChange={handleEntityShareholderTypeChange(idx, replace)} name={owners.type.name}>
                                                                                                    <FormControlLabel
                                                                                                        value={owners.type.options.individual.name}
                                                                                                        control={<Radio 
                                                                                                            required 
                                                                                                            checked={shareholder[owners.type.name] === owners.type.options.individual.name}/>}
                                                                                                        label={owners.type.options.individual.label}
                                                                                                    /> 
                                                                                                    <FormControlLabel
                                                                                                        value={owners.type.options.entity.name}
                                                                                                        control={<Radio 
                                                                                                            required 
                                                                                                            checked={shareholder[owners.type.name] === owners.type.options.entity.name}/>}
                                                                                                        label={owners.type.options.entity.label}
                                                                                                    /> 
                                                                                                </RadioGroup>
                                                                                            </FormControl>
                                                                                        </Grid>
                                                                                        <Grid item md={12} xs={12} className={classes.input} style={{paddingRight: ".5rem"}}>
                                                                                            <TextField
                                                                                                variant="outlined"
                                                                                                fullWidth
                                                                                                name={`[${owners.name}][${index}][${owners.shareholders.name}][${idx}][${owners.entityName.name}]`}
                                                                                                label={owners.entityName.label}
                                                                                                value={shareholder[owners.entityName.name]}
                                                                                                onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                                                                onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.shareholders.name}][${idx}][${owners.entityName.name}]`)} 
                                                                                                error={
                                                                                                    (formikProps.touched[owners.name] && 
                                                                                                    formikProps.touched[owners.name][index] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx][owners.entityName.name] && 
                                                                                                    formikProps.errors[owners.name] && 
                                                                                                    formikProps.errors[owners.name][index] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    Boolean(formikProps.errors[owners.name][index][owners.shareholders.name][idx][owners.entityName.name])) 
                                                                                                    //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                                                                }
                                                                                                helperText={
                                                                                                    (formikProps.touched[owners.name] && 
                                                                                                    formikProps.touched[owners.name][index] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    formikProps.touched[owners.name][index][owners.shareholders.name][idx][owners.entityName.name] && 
                                                                                                    formikProps.errors[owners.name] && 
                                                                                                    formikProps.errors[owners.name][index] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name]) && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name][idx] && 
                                                                                                    formikProps.errors[owners.name][index][owners.shareholders.name][idx][owners.entityName.name]
                                                                                                    //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                                                                } 
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    )
                                                                                }
                                                                            </React.Fragment>
                                                                        )})
                                                                    }
                                                                </React.Fragment>
                                                            )}
                                                        />
                                                    </Grid>   

                                                    <Grid item md={2} xs={12} />         
                                    
                                                    <Grid item md={5} xs={12} className={classes.input}>
                                                        <FieldArray 
                                                            name={`[${owners.name}][${index}][${owners.representatives.name}]`}
                                                            render={({ remove, push }) => (
                                                                <React.Fragment>
                                                                    <Grid container md={12} xs={12} direction="row" style={{display: "inline-flex", alignItems: "center"}}>
                                                                        <Typography variant="h6">{owners.representatives.label}</Typography>
                                                                        <IconButton onClick={() => push({[owners.firstName.name]: null, [owners.lastName.name]: null,})}>
                                                                            <AddIcon color="primary"/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                    {
                                                                        owner[owners.representatives.name].map((owner, idx) => {
                                                                            return (
                                                                            <React.Fragment>
                                                                                <Box mt={1} />
                                                                                <Grid container md={12} xs={12} direction="row" style={{display: "inline-flex", alignItems: "center"}}>
                                                                                    <Typography variant="body2">
                                                                                        #{idx + 1}
                                                                                    </Typography>
                                                                                    {idx != 0  &&
                                                                                        <IconButton onClick={() => remove(idx)}>
                                                                                            <DeleteIcon color="error" className={classes.deleteIcon}/>
                                                                                        </IconButton>
                                                                                    }
                                                                                </Grid>

                                                                                <Grid container md={12} xs={12} direction="row" >
                                                                                    <Grid item md={6} xs={12} className={classes.input} style={{paddingRight: ".5rem"}}>
                                                                                        <TextField
                                                                                            variant="outlined"
                                                                                            fullWidth
                                                                                            name={`[${owners.name}][${index}][${owners.representatives.name}][${idx}][${owners.firstName.name}]`}
                                                                                            label={owners.firstName.label}
                                                                                            value={owner[owners.firstName.name]}
                                                                                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                                                            onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.representatives.name}][${idx}][${owners.firstName.name}]`)} 
                                                                                            error={
                                                                                                (formikProps.touched[owners.name] && 
                                                                                                formikProps.touched[owners.name][index] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name][idx] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name][idx][owners.firstName.name] && 
                                                                                                formikProps.errors[owners.name] && 
                                                                                                formikProps.errors[owners.name][index] && 
                                                                                                formikProps.errors[owners.name][index][owners.representatives.name] && 
                                                                                                formikProps.errors[owners.name][index][owners.representatives.name][idx] && 
                                                                                                Boolean(formikProps.errors[owners.name][index][owners.representatives.name][idx][owners.firstName.name])) 
                                                                                                //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                                                            }
                                                                                            helperText={
                                                                                                (formikProps.touched[owners.name] && 
                                                                                                formikProps.touched[owners.name][index] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name][idx] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name][idx][owners.firstName.name] && 
                                                                                                formikProps.errors[owners.name] && 
                                                                                                formikProps.errors[owners.name][index] && 
                                                                                                formikProps.errors[owners.name][index][owners.representatives.name]) && 
                                                                                                formikProps.errors[owners.name][index][owners.representatives.name][idx] && 
                                                                                                formikProps.errors[owners.name][index][owners.representatives.name][idx][owners.firstName.name]
                                                                                                //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                                                            } 
                                                                                        />
                                                                                    </Grid>

                                                                                    <Grid item md={6} xs={12} className={classes.input} style={{paddingLeft: ".5rem"}}>
                                                                                        <TextField
                                                                                            variant="outlined"
                                                                                            fullWidth
                                                                                            name={`[${owners.name}][${index}][${owners.representatives.name}][${idx}][${owners.lastName.name}]`}
                                                                                            label={owners.lastName.label}
                                                                                            value={owner[owners.lastName.name]}
                                                                                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                                                            onBlur={formikProps.handleBlur(`[${owners.name}][${index}][${owners.representatives.name}][${idx}][${owners.lastName.name}]`)} 
                                                                                            error={
                                                                                                (formikProps.touched[owners.name] && 
                                                                                                formikProps.touched[owners.name][index] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name][idx] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name][idx][owners.lastName.name] && 
                                                                                                formikProps.errors[owners.name] && 
                                                                                                formikProps.errors[owners.name][index] && 
                                                                                                formikProps.errors[owners.name][index][owners.representatives.name] && 
                                                                                                formikProps.errors[owners.name][index][owners.representatives.name][idx] && 
                                                                                                Boolean(formikProps.errors[owners.name][index][owners.representatives.name][idx][owners.lastName.name])) 
                                                                                                //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                                                            }
                                                                                            helperText={
                                                                                                (formikProps.touched[owners.name] && 
                                                                                                formikProps.touched[owners.name][index] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name][idx] && 
                                                                                                formikProps.touched[owners.name][index][owners.representatives.name][idx][owners.lastName.name] && 
                                                                                                formikProps.errors[owners.name] && 
                                                                                                formikProps.errors[owners.name][index] && 
                                                                                                formikProps.errors[owners.name][index][owners.representatives.name]) && 
                                                                                                formikProps.errors[owners.name][index][owners.representatives.name][idx] && 
                                                                                                formikProps.errors[owners.name][index][owners.representatives.name][idx][owners.lastName.name]
                                                                                                //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                                                            } 
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        )})
                                                                    }
                                                                </React.Fragment>
                                                            )}
                                                        />
                                                    </Grid>     
                                                </Grid> 
                                                <Box mt={2} />
                                            </React.Fragment>
                                        )
                                })
                            }
                            
                            <Box mt={1} />

                            <Grid item style={{alignSelf:"center"}}>
                                <Button variant="contained" color="secondary" onClick={() => handleAddOwner(push)}>{t("button.add-owner")}</Button> 
                            </Grid>

                            <Box mt={3} />

                        </React.Fragment>
                    )}
                />
            </Grid>
            <Snackbar
                open={props.openSnackBar}
                autoHideDuration={1800}
                message={props.snackMessage}
                onClose={() => props.setOpenSnackBar(false)}
                ContentProps={{ classes: { root: classes.error } }}
            />
        </React.Fragment>
    );
}
