import { Grid, makeStyles, Typography } from "@material-ui/core";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import DoneIcon from "@material-ui/icons/Done";
import GroupIcon from "@material-ui/icons/Group";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";
import { useHistory } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import { httpPut } from "../../httpcommon";
import { getUser } from "../../services/authService";
import { getDifferenceDays, parseServerDate } from "../../utils";
import "./css-homepage/notificationlist.css";

const useStyles = makeStyles((theme) => ({
  notificationlistsettings: {
    "&:hover": {
      backgroundColor: "rgba(37, 218, 197, 0.1)",
    },
    cursor: "pointer",
    marginTop: "1px",
  },
  readNotificationsettings: {
    color: "rgba(0, 0, 0, 0.2)",
    marginLeft: "8px",
  },
  unreadNotificationsettings: {
    color: "black",
    marginLeft: "8px",
  },
  notificationTimeSettings: {
    fontSize: "0.85rem",
  },
}));

export default function NotificationList({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading,

  // Array of items loaded so far.
  items,

  // Callback function responsible for loading the next page of items.
  loadNextPage,
  closeNotification,
  rerender,
  setRerender,
}) {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => !hasNextPage || index < items.length;

  const history = useHistory();
  //each notification type has its own icon
  const notificationIcon = (type) => {
    switch (type) {
      case "info":
        return <GroupIcon />;
      case "important":
        return <DoneIcon />;
      case "error":
        return <WarningIcon />;
      default:
        return <AnnouncementIcon />;
    }
  };

  const classes = useStyles();
  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    let content;
    let displayedicon;
    let notificationtime;
    let readstatus;
    let notificationId;
    let path;
    if (!isItemLoaded(index)) {
      content = "Loading...";
    } else {
      content = items[index].message;
      notificationId = items[index].id;
      readstatus = items[index].is_read;
      path = items[index].path ? items[index].path : null;
      notificationtime = getDifferenceDays(
        parseServerDate(items[index].notification_date)
      );
      displayedicon = notificationIcon(items[index].type);
    }

    const markNotificationAsRead = async () => {
      let data = { email: getUser().email, notificationid: notificationId };
      try {
        let response = await httpPut(
          "/notifications/mark-a-notification-asread",
          data
        );
      } catch (error) {
        console.error(error);
      }

      if (path === null) {
        return;
      }
      var checkpath = path.includes("/order-details?id=");
      if (checkpath) {
        let order_id = path.split("/order-details?id=")[1];

        history.push({
          pathname: "/order-details",
          state: { id: order_id },
        });
        window.location.reload();
      } else {
        history.push(path);
      }

      closeNotification();
    };

    return (
      <div style={{ width: "100%" }}>
        <Grid
          style={style}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
          className={classes.notificationlistsettings}
          onClick={markNotificationAsRead}
        >
          {!isItemLoaded(index) ? (
            <></>
          ) : (
            <>
              <Grid
                item
                xs={1}
                className={
                  readstatus
                    ? classes.readNotificationsettings
                    : classes.unreadNotificationsettings
                }
              >
                {displayedicon}
              </Grid>
            </>
          )}
          <Grid
            item
            xs={8}
            className={
              readstatus
                ? classes.readNotificationsettings
                : classes.unreadNotificationsettings
            }
          >
            <div>
              <Typography>{content}</Typography>
            </div>
            <div>
              <Typography className={classes.notificationTimeSettings}>
                <i>{notificationtime}</i>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
  if (rerender) {
    return <></>;
  }

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      minimumBatchSize={4}
    >
      {({ onItemsRendered, ref }) => (
        <List
          className="List"
          height={300}
          itemCount={itemCount}
          itemSize={108}
          width={450}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {Item}
        </List>
      )}
    </InfiniteLoader>
  );
}
