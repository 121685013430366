import i18n from "../../../../i18nextConf";
import IndividualIdentificationDocument from "../../../common-components/IndividualIdentificationDocument";
import Details from "../Details";

export const fields = {
    formId: 'individualStepper',
    formField: {
        firstName: {
            name: 'first_name',
            label: i18n.t("labels.first-name"),
            requiredErrorMsg: i18n.t("validation.required.first-name"),
            matchesErrorMsg: i18n.t("validation.matches.name")
        },
        lastName: {
            name: 'last_name',
            label: i18n.t("labels.last-name"),
            requiredErrorMsg: i18n.t("validation.required.last-name"),
            matchesErrorMsg: i18n.t("validation.matches.name")
        },
        fatherName: {
            name: 'father_name',
            label: i18n.t("labels.father-name"),
            requiredErrorMsg: i18n.t("validation.required.father-name"),
            matchesErrorMsg: i18n.t("validation.matches.name")
        },
        maidenName: {
            name: 'maiden_name',
            label: i18n.t("labels.maiden-name"),
            requiredErrorMsg: i18n.t("validation.required.maiden-name"),
            matchesErrorMsg: i18n.t("validation.matches.name")
        },
        gender: {
            name: 'gender',
            label: i18n.t("labels.gender.title"),
            requiredErrorMsg: i18n.t("validation.required.gender")
        },
        doBirth: {
            name: 'date_of_birth',
            label: i18n.t("labels.dob"),
            requiredErrorMsg: i18n.t("validation.required.dob"),
            ageErrorMsg: i18n.t("validation.age")
        },
        employmentStatus: {
            name: 'employment_status_id',
            label: i18n.t("kyc-details-step.employment-label"),
            requiredErrorMsg: i18n.t("validation.required.employment")
        },
        otherEmploymentStatus: {
            name: 'other_employment_status',
            label: i18n.t("kyc-details-step.other-employment-label"),
            requiredErrorMsg: i18n.t("validation.required.other-employment")
        },
        sourcesFund: {
            name: 'sources_of_funds',
            label: i18n.t("kyc-details-step.funds-label"),
            requiredErrorMsg: i18n.t("validation.required.source")
        },
        otherSourceFund: {
            name: 'other_source_of_funds',
            label: i18n.t("kyc-details-step.other-funds-label"),
            requiredErrorMsg: i18n.t("validation.required.other-source")
        },
        phone: {
            name: 'mobile_phone',
            label: i18n.t("kyc-details-step.phone-label"),
            requiredErrorMsg: i18n.t("validation.required.phone"),
            matchesErrorMsg: i18n.t("validation.matches.phone"),
            lengthErrorMsg: i18n.t("validation.phone-length")
        },
        nationality: {
            name: 'nationalities',
            label: i18n.t("labels.nationality"),
            requiredErrorMsg: i18n.t("validation.required.nationality")
        },
        residence: {
            name: 'residences',
            label: i18n.t("labels.residence"),
            requiredErrorMsg: i18n.t("validation.required.residence")
        },
        signature: {
            name: 'signature',
            label: i18n.t("validation.required.signature"),
            requiredErrorMsg: i18n.t("validation.required.signature")
        },
        signatureDate: {
            name: 'signature_date',
            label: i18n.t("validation.required.signature-date"),
            requiredErrorMsg: i18n.t("validation.required.signature-date")
        },
        documentType: {
            name: 'document_type',
            label: i18n.t("kyc-documents.type-label"),
            requiredErrorMsg: i18n.t("validation.required.document-type")
        },
        documentNumber: {
            name: 'document_number',
            label:  i18n.t("kyc-documents.number-label"),
            requiredErrorMsg: i18n.t("validation.required.document-number"),
            passportMatchesErrorMsg: i18n.t("validation.matches.passport-number"),
            idMatchesErrorMsg: i18n.t("validation.matches.id-number")
        },
        documentDate: {
            name: 'document_expiry_date',
            label: i18n.t("kyc-documents.date-label"),
            requiredErrorMsg: i18n.t("validation.required.document-expiry-date")
        },
    }
};

export const steps = 
    [
        {
            id: 0,
            label: i18n.t("stepper.kyc.step2"),
            fields: [fields.formField.gender.name, fields.formField.firstName.name, fields.formField.fatherName.name, fields.formField.maidenName, fields.formField.lastName, fields.formField.doBirth, fields.formField.nationality, fields.formField.residence, fields.formField.employmentStatus, fields.formField.otherEmploymentStatus, fields.formField.sourcesFund, fields.formField.phone]
        },
    
        {
            id: 1,
            label: i18n.t("stepper.kyc.step3"),
            fields: [fields.formField.documentDate, fields.formField.documentNumber, fields.formField.documentType]
        },
    ];

export const stepsContent = (formInputs, formikProps) => {
    return (
        [           
            <Details {...formInputs} formikProps = {formikProps} />,
            <IndividualIdentificationDocument {...formInputs} formikProps = {formikProps}/>          
        ]
    )
}

  