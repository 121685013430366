import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { getUser } from "../services/authService";
import LoginForm from "../components/loginpage/LoginForm";
import LogoImage from "../components/loginpage/LogoImage";
import Title from "../components/loginpage/Title";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    margin: "auto 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  startButton: {
    marginTop: theme.spacing(2),
  },
  companyBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: "4px",
    border: "solid 3px #0088ff",
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "white",
  },
  formSettings: {
    padding: "1.2rem",
    textAlign: "center"
  },
}));

export default () => {
  const classes = useStyles();
  const location = useLocation();
  const [openSnakbar, setOpenSnakbar] = useState(false);
  const [snakMessage, setSnakMessage] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [error, setError] = useState(false);

  const closeSnakbar = () => {
    setOpenSnakbar(false);
  };

  if (getUser() != null) {
    return (
      <Redirect
        to={{
          pathname: "/home",
          state: { from: location },
        }}
      />
    );
  }
  
  return (
    <Grid
      container
      component="main"
      direction="row"
      justify="center"
      className={classes.root}
    >
      <Grid item xs={12} sm={8} md={8}>
        <Grid
          container
          style={{ height: "100%" }}
          alignItems="center"
          justify="center"
          direction="column"
        >
          <Grid item>
            <div className={classes.formSettings}>
              <LogoImage />
              <Title />
              <LoginForm />
            </div>
          </Grid>
          <Snackbar
            open={openSnakbar}
            autoHideDuration={1800}
            message={snakMessage}
            onClose={closeSnakbar}
            ContentProps={{
              classes: { root: error ? classes.error : classes.success },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
