import { Avatar, Box, Button, Popover, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getUser, logout } from "../../services/authService";
import { useTranslation } from 'react-i18next';
import LanguageSelector from "../common-components/languageSelector";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  navigbuttons: {
    [theme.breakpoints.up("md")]: {
      color: "#FFFFFF",
    },
    color: "black",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18",
    fontWeight: 500,
    textTransform: "uppercase"
  },
  registerbutton: {
    color: "white",
    backgroundColor: "#25dac5",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopNavBackground: {
    background: "white",
    [theme.breakpoints.up("md")]: {
      background: theme.palette.primary.main,
    },
  },
  list: {
    width: "100%",
  },
  burgerMenu: {
    color: "#979797",
    position: "fixed",
    fontSize: "35px",
    backgroundColor: theme.palette.white.main,
    borderRadius: "30px",
  },
}));

export default function NavigationBarLandingPage(props) {
  const menuId = "primary-search-account-menu";
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [openSideBar, setOpenSideBar] = useState(false);
  const history = useHistory();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { t } = useTranslation();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogOut = () => {
    logout();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <LanguageSelector />
      </MenuItem> 
      
      <MenuItem>
        <Button
          href="https://cedaroxygen.com/our-story/"
          className={classes.navigbuttons}
          target="_blank"
        >
          {t("navbar.about")}
        </Button>
      </MenuItem>
      {!getUser() ? (
        <>
          <MenuItem onClick={handleProfileMenuOpen}>
            <Button className={classes.navigbuttons} href="/login">
              {t("navbar.login")}
            </Button>
          </MenuItem>
          <MenuItem onClick={handleProfileMenuOpen}>
            <Button variant="contained" color="secondary" href="/registration">
              {t("navbar.register")}
            </Button>
          </MenuItem>
        </>
      ) : (
        <>
          <MenuItem component="button" href="/profile">
            <Button className={classes.navigbuttons} href="/home">
              {t("navbar.home")}
            </Button>
          </MenuItem>
          <MenuItem>
            <Button className={classes.navigbuttons} onClick={handleLogOut}>
              {t("navbar.logout")}
            </Button>
          </MenuItem>
        </>
      )}
    </Menu>
  );

  const renderMenu = (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component="button" href="/home">
        {t("navbar.home")}
      </MenuItem>
      <MenuItem onClick={handleLogOut}>{t("navbar.logout")}</MenuItem>
    </Popover>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.desktopNavBackground}>
        <Toolbar style={{ marginRight: "5%" }}>
          <Avatar
            className={classes.sectionDesktop}
            variant="square"
            style={{ width: "198px", height: "40px", marginLeft: "3%" }}
            src="/images/cedarxneo-logo/cedarxneo-white-logo.png"
          />
          <Avatar
            className={classes.sectionMobile}
            variant="square"
            style={{ width: "198px", height: "40px", marginLeft: "3%" }}
            src="/images/cedarxneo-logo/cedarxneo-green-logo.png"
          />

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Box mr={1} />
            
            <LanguageSelector />
            
            <Button
              className={classes.navigbuttons}
              href="https://cedaroxygen.com/our-story/"
              target="_blank"
            >
              {t("navbar.about")}
            </Button>

            <Box mr={1} />
            {!getUser() ? (
              <>
                {" "}
                <Button className={classes.navigbuttons} href="/login">
                {t("navbar.login")}
                </Button>
                <Box mr={3} />
                <Button
                  variant="contained"
                  color="secondary"
                  href="/registration"
                >
                  {t("navbar.register")}
                </Button>{" "}
              </>
            ) : (
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <img src="/images/homepage-Navbar-images/icon-user.png" />
                <Typography variant="body2" style={{marginLeft: ".5rem"}}>{getUser().email}</Typography>
              </IconButton>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon className={classes.burgerMenu} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {getUser() ? renderMenu : <></>}
    </div>
  );
}
