import * as Yup from 'yup';

import { fields } from "./stepperModel";
import { eighteen, individualDocuments } from '../../../../utils';

const {
    formField: {
        firstName,
        lastName,
        fatherName,
        maidenName,
        gender,
        doBirth,
        employmentStatus,
        otherEmploymentStatus,
        sourcesFund,
        otherSourceFund,
        phone,
        nationality,
        residence,
        documentType,
        documentDate,
        documentNumber
    }
} = fields;

export default [
    //Details Step
    Yup.object({
        [firstName.name]: Yup
            .string()
            .nullable()
            .matches('^[a-zA-Z ]+$', `${firstName.matchesErrorMsg}`)
            .required(`${firstName.requiredErrorMsg}`),

        [lastName.name]: Yup
            .string()
            .nullable()
            .matches('^[a-zA-Z ]+$', `${lastName.matchesErrorMsg}`)
            .when('gender', {
                is: "Male",
                then: Yup
                    .string()
                    .nullable()
                    .matches('^[a-zA-Z ]+$', `${lastName.matchesErrorMsg}`)
                    .required(`${lastName.requiredErrorMsg}`)
            }),
        
        [fatherName.name]: Yup
            .string()
            .nullable()
            .matches('^[a-zA-Z ]+$', `${fatherName.matchesErrorMsg}`)
            .required(`${fatherName.requiredErrorMsg}`),

        [maidenName.name]: Yup
            .string()
            .nullable()
            .matches('^[a-zA-Z ]+$', `${maidenName.matchesErrorMsg}`)
            .when('gender', {
                is: "Female",
                then: Yup
                    .string()
                    .nullable()
                    .matches('^[a-zA-Z ]+$', `${maidenName.matchesErrorMsg}`)
                    .required(`${maidenName.requiredErrorMsg}`)
            }),

        [gender.name]: Yup
            .string()
            .nullable()
            .required(`${gender.requiredErrorMsg}`),

        [doBirth.name]: Yup
            .date()
            .max(eighteen(), `${doBirth.ageErrorMsg}`)
            .nullable()
            .required(`${doBirth.requiredErrorMsg}`),
        
        [employmentStatus.name]: Yup
            .number()
            .nullable()
            .required( `${employmentStatus.requiredErrorMsg}`),
            
        [otherEmploymentStatus.name]: Yup
            .string()
            .nullable(), 
        
        [sourcesFund.name]: Yup
            .array()
            .min(1, `${employmentStatus.requiredErrorMsg}`),  
        
        [otherSourceFund.name]: Yup
            .string()
            .nullable(), 
        
        [phone.name]: Yup
            .string()
            .min(8, `${phone.lengthErrorMsg}`)
            .max(8, `${phone.lengthErrorMsg}`)
            .matches('^(([7][01689]|[8][1]|[0][13456789])[0-9]{6}$)', `${phone.matchesErrorMsg}`)
            .nullable()
            .required(`${phone.requiredErrorMsg}`), 
        
        [nationality.name]: Yup
            .array()
            .min(1, `${nationality.requiredErrorMsg}`),

        [residence.name]: Yup
            .array()
            .min(1, `${residence.requiredErrorMsg}`)

    }),

    //Documents Step
    Yup.object({
        [documentType.name]: Yup
            .string()
            .required(`${documentType.requiredErrorMsg}`),

        [documentNumber.name]: Yup
            .string()
            .nullable()
            .when('document_type', {
                is: (document_type) => document_type == individualDocuments.Passport.name || document_type == individualDocuments.NationalID.name,
                then: Yup
                    .string()
                    .nullable()
                    .required(`${documentNumber.requiredErrorMsg}`)
                    .test('passport-test', `${documentNumber.passportMatchesErrorMsg}`,
                        function(value){
                            if(this.parent.document_type == individualDocuments.Passport.name){
                                const passportRegex = /^(?=.*?[A-Za-z])(?=.*?[0-9]).*$/;
                                let isValidPassport = passportRegex.test(value);
                                return isValidPassport
                            }
                            return true;
                        }
                    )
                    .test('id-test', `${documentNumber.idMatchesErrorMsg}`,
                        function(value){
                            if(this.parent.document_type == individualDocuments.NationalID.name){
                                const idRegex = /^[0-9]+$/;
                                let isValidId = idRegex.test(value);
                                return isValidId
                            }
                            return true;
                        }
                    ),
            }),

        [documentDate.name]: Yup
            .date()
            .nullable()
            .when('document_type', {
                is: individualDocuments.Passport.name,
                then: Yup.date().nullable().required(`${documentDate.requiredErrorMsg}`)
            })
    })

];