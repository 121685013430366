import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function OrderConfirmationDialog({
    title,
    content,
    leftButtonTitle,
    rightButtonTitle,
    onClickLeftButton,
    onClickRightButton,
    open,
    close,
    amountNeeded,
    convertedAmount,
    baseCurrency,
    quoteCurrency,
    buyOrderRequired,
    amountMessage,
    usePlatformRate
}) {

    const { t } = useTranslation();

    const amountLabel = () => { 
        return (buyOrderRequired ? 
        t("homepage.place-order.buy-label") : t("homepage.place-order.sell-label")) + " " + amountNeeded + baseCurrency 
    }

    const convertedLabel = () => {
        return (
            buyOrderRequired ? 
                usePlatformRate ? 
                    t("homepage.place-order.sell-label") + t("homepage.place-order.between-label") + " " + amountMessage + " " + quoteCurrency 
                    : 
                    t("homepage.place-order.sell-label") + " " + convertedAmount + " " + quoteCurrency 
                : 
                usePlatformRate ? 
                    t("homepage.place-order.buy-label") + t("homepage.place-order.between-label") + " " + amountMessage + " " + quoteCurrency 
                    : 
                    t("homepage.place-order.buy-label") + " " + convertedAmount + " " + quoteCurrency 
        )
    }

    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {content}   
            </DialogContentText>

            <Box mt={3} />

            <Grid container direction="column" md={12} xs={12}>
                <Grid item>
                    <Typography variant="h6">{t("homepage.place-order.order-details-label")}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">{amountLabel()}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">{convertedLabel()}</Typography>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={onClickLeftButton} color="primary">
                {leftButtonTitle}
            </Button>
            <Button onClick={onClickRightButton} color="primary" autoFocus>
                {rightButtonTitle}
            </Button>
            </DialogActions>
        </Dialog>
    );
}
