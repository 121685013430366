import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Paper, Typography, Dialog, DialogTitle, DialogContent, 
    DialogActions, useMediaQuery, TextField, IconButton, Avatar,} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { getAvatar, isAccountApproved, isArabic, toArabicDigits } from "../../../utils";
import { useTranslation } from 'react-i18next';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik } from "formik";
import * as yup from "yup";
import { httpPost } from "../../../httpcommon";
import { getUser, logout } from "../../../services/authService";
import commonStyles from "../../../commonStyles";
import moment from "moment";
import { useTheme } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { getProfileType } from "../../../redux-reducer/profileType/profileTypeSlice";
import ConfirmationDialog from "../../common-components/ConfirmationDialog";
import InfoDialog from "../../common-components/InfoDialog";
import { apis } from "../../../apis/apis";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2rem"
    },
    avatarCustomized: {
        height: "10rem",
        width: "10rem",
        border: "solid 1px",
        borderColor: theme.palette.secondary.main,
        borderRadius: "3px",
        backgroundColor: "transparent",
        fontSize: "5rem",
        fontWeight: 500,
        color: theme.palette.secondary.main
    },
    approvedVerificationStatusSettings: {
        color: theme.palette.success.main,
        fontSize: "12px",
    },
    disapproveVerificationStatusSettings: {
        color: "orange",
        fontSize: "12px",
    },
    info: {
        textAlign: "end",
        justifyContent: "end",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            justifyContent: "center",
          },
    },
    title: {
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
    },
    pointer: {
        "&:hover": {
            cursor: "pointer"
        }
    },

    passwordButton: {
        padding: "0px"
    },

    deleteButton: {
        padding: "0px",
        color: theme.palette.error.main
    },

    ...commonStyles,
}));

export default function BusinessSummary({businessDetails}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
    const [openErrorDeleteDialog, setOpenErrorDeleteDialog] = useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const type = useSelector(getProfileType);

    const [showPassword, setShowPassword] = useState({
        oldpassword: false,
        newpassword: false,
        confirmpassword: false,
    });

    const [passwordError, setPasswordError] = useState();

    const [passwordChanged, setPasswordChanged] = useState(false);

    const validationSchema = yup.object({
        oldpassword: yup
        .string(t("validation.empty-password"))
        .required(t("validation.required-password")),
        newpassword: yup
        .string(t("validation.empty-password"))
        .min(8, t("validation.password-length"))
        .required(t("validation.required-password"))
        .matches(
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*-_]).*$",
            t("validation.invalid-password")
        ),
        confirmPassword: yup
        .string(t("validation.empty-confirm-password"))
        .required(t("validation.required-confirm-password"))
        .test(
            "confirm-password-test",
            t("validation.passwords-match"),
            function (value) {
            return value === this.parent.newpassword;
            }
        ),
    });

    const handleOpenPasswordDialog = () => {
        setOpenPasswordDialog(true);
    };

    const handleClosePasswordDialog = () => {
        setOpenPasswordDialog(false);
    };

    const handlePasswordChange = async (values) => {
        const verificationResponse = await httpPost("/changepassword", {
        email: getUser().email,
        oldpassword: values.oldpassword,
        newpassword: values.newpassword,
        });

        if (!verificationResponse.data.success)
        setPasswordError(verificationResponse.data.message);
        else setPasswordChanged(true);
    };

    const handleClickShowOldPassword = () => {
        setShowPassword({
        ...showPassword,
        oldpassword: !showPassword.oldpassword,
        });
    };

    const handleClickShowNewPassword = () => {
        setShowPassword({
        ...showPassword,
        newpassword: !showPassword.newpassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        setShowPassword({
        ...showPassword,
        confirmpassword: !showPassword.confirmpassword,
        });
    };

    const deleteAccount = () => {
        if(businessDetails.ongoing_orders > 0)
            setOpenErrorDeleteDialog(true);
        else setOpenDeleteConfirmationDialog(true);
    }

    const handleDeleteAccount = async () => {
        const response = await apis.deleteAccount(businessDetails.user_id);
        if(response.data.success)
            logout();
    }

    return (
        <Paper elevation={0} className={classes.root}>
            <Grid container direction="column" md={12} xs={12}>
                <Grid item md={12} xs={12} style={{alignSelf: "center"}}>
                    <Avatar className={classes.avatarCustomized}>{getAvatar(businessDetails, type)}</Avatar>  
                </Grid>

                <Box mt={8} />

                <Grid item md={12} xs={12}>
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.title}>
                            <Typography variant="h6">
                                {t("profile-page.username-label")}
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.info}>
                            <Typography variant="body2">
                                {businessDetails.user_email}
                            </Typography>
                        </Grid>
                    </Grid>                              
                </Grid>

                <Box mt={2} />

                <Grid container direction="row" md={12} xs={12}>
                    <Grid item md={6} xs={12} className={classes.title}>
                        <Typography variant="h6">
                            {t("profile-page.status-label")}
                        </Typography>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Grid container direction="row" md={12} xs={12} className={classes.info}>
                            <Grid item>
                                <FiberManualRecordIcon
                                    className={
                                        isAccountApproved(businessDetails)
                                        ? classes.approvedVerificationStatusSettings
                                        : classes.disapproveVerificationStatusSettings
                                    }
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="body2">
                                    {
                                        isAccountApproved(businessDetails)
                                        ? t("profile-status.verified")
                                        : t("profile-status.pending")
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>                              

                <Box mt={2} />

                <Grid item md={12} xs={12}>
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.title}>
                            <Typography variant="h6">
                                {t("profile-page.registered-label")}
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.info}>
                            <Typography variant="body2">
                                {isArabic() ? toArabicDigits(moment(new Date(businessDetails.created_on)).format("DD/MM/yyyy")) : moment(new Date(businessDetails.created_on)).format("DD/MM/yyyy")}
                            </Typography>
                        </Grid>
                    </Grid>                              
                </Grid>

                <Box mt={2} />

                <Grid item md={12} xs={12} className={classes.title}>
                    <Button variant="text" onClick={handleOpenPasswordDialog} color="secondary" className={classes.passwordButton}>
                        {t("change-password-label")}
                    </Button>

                    <Dialog
                        fullScreen={fullScreen}
                        open={openPasswordDialog}
                        onClose={handleClosePasswordDialog}
                        aria-labelledby="responsive-dialog-title"
                    >
                        {!passwordChanged ? (
                            <React.Fragment>
                                <DialogTitle disableTypography>
                                    <Typography variant="h4" color="primary">
                                    {t("change-password-label")}
                                    </Typography>
                                </DialogTitle>

                                <Formik
                                    initialValues={{
                                    oldpassword: "",
                                    newpassword: "",
                                    confirmPassword: "",
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => handlePasswordChange(values)}
                                >
                                    {({
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        values,
                                        touched,
                                        errors,
                                    }) => (
                                    <>
                                        <DialogContent>
                                            <TextField
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                label={t("old-password-label")}
                                                type={
                                                    showPassword.oldpassword ? "text" : "password"
                                                }
                                                onChange={handleChange("oldpassword")}
                                                onBlur={handleBlur("oldpassword")}
                                                value={values.oldpassword}
                                                name="oldpassword"
                                                error={
                                                    touched.oldpassword &&
                                                    Boolean(errors.oldpassword)
                                                }
                                                helperText={
                                                    touched.oldpassword && errors.oldpassword
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowOldPassword}
                                                        edge="end"
                                                        >
                                                        {showPassword.oldpassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                        </IconButton>
                                                    ),
                                                }}
                                                inputProps={{ tabIndex: "1" }}
                                                required
                                            />

                                            <TextField
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                label={t("old-password-label")}
                                                type={
                                                    showPassword.newpassword ? "text" : "password"
                                                }
                                                onChange={handleChange("newpassword")}
                                                onBlur={handleBlur("newpassword")}
                                                value={values.newpassword}
                                                name="newpassword"
                                                error={
                                                    touched.newpassword &&
                                                    Boolean(errors.newpassword)
                                                }
                                                helperText={
                                                    touched.newpassword && errors.newpassword
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowNewPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword.newpassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    ),
                                                }}
                                                inputProps={{ tabIndex: "2" }}
                                                required
                                            />

                                            <TextField
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                id="outlined-adornment-password"
                                                type={
                                                    showPassword.confirmpassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                label={t("confirm-password-label")}
                                                onChange={handleChange("confirmPassword")}
                                                onBlur={handleBlur("confirmPassword")}
                                                value={values.confirmPassword}
                                                name="confirmPassword"
                                                error={
                                                    touched.confirmPassword &&
                                                    Boolean(errors.confirmPassword)
                                                }
                                                helperText={
                                                    touched.confirmPassword &&
                                                    errors.confirmPassword
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword.confirmpassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    ),
                                                }}
                                                inputProps={{ tabIndex: "3" }}
                                                required
                                            />

                                            {passwordError  && (
                                                <Typography variant="h6" color="error">
                                                    {passwordError}
                                                </Typography>
                                            )}
                                        </DialogContent>

                                        <DialogActions>
                                        <Button
                                            autoFocus
                                            onClick={handleClosePasswordDialog}
                                            color="primary"
                                        >
                                            {t("button.cancel")}
                                        </Button>
                                        <Button
                                            onClick={handleSubmit}
                                            color="primary"
                                            autoFocus
                                        >
                                            {t("button.submit")}
                                        </Button>
                                        </DialogActions>
                                    </>
                                    )}
                                </Formik>
                            </React.Fragment>
                        ) : (
                            <DialogContent>
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    textAlign="center"
                                >
                                    {t("password-changed-message")}
                                </Typography>
                            </DialogContent>
                        )}
                    </Dialog>
                </Grid>

                <Box mt={2} />

                <Grid item md={12} xs={12} className={classes.title}>
                    <Button variant="text" onClick={deleteAccount} className={classes.deleteButton}>
                        {t("delete-account-label")}
                    </Button>

                    <ConfirmationDialog
                        title={t("delete-account-label")}
                        content={t("confirmation-dialog.delete-content")}
                        leftButtonTitle={t("button.cancel")}
                        rightButtonTitle={t("button.yes")}
                        onClickLeftButton={() => setOpenDeleteConfirmationDialog(false)}
                        onClickRightButton={handleDeleteAccount}
                        open={openDeleteConfirmationDialog}
                        close={() => setOpenDeleteConfirmationDialog(false)}
                    />
                    
                    <InfoDialog
                        open={openErrorDeleteDialog}
                        onClose={() => setOpenErrorDeleteDialog(false)}
                        title={t("delete-account-label")}
                        subtitle={t("info-dialog.delete-content")}
                    />
                </Grid>

                <Box mt={2} />
            </Grid>
        </Paper>
    )
}
