import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const LoadingDisplay = ({ width, heigth }) => {
  if (width && heigth) {
    return (
      <>
        <Skeleton width={width} height={heigth} />
      </>
    );
  }
  if (width) {
    return (
      <>
        <Skeleton width={width} />
      </>
    );
  }
  if (heigth) {
    return (
      <>
        <Skeleton height={heigth} />
      </>
    );
  } else {
    return <Skeleton />;
  }
};
