import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import EmailIcon from "@material-ui/icons/Email";

import React from "react";
import { theme } from "../../App";
import commonStyles from "../../commonStyles";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  ...commonStyles,
}));

export default function EmailVerification() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      style={{ height: "100vh" }}
      direction="column"
      alignItems="center"
    >
      <Box mt={12} />
      <EmailIcon
        style={{ fontSize: "14rem", color: theme.palette.secondary.main }}
      />
      <Box mt={2} />
      <Box>
        <Typography variant="h3" color="primary">
          {t("email-verification-page.title")}
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="subtitle1" className={classes.bold}>
          {t("email-verification-page.subtitle")}
        </Typography>
      </Box>
    </Grid>
  );
}
