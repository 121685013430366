import { Badge, IconButton, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useTranslation } from 'react-i18next';

const MobileNotificationPopover = ({
  mobileMoreAnchorEl,
  isMobileMenuOpen,
  handleMobileMenuClose,
  showNotification,
  status,
  handleLogOut,
  mobileMenuId,
}) => {
  const { t } = useTranslation();

  return (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component="button" href="/profile">
        {t("navbar.profile")}
      </MenuItem>
      <IconButton
        aria-label="show new notifications"
        color="inherit"
        onClick={showNotification}
      >
        <Badge
          badgeContent={
            status.totalNumberOfItems != -1 ? status.totalNumberOfItems : 0
          }
          color="secondary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <MenuItem onClick={handleLogOut}>{t("navbar.logout")}</MenuItem>
    </Menu>
  );
};

export default MobileNotificationPopover;
