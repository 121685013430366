import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { Snackbar, StepButton, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from "@material-ui/core";
import { getUser, logout, setUser } from "../../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationDetails, getOrganizationLoaded, updateOrganizationDetails } from "../../../redux-reducer/organization/organizationSlice";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";
import { organizationApis } from "../../../apis/organizationApis";
import moment from "moment";
import { containsNonNumericLetter, formatNumber, numberWithCommos, hasStepError, toEnglishDigits, isRegisterStepper, isEditStepper, containsDot, individualDocuments } from "../../../utils";
import TermsDialog from "../../common-components/TermsDialog";
import validationSchema from "./registrationModel/validationSchema";
import { fields, steps, stepsContent } from "./registrationModel/stepperModel";
import { Form, Formik } from "formik";
import { initialValues } from "./registrationModel/stepperInitialValues";
import InfoDialog from "../../common-components/InfoDialog";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    button: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    stepperSettings: {
        width: "100%",
    },
    contentSettings: {
        width: "100%",
        overflowY: "scroll", 
        height: "65vh",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        [theme.breakpoints.down("md")]: {
            padding: "1rem",
            height: "85vh"
        },
    },
    containerSettings: {
        padding: "1rem",
        paddingLeft: "8rem",
        paddingRight: "8rem",
        [theme.breakpoints.down("md")]: {
            padding: "1rem",
        },
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

export default function OrganizationStepper() {
    const { t } = useTranslation();
    let { id, activationkey } = useParams();
    const masked_id = getUser().profile_id;
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const currentValidationSchema = validationSchema[activeStep];
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [loading, setLoading] = useState(false);   
    const theme = useTheme();
    const [edit, setEdit] = useState(false)
    const dispatch = useDispatch();
    const [ submittedData, setSubmittedData] = useState();
    const [openEditConfirmationDialog, setEditConfirmationDialog] = useState(false);

    const { formId, formField } = fields;

    const [percentageMessage, setPercentageMessage] = useState();
    const [contactMessage, setContactMessage] = useState();

    const [buttonClicked, setButtonClicked] = useState();

    const [updatedDocuments, setUpdatedDocuments] = useState({})

    const mobileView = useMediaQuery(theme.breakpoints.down("md"));

    const history = useHistory();

    const [organizationDetails, setOrganizationDetails] = useState();
    const organizationProfile = useSelector(getOrganizationDetails);
    const organizationLoaded = useSelector(getOrganizationLoaded);

    const [openTermsDialog, setOpenTermsDialog] = useState(false);

    const [organizationNumbersDisplayed, setOrganizationNumbersDisplayed] = useState({
        [formField.volume.name]: null
    });

    useEffect(async () => {
        if(organizationLoaded){
            setOrganizationDetails(organizationProfile);
        }

        if(organizationProfile)
            setOrganizationNumbersDisplayed({
                [formField.volume.name]: numberWithCommos(organizationProfile.expected_annual_volume_IN_OUT_in_USD),
            })
    }, [organizationLoaded, organizationProfile]);

    const setOrganizationAttribute = (setFieldValue) => (e) => {
        setFieldValue(e.target.name, e.target.value);
        setEdit(true);
    };

    const setSingleOptionAttribute = (setFieldValue, field_name) => (event, value) => {
        setFieldValue(field_name, value ? value.id : null)
        setEdit(true);
    };

    const setMultipleOptionsAttribute = (setFieldValue, field_name) => (event, values) => {
        let val = values.map((value) => value.id)
        setFieldValue(field_name, val)
        setEdit(true);
    }

    const setOrganizationNumberAttribute = (setFieldValue) => (e) => {
        let newValue = e.target.value
    
        newValue = containsNonNumericLetter(newValue);
        
        if(containsDot(newValue)) {
            return;
        }

        const formattedNewValue = formatNumber(newValue)

        setOrganizationNumbersDisplayed({
            ...organizationNumbersDisplayed,
            [e.target.name]: formattedNewValue
        });

        formattedNewValue.replaceAll(',', '');

        let arabicNumeral = new RegExp(`[٠١٢٣٤٥٦٧٨٩]`, 'g');

        if(formattedNewValue.match(arabicNumeral)){
            let arabicFormattedNewValue = toEnglishDigits(formattedNewValue)

            setFieldValue(e.target.name, arabicFormattedNewValue.replaceAll(',', ''))

            setEdit(true);
            return;
        }

        setFieldValue(e.target.name, formattedNewValue.replaceAll(',', ''))

        setEdit(true);
    }

    const getOwnersDTO = (owners) => {
        if(isRegisterStepper())
            return owners.map((owner) => { 
                if(owner[formField.owners.type.name] !== formField.owners.type.options.individual.name) 
                    return owner;

                let formattedOwner = {
                    ...owner, 
                    documents: {
                        [owner[formField.owners.documentType.name]]: {
                            [formField.owners.documentNumber.name]: owner[formField.owners.documentNumber.name],
                            [formField.owners.documentDate.name]: owner[formField.owners.documentDate.name],
                            document_content: owner.document_content
                        }
                    }
                }
                delete formattedOwner[formField.owners.documentNumber.name];
                delete formattedOwner[formField.owners.documentType.name];
                delete formattedOwner[formField.owners.documentDate.name];
                delete formattedOwner.document_content;
                return formattedOwner;
            })

        if(isEditStepper())
            return owners.map((owner) => { 
                if(owner[formField.owners.type.name] !== formField.owners.type.options.individual.name) 
                    return owner;

                let formattedOwner = {
                    ...owner, 
                    [formField.owners.doBirth.name]: moment(new Date(owner[formField.owners.doBirth.name])).format("yyyy/MM/DD"),
                    documents: {
                        [owner[formField.owners.documentType.name]]: {
                            [formField.owners.documentNumber.name]: owner[formField.owners.documentNumber.name],
                            [formField.owners.documentDate.name]: owner[formField.owners.documentDate.name] ? moment(new Date(owner[formField.owners.documentDate.name])).format("yyyy/MM/DD") : null,
                            document_path: owner.document_path
                        }
                    }
                }
                return formattedOwner;
            })
    }

    const getRepresentativesDTO = (representatives) => {
        if(isRegisterStepper())
            return representatives.map((representative) => { 
                let formattedRepresentative = {
                    ...representative, 
                    documents: {
                        [representative[formField.representatives.documentType.name]]: {
                            [formField.representatives.documentNumber.name]: representative[formField.representatives.documentNumber.name],
                            [formField.representatives.documentDate.name]: representative[formField.representatives.documentDate.name],
                            document_content: representative.document_content
                        },
                        [individualDocuments.Delegation.name]: {
                            document_content: {
                                [individualDocuments.Delegation.name]:representative.delegation_document_content
                            }
                        }
                    }
                }

                delete formattedRepresentative[formField.representatives.documentNumber.name];
                delete formattedRepresentative[formField.representatives.documentType.name];
                delete formattedRepresentative[formField.representatives.documentDate.name];
                delete formattedRepresentative.document_content;
                delete formattedRepresentative.delegation_document_content;

                return formattedRepresentative
            })

        if(isEditStepper())
            return representatives.map((representative) => { 
                let formattedRepresentative = {
                    ...representative, 
                    [formField.representatives.doBirth.name]: moment(new Date(representative[formField.representatives.doBirth.name])).format("yyyy/MM/DD"),
                    documents: {
                        [representative[formField.representatives.documentType.name]]: {
                            [formField.representatives.documentNumber.name]: representative[formField.representatives.documentNumber.name],
                            [formField.representatives.documentDate.name]: representative[formField.representatives.documentDate.name] ? moment(new Date(representative[formField.representatives.documentDate.name])).format("yyyy/MM/DD") : null,
                            document_path: representative.document_path
                        },
                        [individualDocuments.Delegation.name]: {
                            document_path: {
                                [individualDocuments.Delegation.name]: representative.delegation_document_path ? representative.delegation_document_path['Delegation'] : null
                            }
                        }
                    }
                }

                return formattedRepresentative
            })
    }

    const getOptionById = (options, values) => {
        let result = []
        if(values)
        for(var i=0; i<values.length; i++){
            let filter = options.find((option) => option.id === values[i])
            if(filter)
            result.push(filter)
        }
        return result;
    }

    const handleUpdateDocument = (file, document_name) => {
        setUpdatedDocuments({
            ...updatedDocuments,
            [document_name]: file
        })
        setEdit(true);
    }

    const handleRmvUpdatedDocument = (document_name) => {
        const newUpdatedDocuments = updatedDocuments;
        delete newUpdatedDocuments[document_name];
        setUpdatedDocuments({...newUpdatedDocuments});
        setEdit(true);
    }

    const formInputs = {
        formField,
        organizationDetails,
        snackMessage,
        setSnackMessage,
        openSnackBar,
        setOpenSnackBar,
        setOrganizationAttribute,
        setSingleOptionAttribute,
        setMultipleOptionsAttribute,
        getOptionById,
        updatedDocuments,
        setUpdatedDocuments,
        handleUpdateDocument,
        handleRmvUpdatedDocument,
        setEdit,
        setOrganizationNumberAttribute,
        organizationNumbersDisplayed, 
        percentageMessage
    };
    
    const maxSteps = steps.length;

    const handleAgree = async (e) => {
        e.preventDefault()
        
        setOpenTermsDialog(false);

        var formattedDocs = {}

        for (var i = 0; i < submittedData[formField.documents.name].length; i++){
            formattedDocs = {
                ...formattedDocs,
                [submittedData[formField.documents.name][i].document_type]: submittedData[formField.documents.name][i].document_content
            }
        }

        var data = {
            ...submittedData,
            owners: getOwnersDTO(submittedData[formField.owners.name]),
            representatives: getRepresentativesDTO(submittedData[formField.representatives.name]),
            documents: formattedDocs
        }

        setLoading(true);
        const registerResponse = await organizationApis.register(id, activationkey, data);

        if (registerResponse.data.success) {
            setUser(registerResponse.data);
            history.push("/home");
            return;
        } else {
            setSnackMessage("Not Found");
            setOpenSnackBar(true);
        }
    }

    const handleFormSubmit = async (values, actions) => {
        if(activeStep === 3){
            const sumOfPercentage = values[formField.owners.name].reduce((total, owner) => total = total + parseFloat(owner[formField.owners.percentage.name]), 0);

            if(sumOfPercentage != 100){
                setSnackMessage(t("ownership-step.percentage-message", {percentage: numberWithCommos(100)}))
                setOpenSnackBar(true)
                return;
            }
        }

        if(activeStep === 4){
            const hasContact = values[formField.representatives.name].filter((representative) => representative[formField.representatives.contact.name] == true)

            if(!(hasContact.length > 0)){
                setSnackMessage(t("representatives-step.contact-message"))
                setOpenSnackBar(true)
                return;
            }      
        }

        if(isRegisterStepper()){
            if(activeStep === maxSteps -1){
                setSubmittedData(values);
                setOpenTermsDialog(true);
                return;
            }
/*             if(activeStep === 4){
                const emptydocs = values[formField.representatives.name].find((representative) => representative.document_content == null || !representative[formField.representatives.isRepresentative.name] && representative.delegation_document_content == null);
                if(emptydocs){
                    setSnackMessage(t("validation.required.document-content"))
                    setOpenSnackBar(true)
                    return;
                }
            } */
            handleNext(values, actions)
        }

        if(isEditStepper()){
            if(buttonClicked == 'continue'){
                handleNext(values, actions);
            }

            if(buttonClicked == "submit"){
                if(values.ongoing_orders > 0){
                    setEditConfirmationDialog(true)
                    return;
                }

                for(var i=0; i<values.documents.length; i++){
                    if(values.documents[i].document_status == "Rejected" && !updatedDocuments[values.documents[i].document_type]){
                        setSnackMessage(t("validation.edit-rejected-documents"));
                        setOpenSnackBar(true);
                        return;
                    }
                }

                var data = {
                    ...values,
                    [formField.documents.name]: updatedDocuments,
                    [formField.registrationDate.name]: moment(new Date(values[formField.registrationDate.name])).format("yyyy/MM/DD"),
                    [formField.signatureDate.name]: moment(new Date(values[formField.signatureDate.name])).format("yyyy/MM/DD"),
                    [formField.owners.name]: getOwnersDTO(values[formField.owners.name]),
                    [formField.representatives.name]: getRepresentativesDTO(values[formField.representatives.name])
                };

                setLoading(true);
                const editResponse = await organizationApis.edit(masked_id, data);

                if (editResponse.data.success) {
                    history.push("/profile");
                    return;
                } else {
                    setLoading(false);
                    setEdit(false);
                    setSnackMessage(editResponse.data.message);
                    setOpenSnackBar(true);
                }
            }
        }
    };

    const handleNext = (values, actions) => {
        if(isRegisterStepper())
            dispatch(updateOrganizationDetails(values))
        setPercentageMessage('');
        setContactMessage('')
        setActiveStep(activeStep + 1);
        actions.setTouched({});
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleExit = () => {
        if(isEditStepper()){
            history.push("/home");
        }
        if(isRegisterStepper()){
            logout();
            history.push("/");
        }
    }

    const hasRejectedDocs = (step) => {
        if(step.id == maxSteps - 1){
            for(var i=0; i<organizationDetails.documents.length; i++){
                if(organizationDetails.documents[i].document_status === "Rejected")
                  return true;
            }
            return false;
        }
    }

    if(organizationLoaded && organizationDetails)
        return (
            <React.Fragment>
                <Grid container direction="column" className={classes.containerSettings}>
                    {
                        !mobileView &&
                            <Grid item className={classes.stepperSettings}>
                                {
                                isEditStepper() ? 
                                    <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel nonLinear> 
                                        {steps.map((step, index) => (
                                            <Step key={step.label}>
                                                <StepButton
                                                    type="submit"  
                                                    onClick={() => {                        
                                                        setActiveStep(index)
                                                    }}
                                                    color="inherit"
                                                >
                                                    <StepLabel error={hasStepError(step, organizationDetails) || hasRejectedDocs(step)}>
                                                        {step.label}
                                                    </StepLabel>
                                                </StepButton>
                                            </Step>
                                        ))}
                                    </Stepper>
                                :
                                    <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
                                        {steps.map((step) => (
                                            <Step key={step.label}>
                                                <StepLabel >
                                                    {step.label}
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                }
                            </Grid>
                    }

                    <Formik
                        initialValues={initialValues(organizationDetails)}
                        validationSchema={currentValidationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {
                            props => (
                                <Form id={formId}>
                                    <Grid item className={classes.contentSettings}>
                                        {stepsContent(formInputs, props)[activeStep]}
                                    </Grid>

                                    <Grid item className={classes.stepperSettings}>
                                        <Typography color="error" variant="body1" style={{textAlign: "center"}}>{percentageMessage}</Typography>
                                        <Typography color="error" variant="body1" style={{textAlign: "center"}}>{contactMessage}</Typography>
                                        <Grid container justify="center" alignItems="center" direction="row" >
                                            <Grid item>                
                                                <Button
                                                    onClick={handleExit}
                                                    variant="contained"
                                                    className={classes.button}
                                                >
                                                    {t("button.exit")}
                                                </Button>
                                            </Grid>

                                            {
                                                activeStep !== 0 && (
                                                    <Grid item>                
                                                        <Button
                                                            onClick={handleBack}
                                                            variant="contained"
                                                            className={classes.button}
                                                        >
                                                            {t("button.back")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            }

                                            {
                                                activeStep !== maxSteps -1 && (
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            className={classes.button}
                                                            type="submit"
                                                            onClick={(e) => setButtonClicked("continue") }
                                                        >
                                                            {t("button.continue")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            }

                                            {
                                                (isEditStepper() && edit) && (
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            className={classes.button}
                                                            type="submit"
                                                            disabled={loading}
                                                            onClick={(e) => setButtonClicked("submit")}
                                                        >
                                                            {t("button.submit")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            }

                                            {
                                                (isRegisterStepper() && activeStep === maxSteps -1) && (
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            className={classes.button}
                                                            type="submit"
                                                            disabled={loading}
                                                        >
                                                            {t("button.submit")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }
                    </Formik>
                </Grid>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar
                    open={openSnackBar}
                    autoHideDuration={1800}
                    message={snackMessage}
                    onClose={() => setOpenSnackBar(false)}
                    ContentProps={{ classes: { root: classes.error } }}
                />

                <TermsDialog
                    open={openTermsDialog}
                    onClose={() => { setOpenTermsDialog(false); setLoading(false) }}
                    onAgree={handleAgree}
                />

                <InfoDialog
                    title={t("edit-dialog.title")}
                    subtitle={t("edit-dialog.content")}
                    leftButtonTitle={t("button.cancel")}
                    rightButtonTitle={t("button.ok")}
                    open={openEditConfirmationDialog}
                    onClose={() => {setEditConfirmationDialog(false); history.push("/home")}}
                />
            </React.Fragment>
        );
    return <LoadingDisplay />
}
