import {createSlice} from "@reduxjs/toolkit";

export const modeSlice = createSlice({
  name: "mode",
  initialState: {
      mode: 'P2p',
      loaded: false
  },
  reducers: {
    updateMode: (state, action) => {
      state.mode = action.payload;
      state.loaded = true;
    },
    reloadMode: (state) => {
      state.loaded = false;
    },
  }
});

export const { updateMode, reloadMode } = modeSlice.actions;


export const getmode = (state) => state.mode.mode;

export const getmodeLoaded = (state) => state.mode.loaded;


export default modeSlice.reducer;
