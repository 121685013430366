import {
  IconButton,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Box, Button, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import EmailIcon from "@material-ui/icons/Email";
import { theme } from "../App";
import { httpPut } from "../httpcommon";
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openSnakbar, setOpenSnakbar] = useState(false);
  const [snakMessage, setSnakMessage] = useState("");
  const [error, setError] = useState(false);
  const [emailSent, setEmailSent] = useState(false)

  const { t } = useTranslation();

  const forgotPassword = async (e) => {
    e.preventDefault();
    const result = await httpPut(
      "/reset-your-password/" + e.target.email.value
    );
    if (result.data.success) {
      setError(false);
      setEmailSent(true)
      setLoading(false);
      return;
    }
    setSnakMessage(result.data.message);
    setError(true);
    setOpenSnakbar(true);
    setLoading(false);
  };

  const closeSnakbar = () => {
    setOpenSnakbar(false);
  };
  return (
    !emailSent ?
    
      <Grid
        container
        component="main"
        className={classes.root}
        direction="column"
        justify="center"
      >
        <Grid item>
          <Grid
            container
            style={{ height: "100%" }}
            alignItems="center"
            justify="center"
            direction="column"
          >
            <Grid item>
              <div className={classes.formSettings}>
                <IconButton onClick={() => history.push("/")}>
                  <img
                    src="/images/cedarxneo-logo/cedarxneo-green-logo.png"
                    style={{ width: "17rem" }}
                  />
                </IconButton>
                <Typography variant="h3">{t("reset-password-label")}</Typography>
                <Box mt={1} />
                <Typography variant="subtitle1">
                  {t("login-page.subtitle")}{" "}
                  <Link to="/registration" variant="subtitle1">
                    {t("login-page.register")}
                  </Link>
                </Typography>
                <form className={classes.form} onSubmit={forgotPassword}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    type="email"
                    id="standard-basic"
                    label={t("email-label")}
                    name="email"
                    autoFocus
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    className={classes.startButton}
                  >
                    {t("button.reset-password")}
                  </Button>
                </form>
              </div>
            </Grid>
            <Snackbar
              open={openSnakbar}
              autoHideDuration={1800}
              message={snakMessage}
              onClose={closeSnakbar}
              ContentProps={{
                classes: { root: error ? classes.error : classes.success },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    :

      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <EmailIcon
          style={{ fontSize: "14rem", color: theme.palette.secondary.main }}
        />
        <Box mt={2} />
        <Box>
          <Typography variant="h4" color="primary">
            {t("reset-password-message")}
          </Typography>
        </Box>
      </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    margin: "auto 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  startButton: {
    marginTop: theme.spacing(2),
  },
  companyBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: "4px",
    border: "solid 3px #0088ff",
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "white",
  },
  formSettings: {
    padding: "1.2rem",
  },
  titleMobileFontSize: {
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
  },
}));

export default ForgotPassword;
