import {createSlice} from "@reduxjs/toolkit";

export const organizationSlice = createSlice({
  name: "organizationInfo",
  initialState: {
    organizationDetails: null,
    loaded: true
  },
  reducers: {
    updateOrganizationDetails: (state, action) => {
      state.organizationDetails = action.payload;
      state.loaded = true;
    },
    reloadOrganizationDetails: (state) => {
      state.loaded = false;
    },
  }
});

export const { updateOrganizationDetails, reloadOrganizationDetails } = organizationSlice.actions;

export const getOrganizationDetails = (state) => state.organizationInfo.organizationDetails;

export const getOrganizationLoaded = (state) => state.organizationInfo.loaded;

export default organizationSlice.reducer;
