import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonLoading from "../../common-components/MessageFab"
import MessageFab from "../../../components/common-components/MessageFab";
import OrganizationSummary from "./OrganizationSummary";
import { getOrganizationDetails, getOrganizationLoaded } from "../../../redux-reducer/organization/organizationSlice";
import P2pOrderForm from "../P2pOrderForm";
import { apis } from "../../../apis/apis";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";
import P2pOrdersTable from "../P2pOrdersTable";

const OrganizationHomepage = ({classes}) => {
  const organizationDetails = useSelector(getOrganizationDetails);
  const organizationLoaded = useSelector(getOrganizationLoaded);
  const [platformRate, setPlatformRate] = useState()

  useEffect(async () => {
    let platformRate = await apis.getLivePlatformRate("USD", "LBP");
    if (platformRate.data.success) {
      setPlatformRate(platformRate.data);
    }
  }, []);
  
  if (!organizationLoaded) {
    return (
      <>
        <SkeletonLoading page="home" />
      </>
    );
  }
  
  if(platformRate && organizationDetails)
    return (
      
      <>
        <Grid container direction="column" className={classes.homepageSettings} >     
          <Grid item>
            <Grid container direction="row" md={12} xs={12} >
              <Grid item md={3} xs={12} className={classes.itemSettings}>
                <OrganizationSummary organizationDetails={organizationDetails} />
              </Grid>
              <Grid item md={9} xs={12}  className={classes.itemSettings}>
                <P2pOrderForm profileDetails={organizationDetails}/>
              </Grid>
            </Grid> 
          </Grid>

          <Grid item>
            <Box mt={5} />
          </Grid>

          <Grid item xs={12} md={12}>
            <P2pOrdersTable platformRate={platformRate}/>
          </Grid> 
        
          <Grid item>
            <Box mt={12} />
          </Grid>    
        
          <MessageFab /> 
        </Grid>

      </>
    );
    return <LoadingDisplay />
};

export default OrganizationHomepage;
