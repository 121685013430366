import { Wrapper, Status } from "@googlemaps/react-wrapper";
import React, { useEffect, useState } from "react";
import { GOOGLE_MAP_API_KEY } from "../../env";
import { Map } from "./Map";


export const MapWrapper = ({branches}) => {

    const [view, setView] = useState({
        detail: false,
        clinic: null,
        center: {
            lat: 33.89157076941542, 
            lng: 35.505617863934795
        },
        zoomLevel: 15
    });

    const [locations, setLocations] = useState(null)

    useEffect(() => {
        if(branches){
            setLocations(branches)
        }
    }, [branches])

    return (
        <div style={{ display: "flex", height: "100%" }}>
            <Wrapper apiKey={GOOGLE_MAP_API_KEY} libraries={["places"]} >
                {
                    locations &&                
                    <Map locations={locations} view={view} />
                }
            </Wrapper>
        </div>
    );
}