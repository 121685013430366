import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./redux-config/store";
import { Provider } from "react-redux";
import MomentUtils from "@date-io/moment";
import * as serviceWorker from "./serviceWorker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { initializeFirebase } from './push-notification';
import './i18nextConf';

import { create } from "jss";
import rtl from "jss-rtl";
import {
  StylesProvider,
  jssPreset
} from "@material-ui/core/styles";
import "./assets/locales/moment/ar";
import "moment/locale/en-au";
import { isArabic } from "./utils";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

ReactDOM.render(
  <Suspense fallback="...">
    <React.StrictMode>
      <MuiPickersUtilsProvider locale={isArabic() ? "ar" : "en-au"} utils={MomentUtils}>
        <Provider store={store}>
        <StylesProvider jss={jss}>
          <App />
          </StylesProvider>
        </Provider>
      </MuiPickersUtilsProvider>
    </React.StrictMode>
  </Suspense>
  ,
  document.getElementById("root")
);

initializeFirebase();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
