import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useTranslation } from 'react-i18next';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import { getCountries, getEmploymentStatus, getSourceOfFunds } from "../../../redux-reducer/data/dataSlice";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, InputAdornment, Radio, RadioGroup, Tooltip } from "@material-ui/core";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";
import { countryToFlag, isArabic, toArabicDigits, hasFieldError, isEditStepper, getFieldError, eighteen } from "../../../utils";
import DatePicker from "../../common-components/DatePicker";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    input: {
        marginTop: "1rem"
    },
}));

export default function Details(props) {
    const formikProps = props.formikProps;

    const {
        formField: {
            firstName,
            lastName,
            maidenName,
            fatherName,
            gender,
            doBirth,
            employmentStatus,
            otherEmploymentStatus,
            sourcesFund,
            otherSourceFund,
            phone,
            nationality,
            residence
        }
    } = props;

   
    const classes = useStyles();
    const { t } = useTranslation();

    const countries = useSelector(getCountries);

    const sourceOfFunds = useSelector(getSourceOfFunds);

    const employmentStatusOptions = useSelector(getEmploymentStatus);

    const minDate = eighteen()

    const isOther = (id, options) => {
        if(options == employmentStatusOptions){
            for(var i=0; i < options.length; i++){
                if(id == options[i].id && (options[i].name == "Other")){         
                    return true;
                }
            }
            return false;
        }

        if(options == sourceOfFunds){
            for(var i=0; i < options.length; i++){
                if(options[i].name == "Other"){       
                    return id.includes(options[i].id)
                }
            }
        }  
    }

    const handleGenderChange = (e) => {
        if(e.target.value == "Male"){
            formikProps.setFieldValue(maidenName.name, null)
        }

        formikProps.setFieldValue(e.target.name, e.target.value)
    }

if(countries && employmentStatusOptions && sourceOfFunds)
    return (
        <React.Fragment>
            <Typography variant="h4" style={{ lineHeight: 1.33, textAlign: "center" }}>
               {t("stepper.kyc.step2")}
            </Typography>            

            <Box mt={3} /> 

            <Grid container direction="row" md={12} xs={12} >
                <Grid item md={5} xs={12} className={classes.input}>
                    <FormControl 
                        component="fieldset" 
                        error={(formikProps.touched[gender.name] && Boolean(formikProps.errors[gender.name])) || (isEditStepper() && formikProps.values[gender.name] == props.businessDetails[gender.name] && hasFieldError(props.businessDetails, gender.name))}
                    >
                        <FormLabel component="legend">{gender.label}</FormLabel>
                        <Box mt={1} />
                        <RadioGroup row 
                            name={gender.name}
                            value={formikProps.values[gender.name]} 
                            onChange={handleGenderChange} 
                            onBlur={formikProps.handleBlur(gender.name)} 
                        >
                            <FormControlLabel
                                value="Male" 
                                control={<Radio required checked={formikProps.values[gender.name] == "Male"}/>}
                                label={t("labels.gender.male")}
                            /> 
                            <FormControlLabel
                                value="Female" 
                                control={<Radio required checked={formikProps.values[gender.name] == "Female"}/>}
                                label={t("labels.gender.female")}
                            /> 
                        </RadioGroup>
                        <FormHelperText>{(formikProps.touched[gender.name] && formikProps.errors[gender.name]) || (formikProps.values[gender.name] == props.businessDetails[gender.name] && getFieldError(props.businessDetails, gender.name))}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12} />

                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        name={firstName.name}
                        label={firstName.label}
                        value={formikProps.values[firstName.name]}
                        onChange={props.setBusinessAttribute(formikProps.setFieldValue)} 
                        onBlur={formikProps.handleBlur(firstName.name)} 
                        error={(formikProps.touched[firstName.name] && Boolean(formikProps.errors[firstName.name])) || (isEditStepper() && formikProps.values[firstName.name] == props.businessDetails[firstName.name] && hasFieldError(props.businessDetails, firstName.name))}
                        helperText={(formikProps.touched[firstName.name] && formikProps.errors[firstName.name]) || (formikProps.values[firstName.name] == props.businessDetails[firstName.name] && getFieldError(props.businessDetails, firstName.name))}
                        fullWidth
                    />
                </Grid>

                {
                    formikProps.values.gender == "Female" ?

                    <Grid item md={5} xs={12} className={classes.input}>
                        <TextField
                            variant="outlined"
                            name={maidenName.name}
                            label={maidenName.label}
                            value={formikProps.values[maidenName.name]}
                            onChange={props.setBusinessAttribute(formikProps.setFieldValue)} 
                            onBlur={formikProps.handleBlur(maidenName.name)} 
                            error={(formikProps.touched[maidenName.name] && Boolean(formikProps.errors[maidenName.name])) || (isEditStepper() && formikProps.values[maidenName.name] == props.businessDetails[maidenName.name] && hasFieldError(props.businessDetails, maidenName.name))}
                            helperText={(formikProps.touched[maidenName.name] && formikProps.errors[maidenName.name]) || (formikProps.values[maidenName.name] == props.businessDetails[maidenName.name] && getFieldError(props.businessDetails, maidenName.name))}
                            fullWidth
                        />
                    </Grid>

                    : <Grid item md={5} xs={12} />

                }

                <Grid item md={2} xs={12} />

                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        name={fatherName.name}
                        label={fatherName.label}
                        value={formikProps.values[fatherName.name]}
                        onChange={props.setBusinessAttribute(formikProps.setFieldValue)} 
                        onBlur={formikProps.handleBlur(fatherName.name)} 
                        error={(formikProps.touched[fatherName.name] && Boolean(formikProps.errors[fatherName.name])) || (isEditStepper() && formikProps.values[fatherName.name] == props.businessDetails[fatherName.name] && hasFieldError(props.businessDetails, fatherName.name))}
                        helperText={(formikProps.touched[fatherName.name] && formikProps.errors[fatherName.name]) || (formikProps.values[fatherName.name] == props.businessDetails[fatherName.name] && getFieldError(props.businessDetails, fatherName.name))}
                        fullWidth
                    />
                </Grid>

                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        name={lastName.name}
                        label={lastName.label}
                        value={formikProps.values[lastName.name]}
                        onChange={props.setBusinessAttribute(formikProps.setFieldValue)} 
                        onBlur={formikProps.handleBlur(lastName.name)} 
                        error={(formikProps.touched[lastName.name] && Boolean(formikProps.errors[lastName.name])) || (isEditStepper() && formikProps.values[lastName.name] == props.businessDetails[lastName.name] && hasFieldError(props.businessDetails, lastName.name))}
                        helperText={(formikProps.touched[lastName.name] && formikProps.errors[lastName.name]) || (formikProps.values[lastName.name] == props.businessDetails[lastName.name] && getFieldError(props.businessDetails, lastName.name))}
                        fullWidth
                    />
                </Grid>

                <Grid item md={2} xs={12} />
               
                <Grid container direction="row" md={5} xs={12}>
                    <Grid item md={12} xs={12}>
                        <Typography variant="h6">{t("labels.dob")}</Typography>
                    </Grid>
                    <Tooltip title={t("kyc-details-step.age-desc")}>
                        <DatePicker 
                            value={formikProps.values[doBirth.name]}
                            handleDateChange={value => formikProps.setFieldValue(doBirth.name, value)}
                            InputProps={{ 
                                readOnly: true,
                                name: doBirth.name,
                                error: (formikProps.touched[doBirth.name] && Boolean(formikProps.errors[doBirth.name])) || (isEditStepper() && formikProps.values[doBirth.name] == props.businessDetails[doBirth.name] && hasFieldError(props.businessDetails, doBirth.name)),
                                helperText: (formikProps.touched[doBirth.name] && formikProps.errors[doBirth.name]) || (formikProps.values[doBirth.name] == props.businessDetails[doBirth.name] && getFieldError(props.businessDetails, doBirth.name))                            
                            }}
                            disableFuture={true}
                        />
                    </Tooltip>
                </Grid>
                
                <Grid item md={5} xs={12} className={classes.input}>
                    <Autocomplete
                        multiple
                        options={countries}
                        filterSelectedOptions
                        value={props.getOptionById(countries, formikProps.values[nationality.name])}
                        onChange={props.setMultipleOptionsAttribute(formikProps.setFieldValue, nationality.name)}
                        getOptionLabel={
                            (option) => isArabic() ? countryToFlag(option.code) + " " + option.nationality_arabic : countryToFlag(option.code) + " " + option.nationality_en 
                        }
                        renderOption={(option) => (
                            <React.Fragment>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                    style={{marginRight: '1rem'}}
                                />
                                {isArabic() ? option.nationality_arabic : option.nationality_en} 
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={nationality.label}
                                onBlur={formikProps.handleBlur(nationality.name)} 
                                error={(formikProps.touched[nationality.name] && Boolean(formikProps.errors[nationality.name])) || (isEditStepper() && JSON.stringify(formikProps.values[nationality.name]) == JSON.stringify(props.businessDetails[nationality.name]) && hasFieldError(props.businessDetails, nationality.name))}
                                helperText={(formikProps.touched[nationality.name] && formikProps.errors[nationality.name]) || (JSON.stringify(formikProps.values[nationality.name]) == JSON.stringify(props.businessDetails[nationality.name]) && getFieldError(props.businessDetails, nationality.name))}
                            />
                        )}
                    />
                </Grid>

                <Grid item md={2} xs={12} />

                <Grid item md={5} xs={12} className={classes.input}>
                    <Autocomplete
                        multiple
                        options={countries}
                        filterSelectedOptions
                        value={props.getOptionById(countries, formikProps.values[residence.name])}
                        onChange={props.setMultipleOptionsAttribute(formikProps.setFieldValue, residence.name)}
                        getOptionLabel={
                            (option) => isArabic() ? countryToFlag(option.code) + " " + option.name_arabic : countryToFlag(option.code) + " " + option.name 
                        }
                        renderOption={(option) => (
                            <React.Fragment>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                    style={{marginRight: '1rem'}}
                                />
                                {isArabic() ? option.name_arabic : option.name} 
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={residence.label}
                                onBlur={formikProps.handleBlur(residence.name)} 
                                error={(formikProps.touched[residence.name] && Boolean(formikProps.errors[residence.name])) || (isEditStepper() && JSON.stringify(formikProps.values[residence.name]) == JSON.stringify(props.businessDetails[residence.name]) && hasFieldError(props.businessDetails, residence.name))}
                                helperText={(formikProps.touched[residence.name] && formikProps.errors[residence.name]) || (JSON.stringify(formikProps.values[residence.name]) == JSON.stringify(props.businessDetails[residence.name]) && getFieldError(props.businessDetails, residence.name))}
                            />
                        )}
                    />
                </Grid>

                <Grid container md={5} xs={12} className={classes.input} direction="row">
                    <Grid item md={2} xs={2}>
                        <Autocomplete
                            options={countries.find((country) => country.code === "LB")}
                            value={countries.find((country) => country.code === "LB")}
                            disabled
                            getOptionLabel={
                                (option) => countryToFlag(option.code) 
                            }
                            renderOption={(option) => (
                                <React.Fragment>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                        style={{marginRight: '1rem'}}
                                    />
                                    { isArabic() ? option.name_arabic : option.name } 
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required
                                />
                            )}
                        />
                    </Grid>
                    
                    <Grid item md={10} xs={10}>
                        <TextField
                            variant="outlined"
                            name={phone.name}
                            label={phone.label}
                            value={formikProps.values[phone.name]}
                            onChange={props.setBusinessAttribute(formikProps.setFieldValue)} 
                            onBlur={formikProps.handleBlur(phone.name)} 
                            error={(formikProps.touched[phone.name] && Boolean(formikProps.errors[phone.name])) || (isEditStepper() && formikProps.values[phone.name] == props.businessDetails[phone.name] && hasFieldError(props.businessDetails, phone.name))}
                            helperText={(formikProps.touched[phone.name] && formikProps.errors[phone.name]) || (formikProps.values[phone.name] == props.businessDetails[phone.name] && getFieldError(props.businessDetails, phone.name))}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" type="text" disableTypography>{isArabic() ? toArabicDigits("+" + countries.find((country) => country.code === "LB").phone) : "+" + countries.find((country) => country.code === "LB").phone}</InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item md={2} xs={12} />

                <Grid item md={5} xs={12} className={classes.input}>
                    <Autocomplete
                        options={employmentStatusOptions}
                        getOptionLabel={(option) => isArabic() ? option.name_arabic : option.name}
                        filterSelectedOptions
                        value={employmentStatusOptions.find((status) => status.id === formikProps.values[employmentStatus.name])}
                        onChange={props.setSingleOptionAttribute(formikProps.setFieldValue, employmentStatus.name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={employmentStatus.label}
                                onBlur={formikProps.handleBlur(employmentStatus.name)} 
                                error={(formikProps.touched[employmentStatus.name] && Boolean(formikProps.errors[employmentStatus.name])) || (isEditStepper() && JSON.stringify(formikProps.values[employmentStatus.name]) == JSON.stringify(props.businessDetails[employmentStatus.name]) && hasFieldError(props.businessDetails, employmentStatus.name))}
                                helperText={(formikProps.touched[employmentStatus.name] && formikProps.errors[employmentStatus.name]) || (JSON.stringify(formikProps.values[employmentStatus.name]) == JSON.stringify(props.businessDetails[employmentStatus.name]) && getFieldError(props.businessDetails, employmentStatus.name))}
                            />
                        )}
                    />

                    {
                        formikProps.values[employmentStatus.name] &&
                            isOther(formikProps.values[employmentStatus.name], employmentStatusOptions) &&
                            <Grid item md={12} xs={12} className={classes.input}>
                                <Box mt={2} />
                                <TextField
                                    variant="outlined"
                                    name={otherEmploymentStatus.name}
                                    label={otherEmploymentStatus.label}
                                    value={formikProps.values[otherEmploymentStatus.name]}
                                    onChange={formikProps.handleChange(otherEmploymentStatus.name)}
                                    onBlur={formikProps.handleBlur(otherEmploymentStatus.name)} 
                                    error={(isOther(formikProps.values[employmentStatus.name], employmentStatusOptions) && formikProps.touched[otherEmploymentStatus.name] && Boolean(formikProps.errors[otherEmploymentStatus.name])) || (isEditStepper() && formikProps.values[otherEmploymentStatus.name] == props.businessDetails[otherEmploymentStatus.name] && hasFieldError(props.businessDetails, otherEmploymentStatus.name))}
                                    helperText={(formikProps.touched[otherEmploymentStatus.name] && formikProps.errors[otherEmploymentStatus.name]) || (formikProps.values[otherEmploymentStatus.name] == props.businessDetails[otherEmploymentStatus.name] && getFieldError(props.businessDetails, otherEmploymentStatus.name))}
                                    fullWidth
                                    required
                                />
                            </Grid> 
                    }
                </Grid>

                <Grid item md={5} xs={12} className={classes.input}>
                    <Autocomplete
                        multiple
                        options={sourceOfFunds}
                        getOptionLabel={(option) => isArabic() ? option.name_arabic : option.name}
                        filterSelectedOptions
                        value={props.getOptionById(sourceOfFunds, formikProps.values[sourcesFund.name])}
                        onChange={props.setMultipleOptionsAttribute(formikProps.setFieldValue, sourcesFund.name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={sourcesFund.label}
                                onBlur={formikProps.handleBlur(sourcesFund.name)} 
                                error={(formikProps.touched[sourcesFund.name] && Boolean(formikProps.errors[sourcesFund.name])) || (isEditStepper() && JSON.stringify(formikProps.values[sourcesFund.name]) == JSON.stringify(props.businessDetails[sourcesFund.name]) && hasFieldError(props.businessDetails, sourcesFund.name))}
                                helperText={(formikProps.touched[sourcesFund.name] && formikProps.errors[sourcesFund.name]) || (JSON.stringify(formikProps.values[sourcesFund.name]) == JSON.stringify(props.businessDetails[sourcesFund.name]) && getFieldError(props.businessDetails, sourcesFund.name))}
                            />
                        )}
                    />
                    {
                        formikProps.values[sourcesFund.name] && 
                           isOther(formikProps.values[sourcesFund.name], sourceOfFunds) &&
                           
                            <Grid item md={12} xs={12} className={classes.input}>
                                <Box mt={2} />
                                <TextField
                                    variant="outlined"
                                    multiline
                                    name={otherSourceFund.name}
                                    label={otherSourceFund.label}
                                    value={formikProps.values[otherSourceFund.name]}
                                    onChange={props.setBusinessAttribute(formikProps.setFieldValue)} 
                                    onBlur={formikProps.handleBlur(otherSourceFund.name)} 
                                    error={(isOther(formikProps.values[sourcesFund.name], sourceOfFunds) && formikProps.touched[otherSourceFund.name] && Boolean(formikProps.errors[otherSourceFund.name])) || (isEditStepper() && formikProps.values[otherSourceFund.name] == props.businessDetails[otherSourceFund.name] && hasFieldError(props.businessDetails, otherSourceFund.name))}
                                    helperText={(formikProps.touched[otherSourceFund.name] && formikProps.errors[otherSourceFund.name]) || (formikProps.values[otherSourceFund.name] == props.businessDetails[otherSourceFund.name] && getFieldError(props.businessDetails, otherSourceFund.name))}
                                    fullWidth
                                    required
                                />
                            </Grid> 
                    }
                </Grid>
            </Grid>       
        </React.Fragment>
    );

    return <LoadingDisplay />
}
