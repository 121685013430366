import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Box, Grid, Chip, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { numberWithCommos } from "../../utils";
import moment from "moment";
import Dropzone from "./DropzoneArea";
import { AttachFile } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  input: {
      marginTop: "1rem"
  },
}));

export default function FundDialog({
  title,
  leftButtonTitle,
  rightButtonTitle,
  onClickLeftButton,
  onClickRightButton,
  open,
  close,
  fundProof,
  setFundProof,
  cumulativeAmount,
  unitaryAmount,
  profileDetails,
  transactionLimit
}) {

  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={close}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("fund-dialog.content", {amount: transactionLimit})}
        </DialogContentText>
        <Box mt={3} />
        <Grid container direction="row" md={12} xs={12} spacing={1}>
          <Grid item md={6} xs={12} className={classes.input}>
            <TextField
              variant="standard"
              label={t("labels.name")}
              fullWidth
              value={
                profileDetails.first_name && profileDetails.last_name ?
                profileDetails.first_name + " " + profileDetails.last_name :
                profileDetails.name
              }
              disabled
            />
          </Grid>

          <Grid item md={6} xs={12} className={classes.input}>
            <TextField
              variant="standard"
              label={t("fund-dialog.date-label")}
              fullWidth
              value={moment(new Date()).format("yyyy/MM/DD")}
              disabled
            />
          </Grid>

          <Grid item md={6} xs={12} className={classes.input}>
            <TextField
              variant="standard"
              label={t("fund-dialog.unitary-amount")}
              fullWidth
              value={unitaryAmount}
              disabled
            />
          </Grid>

          <Grid item md={6} xs={12} className={classes.input}>
            <TextField
              variant="standard"
              label={t("fund-dialog.cumulative-amount")}
              fullWidth
              value={cumulativeAmount}
              disabled
            />
          </Grid>

          <Grid item md={12} xs={12} className={classes.input}>
            <TextField
              variant="outlined"
              required
              label={t("fund-dialog.details-label")}
              multiline
              fullWidth
              rowsMax={4}
              value={fundProof.details}
              onChange={(e) => setFundProof({...fundProof, details: e.target.value})}
            />
          </Grid>

          <Box mt={2} />

          <Grid item md={12} xs={12} className={classes.input}>
            <Typography variant="h6">{t("fund-dialog.document-label")}</Typography>
            {
              fundProof.document_path ? 
                <Chip
                    icon={<AttachFile />}
                    variant='outlined'
                    label={"fund_document"}
                    onDelete={() => setFundProof({...fundProof, document_path: null})}
                />
            :
              <Dropzone
                handleFileChange={(file) => setFundProof({...fundProof, document_path: file})}  
                documentName={"fund_document"}
              />
            }
          </Grid>

          <Grid item  md={6} xs={12} className={classes.input}>
            <FormControl required component="fieldset">
              <FormLabel component="legend">{t("fund-dialog.owner.label")}</FormLabel>
              <Box mt={1} />
              <RadioGroup row value={fundProof.beneficial_right_owner_myself} onChange={(e) => setFundProof({...fundProof, beneficial_right_owner_myself: e.target.value == "myself" ? true : false})} name="beneficial_right_owner">
                <FormControlLabel
                  value="myself" 
                  control={<Radio required checked={fundProof.beneficial_right_owner_myself === true}/>}
                  label={t("fund-dialog.owner.myself-label")}
                /> 
                <FormControlLabel
                  value="other" 
                  control={<Radio required checked={fundProof.beneficial_right_owner_myself === false}/>}
                  label={t("fund-dialog.owner.other-label")}
                /> 
              </RadioGroup>
            </FormControl>
            {
              fundProof.beneficial_right_owner_myself == false &&
              <TextField
                variant="outlined"
                required
                label={t("fund-dialog.owner.other-name-label")}
                value={fundProof.beneficial_right_owner_other}
                onChange={(e) => setFundProof({...fundProof, beneficial_right_owner_other: e.target.value})}
                fullWidth
              />
            }
          </Grid>
          
          <Grid item md={6} xs={12} className={classes.input}>
            <TextField
              variant="outlined"
              required
              label={t("labels.signature")}
              value={fundProof.signature}
              onChange={(e) => setFundProof({...fundProof,signature: e.target.value})}
              fullWidth
            />
          </Grid>
        </Grid>     
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickLeftButton} color="primary">
          {leftButtonTitle}
        </Button>
        <Button onClick={onClickRightButton} color="primary" autoFocus>
          {rightButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
