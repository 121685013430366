import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import commonStyles from "../../../commonStyles";
import { useSelector } from "react-redux";
import { getOrganizationDetails } from "../../../redux-reducer/organization/organizationSlice";
import OrganizationSummary from "./summary";
import OrganizationDetails from "./details";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";
import EditProfileButton from "../../common-components/EditProfileButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f6f6f6",
    padding: "5rem",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  ...commonStyles,
}));

export default function OrganizationProfile() {
  const classes = useStyles();
  const organizationDetails = useSelector(getOrganizationDetails);

  if(organizationDetails)
    return (
        <Grid container direction="row" md={12} xs={12} className={classes.root}>
          <Grid item md={4} xs={12}>
            <OrganizationSummary organizationDetails={organizationDetails} />
          </Grid>

          <Grid item md={1} xs={12} />

          <Grid item md={7} xs={12}>
            <OrganizationDetails organizationDetails={organizationDetails} />
          </Grid>

          <EditProfileButton ongoingOrders={organizationDetails.ongoing_orders}/>
        </Grid>   
    )
  return <LoadingDisplay />
}
