import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStylesCommon } from "../common-ui-fit/commonFields";
import { LoadingDisplay } from "../common-ui-fit/LoadingDisplay";
import Match from "./Match";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  dividerSettings: {
    backgroundColor: theme.palette.secondary.main,
  },
  noteSetting: {
    textAlign: "center",
  },
  messageAreaSettings: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  agreedDateSettings: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  agreedDateSettings: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

const MatchList = ({
  orderDetails,
  matches,
  openSuccessNotification,
  openFailedNotification,
}) => {

  const theme = useTheme();
  const { t } = useTranslation();

  if (!orderDetails && !matches) {
    return (
      <Grid container direction="column">
        <Grid item>
          <Box mt={1} />
        </Grid>
        <Grid item>
          <LoadingDisplay heigth={"15rem"} />
        </Grid>
      </Grid>
    );
  }

  const matchesList = () => {
    if (matches.length == 0) {
      return <> </>;
    }
    return matches.map((match, index) => {

      return (
        <Match
          match={match}
          orderDetails={orderDetails}
          openFailedNotification={openFailedNotification}
          openSuccessNotification={openSuccessNotification}
        />
      );
    });
  };

  return (
    <>
      <Grid container direction="column" justify="flex-start">
        {matchesList()}
      </Grid>
    </>
  );
};

export default MatchList;
