import React from 'react';
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player';
import heroVideo from './heroVideo.mp4';
import { Grid } from '@material-ui/core';
import RateCard from './RateCard';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      height: '100vh',
      position: 'relative',
      '& video': {
        objectFit: 'cover',
      },
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 84, 84, 0.6)',
    },
    title: {
      paddingBottom: theme.spacing(4),
      color: '#fff',
      textAlign: 'center',
      [theme.breakpoints.down("md")]: {
        paddingLeft: '1rem', 
        paddingRight: '1rem'
      }, 
    },

  }));

const Hero = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
  
    return (
      <Grid container className={classes.root}>
        <ReactPlayer
          url={heroVideo}
          playing
          loop
          muted
          width="100%"
          height="100%"
        />
        <div className={classes.overlay}>
          <Box mt={10} />
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="#fff"
          >
            <Typography variant="h5" className={classes.title}>
                {t("landing-page.hero.title1")}
            </Typography>
            <Typography variant="h4" className={classes.title}>
              {t("landing-page.hero.title2")}
            </Typography>
            <Typography variant="h5" className={classes.title}>
              {t("landing-page.hero.title3")}
            </Typography>

            <RateCard />
          </Box>

        </div>
      </Grid>
    );
};

export default Hero;