import React, { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import PublishIcon from "@material-ui/icons/Publish";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { theme } from "../../App";
import { useTranslation } from 'react-i18next';
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dropzone: {
        fontSize: "0.8rem",
        marginTop: "1.8rem",
    },
    previewChip: {
        marginTop: "0.4rem",
        color: theme.palette.secondary.main
    },  
    avatar: {
        color: theme.palette.secondary.main,
        backgroundColor: 'transparent'
    },
    deleteIcon: {
        color: theme.palette.error.main
    }
  }));

export default function Dropzone(props) {
    const classes = useStyles();
    const { t } = useTranslation()

    const document_name = props.documentName;

    const handleChange = (files) => {
        let file = files[0];
        if(!file) return
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let file = reader.result;
            props.handleFileChange(file, document_name);
        };       
    }

    const acceptedFiles = "*.jpeg, *.png, *.pdf, *.docx, *.pptx"

    return (
        <React.Fragment>
            <DropzoneArea
                Icon={PublishIcon}
                filesLimit={1}
                maxFileSize={6000000}
                acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                onChange={ (files) => handleChange(files) }
                dropzoneText={t("dropzone.text")}
                dropzoneParagraphClass={classes.dropzone}
                showPreviewsInDropzone={false}
            />
            <Typography variant="caption">{t("dropzone.accepted-files", {files: acceptedFiles})}</Typography>
        </React.Fragment>
    )
}