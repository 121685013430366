import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { numberWithCommos, resizeFlagImage } from "../../utils.js";
import { useStylesCommon } from "../common-ui-fit/commonFields";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  proposedItemSettings: {
    paddingTop: "0.2rem",
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem",
    paddingBottom: "0.5rem",
    borderRadius: "4px",
    border: `1px solid #25DAC5 `,
    backgroundColor: "rgba(37, 218, 197, 0.05)",
    [theme.breakpoints.down("md")]: {
      width: "-webkit-fill-available",
    },
  },
  amountFontSettings: {
    fontSize: "2.4rem",
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      lineHeight: 1,
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: "0",
    },
  },
  centerText: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
}));

export const ProposedAmount = ({ match, orderDetails }) => {
  const commonClasses = useStylesCommon();
  const classes = useStyles();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const orderType = () => {
    if(orderDetails.order_type == "buy")
      return t("sell-label")
    if(orderDetails.order_type == "sell")
      return t("buy-label")
  };

  const amountDisplayed = () => {
    if (orderDetails.normalized_type != orderDetails.order_type) {
      return Math.round(
        parseFloat(match.amount_suggested) * parseFloat(match.match_rate)
      );
    }
    var number = match.amount_suggested.toFixed(2);
    number = number.toString().replace(/\.00$/,'');
    return number
  };

  return (
    <>
      <Box className={classes.proposedItemSettings} xs={12} md={"auto"}>
        <Typography
          variant="h6"
          color="secondary"
          className={classes.centerText}
        >
          {orderType()}
        </Typography>

        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.centerText}
          justify={mediaQuery ? "center" : ""}
        >
          <Grid item xs={12} lg={"auto"} md={"auto"}>
            <Typography
              variant="h4"
              className={classes.amountFontSettings}
              style={{ marginRight: 6 }}
            >
              {numberWithCommos(amountDisplayed())}
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={match.currency_flag}
              className={commonClasses.flagSettings}
            />
          </Grid> 
          <Grid item style={{ marginLeft: "8px" }}>
            <Typography variant="h5">{orderDetails.base_currency}</Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
