import { Box, Grid, Link, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  cedaroxygenLogoGreen: {
    width: "15rem",
  },
}));

const LandingPageFooter = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktopVersion = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems={isDesktopVersion ? "center" : "flex-start"}
      style={{ marginLeft: isDesktopVersion ? "" : "16px" }}
    >
      <Grid item>
        <Box mt={8} />
        <img
          src="/images/cedarxneo-logo/cedarxneo-green-logo.png"
          alt="cedar oxygen logo green"
          className={classes.cedaroxygenLogoGreen}
        />
      </Grid>
      <Grid item>
        <hr
          style={{
            border: "none",
            borderTop: "2px solid #EBEAED",
            width: isDesktopVersion ? "70rem" : "10rem",
          }}
        />
        <Box mb={2} />
      </Grid>
      <Grid
        container
        direction={isDesktopVersion ? "row" : "column"}
        justify="space-evenly"
        alignItems="flex-start"
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6">About us</Typography>
            </Grid>
            <Grid item>
              <Box mt={1} />
              <Link
                href="https://cedaroxygen.com/our-story/"
                variant="subtitle1"
                target="_blank"
              >
                Our Story
              </Link>
              <Box mb={1} />
            </Grid>
            <Grid item>
              <Box mt={1} />
              <Link
                href="https://cedaroxygen.com/the-team/"
                variant="subtitle1"
                target="_blank"
              >
                The Team
              </Link>
              <Box mb={1} />
            </Grid>
            <Grid item>
              <Box mt={1} />
              <Link
                href="https://cedaroxygen.com/our-partners/"
                variant="subtitle1"
                target="_blank"
              >
                Our Partners
              </Link>
              <Box mb={1} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6">The Fund</Typography>
            </Grid>
            <Grid item>
              <Box mt={1} />
              <Link
                href="https://cedaroxygen.com/economic-recovery-platform/"
                variant="subtitle1"
                target="_blank"
              >
                Our Investment Approch
              </Link>
              <Box mb={1} />
            </Grid>
            <Grid item>
              <Box mt={1} />
              <Link
                href="https://cedaroxygen.com/esg-impact/"
                variant="subtitle1"
                target="_blank"
              >
                Impact Investing ESG
              </Link>
              <Box mb={1} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6">Links</Typography>
            </Grid>
            <Grid item>
              <Box mt={1} />
              <Link
                href="https://cedaroxygen.com/borrowers/"
                variant="subtitle1"
                target="_blank"
              >
                Borrowers
              </Link>
              <Box mb={1} />
            </Grid>
            <Grid item>
              <Box mt={1} />
              <Link
                href="https://cedaroxygen.com/insights/"
                variant="subtitle1"
                target="_blank"
              >
                Insights
              </Link>
              <Box mb={1} />
            </Grid>
            <Grid item>
              <Box mt={1} />
              <Link
                href="https://cedaroxygen.com/contact-us/"
                variant="subtitle1"
                target="_blank"
              >
                Contact Us
              </Link>
              <Box mb={1} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
      <Box mb={6} />
    </Grid>
  );
};

export default LandingPageFooter;
