import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

export default function InfoDialog({open,onClose,title,subtitle}) {
    const { t } = useTranslation();
    return (  
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                {subtitle}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={onClose} color="primary">
                {t("button.ok")}
            </Button>
            </DialogActions>
        </Dialog>
    );
}