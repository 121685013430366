import React, { useEffect, useRef } from "react"
import { Button, ClickAwayListener, Grid, Link, makeStyles, Popover, Tooltip, Typography, withStyles } from "@material-ui/core";


export const Map = ({locations, view}) => {
    const mapRef = useRef()
    let map;
    
    let infoWindow = new window.google.maps.InfoWindow();

    const showMarkers = (branches) => {
        branches.forEach((branch, i) => {
            const {branch_name, region, address} = branch

            const marker = new window.google.maps.Marker({
                position: {
                  lat: Number(branch.latitude),
                  lng: Number(branch.longitude)
                }
            });

            marker.addListener("click", () => {
                infoWindow.setContent(
                    `<div style="padding: 1rem">` +
                    `<h3>` +
                    `${branch_name}` +
                    `</h3>` +
                    `<p>${address}</p>` +
                    `<p>${region}</p>` +
                    `<p>Lebanon</p>` +
                    `<p><a target="_blank" href='https://maps.google.com/?q="${branch.latitude},${branch.longitude}"'>View on google map</a></p>` +
                    '</div>'
                );
        
                infoWindow.open({
                  anchor: marker,
                  map,
                  shouldFocus: true
                });
              });
        
              marker.setMap(map);

        });      
    }

    useEffect(() => {
        map = new window.google.maps.Map(mapRef.current, {
            zoom: view.zoomLevel,
            center: view.center,
            mapTypeControl: false
        });

        if (mapRef.current) {
            showMarkers(locations);
        }
    }, [locations.length])

    return (
        <div ref={mapRef} id="map" style={{ width: "100%", height: "100%" }} />
    );
}