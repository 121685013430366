import { configureStore } from "@reduxjs/toolkit";
import organizationReducer from "../redux-reducer/organization/organizationSlice";
import orderTypeReducer from "../redux-reducer/orderType/orderTypeSlice";
import centrifugeReducer from "../redux-reducer/centrifuge/centrifugeSlice.js";
import modeReducer from "../redux-reducer/mode/modeSlice.js";
import dataReducer from "../redux-reducer/data/dataSlice";
import profileTypeReducer from "../redux-reducer/profileType/profileTypeSlice"
import businessReducer from "../redux-reducer/business/businessSlice";
import individualReducer from "../redux-reducer/individual/individualSlice";

const store = configureStore({
  reducer: {
    organizationInfo: organizationReducer,
    orderType: orderTypeReducer,
    realtimeUpdates: centrifugeReducer,
    mode: modeReducer, 
    data: dataReducer,
    profileType: profileTypeReducer,
    businessInfo: businessReducer,
    individualInfo: individualReducer
  },
});

export default store;
