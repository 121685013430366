import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Link,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from '@material-ui/icons/Instagram';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  footerContainer: {
      backgroundColor: theme.palette.primary.main,
      textAlign: "center",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
          paddingLeft: '3rem',
          paddingRight: '3rem'
      },
  },
}));

function SocialMediaSection(props) {
  const theme = useTheme();
  const classes = useStyles();
  const isDesktopVersion = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <IconButton
            href="https://www.linkedin.com/company/cedarxneo"
            target="_blank"
          >
            <LinkedInIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            href="https://www.facebook.com/CedarXneo"
            target="_blank"
          >
            <FacebookIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            href="https://twitter.com/cedaroxygen"
            target="_blank"
            alt="twitter"
          >
            <TwitterIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            href="https://www.instagram.com/cedarxneo/"
            target="_blank"
            alt="instagram"
          >
            <InstagramIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Box mt={1} />

      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} className={classes.footerContainer} md={12} xs={12}>
        <Grid item style={{ color: "white", fontSize: "14px" }}>
          © {new Date().getFullYear()} CedarXneo. {t("landing-page.footer.rights")}
        </Grid>
        <Grid item >
          <Link
            href="https://cedaroxygen.com/borrowers/"
            variant="subtitle1"
            target="_blank"
            color="secondary"
            style={{ fontSize: "14px" }}
          >
            {t("landing-page.footer.conditions")}
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default SocialMediaSection;
