import React from "react";
import { Grid,  makeStyles, Typography, } from "@material-ui/core";
import { isArabic, numberWithCommos, toArabicDigits } from "../../utils.js";
import { useStylesCommon } from "../common-ui-fit/commonFields";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    amountFontSettings: {
      fontSize: "2.4rem",
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        lineHeight: 1,
        fontSize: "1.4rem"
      },
      [theme.breakpoints.up("md")]: {
        lineHeight: "0",
      },
    },
}));

export default function CustomizedAmountBox (props) {
    const classes = useStyles();
    const orderDetails = props.orderDetails;
    const commonClasses = useStylesCommon();
    const { t } = useTranslation();


    const orderType = (type) => {
        if(type == "buy")
            return t("buy-label")
        if(type == "sell")
            return t("sell-label")
    };

    return (
        <>
            <Typography variant="h6" color="secondary" style={{ textTransform: "capitalize" }} >
                {orderType(orderDetails.order_type)}
            </Typography>

            <Grid container direction="row" alignItems="center" spacing={1} style={{justifyContent: "center", textAlign: "center"}} >
                <Grid item xs={12} md={6}>
                    <Typography className={classes.amountFontSettings}>
                        {numberWithCommos(orderDetails.amount)}
                    </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                    <Grid container direction="row" style={{justifyContent: "center"}}>
                        <Grid item>
                            <img
                                src={orderDetails.base_currency_flag}
                                className={commonClasses.flagSettings}
                            />
                        </Grid>
                        <Grid item style={{alignSelf: "center"}}>
                            <Typography variant="h5">
                                {orderDetails.base_currency}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}