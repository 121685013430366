import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getUser } from "../services/authService";

export function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (getUser() != null && getUser()["onboarded"] == 0) {
          return (
            <Redirect
              to={{
                pathname: `/redirect`,
                state: { from: location },
              }}
            />
          );
        }
        return getUser() != null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
