import React from 'react';
import { makeStyles } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import ChatIcon from '@material-ui/icons/Chat';


const useStyles = makeStyles((theme) => ({
fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 100
    },
  }));

export default function MessageFab(){
    const classes = useStyles();
    return (
        <Fab color="primary" aria-label="message" className={classes.fab}>
            <ChatIcon />
        </Fab>
    )
}