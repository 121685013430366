import { httpGet, httpPost, httpPut } from "../httpcommon";
import { getUser } from "../services/authService";

export const businessApis = {
  getBusinessInformation: async function (profileId) {
    try {
      return await httpGet("/enterprise-information/" + profileId);
    } catch (error) {
      console.log(error.message);
    }
  },

  register: async function (id, activationkey, data) {
    try {
      return await httpPost("/fill-enterprise-information/" + id + "/" + activationkey, data);
    } catch (error) {
      console.log(error.message);
    }
  },

  edit: async function (masked_id, data) {
    try {
      return await httpPut("/enterprise/" + masked_id, data);
    } catch (error) {
      console.log(error.message);
    }
  },

};