import axios from "axios";
import {SERVER_BASE_URL} from "./env";
import {getApiToken, getUser, logout, setUser} from "./services/authService";

const axiosInstance = axios.create({
    baseURL: SERVER_BASE_URL,
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
    }
});

function handlePostApiCall(r){ 
    if(r.data.refresh_needed)
    {
        logout();
    }
}

export function refreshToken(){
    let apiToken = getApiToken();
    if(apiToken != null){
        let res = axiosInstance.post('/refresh-token', {}, {
            headers: {'Authorization': "Bearer " + apiToken},
        });
        res.then((result) => {if(result.data.token){
            setUser({token: result.data.token});
        }});
    }
}

function handlePreApiCall(){
    refreshToken();
}

export function httpPost(url, data) {
    handlePreApiCall();
    if (getApiToken() != null) {
        let res = 
            axiosInstance.post(url, data, {
                headers: {'Authorization': "Bearer " + getApiToken()},
            });
        res.then(handlePostApiCall);
        return res;
    }

    return axiosInstance.post(url, data);
}

export function httpDelete(url) {    
    handlePreApiCall();
    if (getApiToken() != null) {
        let res = axiosInstance.delete(url, {
            headers: {'Authorization': "Bearer " + getApiToken()}
        });
        res.then(handlePostApiCall);
        return res;
    }
    
    return axiosInstance.delete(url);
}

export function httpGet(url) {
    handlePreApiCall();
    if (getApiToken() != null) {
        let res =  axiosInstance.get(url, {
            headers: {'Authorization': "Bearer " + getApiToken()}
        });
        res.then(handlePostApiCall);
        return res;
    }

    return axiosInstance.get(url);
}

export function httpPut(url, data = {}) {
    handlePreApiCall();
    if (getApiToken() != null) {
        let res =  axiosInstance.put(url, data, {
            headers: {'Authorization': "Bearer " + getApiToken()},
        });
        res.then(handlePostApiCall);
        return res;
    }

    return axiosInstance.put(url, data);
}

export default axiosInstance;
