import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { httpGet, httpPut } from "../../httpcommon";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { FRONT_BASE_URL } from "../../env";
import { theme } from "../../App";
import commonStyles from "../../commonStyles";
import Box from "@material-ui/core/Box";
import DraftsIcon from "@material-ui/icons/Drafts";
import { useTranslation, Trans } from 'react-i18next';


const useStyle = makeStyles((theme) => ({
  ...commonStyles,
  container: {
    height: "100vh",
  },
}));

function EmailVerified(props) {
  const classes = useStyle();
  let { id, activationkey } = useParams();
  const [messageDisplayed, setMessageDisplayed] = useState("Loading...");
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      let res = await httpPut("/email-verification", {
        id: id,
        key: activationkey,
      });
      if (res.data.success) {
        setMessageDisplayed(res.data.message);
        return;
      }
      setMessageDisplayed(res.data.message);
    })();
  }, []);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <DraftsIcon
        style={{ fontSize: "14rem", color: theme.palette.secondary.main }}
      />
      <Box mt={2} />
      <Box>
        <Typography variant="h3" color="primary">
          {messageDisplayed}
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="h5" className={classes.bold}>
          {t("email-verified.message")} <a href={`${FRONT_BASE_URL}/login`}>{t("login")}</a>
        </Typography>
      </Box>
  </Grid>
  );
}

export default EmailVerified;
