import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from "react-redux";
import { getCountries } from "../../../redux-reducer/data/dataSlice";
import commonStyles from "../../../commonStyles";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";
import { countryToFlag, isArabic, hasFieldError, isEditStepper, getFieldError } from "../../../utils";
import DatePicker from "../../common-components/DatePicker";

const useStyles = makeStyles((theme) => ({
    avatarCustomized: {
        height: "7.2rem",
        width: "7.2rem",
        border: "solid 1px",
        borderColor: theme.palette.secondary.main,
        borderRadius: "3px",
    },
    dropzone: {
        fontSize: "0.8rem",
        marginTop: "1.8rem",
    },

    input: {
        marginTop: "1rem"
    },
    ...commonStyles,
}));

export default function Identification(props) {
    const formikProps = props.formikProps;

    const {
        formField: {
            organizationName,
            registrationCountries,
            registrationDate,
        }
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const countries = useSelector(getCountries);

    if(countries)
        return (
            <React.Fragment>
                <Grid container md={12} xs={12} direction="column">
                    <Grid item md={12} xs={12}>
                        <Typography variant="h4" style={{ lineHeight: 1.33, textAlign: "center" }}>
                            {t("stepper.kyb.step1")}
                        </Typography>
                    </Grid>

                    <Box mt={5} />            
                </Grid>  


                <Grid container direction="row" md={12} xs={12} >
                    <Grid item md={5} xs={12} className={classes.input}>
                        <TextField
                            variant="outlined"
                            name={organizationName.name}
                            label={organizationName.label}
                            value={formikProps.values[organizationName.name]}
                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                            onBlur={formikProps.handleBlur(organizationName.name)} 
                            error={(formikProps.touched[organizationName.name] && Boolean(formikProps.errors[organizationName.name])) || (isEditStepper() && formikProps.values[organizationName.name] == props.organizationDetails[organizationName.name] && hasFieldError(props.organizationDetails, organizationName.name))}
                            helperText={(formikProps.touched[organizationName.name] && formikProps.errors[organizationName.name]) || (formikProps.values[organizationName.name] == props.organizationDetails[organizationName.name] && getFieldError(props.organizationDetails, organizationName.name))}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={2} xs={12} />
                    
                    <Grid item md={5} xs={12} className={classes.input}>
                        <Autocomplete
                            options={countries}
                            getOptionLabel={
                                (option) => isArabic() ? countryToFlag(option.code) + " " + option.name_arabic : countryToFlag(option.code) + " " + option.name 
                            }
                            value={countries.find((country) => country.id === formikProps.values[registrationCountries.name])}
                            onChange={props.setSingleOptionAttribute(formikProps.setFieldValue, registrationCountries.name)}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                        style={{marginRight: '1rem'}}
                                    />
                                    {isArabic() ? option.name_arabic : option.name} 
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    name={registrationCountries.name}
                                    label={registrationCountries.label}
                                    onBlur={formikProps.handleBlur(registrationCountries.name)} 
                                    error={(formikProps.touched[registrationCountries.name] && Boolean(formikProps.errors[registrationCountries.name])) || (isEditStepper() && JSON.stringify(formikProps.values[registrationCountries.name]) == JSON.stringify(props.organizationDetails[registrationCountries.name]) && hasFieldError(props.organizationDetails, registrationCountries.name))}
                                    helperText={(formikProps.touched[registrationCountries.name] && formikProps.errors[registrationCountries.name]) || (JSON.stringify(formikProps.values[registrationCountries.name]) == JSON.stringify(props.organizationDetails[registrationCountries.name]) && getFieldError(props.organizationDetails, registrationCountries.name))}
                                />
                            )}
                        />
                    </Grid>

                    <Grid container direction="row" md={5} xs={12} className={classes.input}>
                        <Grid item md={12} xs={12}>
                                <Typography variant="h6">{t("company-identification-step.date-label")}</Typography>
                        </Grid>
                        <DatePicker 
                            value={formikProps.values[registrationDate.name]}
                            handleDateChange={value => { formikProps.setFieldValue(registrationDate.name, value); props.setEdit(true) }}
                            InputProps={{ 
                                readOnly: true,
                                name: registrationDate.name,
                                error: (formikProps.touched[registrationDate.name] && Boolean(formikProps.errors[registrationDate.name])) || (isEditStepper() && formikProps.values[registrationDate.name] == props.organizationDetails[registrationDate.name] && hasFieldError(props.organizationDetails, registrationDate.name)),
                                helperText: (formikProps.touched[registrationDate.name] && formikProps.errors[registrationDate.name]) || (formikProps.values[registrationDate.name] == props.organizationDetails[registrationDate.name] && getFieldError(props.organizationDetails, registrationDate.name))                            
                            }}
                            disableFuture={true}
                            maxDate={new Date()}
                        />
                    </Grid>

                </Grid>       
            </React.Fragment>
        );
    return <LoadingDisplay />
}
