import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { numberWithCommos, resizeFlagImage } from "../../utils.js";
import { useStylesCommon } from "../common-ui-fit/commonFields";

const useStyles = makeStyles((theme) => ({
  proposedItemSettings: {
    paddingTop: "0.2rem",
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem",
    paddingBottom: "0.5rem",
    borderRadius: "4px",
    border: `1px solid #25DAC5 `,
    backgroundColor: "rgba(37, 218, 197, 0.05)",
    [theme.breakpoints.down("md")]: {
      width: "-webkit-fill-available",
    },
  },
  amountFontSettings: {
    fontSize: "2.4rem",
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      lineHeight: 1,
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: "0",
    },
  },
  centerText: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
}));

export const ProposedRate = ({ match, orderDetails }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const commonClasses = useStylesCommon();

  const differanceRate = (match) => {
    let result =
      parseFloat(
        (orderDetails.rate - match.rate_suggested) / orderDetails.rate
      ) * 100;
    return result.toFixed(2);
  };

  return (
    <>
      <Box className={classes.proposedItemSettings}>
        <Typography
          variant="h6"
          color="secondary"
          className={classes.centerText}
        >
          {t("order-details-page.proposed-label")}
        </Typography>

        <Grid
          container
          direction="row"
          alignItems="center"
          justify={matchMedia ? "center" : ""}
          className={classes.centerText}
        >
          <Grid item xs={12} lg={"auto"} md={"auto"}>
            <Typography
              variant="h4"
              className={classes.amountFontSettings}
              style={{ marginRight: 6 }}
            >
              {" "}
              {numberWithCommos(
                match.match_rate ? match.match_rate : 0
              )}
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={orderDetails.base_currency_flag}
              className={commonClasses.flagSettings}
            />
          </Grid>
          <Grid item style={{ marginLeft: "8px" }}>
            <Typography variant="h5">{orderDetails.currency_1}</Typography>
          </Grid>
          <Grid item style={{ marginLeft: "8px" }}>
            <Typography variant="h5">-</Typography>
          </Grid>
          <Grid item style={{ marginLeft: "8px" }}>
            <Typography variant="h5">{orderDetails.currency_2}</Typography>
          </Grid>

          <Grid item>
            <img
              src={orderDetails.quote_currency_flag}
              className={commonClasses.flagSettings}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
