import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import commonStyles from "../../../commonStyles";
import { useSelector } from "react-redux";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";
import { getIndividualDetails } from "../../../redux-reducer/individual/individualSlice";
import IndividualSummary from "./summary";
import IndividualDetails from "./details";
import EditProfileButton from "../../common-components/EditProfileButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f6f6f6",
    padding: "5rem",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  ...commonStyles,
}));

export default function IndividualProfile() {
  const classes = useStyles();
  const individualDetails = useSelector(getIndividualDetails);

  if(individualDetails)
    return (
        <Grid container direction="row" md={12} xs={12} className={classes.root}>
          <Grid item md={4} xs={12}>
            <IndividualSummary individualDetails={individualDetails} />
          </Grid>

          <Grid item md={1} xs={12} />

          <Grid item md={7} xs={12}>
            <IndividualDetails individualDetails={individualDetails}/>
          </Grid>

          <EditProfileButton ongoingOrders={individualDetails.ongoing_orders}/>
        </Grid>   
    )
  return <LoadingDisplay />
}
