import { httpGet, httpPost, httpPut } from "../httpcommon";
import { getUser } from "../services/authService";

export const individualApis = {
  getIndividualInformation: async function (profileId) {
    try {
      return await httpGet("/individual-information/" + profileId);
    } catch (error) {
      console.log(error.message);
    }
  },

  register: async function (id, activationkey, data) {
    try {
      return await httpPost("/fill-individual-information/" + id + "/" + activationkey, data);
    } catch (error) {
      console.log(error.message);
    }
  },

  edit: async function (masked_id, data) {
    try {
      return await httpPut("/individual/" + masked_id, data);
    } catch (error) {
      console.log(error.message);
    }
  },

  approveMatch: async function(data) {
    try {
      return await httpPut("/match/approve-match", data);
    } catch (error) {
      console.log(error.message);
    }
  },

  disapproveMatch: async function(data) {
    try {
      return await httpPut("/match/disapprove-match", data);
    } catch (error) {
      console.log(error.message);
    }
  },
};