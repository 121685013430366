import React, { useState, useEffect } from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import Popover from "@material-ui/core/Popover";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  Grid,
  IconButton,
  Chip,
  Box,
  Button,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "white",
    paddingRight: "1%",
  },
  dialogContainer: {
    padding: "5%",
  },
  chipContainer: {
    paddingLeft: "1%",
  },
}));

function Filter({
  openFilter,
  closeFilter,
  isOpen,
  applyFilterOnTable,
  maxDate,
  minDate,
  updateMaxDate,
  updateMinDate,
}) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item className={classes.chipContainer}>
        <Grid container direction="row" alignItems="center" spacing={3}>
          {minDate && (
            <Grid item>
              <Chip
                size="medium"
                label={`Min Date ${minDate}`}
                icon={<CalendarTodayIcon />}
                onDelete={() => {
                  // setMinDate("");
                  updateMinDate("");
                  applyFilterOnTable("", maxDate);
                }}
              />
            </Grid>
          )}
          {maxDate && (
            <Grid item>
              <Chip
                size="medium"
                label={`Max Date ${maxDate}`}
                icon={<CalendarTodayIcon />}
                onDelete={() => {
                  updateMaxDate("");
                  applyFilterOnTable(minDate, "");
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Tooltip title="Filter">
          <IconButton onClick={openFilter}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Popover
          open={isOpen}
          anchorEl={isOpen}
          onClose={closeFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={classes.dialogContainer}>
            <Grid item>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Created On
              </Typography>
              <hr />
            </Grid>
            <Grid item>
              <Typography variant="h6"> Minimum Date</Typography>
              <KeyboardDatePicker
                clearable
                value={minDate ? minDate : new Date()}
                format="dd/MM/yyyy"
                onChange={(date) =>
                  // setMinDate(moment(date).format("YYYY-MM-DD"))
                  updateMinDate(date)
                }
              />
            </Grid>
            <Box mb={2} />
            <Grid item>
              <Typography variant="h6"> Maximum Date</Typography>
              <KeyboardDatePicker
                clearable
                format="dd/MM/yyyy"
                value={maxDate ? maxDate : new Date()}
                onChange={(date) =>
                  // setMaxDate(moment(date).format("YYYY-MM-DD"))
                  updateMaxDate(date)
                }
              />
              <Box mb={2} />
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                // onClick={handleFilter}
                onClick={() => applyFilterOnTable(minDate, maxDate)}
              >
                Apply Filter
              </Button>
              <Box mb={2} />
              <Button
                variant="contained"
                onClick={() => {
                  // setMaxDate();
                  // setMinDate();
                  updateMaxDate("");
                  updateMinDate("");
                  applyFilterOnTable("", "");
                }}
              >
                Reset Filter
              </Button>
            </Grid>
          </div>
        </Popover>
      </Grid>
    </Grid>
  );
}

export default Filter;
