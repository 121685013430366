import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import commonStyles from "../../commonStyles";
import { httpPost } from "../../httpcommon";
import EmailVerification from "./EmailVerification";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { dataApis } from "../../apis/dataApis";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { apis } from "../../apis/apis";

export default function EmailRegistration() {
  const classes = useStyles();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openPasswordSnackbar, setOpenPasswordSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [legalForms, setLegalForms] = useState([]);
  const [accountTabValue, setAccountTabValue] = useState(0);

  useEffect(async () => {
    let legalFormsResult = await dataApis.getLegalForms();
    if (legalFormsResult.data.success) {
        setLegalForms(legalFormsResult.data.legal_forms);
    }
  }, []);

  const handleClose = () => {
    setOpenSnackbar(false);
    setOpenPasswordSnackbar(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignup = async (values) => {

    const data = {
      email: values.email,
      password: values.password,
      legal_form: values.legal_form?.id,
      account_type: values.account_type
    };

    const result = await apis.signUp(data);

    if (result.data.success) {
      setEmailSent(true);
    } else {
      if(result.data.message == "User already exists"){
        setSnackMessage(result.data.message);
        setOpenPasswordSnackbar(true);
        setLoading(false);
      }
      setSnackMessage(result.data.message);
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const validationSchema = yup.object({
    email: yup
      .string(t("validation.empty-email"))
      .email(t("validation.invalid-email"))
      .required(t("validation.required-email"))
      .nullable(),
    password: yup
      .string(t("validation.empty-password"))
      .min(8, t("validation.password-length"))
      .required(t("validation.required-password"))
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*-_]).*$", 
        t("validation.invalid-password")
      )
      .nullable(),
    confirmPassword: yup
      .string(t("validation.empty-confirm-password"))
      .required(t("validation.required-confirm-password"))
      .test(
        "confirm-password-test",
        t("validation.passwords-match"),
        function (value) {
          return value === this.parent.password;
        }
      )
      .nullable(),
  });

  if (!emailSent) {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        component="main"
        className={classes.root}
      >
        <Grid item xs={12} sm={8} md={4}>
          <Grid
            container
            style={{ height: "100%" }}
            alignItems="center"
            justify="center"
          >
            <div className={classes.formSettings}>
              <IconButton onClick={() => history.push("/")} style={{ backgroundColor: "transparent" }}>
                <img
                  src="/images/cedarxneo-logo/cedarxneo-green-logo.png"
                  style={{ width: "17rem" }}
                />
              </IconButton>
              <Box mt={2} />

              <Box mt={1} />
              <span style={{ textAlign: "center"}}>
                <Typography variant="h6" color="black">
                  {t("register-page.subtitle")}{" "}
                  <Link
                    href="/login"
                    variant="subtitle1"
                    className={classes.linkDecoration}
                  >
                    {t("register-page.login")}
                  </Link>
                </Typography>
              </span>

              <Box mt={3} />    

              <Formik
                initialValues={{
                  email: null,
                  password: null,
                  confirmPassword: null,
                  legal_form: null,
                  account_type: "business"
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSignup(values)}
                } 
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  values,
                  touched,
                  errors,
                }) => (
                  <>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={accountTabValue}
                        onChange={(event, value) => {
                          setAccountTabValue(value);
                          value == 0 ? 
                            setFieldValue("account_type", "business")                           
                          : setFieldValue("account_type", "individual")
                        }}
                        indicatorColor="secondary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        <Tab label={t("register-page.business")} />
                        <Tab label={t("register-page.individual")} />
                      </Tabs>
                    </AppBar>
                    <Box mt={3} />
                    {
                      values.account_type === "business" ? 
                        <Autocomplete
                          name="legal_form"
                          options={legalForms}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            setFieldValue("legal_form", value)
                          }}
                          value={values.legal_form}
                          renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={t("company-identification-step.legal-form-label")}
                                required
                            />
                          )}
                        />
                       : ''                   
                    }
                    <TextField
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      id="standard-basic"
                      type="email"
                      label={t("email-label")}
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      inputProps={{ tabIndex: "1" }}
                      required
                    />
                    
                    <TextField
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      label={t("password-label")}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      value={values.password}
                      name="password"
                      labelWidth={70}
                      required
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        ),
                      }}
                      inputProps={{ tabIndex: "2" }}
                    />

                    <TextField
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      id="outlined-adornment-password"
                      type="password"
                      label={t("confirm-password-label")}
                      onChange={handleChange("confirmPassword")}
                      onBlur={handleBlur("confirmPassword")}
                      value={values.confirmPassword}
                      name="confirmPassword"
                      labelWidth={70}
                      error={
                        touched.confirmPassword &&
                        Boolean(errors.confirmPassword)
                      }
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      inputProps={{ tabIndex: "3" }}
                      required
                      onPaste={(e) => e.preventDefault()}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      disabled={loading}
                      className={classes.startButton}
                      onClick={handleSubmit}
                      inputProps={{ tabIndex: "4" }}
                    >
                      {t("button.start")}
                    </Button>
                  </>
                )}
              </Formik>
            </div>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={1800}
              message={snackMessage}
              onClose={handleClose}
              ContentProps={{ classes: { root: classes.error } }}
            />
            <Link href="/forgot-password">
              <Snackbar
                open={openPasswordSnackbar}
                autoHideDuration={2000}
                message={snackMessage}
                onClose={handleClose}
                ContentProps={{ classes: { root: classes.error } }}
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return <EmailVerification />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  startButton: {
    marginTop: theme.spacing(2),
  },
  
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  formSettings: {
    padding: "1.2rem",
    textAlign: "center"
  },
  ...commonStyles,
}));
