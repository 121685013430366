import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const StyledSlider = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        height: 2,
        padding: '15px 0',
    },
    thumb: {
        height: 15,
        width: 15,
        backgroundColor: '#fff',
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
        marginTop: -8,
        marginLeft: -8,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
            },
        },
    }, 
    active: {},
    valueLabel: {
        left: 'calc(-50%)',
        top: -12,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
}))(Slider);

export default function CustomSlider({ min, max, step, marks, value, onChange, valueLabelFormat }) {
    return ( 
        <StyledSlider 
            min={min} 
            max={max} 
            step={step} 
            marks={marks} 
            value={value} 
            valueLabelDisplay="on"
            onChange={onChange}
            valueLabelFormat={valueLabelFormat}
        />
    );
}