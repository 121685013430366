import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Box, Chip, Link } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import DropzoneArea from "../../common-components/DropzoneArea";
import { useTranslation } from 'react-i18next';
import { isEditStepper, isRegisterStepper, organizationRequiredDocuments } from "../../../utils";
import { FieldArray } from "formik";
import DocumentChip from "../../common-components/DocumentChip";
import { AttachFile } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(2),
        fontWeight: 600
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    dropzone: {
        fontSize: "0.8rem",
        marginTop: "1.8rem",
    },
    float: {
        float: 'left',
        textDecoration: 'underline',
    },
    titleStyle: {
        alignItems: 'baseline',
        justifyContent: 'space-between'
    },
    tooltipStyle: {
        fontSize: '15px'
    },
    docContainer: {
        marginTop: '1rem'
    }
}));

export default function CompanyVerification(props) {
    const formikProps = props.formikProps;

    const {
        formField: {
            documents,
        }
    } = props;
    
    const { t } = useTranslation();

    const classes = useStyles();

    const handleAddDocument = (push) => (file, document_name) => {
        push({
            document_type: document_name,
            document_content: file
        })
    }

    const handleRmvAddedDocument = (document_name, remove) => {
        let index = formikProps.values[documents.name].findIndex((document) => document.document_type == document_name)
        if(index > 0)
            remove(index);
    }

    const getDocument = (document_name) => {
        let document = formikProps.values[documents.name].find((document) => document.document_type == document_name)
        return document;
    }

    const documentExists = (document) => {
        return formikProps.values[documents.name].map((doc) => doc.document_type).includes(document);
    }

    const DocumentTitle = ({document}) => {
        return (
            <Grid container direction="row" className={classes.titleStyle}>
                <Grid item md={11} xs={11}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        {t("documents." + document + ".label")}
                    </Typography>
                </Grid>
                <Grid item md={1} xs={1} style={{textAlign: "end"}}>
                    <Tooltip title={t("documents." + document + ".desc")} className={classes.tooltipStyle}>              
                        <HelpIcon color="secondary" fontSize= "small" />              
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }

    const DocumentComponent = ({document, remove, push}) => {
        if(isRegisterStepper()){
            if(documentExists(document))
                return (
                    <Chip
                        icon={<AttachFile />}
                        variant='outlined'
                        label={document}
                        onDelete={(e) => handleRmvAddedDocument(document, remove)}
                    />
                )

            return (
                <DropzoneArea
                    acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                    handleFileChange={handleAddDocument(push)}  
                    documentName={document}
                />
            )
        }

        if(isEditStepper()){
            if(documentExists(document)){
                const doc = getDocument(document)
                return (
                    <DocumentChip
                        label={document}
                        link={doc.document_path}
                        status={doc.document_status}
                        handleFileChange={props.handleUpdateDocument}
                        handleDeleteFile={props.handleRmvUpdatedDocument} 
                        documentName={document}
                        updatedDocuments={props.updatedDocuments}
                        rejectionReason={doc.document_rejection_reason}
                    />
                )
            }
    
            if(props.updatedDocuments[document])
                return (
                    <Chip 
                        icon={<AttachFile />}
                        variant='outlined'
                        label={document}
                        onDelete={(e) => props.handleRmvUpdatedDocument(document)}
                    />
                )
    
            return (
                <DropzoneArea
                    acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                    handleFileChange={props.handleUpdateDocument}  
                    documentName={document}
                />
            )
        }
        
    }

    return (
        <React.Fragment>
            <Typography variant="h4" style={{ lineHeight: 1.33, fontSize: "1.5rem" }}>
                {t("company-verification-step.title")}
            </Typography>

            <Box mt={2} />
            
            <Typography variant="subtitle1">
                {t("company-verification-step.subtitle1")} <br /><br />
                {t("company-verification-step.subtitle2")}
            </Typography>

            <Grid container direction="row" md={12} xs={12}>
                <FieldArray
                    name={documents.name}
                    render={({ remove, push }) => (
                        <React.Fragment>                     
                            {
                                organizationRequiredDocuments.map((document, index) => {
                                    return (
                                        <React.Fragment>
                                            <Grid item md={5} xs={12} className={classes.docContainer}>
                                                <DocumentTitle document={document} />                   
                                                <DocumentComponent document={document} remove={remove} push={push}/>
                                            </Grid>
                                            {
                                                index % 2 == 0 &&
                                                    <Grid item md={2} xs={12} /> 
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </React.Fragment>
                    )}
                />
            </Grid>

            <Box mt={3} />

            <Snackbar
                open={props.openSnackBar}
                autoHideDuration={1800}
                message={props.snackMessage}
                onClose={() => props.setOpenSnackBar(false)}
                ContentProps={{ classes: { root: classes.error } }}
            />
        </React.Fragment>
    );
}
