import React, { useState } from "react";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Chip from '@material-ui/core/Chip';
import { Box, IconButton, Typography, useTheme, Collapse } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from "@material-ui/styles";
import { documentStatus } from "../../utils";
import DropzoneArea from "./DropzoneArea";
import AttachFile from "@material-ui/icons/AttachFile";

export default function DocumentChip(props){
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const handleColor = () => {
        if(props.status == "Approved")
            return theme.palette.secondary.main
        if(props.status == "Rejected")
            return theme.palette.error.main
        if(props.status == "Pending Approval")
            return "orange"
    }

    const getDocStatus = (status) => {
        if(status == "Pending Approval")
            return documentStatus.PendingApproval
        if(status == "Rejected")
            return documentStatus.Rejected
    }

    const StyledChip = withStyles({
        root: {
            'color': handleColor(),
        },
        icon: {
            color: handleColor(),
        },
        outlined: {
            color: handleColor(),
            border: `1px solid ${handleColor()}`,
            backgroundColor: `transparent !important`,
        },
    })(Chip);

    return (
        <React.Fragment>
            <StyledChip
                icon= {<AttachFileIcon color="secondary"/>}
                variant='outlined'
                label={props.label}
                onClick={()=> window.open(props.link, 'blank')}
            />

            {
                (props.status == "Rejected" || props.status == "Pending Approval") &&

                <Typography variant="caption" style={{color: handleColor(), marginLeft: '1rem', marginRight: '1rem'}}>
                    {getDocStatus(props.status)}
                </Typography>
            }

            {
                (props.status == "Rejected") &&
                <React.Fragment>
                    <IconButton onClick={handleExpandClick}>
                        <EditIcon />
                    </IconButton>

                    <Collapse in={expanded} style={{ width: "100%" }}> 
                        <DropzoneArea
                            acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                            handleFileChange={props.handleFileChange}
                            handleDeleteFile={props.handleDeleteFile}  
                            documentName={props.documentName} 
                        />
                    </Collapse>
                    <Typography variant="caption" style={{color: handleColor(), marginLeft: '1rem', marginRight: '1rem'}}>
                        {props.rejectionReason}
                    </Typography>
                </React.Fragment>         
            }

            {
                (props.status == "Rejected" && props.updatedDocuments && props.updatedDocuments[props.documentName]) &&
                <Chip
                    icon={<AttachFile />}
                    variant='outlined'
                    label={props.documentName}
                    onDelete={(e) => props.handleDeleteFile(props.documentName)}
                />        
            }
        </React.Fragment>
    )
}