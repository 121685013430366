import { httpGet, httpPost, httpPut } from "../httpcommon";
import { getUser } from "../services/authService";

export const apis = {
  signUp: async function (data) {
    try {
      return await httpPost("/signup", data);
    } catch (error) {
      console.log(error.message);
    }
  },

  deleteAccount: async function (user_id) {
    try {
      return await httpPut("/delete/" + user_id);
    } catch (error) {
      console.log(error.message);
    }
  },

  getLiveRate: async function () {
    try {
      //TODO centralize http request error management
      return await httpGet("/liveplatformrate");
    } catch (error) {
      console.log(error.message);
    }
  },
  getPublicLiveRate: async function () {
    try {
      return await httpGet("/public-live-rate");
    } catch (error) {
      console.log(error.message);
    }
  },
  getUnreadNotifications: async function () {
    try {
      return await httpGet("/notifications/count-unread/" + getUser().email);
    } catch (error) {
      console.log(error.message);
    }
  },
  getTotalNotifications: async function () {
    try {
      return await httpGet("notifications/count/" + getUser().email);
    } catch (error) {
      console.log(error.message);
    }
  },
  updateReadNotifications: async function () {
    try {
      return await httpPut("/notifications/mark-all-asread", {
        email: getUser().email,
      });
    } catch (error) {
      console.log(error.message);
    }
  },
  getNotificationContent: async function (startIndex) {
    try {
      return httpGet(
        "/notifications/list/" +
          getUser().email +
          "?startIndex=" +
          startIndex +
          "&pageSize=3"
      );
    } catch (error) {
      console.log(error.message);
    }
  },
  getLivePlatformRate: async function (baseCurrency, quoteCurrency) {
    try {
      return await httpGet(
        `/liveplatformrate?baseCurrency=${baseCurrency}&quoteCurrency=${quoteCurrency}`
      );
    } catch (error) {
      console.log(error.message);
    }
  },
  getRateRules: async function (baseCurrency, quoteCurrency) {
    try {
      return await httpGet(
        `/raterules?baseCurrency=${baseCurrency}&quoteCurrency=${quoteCurrency}`
      );
    } catch (error) {
      console.log(error.message);
    }
  },
  getProfileRules: async function (baseCurrency, quoteCurrency, profileType) {
    try {
      return await httpGet(
        `/profile_rules?baseCurrency=${baseCurrency}&quoteCurrency=${quoteCurrency}&profileType=${profileType}`
      );
    } catch (error) {
      console.log(error.message);
    }
  },
  getOrderDetails: async function (id) {
    try {
      return await httpGet("/get-order-details/" + id);
    } catch (error) {
      console.log(error.message);
    }
  },
  getBuyerMatches: async function(masked_id) {
    try {
      return await httpGet("/matching-list/buyer/" + masked_id);
    } catch (error) {
      console.log(error.message);
    }
  },
  getSellerMatches: async function(masked_id) {
    try {
      return await httpGet("/matching-list/seller/" + masked_id);
    } catch (error) {
      console.log(error.message);
    }
  },
  getAllPartnerBranches: async function () {
    try {
      return await httpGet("/partner-branches");
    } catch (error) {
      console.log(error.message);
    }
  },
  getPartnerMainBranches: async function () {
    try {
      return await httpGet("/partner-main-branches");
    } catch (error) {
      console.log(error.message);
    }
  },
  extendOrderExpiryTime: async function (id) {
    try {
      return await httpPut("/extend-order-time/" + id);
    } catch (error) {
      console.log(error.message);
    }
  },
};
