import { httpGet } from "../httpcommon";

export const dataApis = {
  getCountries: async function () {
    try { 
      return await httpGet(`/countries`);
    } catch (error) {
      console.log(error.message);
    }
  },
  getLegalForms: async function () {
    try {
      //TODO rename to /options/organization_legal_forms
      return await httpGet(`/organization_legal_forms`);
    } catch (error) {
      console.log(error.message);
    }
  },
  getOwnershipBeneficialRights: async function () {
    try { 
      return await httpGet(`/organization_ownership_beneficial_right`);
    } catch (error) {
      console.log(error.message);
    }
  },
  getOccupations: async function () {   
    try { 
      return await httpGet(`/option/occupations`);
    } catch (error) {
      console.log(error.message);
    }
  },
  getSourceOfFunds: async function () {   
    try { 
      return await httpGet(`/options/source_of_funds`);
    } catch (error) {
      console.log(error.message);
    }
  },
  getEmploymentStatus: async function () {   
    try { 
      return await httpGet(`/options/employment_status`);
    } catch (error) {
      console.log(error.message);
    }
  },
};
