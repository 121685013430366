import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { httpGet } from "../httpcommon";
import { getUser } from "../services/authService";

function MiddleWareRoute(props) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (getUser() != null && getUser()["onboarded"] == 0) {
        let res = await httpGet("/registration-form-url");
        if (res.data.success) {
          history.push(`/public/register/${res.data.url}`);
          return;
        }
      }
      history.push("home");
    })();
  }, []);

  return (
    <Backdrop open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default MiddleWareRoute;
