import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "./ConfirmationDialog";
import InfoDialog from "./InfoDialog";

export default function EditProfileButton({ongoingOrders}) {
    const history = useHistory();
    const { t } = useTranslation();
    const [openEditConfirmationDialog, setEditConfirmationDialog] = useState(false);


    const cancelEdit = () => {
        setEditConfirmationDialog(false);
        history.push("/home")
    }

    const openWarning = () => {
        if(ongoingOrders > 0)
            setEditConfirmationDialog(true)
        else 
            history.push("/profile/edit"); 
    }

    return (
        <React.Fragment>
            <Grid item md={12} xs={12} style={{textAlign: "end", marginTop: '1rem'}}>
            <Button color="secondary" variant="contained" onClick={openWarning}>
                {t("button.edit-profile")} 
            </Button>
            </Grid>

            <InfoDialog
                title={t("edit-dialog.title")}
                subtitle={t("edit-dialog.content")}
                leftButtonTitle={t("button.cancel")}
                rightButtonTitle={t("button.ok")}
                open={openEditConfirmationDialog}
                onClose={cancelEdit}
            /> 
        </React.Fragment> 
    )

}
