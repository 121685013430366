import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { Snackbar, StepButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from "@material-ui/core";
import { getUser, logout, setUser } from "../../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";
import moment from "moment";
import { getIndividualDetails, getIndividualLoaded, updateIndividualDetails } from "../../../redux-reducer/individual/individualSlice";
import { individualApis } from "../../../apis/individualApis";
import commonStyles from "../../../commonStyles";
import TermsDialog from "../../common-components/TermsDialog";
import { hasStepError, isEditStepper, isRegisterStepper } from "../../../utils";
import { Formik } from "formik";
import validationSchema from "./registrationModel/validationSchema";
import { fields, steps, stepsContent } from "./registrationModel/stepperModel";
import { initialValues } from "./registrationModel/stepperInitialValues";
import InfoDialog from "../../common-components/InfoDialog";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  stepperSettings: {
    width: "100%",
  },
  contentSettings: {
    width: "100%",
    overflowY: "scroll", 
    height: "65vh",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
      height: "85vh"
    },
  },
  containerSettings: {
    padding: "1rem",
    paddingLeft: "8rem",
    paddingRight: "8rem",
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
  },
  error: {
    backgroundColor: theme.palette.error.main
  },
  ...commonStyles
}));

export default function IndividualStepper() {
    const { t } = useTranslation();
    let { id, activationkey } = useParams();
    const masked_id = getUser().profile_id;
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const currentValidationSchema = validationSchema[activeStep];
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [loading, setLoading] = useState(false);   
    const theme = useTheme();
    const [edit, setEdit] = useState(false)
    const dispatch = useDispatch();
    const [ submittedData, setSubmittedData] = useState();
    const [openEditConfirmationDialog, setEditConfirmationDialog] = useState(false);

    const { formId, formField } = fields;

    const [buttonClicked, setButtonClicked] = useState();

    const [updatedDocuments, setUpdatedDocuments] = useState({})

    const mobileView = useMediaQuery(theme.breakpoints.down("md"));

    const history = useHistory();

    const [individualDetails, setIndividualDetails] = useState();
    const individualProfile = useSelector(getIndividualDetails);
    const individualLoaded = useSelector(getIndividualLoaded);

    const [openTermsDialog, setOpenTermsDialog] = useState(false);

    useEffect(async () => {
        if(individualLoaded){
        setIndividualDetails(individualProfile);
        }
    }, [individualLoaded, individualProfile]);
    
    const setIndividualAttribute = (setFieldValue) => (e) => {
        setFieldValue(e.target.name, e.target.value);
        setEdit(true);
    };

    const setSingleOptionAttribute = (setFieldValue, field_name) => (event, value) => {
        setFieldValue(field_name, value ? value.id : null)
        setEdit(true);
    };

    const setMultipleOptionsAttribute = (setFieldValue, field_name) => (event, values) => {
        let val = values.map((value) => value.id)
        setFieldValue(field_name, val)
        setEdit(true);
    }


    const getOptionById = (options, values) => {
        let result = []
        if(values)
        for(var i=0; i<values.length; i++){
            let filter = options.find((option) => option.id === values[i])
            if(filter)
            result.push(filter)
        }
        return result;
    }


    const handleUpdateID = (file, document_name) => {
        setUpdatedDocuments({
            [document_name]: {
                content: file,
                document_number: null,
                document_expiray_date: null
            }
        })
        setEdit(true);
    }

    const handleRemoveID = (document_name) => {
        setUpdatedDocuments({
            [document_name]: null
        });
        setEdit(true);
    }

    const formInputs = {
        formField,
        individualDetails,
        snackMessage,
        setSnackMessage,
        openSnackBar,
        setOpenSnackBar,
        setIndividualAttribute,
        setSingleOptionAttribute,
        setMultipleOptionsAttribute,
        getOptionById,
        updatedDocuments,
        setUpdatedDocuments,
        handleUpdateID,
        handleRemoveID,
        setEdit
    };

    const maxSteps = steps.length;

    const handleAgree = async (e) => {
        e.preventDefault();

        setOpenTermsDialog(false);

        var data = {
            ...submittedData,
            documents: {
                [submittedData[formField.documentType.name]]: {
                    [formField.documentNumber.name]: submittedData[formField.documentNumber.name],
                    [formField.documentDate.name]: submittedData[formField.documentDate.name],
                    document_content: submittedData.document_content
                }
            }
        }

        setLoading(true);

        const registerResponse = await individualApis.register(id, activationkey, data);

        if(registerResponse.data.success) {
            setUser(registerResponse.data);
            history.push("/home");
            return;
        } else {
            setSnackMessage(registerResponse.data.message);
            setOpenSnackBar(true);
        }
    }

    const handleFormSubmit = async (values, actions) => {
        if(isRegisterStepper()){
            if(activeStep === maxSteps -1){
                for (const [name, value] of Object.entries(values.document_content)) {
                    if(!value){
                        setSnackMessage(t("validation.required.document-content"));
                        setOpenSnackBar(true);
                        return;
                    }
                }
                
                setSubmittedData(values);
                setOpenTermsDialog(true);
                return;
            }
            handleNext(values, actions)
        }

        if(isEditStepper()){
            if(buttonClicked == 'continue'){
                handleNext(values, actions);
            }

            if(buttonClicked == "submit"){
                if(values.ongoing_orders > 0){
                    setEditConfirmationDialog(true)
                    return;
                }

                if(values.document_status == "Rejected" && !values.document_content){
                    setSnackMessage(t("validation.edit-rejected-documents"));
                    setOpenSnackBar(true);
                    return;
                }
                var data = {
                    ...values,
                    documents: {
                        [values[formField.documentType.name]]: {
                            [formField.documentNumber.name]: values[formField.documentNumber.name],
                            [formField.documentDate.name]: values[formField.documentDate.name] ? moment(new Date(values[formField.documentDate.name])).format("yyyy/MM/DD") : null,
                            document_path: values.document_path,
                            document_status: values.document_status,
                            document_rejection_reason: values.document_rejection_reason,
                            document_content: values.document_content ? values.document_content : null
                        }
                    },
                    [formField.doBirth.name]: values[formField.doBirth.name] ? moment(new Date(values[formField.doBirth.name])).format("yyyy/MM/DD") : null,
                }

                setLoading(true);

                const editResponse = await individualApis.edit(masked_id, data);

                if (editResponse.data.success) {
                    history.push("/profile");
                    return;
                } else {
                    setLoading(false);
                    setEdit(false);
                    setSnackMessage(editResponse.data.message);
                    setOpenSnackBar(true);
                }
            }
        }
    };

    const handleNext = (values, actions) => {
        if(isRegisterStepper())
            dispatch(updateIndividualDetails(values))
        setActiveStep(activeStep + 1);
        actions.setTouched({});
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleExit = () => {
        if(isEditStepper()){
            history.push("/home");
        }
        if(isRegisterStepper()){
            logout();
            history.push("/");
        }
    }

    const hasRejectedDocs = (step) => {
        if(step.id == maxSteps - 1){
            if(individualDetails.document_status == "Rejected")
                return true;
            else return false;
        }
    }

    if(individualLoaded && individualDetails)
        return (
            <React.Fragment>
                <Grid container direction="column" className={classes.containerSettings}>
                    {
                        !mobileView &&
                            <Grid item className={classes.stepperSettings}>
                                {
                                isEditStepper() ? 
                                    <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel nonLinear> 
                                    {steps.map((step, index) => (
                                        <Step key={step.label}>
                                        <StepButton
                                            type="submit"  
                                            onClick={() => {                        
                                                setActiveStep(index)
                                            }}
                                            color="inherit"
                                        >
                                            <StepLabel error={hasStepError(step, individualDetails) || hasRejectedDocs(step)}>
                                            {step.label}
                                            </StepLabel>
                                        </StepButton>
                                        </Step>
                                    ))}
                                    </Stepper>
                                :
                                    <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
                                    {steps.map((step) => (
                                        <Step key={step.label}>
                                        <StepLabel >
                                            {step.label}
                                        </StepLabel>
                                        </Step>
                                    ))}
                                    </Stepper>
                                }
                            </Grid>
                    }

                    <Formik
                        initialValues={initialValues(individualDetails)}
                        validationSchema={currentValidationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {
                            props => (
                                <form onSubmit={props.handleSubmit} id={formId}>
                                    <Grid item className={classes.contentSettings}>
                                        {stepsContent(formInputs, props)[activeStep]}
                                    </Grid>

                                    <Grid item className={classes.stepperSettings}>
                                        <Grid container justify="center" alignItems="center" direction="row" >
                                            <Grid item>                
                                                <Button
                                                    onClick={handleExit}
                                                    variant="contained"
                                                    className={classes.button}
                                                >
                                                    {t("button.exit")}
                                                </Button>
                                            </Grid>

                                            {
                                                activeStep !== 0 && (
                                                    <Grid item>                
                                                        <Button
                                                            onClick={handleBack}
                                                            variant="contained"
                                                            className={classes.button}
                                                        >
                                                            {t("button.back")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            }

                                            {
                                                activeStep !== maxSteps -1 && (
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            className={classes.button}
                                                            type="submit"
                                                            onClick={(e) => setButtonClicked("continue")}
                                                        >
                                                            {t("button.continue")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            }

                                            {
                                                (isEditStepper() && edit) && (
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            className={classes.button}
                                                            type="submit"
                                                            disabled={loading}
                                                            onClick={(e) => setButtonClicked("submit")}
                                                        >
                                                            {t("button.submit")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            }

                                            {
                                                (isRegisterStepper() && activeStep === maxSteps -1) && (
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            className={classes.button}
                                                            type="submit"
                                                            disabled={loading}
                                                        >
                                                            {t("button.submit")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    </Formik>
                </Grid>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar
                    open={openSnackBar}
                    autoHideDuration={1800}
                    message={snackMessage}
                    onClose={() => setOpenSnackBar(false)}
                    ContentProps={{ classes: { root: classes.error } }}
                />
                <TermsDialog
                    open={openTermsDialog}
                    onClose={() => { setOpenTermsDialog(false); setLoading(false) }}
                    onAgree={handleAgree}
                />
                <InfoDialog
                    title={t("edit-dialog.title")}
                    subtitle={t("edit-dialog.content")}
                    leftButtonTitle={t("button.cancel")}
                    rightButtonTitle={t("button.ok")}
                    open={openEditConfirmationDialog}
                    onClose={() => {setEditConfirmationDialog(false); history.push("/home")}}
                /> 
            </React.Fragment>
        );
    return <LoadingDisplay />
}
