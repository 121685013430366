import React, { useState, useEffect } from "react";
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { isArabic, numberWithCommos, toArabicDigits } from "../../utils";
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import PlusIcon from '@material-ui/icons/AddBox';
import MinusIcon from '@material-ui/icons/IndeterminateCheckBox';
import { useTranslation } from 'react-i18next';
import { LoadingDisplay } from "../common-ui-fit/LoadingDisplay";
import HelpIcon from "@material-ui/icons/Help";
import CustomSlider from "../common-components/CustomSlider";

function RatePart({
  customRate,
  setCustomRate,
  minRate,
  maxRate,
  rateIncrement,
  getMinRate,
  getMaxRate,
  usePlatformRate, 
  setUsePlatformRate,
  probabilityMessage,
  platformRate,
  getRate,
  setHasAmountNeededError,
  setAmountMessage
}) {
  
  const theme = useTheme();
  const { t } = useTranslation();

  const step = rateIncrement;

  const [value, setValue] = useState()

  const [marks, setMarks] = useState([])

  useEffect(() => {
    setValue(customRate);
    setMarks([
      {
        value: getMinRate,
      },
      {
        value: customRate,
      },
      {
        value: getMaxRate,
      },
    ])
  }, [customRate])

  const handleSliderChange = (event, newValue) => {
    setHasAmountNeededError(false)
    setValue(newValue);
    setCustomRate(newValue);
  }

  const decrementValue = () => {
    setHasAmountNeededError(false)
    const decrementValue = value - step;

    if(decrementValue < minRate){
      setValue(minRate);
      setCustomRate(minRate);
    } else {
      setValue(decrementValue);
      setCustomRate(decrementValue);
    }
  }

  const incrementValue = () => {
    setHasAmountNeededError(false)
    const incrementValue = value + step;

    if(incrementValue > maxRate){
      setValue(maxRate);
      setCustomRate(maxRate);
    } else {
      setValue(incrementValue);
      setCustomRate(incrementValue);
    }
  }

  const labelFormat = (value) => {
    return numberWithCommos(value)
  }

  const handleRateChange = (e) => {
    if(e.target.value == "Platform Rate")
      setUsePlatformRate(true)
    if(e.target.value == "Custom Rate"){
      setUsePlatformRate(false)
      setAmountMessage('')
    }
  }
  
  if(value && marks)
    return (
      <>
          <Grid container direction="column" md={12} xs={12}>
              <Grid container direction="row" md={12} xs={12} style={{alignItems: "center"}}>
                <Grid item>
                  <Typography variant="h6" color="primary">
                    {t("rate-part.rate-label")}
                  </Typography>
                </Grid>
                <Box mr={1} />
                <Grid item>
                  <Tooltip title={t("rate-part.tooltip")}>
                    <HelpIcon color="secondary" fontSize= "small" />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid item md={12} xs={12}>
                <FormControl required component="fieldset">
                  <Box mt={1} />
                  <RadioGroup value={usePlatformRate} name="rate" onChange={handleRateChange}>
                      <FormControlLabel
                          value="Platform Rate"
                          control={<Radio 
                              required 
                              checked={usePlatformRate == true}/>}
                          label={t("rate-part.platform-label", {rate: numberWithCommos(getRate(platformRate))})}
                      /> 
                      <FormControlLabel
                          value="Custom Rate"
                          control={<Radio 
                              required
                              checked={usePlatformRate == false}/>}
                          label={t("rate-part.custom-label")}
                      /> 
                  </RadioGroup>
                </FormControl>
              </Grid>

              {
                usePlatformRate == false &&
                <React.Fragment>
                  <Grid item md={12} xs={12}>
                      <Grid container direction="row" alignItems="center" >
                          <Grid item md={2} xs={2} style={{textAlign: "center"}}>
                              <IconButton onClick={decrementValue}>
                                  <MinusIcon color="primary" />
                              </IconButton>
                          </Grid>
                          
                          <Grid item md={8} xs={8} alignItems="center">
                              <CustomSlider
                                  min={minRate} 
                                  max={maxRate} 
                                  step={step} 
                                  marks={marks} 
                                  value={value} 
                                  onChange={handleSliderChange}
                                  valueLabelFormat={labelFormat}
                              />
                          </Grid>

                          <Grid item md={2} xs={2} style={{textAlign: "center"}}>
                              <IconButton onClick={incrementValue}>
                                  <PlusIcon color="primary" />
                              </IconButton>
                          </Grid>
                      </Grid>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Typography variant="caption" style={{color: theme.palette.error.main}}>
                      {probabilityMessage}
                    </Typography>
                  </Grid>
                </React.Fragment>
                }
          </Grid>    
      </>
    );
  return <LoadingDisplay />
}

export default RatePart;
