import { Box, Button, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { httpPost } from "../../httpcommon";
import { NotificationSnakBar } from "../common-components/NotificationSnakBar";
import { LoadingDisplay } from "../common-ui-fit/LoadingDisplay";
import AmountNeededPart from "./AmountNeededPart";
import { getProbabilityFor, isSystemStopped, numberWithCommos, profileType } from "../../utils";
import { getAccountStatus, getUnreadNotifications } from "../../redux-reducer/centrifuge/centrifugeSlice";
import { apis } from "../../apis/apis";
import Cards from "../homepage/Cards";
import { useTranslation } from 'react-i18next';
import FundDialog from "../common-components/FundDialog";
import { getProfileType } from "../../redux-reducer/profileType/profileTypeSlice";
import InfoDialog from "../common-components/InfoDialog";
import DelegateDialog from "../common-components/DelegateDialog";
import OrderConfirmationDialog from "../common-components/OrderConfirmationDialog";

const P2pOrderForm = ({profileDetails}) => {
  const history = useHistory();
  const theme = useTheme();
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const [quoteCurrency, setQuoteCurrency] = useState("LBP");
  const [platformRate, setPlatformRate] = useState();
  const [amountNeeded, setAmountNeeded] = useState(0);
  const [amountNeededDisplayed, setAmountNeededDisplayed] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [convertedAmountDisplayed, setConvertedAmountDisplayed] = useState(0);
  const [openSnakbar, setOpenSnakbar] = useState(false);
  const [error, setError] = useState(false);
  const [snakMessage, setSnakMessage] = useState("");
  const [buyOrderRequired, setBuyOrderRequired] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [disablePlaceOrder, setDisablePlaceOrder] = useState(false);
  const [customRate, setCustomRate] = useState();
  const accountStatus = useSelector(getAccountStatus);
  const unreadNotificationsFromRealtime = useSelector(getUnreadNotifications);
  const [probabilityMessage, setProbabilityMessage] = useState();
  const [usePlatformRate, setUsePlatformRate] = useState(true);
  const [openFundDialog, setOpenFundDialog] = useState(false);
  const [openDepositDialog, setOpenDepositDialog] = useState(false);
  const [openComplianceDialog, setOpenComplianceDialog] = useState(false);
  const [fundProof, setFundProof] = useState({
      details: null,
      beneficial_right_owner_myself: true,
      beneficial_right_owner_other: '',
      signature: null,
      document_path: ''
  });
  const profile = useSelector(getProfileType);

  const [openDelegateDialog, setOpenDelegateDialog] = useState(false)
  const [delegateDetails, setDelegateDetails] = useState({
    order_delegate: 'Myself',
    order_delegate_id: null,
  })

  const [cumulativeAmount, setCumulativeAmount] = useState()
  const [unitaryAmount, setUnitaryAmount] = useState()
  const [transactionLimit, setTransactionLimit] = useState()

  const [hasAmountNeededError, setHasAmountNeededError] = useState(false)
  const [amountNeededErrorMessage, setAmountNeededErrorMessage] = useState()

  const [profileRules, setProfileRules] = useState() 

  const [precisionAmount, setPrecisionAmount] = useState();
  const [amountMessage, setAmountMessage] = useState();
  const [orderId, setOrderId] = useState();
 
  const { t } = useTranslation();

  const getRateRules = async () => {
    const livePlatformRate = await apis.getLivePlatformRate(baseCurrency, quoteCurrency);

    if (livePlatformRate.data.success) {
      setPlatformRate(livePlatformRate.data);
      setCustomRate(getRate(livePlatformRate.data))
      return;
    }
    setSnakMessage(livePlatformRate.data.message);
    setError(true);
    setOpenSnakbar(true);
  };

  const getProfileRules = async () => {
    const profileRulesResponse = await apis.getProfileRules(baseCurrency, quoteCurrency, profile)

    if(profileRulesResponse.data.success) {
      setProfileRules(profileRulesResponse.data)
      return;
    }
    setSnakMessage(profileRulesResponse.data.message);
    setError(true);
    setOpenSnakbar(true);
  }

  useEffect(() => {
    getRateRules();
    getProfileRules();
  }, [baseCurrency]);

  useEffect(() => {
    if(platformRate)
      setCustomRate(getRate(platformRate))
  }, [buyOrderRequired, usePlatformRate])


  useEffect(() => {   
    if (profileDetails?.account_status?.type === "Guest Mode" || profileDetails?.account_status?.type === "System Stopped" || isSystemStopped(accountStatus) ) {
      setDisablePlaceOrder(true);
    }else{
      setDisablePlaceOrder(false)
    }
  }, [accountStatus, unreadNotificationsFromRealtime, profileDetails])

  useEffect((e) => {
    if (platformRate && customRate) {
      conversionMachine(customRate)
    };
  }, [amountNeeded]);

  useEffect(() => {
    if(amountNeeded)
      handleOnBlur()
  }, [usePlatformRate, customRate])

  useEffect(() => {
    if (platformRate && customRate) {
      setProbabilityMessage(getProbabilityFor(getRate(platformRate), customRate, getRateLimit(), buyOrderRequired))
    };
  }, [customRate, buyOrderRequired])

  const getAmountMessage = (amountWithPrecision, convertedWithPrecision) => {
    if(platformRate.base_currency == baseCurrency){
      let maxConvertedAmount = amountWithPrecision
      if(buyOrderRequired){
        maxConvertedAmount = maxConvertedAmount * platformRate.rate_rules['buy'].max_rate
        if(maxConvertedAmount > 0){
          setAmountMessage(t("homepage.place-order.sell-amount-message", {
              min_amount: numberWithCommos(convertedWithPrecision),
              max_amount: numberWithCommos(maxConvertedAmount)
            })
          )
        }
        else{
          setAmountMessage(t("homepage.place-order.sell-amount-message", {
              min_amount: numberWithCommos(0),
              max_amount: numberWithCommos(0)
            })
          )
        }
      }
      else{
        maxConvertedAmount = maxConvertedAmount * platformRate.rate_rules['sell'].min_rate
        if(maxConvertedAmount > 0){
          setAmountMessage(t("homepage.place-order.buy-amount-message", {
              min_amount: numberWithCommos(maxConvertedAmount),
              max_amount: numberWithCommos(convertedWithPrecision)
            })
          )
        }
        else{
          setAmountMessage(t("homepage.place-order.sell-amount-message", {
              min_amount: numberWithCommos(0),
              max_amount: numberWithCommos(0)
            })
          )
        }
      }
    }
    else{
      let maxConvertedAmount = amountWithPrecision
      if(buyOrderRequired){
        maxConvertedAmount = maxConvertedAmount / platformRate.rate_rules['sell'].min_rate
        if(maxConvertedAmount > 0){
          setAmountMessage(t("homepage.place-order.sell-amount-message", {
              min_amount: numberWithCommos(convertedWithPrecision),
              max_amount: numberWithCommos(maxConvertedAmount)
            })
          )
        }
      }
      else{
        maxConvertedAmount = maxConvertedAmount / platformRate.rate_rules['buy'].max_rate
        if(maxConvertedAmount > 0){
          setAmountMessage(t("homepage.place-order.buy-amount-message", {
              min_amount: numberWithCommos(maxConvertedAmount) ,
              max_amount: numberWithCommos(convertedWithPrecision)
            })
          )
        }
      }
    }
  }

  const conversionMachine = (rate) => {
    if (rate == 0) {
      setConvertedAmountDisplayed(0);
      setConvertedAmount(0);
      return;
    }
    let amountConverted = 0

    if (platformRate.base_currency == baseCurrency) {
      amountConverted = amountNeeded * rate 
      setConvertedAmountDisplayed(numberWithCommos(amountConverted));
      setConvertedAmount(amountConverted);
    }

    else {
      amountConverted = (amountNeeded / rate).toFixed(2) 
      setConvertedAmountDisplayed(numberWithCommos(amountConverted));
      setConvertedAmount(amountConverted);
    }

    if(usePlatformRate){
      getAmountMessage(amountNeeded, amountConverted)
    }
  };

  const handleOnBlur = () => {
    let amountWithPrecision = 0
    let convertedWithPrecision = 0

    if (platformRate.base_currency == baseCurrency) {

      amountWithPrecision = Math.floor(amountNeeded / getPrecision()) * getPrecision()

      setPrecisionAmount(amountWithPrecision)
      setAmountNeededDisplayed(numberWithCommos(amountWithPrecision))

      convertedWithPrecision = amountWithPrecision * customRate

      setConvertedAmountDisplayed(numberWithCommos(convertedWithPrecision));
      setConvertedAmount(convertedWithPrecision);
    }

    else {
      let convertedAmount = (amountNeeded / customRate).toFixed(2)

      convertedWithPrecision = Math.floor(convertedAmount/ getPrecision()) * getPrecision()
      
      setConvertedAmountDisplayed(numberWithCommos(convertedWithPrecision));
      setConvertedAmount(convertedWithPrecision);

      amountWithPrecision = convertedWithPrecision * customRate
      setPrecisionAmount(amountWithPrecision)
      setAmountNeededDisplayed(numberWithCommos(amountWithPrecision))
    }

    if(usePlatformRate){
      getAmountMessage(amountWithPrecision, convertedWithPrecision)
    }
  }

  const getRate = (platformRate) => {
    if (buyOrderRequired) {
      if (platformRate.base_currency != baseCurrency) {
        return platformRate.sell_rate;
      }
      return platformRate.buy_rate;
    } else if (!buyOrderRequired) {
      if (platformRate.base_currency != baseCurrency) {
        return platformRate.buy_rate;
      }
      return platformRate.sell_rate;
    }
  };

  const getRateLimit = () => {
    if(buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['sell'].rate_limit / 100;
      return platformRate.rate_rules['buy'].rate_limit / 100;
    } else if(!buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['buy'].rate_limit / 100;
      return platformRate.rate_rules['sell'].rate_limit / 100;
    }  
  }

  const getMaxRate = () => {
    if(buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['sell'].max_rate;
      return platformRate.rate_rules['buy'].max_rate;
    } else if(!buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['buy'].max_rate;
      return platformRate.rate_rules['sell'].max_rate;
    } 
  }

  const getMinRate = () => {
    if(buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['sell'].min_rate;
      return platformRate.rate_rules['buy'].min_rate;
    } else if(!buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['buy'].min_rate;
      return platformRate.rate_rules['sell'].min_rate;
    } 
  }

  const getMinSellRate = () => {
    return platformRate.rate_rules['sell'].min_rate;
  }

  const getMaxBuyRate = () => {
    return platformRate.rate_rules['buy'].max_rate;
  }

  const getRateIncrement = () => {
    if(buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['sell'].increment;
      return platformRate.rate_rules['buy'].increment;
    } else if(!buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['buy'].increment;
      return platformRate.rate_rules['sell'].increment;
    } 
  }

  const getPrecision = () => {
    if(buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['sell'].precision;
      return platformRate.rate_rules['buy'].precision;
    } else if(!buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['buy'].precision;
      return platformRate.rate_rules['sell'].precision;
    } 
  }

  const precisionFollowsBase = () => {
    if(buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['sell'].follows_base_currency_precision;
      return platformRate.rate_rules['buy'].follows_base_currency_precision;
    } else if(!buyOrderRequired){
      if(platformRate.base_currency != baseCurrency)
        return platformRate.rate_rules['buy'].follows_base_currency_precision;
      return platformRate.rate_rules['sell'].follows_base_currency_precision;
    }
  } 

  const orderConfirmedToPost = () => {
    setOpenConfirmationDialog(false);
    postYourOrder();
  };

  const orderFundConfirmedToPost = () => {
    postOrderWithFund();
  }

  const disconfirmOrder = () => {
    setOpenConfirmationDialog(false);
  };

  const disconfirmFund = () => {
    setOpenFundDialog(false);
    window.location.reload();
  };

  const validateYourOrderBeforePost = (e) => {
    e.preventDefault();

    if (!amountNeeded) {
      setError(true);
      setSnakMessage("Please fill your needed amount");
      setOpenSnakbar(true);
      return;
    }

    if(baseCurrency == platformRate.base_currency){
      if(precisionAmount < profileRules.min_order_amount){
        setHasAmountNeededError(true)
        setAmountNeededErrorMessage(t("homepage.place-order.minimum-amount-error", {amount: numberWithCommos(profileRules.min_order_amount) + baseCurrency}))
        return;
      }
      if(precisionAmount > profileRules.max_order_amount){
        setHasAmountNeededError(true)
        setAmountNeededErrorMessage(t("homepage.place-order.maximum-amount-error", {amount: numberWithCommos(profileRules.max_order_amount) + baseCurrency}))
        return;
      }
      setAmountNeededErrorMessage('')
    }

    if(profile === profileType.Organization || profile == profileType.Business)
      setOpenDelegateDialog(true);

    else
      setOpenConfirmationDialog(true);
  };

  const closeDepositDialog = () => {
    setOpenDepositDialog(false);
    setLoading(false);
    history.push("/order-details", { id: orderId })
  }

  const closeComplianceDialog = () => {
    setOpenComplianceDialog(false);
    setLoading(false);
    history.push("/order-details", { id: orderId })
  }

  const postYourOrder = async () => {
    var data = {
      "amountNeeded":parseFloat(precisionAmount),
      "base_currency":baseCurrency,
      "quote_currency":quoteCurrency,
      "rate":customRate,
      "use_platform_rate": usePlatformRate,
      "order_delegate_details": delegateDetails,
      buyOrderRequired,
    };

    setLoading(true);
    const response = await httpPost("/place-order", data);
    if (!response.data.success){
      setLoading(false);
      setError(true);
      if(response.data.order_limit_reached){
        setCumulativeAmount(numberWithCommos(response.data.cumulative_amount) + response.data.currency);
        setUnitaryAmount(numberWithCommos(response.data.amount) + response.data.currency);
        setTransactionLimit(numberWithCommos(response.data.transaction_limit) + response.data.currency)
        setOpenFundDialog(true);
      }
      else{
        setSnakMessage(response.data.message);
        setOpenSnakbar(true);
      }
    }
    else {
      let successFn = function(){
        setLoading(false);
        setError(false);
        setSnakMessage(response.data.message);
        setOpenSnakbar(true);
        setAmountNeeded(0);
        setAmountNeededDisplayed(0);
        if(profile == profileType.Organization || profile == profileType.Business){
          setOpenDepositDialog(true);
          setOrderId(response.data.id);
        }
        history.push("/order-details", { id: response.data.id })}
      if(profile == profileType.Organization || profile == profileType.Business){
        successFn();
      }
      else{
        window.setTimeout(successFn, 2000);
      }
    }
    
  };

  const postOrderWithFund = async () => {
    var data = {
      "amountNeeded":parseFloat(amountNeeded),
      "base_currency":baseCurrency,
      "quote_currency":quoteCurrency,
      "rate":customRate,
      "use_platform_rate": usePlatformRate,
      "source_of_fund_proof": fundProof,
      "order_delegate_details": delegateDetails,
      buyOrderRequired,
    };
    setLoading(true);
    const response = await httpPost("/place-order", data);
    setLoading(false);
    if (!response.data.success) {
      setError(true);
      setSnakMessage(response.data.message);
      setOpenSnakbar(true);
      return;
    }
    setOpenFundDialog(false);
    setError(false);
    setOpenComplianceDialog(true);
    setSnakMessage(response.data.message);
    setOpenSnakbar(true);
    setAmountNeeded(0);
    setAmountNeededDisplayed(0);
    setFundProof({
      details: null,
      beneficial_right_owner_myself: true,
      beneficial_right_owner_other: ''
    });
    setOrderId(response.data.id);
  };
  
  if (!platformRate || loading) {
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        style={{ padding: "2%" }}
      >
        <Grid item>
          <LoadingDisplay width="100%" heigth="20rem" />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} md={4} >
          <Cards minSellRate={getMinSellRate()} maxBuyRate={getMaxBuyRate()} rate={getRate(platformRate)} quoteCurrency={platformRate.quote_currency}/>
        </Grid>
        
        <Grid item xs={12} md={8}> 
          <Grid container direction="column" justify="flex-start" >
            <form onSubmit={validateYourOrderBeforePost} style={{width: "100%"}} >
              <Grid item>
                <AmountNeededPart
                  baseCurrency={baseCurrency}
                  setBaseCurrency={setBaseCurrency}
                  quoteCurrency={quoteCurrency}
                  setQuoteCurrency={setQuoteCurrency}
                  buyOrderRequired={buyOrderRequired}
                  setBuyOrderRequired={setBuyOrderRequired}
                  amountNeeded={amountNeeded}
                  setAmountNeeded={setAmountNeeded}
                  amountNeededDisplayed={amountNeededDisplayed}
                  setAmountNeededDisplayed={setAmountNeededDisplayed}
                  platformRate={platformRate}
                  conversionMachine={conversionMachine}
                  convertedAmountDisplayed={convertedAmountDisplayed}
                  setConvertedAmountDisplayed={setConvertedAmountDisplayed}
                  setConvertedAmount={setConvertedAmount}
                  customRate={getRate(platformRate)}
                  setCustomRate={setCustomRate}
                  minRate={getMinRate()}
                  maxRate={getMaxRate()}
                  rateIncrement={getRateIncrement()}
                  probabilityMessage={probabilityMessage}
                  getMinRate={getMinRate}
                  getMaxRate={getMaxRate}
                  usePlatformRate={usePlatformRate}
                  setUsePlatformRate={setUsePlatformRate}
                  getRate={getRate}
                  hasAmountNeededError={hasAmountNeededError}
                  amountNeededErrorMessage={amountNeededErrorMessage}
                  setHasAmountNeededError={setHasAmountNeededError}
                  amountMessage={amountMessage}
                  setAmountMessage={setAmountMessage}
                  handleOnBlur={handleOnBlur}
                  getPrecision={getPrecision}
                  precisionFollowsBase={precisionFollowsBase}
                />
              </Grid>

              <Grid item>
                <Box mt={3} />
              </Grid>

              <Grid item style={{textAlign: 'end'}}>
                <Button variant="contained" color="secondary" type="submit" disabled={disablePlaceOrder}>
                  {t("button.place-order")}
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
        
        <Grid item xs={12} md={1} />

      </Grid>
      
      <FundDialog
        title={t("fund-dialog.title")}
        leftButtonTitle={t("button.cancel")}
        rightButtonTitle={t("button.submit")}
        onClickLeftButton={disconfirmFund}
        onClickRightButton={orderFundConfirmedToPost}
        open={openFundDialog}
        close={disconfirmFund}
        fundProof={fundProof}
        setFundProof={setFundProof}
        cumulativeAmount={cumulativeAmount}
        unitaryAmount={unitaryAmount}
        profileDetails={profileDetails}
        transactionLimit={transactionLimit}
      />

      <OrderConfirmationDialog
        title={t("homepage.place-order.dialog-title")}
        content={t("homepage.place-order.dialog-content")}
        leftButtonTitle={t("button.cancel")}
        rightButtonTitle={t("button.yes")}
        onClickLeftButton={disconfirmOrder}
        onClickRightButton={orderConfirmedToPost}
        open={openConfirmationDialog}
        close={disconfirmOrder}
        amountNeeded={numberWithCommos(precisionAmount)}
        convertedAmount={convertedAmount}
        baseCurrency={baseCurrency}
        quoteCurrency={quoteCurrency}
        buyOrderRequired={buyOrderRequired}
        amountMessage={amountMessage}
        usePlatformRate={usePlatformRate}
      />

      <DelegateDialog
        title={"Order Delegate"} 
        content={"Who will handle the deposit and withdrawal of cash at the Partner Network?"}
        leftButtonTitle={t("button.cancel")}
        rightButtonTitle={t("button.submit")}
        onClickLeftButton={() => setOpenDelegateDialog(false)}
        onClickRightButton={() => {setOpenDelegateDialog(false); setOpenConfirmationDialog(true)}}
        open={openDelegateDialog}
        close={() => setOpenDelegateDialog(false)}
        profileDelegates={profileDetails.order_delegates}
        setDelegateDetails={setDelegateDetails}
      />

      <InfoDialog
        open={openDepositDialog}
        onClose={closeDepositDialog}
        title={t("info-dialog.deposit-title")}
        subtitle={t("info-dialog.deposit-content")}
      />

      <InfoDialog
        open={openComplianceDialog}
        onClose={closeComplianceDialog}
        title={t("info-dialog.fund-title")}
        subtitle={t("info-dialog.fund-content")}
      />​

      <NotificationSnakBar
        setOpenSnakbar={setOpenSnakbar}
        openSnakbar={openSnakbar}
        error={error}
        snakMessage={snakMessage}
      />
    </>
  );
};

export default P2pOrderForm;
