import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataApis } from "../../apis/dataApis";
import { getAccountStatus } from "../../redux-reducer/centrifuge/centrifugeSlice";
import { updateOrganizationDetails } from "../../redux-reducer/organization/organizationSlice";
import { updateProfileType } from "../../redux-reducer/profileType/profileTypeSlice";
import { getUser } from "../../services/authService";
import { initializeRealtimeNotification } from "../../services/centrifugeService";
import NavigationBar from "./NavigationBar";
import NoteBar from "./NoteBar";
import { profileType } from "../../utils";
import { updateCountries, updateLegalForms, updateOccupationCodes, updateOwnershipBeneficialRights, updateSourceOfFunds, updateEmploymentStatus } from "../../redux-reducer/data/dataSlice";
import { organizationApis } from "../../apis/organizationApis";
import { businessApis } from "../../apis/businessApis";
import { individualApis } from "../../apis/individualApis";
import { updateBusinessDetails } from "../../redux-reducer/business/businessSlice";
import { updateIndividualDetails } from "../../redux-reducer/individual/individualSlice";
import { LoadingDisplay } from "../common-ui-fit/LoadingDisplay";

const NavigationBarWithNoteBar = () => {
  const profileId = getUser().profile_id;
  const profile = getUser().profile_type;
  const dispatch = useDispatch();
  initializeRealtimeNotification();
  const accountStatus = useSelector(getAccountStatus);
  const [profileDetails, setProfileDetails] = useState();
  
  useEffect(async () => {
    dispatch(updateProfileType(profile));

    let countriesResult = await dataApis.getCountries();
    if (countriesResult.data.success) {
      dispatch(updateCountries(countriesResult.data.countries));
    }

    if(profile == profileType.Organization){
      let beneficialRightsResult = await dataApis.getOwnershipBeneficialRights();
      if (beneficialRightsResult.data.success) {
        dispatch(updateOwnershipBeneficialRights(beneficialRightsResult.data.beneficial_right));
      }

      let legalForms = await dataApis.getLegalForms();
        if(legalForms.data.success) {
            dispatch(updateLegalForms(legalForms.data.legal_forms))
      }

      let occupations = await dataApis.getOccupations();
      if(occupations.data.success) {
          dispatch(updateOccupationCodes(occupations.data.occupations))
      }
      
      return;
    }

    if(profile == profileType.Business || profile == profileType.Individual){
      let sourceOfFunds = await dataApis.getSourceOfFunds();
      if(sourceOfFunds.data.success) {
          dispatch(updateSourceOfFunds(sourceOfFunds.data.sources_of_funds))
      }

      let employmentStatus = await dataApis.getEmploymentStatus();
      if(employmentStatus.data.success) {
          dispatch(updateEmploymentStatus(employmentStatus.data.employment_status))
      }

      return;
    }
  }, []);
  
  useEffect(async () => {
    
    if(profile == profileType.Organization) {
      let result = await organizationApis.getOrganizationInformation(profileId);
      if (result.data.success) {
        dispatch(updateOrganizationDetails(result.data));
        setProfileDetails(result.data);
      }
      return;
    }

    if(profile == profileType.Business) {
      
      let result = await businessApis.getBusinessInformation(profileId);
      if(result.data.success) {
       
        dispatch(updateBusinessDetails(result.data));
        setProfileDetails(result.data);
      }
      return;
    }

    if(profile == profileType.Individual) {
      let result = await individualApis.getIndividualInformation(profileId);
      if (result.data.success) {
        dispatch(updateIndividualDetails(result.data));
        setProfileDetails(result.data);
      }
      return;
    }
  }, []);

  if(profileDetails)
    return (
      <>
        <NavigationBar profileDetails={profileDetails} />

        <NoteBar profileDetails={profileDetails}/>
      </>
    );
  return <LoadingDisplay />
};

export default NavigationBarWithNoteBar;
