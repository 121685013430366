import {createSlice} from "@reduxjs/toolkit";

export const dataSlice = createSlice({
    name: "data",
    initialState: {
        countries: null,
        legalForms: null,
        occupationCodes: null,
        ownershipBeneficialRights: null,
        sourceOfFunds: null,
        employmentStatus: null
    },
    reducers: {
        updateCountries: (state, action) => {
            state.countries = action.payload;
        },
        updateLegalForms: (state, action) => {
            state.legalForms = action.payload;
        },
        updateOccupationCodes: (state, action) => {
            state.occupationCodes = action.payload;
        },
        updateOwnershipBeneficialRights: (state, action) => {
            state.ownershipBeneficialRights = action.payload;
        },
        updateSourceOfFunds: (state, action) => {
            state.sourceOfFunds = action.payload;
        },
        updateEmploymentStatus: (state, action) => {
            state.employmentStatus = action.payload;
        },
    }
});

export const { updateCountries, updateLegalForms, updateOccupationCodes, updateOwnershipBeneficialRights, updateSourceOfFunds, updateEmploymentStatus } = dataSlice.actions;

export const getCountries = (state) => state.data.countries;

export const getLegalForms = (state) => state.data.legalForms;

export const getOccupationCodes = (state) => state.data.occupationCodes;

export const getOwnershipBeneficialRights = (state) => state.data.ownershipBeneficialRights;

export const getSourceOfFunds = (state) => state.data.sourceOfFunds;

export const getEmploymentStatus = (state) => state.data.employmentStatus;

export default dataSlice.reducer;
