import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { MapWrapper } from './MapWrapper';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MapDialog({open,onClose,partnerBranches}) {
    const { t } = useTranslation();
    return (  
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
            TransitionComponent={Transition}
        >
            <AppBar style={{position: "relative"}}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        {t("order-details-page.map-branches-label")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <MapWrapper branches={partnerBranches}/>
        </Dialog>
    );
}