import {createSlice} from "@reduxjs/toolkit";

export const businessSlice = createSlice({
  name: "businessInfo",
  initialState: {
    businessDetails: null,
    loaded: true
  },
  reducers: {
    updateBusinessDetails: (state, action) => {
      state.businessDetails = action.payload;
      state.loaded = true;
    },
    reloadBusinessDetails: (state) => {
      state.loaded = false;
    },
  }
});

export const { updateBusinessDetails, reloadBusinessDetails } = businessSlice.actions;

export const getBusinessDetails = (state) => state.businessInfo.businessDetails;

export const getBusinessLoaded = (state) => state.businessInfo.loaded;

export default businessSlice.reducer;
