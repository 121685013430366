import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Typography, Box } from "@material-ui/core";
import { formatDate, getUsername, isArabic, parseServerDate, toArabicDigits  } from "../../../utils";
import { useTranslation } from 'react-i18next';

import commonStyles from "../../../commonStyles";
import moment from "moment";
import { getCountries, getEmploymentStatus, getSourceOfFunds } from "../../../redux-reducer/data/dataSlice";
import { useSelector } from "react-redux";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";
import { getProfileType } from "../../../redux-reducer/profileType/profileTypeSlice";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2rem"
    },
    center: {
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
    },

  ...commonStyles,
}));

export default function IndividualDetails({individualDetails}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const countries = useSelector(getCountries);
    const employmentStatus = useSelector(getEmploymentStatus);
    const sourceOfFunds = useSelector(getSourceOfFunds);
    const type = useSelector(getProfileType);

    const getOptionsById = (options, values) => {
        let result = []
        if(values)
          for(var i=0; i<values.length; i++){
            let filter = options.find((option) => option.id === values[i])
            if(filter)
              result.push(filter)
          }
        return result;
    }

    const getEmploymentStatusById = (value) => {
        return employmentStatus.find((status) => status.id == value);
    }

    const getSourceOfFundsById = (values) => {
        return getOptionsById(sourceOfFunds, individualDetails.sources_of_funds).map(option => option.name == "Other" ? individualDetails.other_source_of_funds : (isArabic() ? option.name_arabic : option.name)).join(", ")
    }

    if(countries && employmentStatus && sourceOfFunds && type)
        return (
            <Paper elevation={0} className={classes.root}>
                <Grid container direction="column" md={12} xs={12}>           
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("labels.name")}
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {getUsername(individualDetails, type)}
                            </Typography>
                        </Grid>
                    </Grid>                              
                                        
                    <Box mt={3} />
            
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("labels.dob")}
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {isArabic() ? toArabicDigits(moment(new Date(individualDetails.date_of_birth)).format("DD/MM/yyyy")) : moment(new Date(individualDetails.date_of_birth)).format("DD/MM/yyyy")}
                            </Typography>
                        </Grid>
                    </Grid>                                   

                    <Box mt={3} />
                
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("kyc-details-step.phone-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                            {isArabic() ? toArabicDigits((individualDetails.mobile_phone).toString()) : individualDetails.mobile_phone}
                            </Typography>
                        </Grid>
                    </Grid>
            
                    <Box mt={3} />
        
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("labels.nationality")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {getOptionsById(countries, individualDetails.nationalities).map(option => isArabic() ? option.nationality_arabic : option.nationality_en).join(", ")}
                            </Typography>
                        </Grid>
                    </Grid>
        
                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("labels.residence")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {getOptionsById(countries, individualDetails.residences).map(option => isArabic() ? option.name_arabic : option.name).join(", ")} 
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("kyc-details-step.employment-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {
                                    getEmploymentStatusById(individualDetails.employment_status_id).name == "Other" ? 
                                    individualDetails.other_employment_status : isArabic() ? getEmploymentStatusById(individualDetails.employment_status_id).name_arabic : getEmploymentStatusById(individualDetails.employment_status_id).name
                                }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("kyc-details-step.funds-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {getSourceOfFundsById(individualDetails.sources_of_funds)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        )

        return <LoadingDisplay />
}
