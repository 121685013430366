import { createSlice } from "@reduxjs/toolkit";

export const centrifugeSlice = createSlice({
  name: "realtimeUpdates",
  initialState: {
    notificationUnread: 0,
    accountStatus: "",
  },
  reducers: {
    updateUnreadNotifications: (state, action) => {
      state.notificationUnread = action.payload;
    },
    updateAccountStatus: (state, action) => {
      state.accountStatus = action.payload;
    },
  },
});

export const { updateUnreadNotifications, updateAccountStatus } =
  centrifugeSlice.actions;

export const getUnreadNotifications = (state) =>
  state.realtimeUpdates.notificationUnread;

export const getAccountStatus = (state) => state.realtimeUpdates.accountStatus;

export default centrifugeSlice.reducer;
