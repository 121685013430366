import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { theme } from "../App";
import commonStyles from "../commonStyles";
import { httpPut } from "../httpcommon";
import { setUser } from "../services/authService";
import { Formik } from "formik";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  startButton: {
    marginTop: theme.spacing(2),
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  ...commonStyles,
}));

export default function ResetPassword() {
  let { id, activationkey } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordReset = async (values) => {
    setLoading(true);
    const data = {
      password: values.password,
    };

    const result = await httpPut("/resetyourpassword/" + id + "/" + activationkey, data);
    if (result.data.success) {
      setUser(result.data);
      history.push("/home");
    } else {
      setSnackMessage(result.data.message);
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const validationSchema = yup.object({
    password: yup
      .string("Enter your password")
      .min(10, "Password should be of minimum 10 characters length")
      .required("Password is required")
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*-_]).*$",
        "At least one upper case, one lower case, one number and one special character"
      ),
    confirmPassword: yup
      .string("Confirm your password")
      .required("Confirm Password is required")
      .test(
        "confirm-password-test",
        "Passwords do not match",
        function (value) {
          return value === this.parent.password;
        }
      ),
  });

  return (
    <Grid
      container
      direction="row"
      justify="center"
      component="main"
      className={classes.root}
    >
      <Grid item xs={12} md={6}>
        <Grid
          container
          style={{ height: "100%" }}
          alignItems="center"
          justify="center"
        >
          <div style={{textAlign: "center"}}>
            <IconButton onClick={() => history.push("/")} style={{ backgroundColor: "transparent" }}>
              <img
                src="/images/cedarxneo-logo/cedarxneo-green-logo.png"
                style={{ width: "17rem" }}
              />
            </IconButton>
            <Box mt={2} />
            <Typography variant="h3">Reset Your Password</Typography>
            <Box mt={1} />

            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => handlePasswordReset(values)}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                touched,
                errors,
              }) => (
                <>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    label="Password*"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    value={values.password}
                    name="password"
                    labelWidth={70}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                    }}
                    inputProps={{ tabIndex: "1" }}
                  />

                  <TextField
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    id="outlined-adornment-password"
                    type="password"
                    label="Confirm Password*"
                    onChange={handleChange("confirmPassword")}
                    onBlur={handleBlur("confirmPassword")}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    labelWidth={70}
                    error={
                      touched.confirmPassword &&
                      Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    inputProps={{ tabIndex: "2" }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    className={classes.startButton}
                    onClick={handleSubmit}
                    inputProps={{ tabIndex: "4" }}
                  >
                    Reset Password
                  </Button>
                </>
              )}
            </Formik>
          </div>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={1800}
            message={snackMessage}
            onClose={handleClose}
            ContentProps={{ classes: { root: classes.error } }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
