import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useTranslation } from 'react-i18next';
import { MenuItem, Tooltip } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import { hasFieldError, getFieldError, isEditStepper, isArabic, toArabicDigits } from "../../../utils";

const useStyles = makeStyles((theme) => ({
    input: {
        marginTop: "1rem"
    },
    inputContainer: {
        alignItems: "center"
    }
}));

export default function FinancialSituation(props) {
    const formikProps = props.formikProps;

    const {
        formField: {
            volume,
            sales,
            information
        }
    } = props;
    
    const classes = useStyles();
    const { t } = useTranslation();

    const annualSales = [
        "0 – 50,000",
        "50,001 – 100,000",
        "100,001 – 300,000",
        "300,001 – 600,000",
        "600,001 – 1,000,000",
        "1,000,001 – 2,000,000",
        "2,000,001 – 4,000,000",
        "4,000,001 +"
    ]

    return (
        <React.Fragment>
            <Typography variant="h4" style={{ lineHeight: 1.33, textAlign: "center" }}>
                {t("stepper.kyb.step3")}
            </Typography>
                
            <Box mt={5} /> 

            <Grid container direction="row" md={12} xs={12} >
                <Grid container direction="row" md={5} xs={12} className={classes.inputContainer}>
                    <Grid item md={11} xs={11} className={classes.input}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name={volume.name}
                            label={volume.label}
                            value={props.organizationNumbersDisplayed[volume.name]}
                            onChange={props.setOrganizationNumberAttribute(formikProps.setFieldValue)}
                            onBlur={formikProps.handleBlur(volume.name)} 
                            error={(formikProps.touched[volume.name] && Boolean(formikProps.errors[volume.name])) || (isEditStepper() && formikProps.values[volume.name] == props.organizationDetails[volume.name] && hasFieldError(props.organizationDetails, volume.name))}
                            helperText={(formikProps.touched[volume.name] && formikProps.errors[volume.name]) || (formikProps.values[volume.name] == props.organizationDetails[volume.name] && getFieldError(props.organizationDetails, volume.name))}
                        />
                    </Grid>
                    <Grid item md={1} xs={1} className={classes.input} style={{textAlign: "end"}}>
                        <Tooltip title={t("financial-step.volume-desc")}>
                            <HelpIcon color="secondary" fontSize= "small" />
                        </Tooltip>
                    </Grid>
                </Grid>

                <Grid item md={2} xs={12} />

                
                <Grid container direction="row" md={5} xs={12} className={classes.inputContainer}>
                    <Grid item md={11} xs={11} className={classes.input}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            select
                            name={sales.name}
                            label={sales.label}
                            value={formikProps.values[sales.name]}
                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                            onBlur={formikProps.handleBlur(sales.name)} 
                            error={(formikProps.touched[sales.name] && Boolean(formikProps.errors[sales.name])) || (isEditStepper() && formikProps.values[sales.name] == props.organizationDetails[sales.name] && hasFieldError(props.organizationDetails, sales.name))}
                            helperText={(formikProps.touched[sales.name] && formikProps.errors[sales.name]) || (formikProps.values[sales.name] == props.organizationDetails[sales.name] && getFieldError(props.organizationDetails, sales.name))}
                        >
                            {
                                annualSales.map((option) => {
                                    return (
                                        <MenuItem value={option}>{isArabic() ? toArabicDigits(option) : option}</MenuItem>
                                    )}
                                )
                            }
                        </TextField>
                    </Grid>
                    <Grid item md={1} xs={1} />
                </Grid>
            

                <Grid container direction="row" md={5} xs={12} className={classes.inputContainer}>
                    <Grid item md={11} xs={11} className={classes.input}>
                        <TextField
                            variant="outlined"
                            multiline
                            fullWidth
                            name={information.name}
                            label={information.label}
                            value={formikProps.values[information.name]}
                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                            onBlur={formikProps.handleBlur(information.name)} 
                            error={(formikProps.touched[information.name] && Boolean(formikProps.errors[information.name])) || (isEditStepper() && formikProps.values[information.name] == props.organizationDetails[information.name] && hasFieldError(props.organizationDetails, information.name))}
                            helperText={(formikProps.touched[information.name] && formikProps.errors[information.name]) || (formikProps.values[information.name] == props.organizationDetails[information.name] && getFieldError(props.organizationDetails, information.name))}
                        />
                    </Grid>
                    <Grid item md={1} xs={1} className={classes.input} style={{textAlign: "end"}}>
                        <Tooltip title={t("financial-step.information-desc")}>
                            <HelpIcon color="secondary" fontSize= "small" />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>       
        </React.Fragment>
    );
}
