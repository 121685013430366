import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { getOrganizationDetails } from "../../redux-reducer/organization/organizationSlice";
import { isArabic, profileStatus } from "../../utils";
import { useTranslation } from "react-i18next";

const NoteBar = ({profileDetails}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  if (!profileDetails)
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
        className={classes.guestNote}
      >
        <Grid item>
          <Box mt={9} />
        </Grid>

        <Grid item>
          <Typography variant="h6" style={{ color: "black" }}>
            {t("navbar.loading")}
          </Typography>
        </Grid>
      </Grid>
    );

  if (profileDetails?.account_status?.status === profileStatus.Rejected)
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
        className={classes.systemNote}
      >
        <Grid item>
          <Box mt={9} />
        </Grid>

        <Grid item>
          <Typography variant="h6" style={{ color: "white" }}>
            {t("rejected-message")}
          </Typography>
        </Grid>
      </Grid>
    );


  if (profileDetails?.account_status?.type === profileStatus.Guest)
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
        className={classes.systemNote}
      >
        <Grid item>
          <Box mt={9} />
        </Grid>

        <Grid item>
          <Typography variant="h6" style={{ color: "white" }}>
            {isArabic()
              ? profileDetails?.account_status?.message_arabic
              : profileDetails.account_status.message}
          </Typography>
        </Grid>
      </Grid>
    );

  if (profileDetails?.account_status?.type === profileStatus.SystemStopped)
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
        className={classes.systemNote}
      >
        <Grid item>
          <Box mt={9} />
        </Grid>

        <Grid item>
          <Typography variant="h6" style={{ color: "white" }}>
            {profileDetails.account_status.message}
          </Typography>
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid item>
        <Box mt={5} />
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  guestNote: {
    backgroundColor: "rgba(37, 218, 197, 0.1)",
    padding: theme.spacing(0.1),
    textAlign: "center"
  },
  systemNote: {
    backgroundColor: "rgba(255, 0, 0, 0.5)",
    padding: theme.spacing(0.1),
    textAlign: "center"
  },
}));

export default NoteBar;
