import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import commonStyles from "../../../commonStyles";
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormControlLabel,  Radio, RadioGroup, Chip, Tooltip } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from "react-redux";
import { getCountries } from "../../../redux-reducer/data/dataSlice";
import { countryToFlag, isArabic, eighteen, hasFieldError, isEditStepper, getFieldError, isRegisterStepper, individualDocuments } from "../../../utils";
import DatePicker from "../../common-components/DatePicker";
import { FieldArray } from "formik";
import AttachFile from "@material-ui/icons/AttachFile";
import DropzoneArea from "../../common-components/DropzoneArea";
import DocumentChip from "../../common-components/DocumentChip";
import HelpIcon from '@material-ui/icons/Help';
import Snackbar from "@material-ui/core/Snackbar";
import CorporateIdentificationDocument from "../../common-components/CorporateIdentificationDocument";

const useStyles = makeStyles((theme) => ({
    avatarCustomized: {
        height: "7.2rem",
        width: "7.2rem",
        border: "solid 1px",
        borderColor: theme.palette.secondary.main,
        borderRadius: "3px",
    },
    dropzone: {
        fontSize: "0.8rem",
        marginTop: "1.8rem",
    },

    input: {
        marginTop: "1rem"
    },

    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    deleteIcon: {
        fontSize: '1rem'
    },

    title: {
        marginTop: theme.spacing(2),
        fontWeight: 600
    },

    titleStyle: {
        alignItems: 'baseline',
        justifyContent: 'space-between'
    },

    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    ...commonStyles,
}));

export default function Representatives(props) {
    const formikProps = props.formikProps;

    const {
        formField: {
            representatives
        }
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const countries = useSelector(getCountries);

    const minDate = eighteen();

    const checkMainContactExists = () => {
        let exists = formikProps.values[representatives.name].find((rep) => !rep[representatives.isRepresentative.name])
        if (exists)
            return true
        else return false
    }

    const handleAddRepresentative = (push, insert) => {       
        var newRepresentative = {
            [representatives.contact.name]: false,
            [representatives.isRepresentative.name]: true,
            [representatives.firstName.name]: "",
            [representatives.lastName.name]: "",
            [representatives.fatherName.name]: "",
            [representatives.doBirth.name]: null,
            [representatives.nationality.name]: [],
            [representatives.residence.name]: [],
            [representatives.documentType.name]: individualDocuments.NationalID.name,
            [representatives.documentNumber.name]: "",
            [representatives.documentDate.name]: null,
            document_content: {
                [individualDocuments.IdFrontSide.name]: null,
                [individualDocuments.IdBackSide.name]: null
            },
            delegation_document_content: null
        }

        if(checkMainContactExists()){
            insert(formikProps.values[representatives.name].length - 1, newRepresentative);
        }
        else
            push(newRepresentative);
        

        props.setEdit(true);
    };

    const handleAddMainContact = (push, replace) => { 
        let representativesArray = formikProps.values[representatives.name];

        for (var i = 0; i < representativesArray.length; i++){
            if(representativesArray[i][representatives.contact.name]){
                //if already a main contact person is added break
                if(!(representativesArray[i][representatives.isRepresentative.name])){
                    return;
                }
                //if already another legal rep is assigned as main contact, unassign him
                if(representativesArray[i][representatives.isRepresentative.name]){
                    replace(i, {
                        ...representativesArray[i],
                        [representatives.contact.name]: !(representativesArray[i][representatives.contact.name])
                    })
                }
            }
        }
        // add main contact person
        push(
            {
                [representatives.contact.name]: true,
                [representatives.isRepresentative.name]: false,
                [representatives.firstName.name]: "",
                [representatives.lastName.name]: "",
                [representatives.fatherName.name]: "",
                [representatives.doBirth.name]: null,
                [representatives.nationality.name]: [],
                [representatives.residence.name]: [],
                [representatives.documentType.name]: individualDocuments.NationalID.name,
                [representatives.documentNumber.name]: "",
                [representatives.documentDate.name]: null,
                document_content: {
                    [individualDocuments.IdFrontSide.name]: null,
                    [individualDocuments.IdBackSide.name]: null
                },
                delegation_document_content: null
            }
        )

        props.setEdit(true);
    };

    const handleRemoveRepresentative = (index, remove) => {
        remove(index);

        props.setEdit(true);
    };
    
    const handleContactChange = (index, replace, remove) => (event) => {
        let representativesArray = formikProps.values[representatives.name];

        for (var i = 0; i < representativesArray.length; i++){
            if(representativesArray[i][representatives.contact.name]){
                //if already a main contact person is added, remove it
                if(!(representativesArray[i][representatives.isRepresentative.name])){
                    remove(i);
                }
                //if already another legal rep is assigned as main contact, unassign him
                if(representativesArray[i][representatives.isRepresentative.name]){
                    replace(i, {
                        ...representativesArray[i],
                        [representatives.contact.name]: !(representativesArray[i][representatives.contact.name])
                    })
                }
            }
        }

        //asssign current representative index as main contact
        replace(index, {
            ...representativesArray[index],
            [representatives.contact.name]: !representativesArray[index][representatives.contact.name]
        })

        props.setEdit(true);
    }

    const DelegationDocumentComponent = ({representative, index}) => {
        if(isRegisterStepper()){
            if(representative.delegation_document_content){
                return (
                    <Chip
                        icon={<AttachFile />}
                        variant='outlined'
                        label={"delegation_document"}
                        onDelete={() => formikProps.setFieldValue(`[${representatives.name}][${index}].delegation_document_content`, null)}
                    />
                )
            }

            return (
                <DropzoneArea
                    acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                    handleFileChange={(file) => formikProps.setFieldValue(`[${representatives.name}][${index}].delegation_document_content`, file)}  
                    documentName={"delegation_document"}
                />
            )

        }

        if(isEditStepper()){
            if(representative.delegation_document_path){
                return (
                    <DocumentChip
                        label={"delegation_document"}
                        link={representative.delegation_document_path['Delegation']}
                        status={representative.delegation_document_status}
                        documentName={"delegation_document"}
                        updatedDocuments={props.updatedDocuments}
                    />
                )
            }

            if(representative.delegation_document_content)
                return (
                    <Chip 
                        icon={<AttachFile />}
                        variant='outlined'
                        label={"delegation_document"}
                    />
                )

            return (
                <DropzoneArea
                    acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                    documentName={representative[representatives.documentType.name]}
                />
            )
        }
    }

    const handleDocumentTypeChange = (replace, index) => (e) => {
        let document_content = {
            [individualDocuments.IdFrontSide.name]: null,
            [individualDocuments.IdBackSide.name]: null
        }
        if(e.target.value == individualDocuments.Passport.name){
            document_content = {
                [individualDocuments.Passport.name]: null,
            }     
        }
        replace(index, {
            ...formikProps.values[representatives.name][index],
            [representatives.documentType.name]: e.target.value,
            [representatives.documentNumber.name]: "",
            [representatives.documentDate.name]: null,
            document_content: document_content
        });

        props.setEdit(true);
    }

    return (
        <React.Fragment>
            <Typography variant="h4" style={{ lineHeight: 1.33, textAlign: "center" }}>
                {t("stepper.kyb.step5")}
            </Typography>

            {
                isEditStepper() && hasFieldError(props.organizationDetails, representatives.name) &&
                <React.Fragment>
                    <Box mt={5} /> 
                    <Grid item md={12} xs={12}>
                        <Typography variant="body2" color="error" style={{ textAlign: "center" }}>
                            {getFieldError(props.organizationDetails, representatives.name)}
                        </Typography>
                    </Grid>
                </React.Fragment>
                
            }
                
            <Box mt={5} /> 

            <FieldArray
                name={representatives.name}
                render={({ remove, push, replace, insert }) => {
                    return (
                    <React.Fragment>
                        {
                            formikProps.values[representatives.name].map((representative, index) => {           
                                return (
                                    <React.Fragment>
                                        <Grid container direction="row" md={12} xs={12} alignItems="center" style={{justifyContent: "space-between"}} key={index}>
                                            <Grid item md={10} xs ={12} style={{display: "inline-flex", alignItems: "center"}}>
                                                <Typography variant="h5">
                                                    {
                                                        representative[representatives.isRepresentative.name] ? representatives.label + " #" + `${index + 1}` : representatives.isRepresentative.label
                                                    }
                                                    
                                                </Typography>
                                                {index != 0  &&
                                                    <IconButton onClick={() => handleRemoveRepresentative(index, remove)}>
                                                        <DeleteIcon color="error" className={classes.deleteIcon}/>
                                                    </IconButton>
                                                }
                                            </Grid>
                                            <Grid container direction="row" md={2} xs={12} style={{justifyContent: "end", alignItems: "center"}}>
                                                <Grid item>
                                                    <FormControl component="fieldset">
                                                        <Box mt={1} />
                                                        <RadioGroup row value={representative[representatives.contact.name]} onChange={handleContactChange(index, replace, remove)} name={`[${representatives.name}][${index}][${representatives.contact.name}]`}>
                                                            <FormControlLabel
                                                                value={representative[representatives.contact.name]}
                                                                control={<Radio 
                                                                    checked={representative[representatives.contact.name]}/>}
                                                                label={representatives.contact.label}
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title={t("representatives-step.contact-desc")}>
                                                        <HelpIcon color="secondary" fontSize= "small" />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                    
                                        <Grid container direction="row" md={12} xs={12} >
                                            <Grid item md={5} xs={12} className={classes.input}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name={`[${representatives.name}][${index}][${representatives.firstName.name}]`}
                                                    label={representatives.firstName.label}
                                                    value={representative[representatives.firstName.name]}
                                                    onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                    onBlur={formikProps.handleBlur(`[${representatives.name}][${index}][${representatives.firstName.name}]`)} 
                                                    error={
                                                        (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.firstName.name] && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && Boolean(formikProps.errors[representatives.name][index][representatives.firstName.name])) 
                                                        //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                    }
                                                    helperText={
                                                        (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.firstName.name]  && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && formikProps.errors[representatives.name][index][representatives.firstName.name]) 
                                                        //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                    }
                                                    
                                                />
                                            </Grid>
                            
                                            <Grid item md={2} xs={12} />
                            
                                            
                                            <Grid item md={5} xs={12} className={classes.input}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name={`[${representatives.name}][${index}][${representatives.lastName.name}]`}
                                                    label={representatives.lastName.label}
                                                    value={representative[representatives.lastName.name]}
                                                    onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                    onBlur={formikProps.handleBlur(`[${representatives.name}][${index}][${representatives.lastName.name}]`)} 
                                                    error={
                                                        (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.lastName.name] && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && Boolean(formikProps.errors[representatives.name][index][representatives.lastName.name])) 
                                                        //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                    }
                                                    helperText={
                                                        (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.lastName.name]  && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && formikProps.errors[representatives.name][index][representatives.lastName.name]) 
                                                        //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                    }
                                                    
                                                />
                                            </Grid>
                                            
                                            <Grid item md={5} xs={12} className={classes.input}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name={`[${representatives.name}][${index}][${representatives.fatherName.name}]`}
                                                    label={representatives.fatherName.label}
                                                    value={representative[representatives.fatherName.name]}
                                                    onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                                                    onBlur={formikProps.handleBlur(`[${representatives.name}][${index}][${representatives.fatherName.name}]`)} 
                                                    error={
                                                        (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.fatherName.name] && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && Boolean(formikProps.errors[representatives.name][index][representatives.fatherName.name])) 
                                                        //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                    }
                                                    helperText={
                                                        (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.fatherName.name]  && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && formikProps.errors[representatives.name][index][representatives.fatherName.name]) 
                                                        //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                    }
                                                />
                                            </Grid>
                            
                                            <Grid item md={2} xs={12} />
                            
                                            <Grid item md={5} xs={12} className={classes.input}>
                                                <Autocomplete
                                                    multiple
                                                    options={countries}
                                                    filterSelectedOptions
                                                    value={props.getOptionById(countries, representative[representatives.nationality.name])}
                                                    onChange={props.setMultipleOptionsAttribute(formikProps.setFieldValue, `[${representatives.name}][${index}][${representatives.nationality.name}]`)}
                                                    getOptionLabel={
                                                        (option) => isArabic() ? countryToFlag(option.code) + " " + option.nationality_arabic : countryToFlag(option.code) + " " + option.nationality_en 
                                                    }
                                                    renderOption={(option) => (
                                                        <React.Fragment>
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                alt=""
                                                                style={{marginRight: '1rem'}}
                                                            />
                                                            {isArabic() ? option.nationality_arabic : option.nationality_en} 
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            name={`[${representatives.name}][${index}][${representatives.nationality.name}]`}
                                                            label={representatives.nationality.label}
                                                            onBlur={formikProps.handleBlur(`[${representatives.name}][${index}][${representatives.nationality.name}]`)} 
                                                            error={
                                                                (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.nationality.name] && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && Boolean(formikProps.errors[representatives.name][index][representatives.nationality.name])) 
                                                                //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                            helperText={
                                                                (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.nationality.name]  && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && formikProps.errors[representatives.name][index][representatives.nationality.name]) 
                                                                //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                            
                                            <Grid item md={5} xs={12} className={classes.input}>
                                                <Autocomplete
                                                    multiple
                                                    filterSelectedOptions
                                                    options={countries}
                                                    value={props.getOptionById(countries, representative[representatives.residence.name])}
                                                    onChange={props.setMultipleOptionsAttribute(formikProps.setFieldValue, `[${representatives.name}][${index}][${representatives.residence.name}]`)}
                                                    getOptionLabel={
                                                        (option) => isArabic() ? countryToFlag(option.code) + " " + option.name_arabic : countryToFlag(option.code) + " " + option.name 
                                                    }
                                                    renderOption={(option) => (
                                                        <React.Fragment>
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                alt=""
                                                                style={{marginRight: '1rem'}}
                                                            />
                                                            {isArabic() ? option.name_arabic : option.name} 
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            name={`[${representatives.name}][${index}][${representatives.residence.name}]`}
                                                            label={representatives.residence.label} 
                                                            onBlur={formikProps.handleBlur(`[${representatives.name}][${index}][${representatives.residence.name}]`)} 
                                                            error={
                                                                (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.residence.name] && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && Boolean(formikProps.errors[representatives.name][index][representatives.residence.name])) 
                                                                //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                            helperText={
                                                                (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.residence.name]  && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && formikProps.errors[representatives.name][index][representatives.residence.name]) 
                                                                //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                            }
                                                        />
                                                    )}
                                                />
                                                
                                            </Grid>
                                            
                            
                                            <Grid item md={2} xs={12} />
                            
                                            <Grid container direction="row" md={5} xs={12}>
                                                <Grid item md={12} xs={12}>
                                                    <Typography variant="h6">{representatives.doBirth.label}</Typography>
                                                </Grid>
                                                <DatePicker
                                                    value={representative[representatives.doBirth.name]}
                                                    handleDateChange={value => { formikProps.setFieldValue(`[${representatives.name}][${index}][${representatives.doBirth.name}]`, value); props.setEdit(true) }}
                                                    InputProps={{ 
                                                        readOnly: true,
                                                        name: `[${representatives.name}][${index}][${representatives.doBirth.name}]`,
                                                        error:
                                                            (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.doBirth.name] && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && Boolean(formikProps.errors[representatives.name][index][representatives.doBirth.name])), 
                                                            //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                                        
                                                        helperText:
                                                            (formikProps.touched[representatives.name] && formikProps.touched[representatives.name][index] && formikProps.touched[representatives.name][index][representatives.doBirth.name]  && formikProps.errors[representatives.name] && formikProps.errors[representatives.name][index] && formikProps.errors[representatives.name][index][representatives.doBirth.name]), 
                                                            //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                                                    }}
                                                    disableFuture={true}
                                                />
                                            </Grid> 
                                            
                                            <CorporateIdentificationDocument 
                                                field={representative}
                                                arrayField={representatives}
                                                index={index}
                                                classes={classes}
                                                formikProps={formikProps}
                                                onChange={props.setOrganizationAttribute(formikProps.setFieldValue)}
                                                setEdit={props.setEdit}
                                                handleDocumentTypeChange={handleDocumentTypeChange(replace, index)}
                                                otherProps={props}
                                            />

                                            {
                                                !representative[representatives.isRepresentative.name] &&
                                                <Grid item md={5} xs={12}>
                                                    <Grid container direction="row" className={classes.titleStyle}>
                                                        <Grid item md={11} xs={11}>
                                                            <Typography variant="h6" gutterBottom className={classes.title}>
                                                                {t("documents.delegation_document.label")}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <DelegationDocumentComponent representative={representative} index={index} />

                                                </Grid>
                                            }

                                        </Grid> 
                                    </React.Fragment>
                                )
                            })
                        }

                        <Box mt={3} /> 

                        <Grid item style={{textAlign:"center"}}>
                            <Button variant="contained" color="secondary" onClick={e => handleAddRepresentative(push, insert)}>{t("button.add-representative")}</Button> 
                        </Grid>

                        <Box mt={3} />

                        {
                            !checkMainContactExists() &&
                            <Typography variant="h6">In case the Company wants to assign a Main Contact Person who is different than any of the Legal Representatives, please<Button variant="text" color="secondary" onClick={() => handleAddMainContact(push, replace)}>click here</Button></Typography>
                        }
                    </React.Fragment>
                );}}
            /> 
            <Snackbar
                open={props.openSnackBar}
                autoHideDuration={1800}
                message={props.snackMessage}
                onClose={() => props.setOpenSnackBar(false)}
                ContentProps={{ classes: { root: classes.error } }}
            />  
        </React.Fragment>
    );
}

