import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";

import { Field, Formik } from "formik";
import * as yup from "yup";
import { Box, Button, Grid, IconButton, MenuItem, TextField, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { httpPost } from "../httpcommon";
import { numberWithCommos } from "../utils";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2rem",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
    },

    center: {
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
    },
    
    doneIcon: {
        color: theme.palette.primary.main,
        fontSize: 50,
    },

}));

export default () => {
    const classes = useStyles();
    const history = useHistory();

    const [response, setResponse] = useState();
    const [validated, setValidated] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [confirmed, setConfirmed] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState();
    const [disabled, setDisabled] = useState();

    const validationSchema = yup.object({
        transaction_id: yup
            .string()
            .required(),
        customer_identity_type: yup
            .string()
            .required(),
        customer_identity_number: yup
            .string()
            .required()
    });

    const handleValidateTransaction = async (values) => {
        setTransactionDetails(values);

        const validationResponse = await httpPost("/transaction/validate", values);

        if(validationResponse){
            if(validationResponse.data.success){
                setResponse(validationResponse.data);
                setValidated(true);
                setDisabled(true);
                setValidationMessage("Valid Transaction")
            }
            else setValidationMessage(validationResponse.data.message)
        }
    }
  
    const handleConfirmTransaction = async (transaction_id) => {
        var data = {
            "transaction_id": transaction_id,
            "partner_transaction_id": transaction_id,
            "partner_store_id": "1233",
            "partner_operator_id": "5678"
        }

        const confirmationResponse = await httpPost("/transaction/confirm", data);

        if(confirmationResponse){
            if(confirmationResponse.data.success){
                setConfirmed(true);
                setValidated(false);
                setValidationMessage('');
                setResponse(confirmationResponse.data);
            }
        }

    }

    console.log("confirmed: ", confirmed)

    return (
        <Grid container md={12} xs={12} className={classes.root}>
            <Grid item md={12} xs={12}>
                <IconButton onClick={() => history.push("/")} style={{ backgroundColor: "transparent" }}>
                    <img
                        src="/images/cedarxneo-logo/cedarxneo-green-logo.png"
                        style={{ width: "17rem" }}
                    />
                </IconButton>
            </Grid>

            <Grid container direction="row" md={12} xs={12} className={classes.root}>
                <Formik
                    initialValues={{
                        transaction_id: "",
                        customer_identity_type: "",
                        customer_identity_number: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleValidateTransaction(values)}
                >
                    {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                        touched,
                        errors,
                    }) => (
                        <React.Fragment>
                            <Grid item md={3} xs={12}>
                                <TextField
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    label= "Transaction Id"
                                    onChange={handleChange("transaction_id")}
                                    onBlur={handleBlur("transaction_id")}
                                    value={values.transaction_id}
                                    name="transaction_id"
                                    error={
                                        touched.transaction_id &&
                                        Boolean(errors.transaction_id)
                                    }
                                    helperText={
                                        touched.transaction_id && errors.transaction_id
                                    }
                                    required
                                />
                            </Grid>

                            <Grid item md={1} xs={12}></Grid>

                            <Grid item md={3} xs={12}>
                                <TextField
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    label="Document Type"
                                    onChange={handleChange("customer_identity_type")}
                                    onBlur={handleBlur("customer_identity_type")}
                                    value={values.customer_identity_type}
                                    name="customer_identity_type"
                                    error={
                                        touched.customer_identity_type &&
                                        Boolean(errors.customer_identity_type)
                                    }
                                    helperText={
                                        touched.customer_identity_type &&
                                        errors.customer_identity_type
                                    }
                                    required
                                >
                                        <MenuItem value="National ID">National Id</MenuItem>
                                        <MenuItem value="Passport">Passport</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item md={1} xs={12}></Grid>

                            <Grid item md={3} xs={12}>
                                <TextField
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    label="Document Number"
                                    onChange={handleChange("customer_identity_number")}
                                    onBlur={handleBlur("customer_identity_number")}
                                    value={values.customer_identity_number}
                                    name="customer_identity_number"
                                    error={
                                        touched.customer_identity_number &&
                                        Boolean(errors.customer_identity_number)
                                    }
                                    helperText={
                                        touched.customer_identity_number &&
                                        errors.customer_identity_number
                                    }
                                    required
                                />
                            </Grid>

                            <Grid item md={1} xs={12}>
                                <Button
                                    onClick={handleSubmit}
                                    color="primary"
                                    variant="contained"
                                    disabled={disabled}
                                >
                                    Validate
                                </Button>
                            </Grid>
                        </React.Fragment>
                    )}
                </Formik>
            </Grid>

            {
                confirmed && 
                    ((response.cxn_fees && response.partner_fees && response.fees_currency) ?
                    (
                        <Grid container md={12} xs={12} className={classes.center}>
                            <Grid item md={12} xs={12}>
                                <CheckCircleOutlineIcon className={classes.doneIcon} />
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <Typography variant="h6">{"CXN Fees: " + numberWithCommos(response.cxn_fees) + " " + response.fees_currency}</Typography>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <Typography variant="h6">{"Fees: " + numberWithCommos(response.partner_fees) + " " + response.fees_currency}</Typography>
                            </Grid>
                        </Grid>


                    )
                : 
                (
                    <Grid container md={12} xs={12} className={classes.center}>
                        <Grid item md={12} xs={12}>
                            <CheckCircleOutlineIcon className={classes.doneIcon} />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Typography variant="h6">{"The Transaction was successfully made"}</Typography>
                        </Grid>
                    </Grid>
                ))
            }

            {
                response &&
                    validated ? 
                    (
                        <Grid container direction="column" md={12} xs={12} className={classes.center}>
                            <Grid item md={12} xs={12}>
                                <Typography color="secondary" variant="h5">{validationMessage}</Typography>
                            </Grid>

                            <Box mt={5} />

                            {
                                response.person_name &&
                                <Grid item md={12} xs={12}>
                                    <Typography variant="h6">{"Customer Name: " + response.person_name}</Typography>
                                </Grid>
                            }

                            <Box mt={2} />

                            {
                                response.amounts && response.amounts.map((amount) => {
                                    if(amount.type == "to_be_received")
                                        return(
                                            <React.Fragment>
                                                {
                                                    amount.amount && amount.currency &&
                                                        <React.Fragment>
                                                            <Grid item md={12} xs={12}>
                                                                <Typography variant="h6">{"Amount to be received from customer: " + numberWithCommos(amount.amount) + " " + amount.currency}</Typography>
                                                            </Grid>

                                                            <Box mt={2} />   
                                                        <Box mt={2} />
                                                            <Box mt={2} />   
                                                        <Box mt={2} />
                                                            <Box mt={2} />   
                                                        <Box mt={2} />
                                                            <Box mt={2} />   
                                                        </React.Fragment>
                                                }
                                            </React.Fragment>
                                        )
                    
                                    if(amount.type == "to_be_given")
                                        return( 
                                            <React.Fragment>
                                                {
                                                    amount.amount && amount.currency &&
                                                        <React.Fragment>
                                                            <Grid item md={12} xs={12}>
                                                                <Typography variant="h6">{"Amount to be paid to customer: " + numberWithCommos(amount.amount) + " " + amount.currency}</Typography>
                                                            </Grid>

                                                            <Box mt={2} />
                                                        </React.Fragment>
                                                }
                                            </React.Fragment>
                                        )
                                })
                            }

                            <Box mt={5} />

                            <Grid item md={12} xs={12}>
                                <Button
                                    onClick={() => handleConfirmTransaction(transactionDetails.transaction_id)}
                                    color="primary"
                                    variant="contained"
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    )

                    :
                    
                    (
                        <Grid container md={12} xs={12} className={classes.center}>
                            <Grid item md={12} xs={12}>
                                <Typography color="error" variant="h5">{validationMessage}</Typography>
                            </Grid>
                        </Grid>
                    )
            }
        </Grid>
    );
};
