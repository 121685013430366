import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Chip } from "@material-ui/core";
import AttachFile from "@material-ui/icons/AttachFile";
import { individualDocuments, isEditStepper, isRegisterStepper } from "../../utils";
import DocumentChip from "./DocumentChip";
import DropzoneArea from "./DropzoneArea";
import DatePicker from "./DatePicker";

export default function CorporateIdentificationDocument({field, arrayField, index, onChange, classes, formikProps, setEdit, handleDocumentTypeChange, otherProps}) {
    const { t } = useTranslation();

    const getDocumentLabel = (name) => {
        if(name == individualDocuments.IdFrontSide.name)
            return individualDocuments.IdFrontSide.label;
        if(name == individualDocuments.IdBackSide.name)
            return individualDocuments.IdBackSide.label;
        if(name == individualDocuments.Passport.name)
            return individualDocuments.Passport.label
    }

    const DocumentComponent = () => {
        if(isRegisterStepper()){
          let document_content = field.document_content || [];
            return Object.entries(document_content).map((document) => {
                let [name, value] = document;
                if(value){
                    return (
                        <React.Fragment>
                            <Grid container direction="row" className={classes.titleStyle}>
                                <Grid item md={11} xs={11}>
                                    <Typography variant="h6" gutterBottom className={classes.title}>
                                        {getDocumentLabel(name)}
                                    </Typography>
                                </Grid>
                            </Grid>
            
                            <Chip
                                icon={<AttachFile />}
                                variant='outlined'
                                label={getDocumentLabel(name)}
                                onDelete={() => formikProps.setFieldValue(`[${arrayField.name}][${index}].document_content[${name}]`, null)}
                            />
            
                        </React.Fragment>
                    )
                }
                return (
                    <React.Fragment>
                        <Grid container direction="row" className={classes.titleStyle}>
                            <Grid item md={11} xs={11}>
                                <Typography variant="h6" gutterBottom className={classes.title}>
                                    {getDocumentLabel(name)}
                                </Typography>
                            </Grid>
                        </Grid>
        
                        <DropzoneArea
                            acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                            handleFileChange={(file) => formikProps.setFieldValue(`[${arrayField.name}][${index}].document_content[${name}]`, file)}  
                            documentName={name}
                        />
                    </React.Fragment>
                )
            })
        }

        if(isEditStepper()){
          let document_path = field.document_path || [];
            return Object.entries(document_path).map((document) => {
                let [name, value] = document;
                if(value){
                    return (
                        <React.Fragment>
                            <Grid container direction="row" className={classes.titleStyle}>
                                <Grid item md={11} xs={11}>
                                    <Typography variant="h6" gutterBottom className={classes.title}>
                                        {getDocumentLabel(name)}
                                    </Typography>
                                </Grid>
                            </Grid>
            
                            <DocumentChip
                                label={getDocumentLabel(name)}
                                link={value}
                                status={field.document_status} 
                                documentName={field[arrayField.documentType.name]}
                                updatedDocuments={otherProps.updatedDocuments}
                            />
            
                        </React.Fragment>
                    )
                }
                return (
                    <React.Fragment>
                        <Grid container direction="row" className={classes.titleStyle}>
                            <Grid item md={11} xs={11}>
                                <Typography variant="h6" gutterBottom className={classes.title}>
                                    {getDocumentLabel(name)}
                                </Typography>
                            </Grid>
                        </Grid>
        
                        <DropzoneArea
                            acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                            handleFileChange={(file) => formikProps.setFieldValue(`[${arrayField.name}][${index}].document_content[${name}]`, file)}  
                            documentName={name}
                        />
                    </React.Fragment>
                )
            })
        }
    }

    return (
        <React.Fragment>
            <Grid item md={5} xs={12} className={classes.input}>
                <FormControl required component="fieldset">
                    <FormLabel component="legend">{arrayField.documentType.label}</FormLabel>
                    <Box mt={1} />
                    <RadioGroup 
                        row 
                        value={field[arrayField.documentType.name]}
                        name={`[${arrayField.name}][${index}][${arrayField.documentType.name}]`}
                        //onChange={props.setOrganizationAttribute(formikProps.setFieldValue)}
                        onChange={handleDocumentTypeChange}
                        onBlur={formikProps.handleBlur(`[${arrayField.name}][${index}][${arrayField.documentType.name}]`)} 
                        error={
                            (formikProps.touched[arrayField.name] && formikProps.touched[arrayField.name][index] && formikProps.touched[arrayField.name][index][arrayField.documentType.name] && formikProps.errors[arrayField.name] && formikProps.errors[arrayField.name][index] && Boolean(formikProps.errors[arrayField.name][index][arrayField.documentType.name])) 
                            //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                        }
                        helperText={
                            (formikProps.touched[arrayField.name] && formikProps.touched[arrayField.name][index] && formikProps.touched[arrayField.name][index][arrayField.documentType.name]  && formikProps.errors[arrayField.name] && formikProps.errors[arrayField.name][index] && formikProps.errors[arrayField.name][index][arrayField.documentType.name]) 
                            //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                        }
                    >
                        <FormControlLabel
                            value={individualDocuments.NationalID.name} 
                            control={<Radio required checked={field[arrayField.documentType.name] == individualDocuments.NationalID.name}/>}
                            label={individualDocuments.NationalID.label}
                        /> 
                        <FormControlLabel
                            value={individualDocuments.Passport.name} 
                            control={<Radio required checked={field[arrayField.documentType.name] == individualDocuments.Passport.name}/>}
                            label={individualDocuments.Passport.label}
                        /> 
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item md={2} xs={12} />

            <Grid item md={5} xs={12} className={classes.input}>
                <TextField
                    variant="outlined"
                    fullWidth
                    name={`[${arrayField.name}][${index}][${arrayField.documentNumber.name}]`}
                    label={arrayField.documentNumber.label}
                    value={field[arrayField.documentNumber.name]}
                    onChange={onChange} 
                    onBlur={formikProps.handleBlur(`[${arrayField.name}][${index}][${arrayField.documentNumber.name}]`)} 
                    error={
                        (formikProps.touched[arrayField.name] && formikProps.touched[arrayField.name][index] && formikProps.touched[arrayField.name][index][arrayField.documentNumber.name] && formikProps.errors[arrayField.name] && formikProps.errors[arrayField.name][index] && Boolean(formikProps.errors[arrayField.name][index][arrayField.documentNumber.name])) 
                        //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                    }
                    helperText={
                        (formikProps.touched[arrayField.name] && formikProps.touched[arrayField.name][index] && formikProps.touched[arrayField.name][index][arrayField.documentNumber.name]  && formikProps.errors[arrayField.name] && formikProps.errors[arrayField.name][index] && formikProps.errors[arrayField.name][index][arrayField.documentNumber.name]) 
                        //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                    }
                />
            </Grid>

            <Grid item md={5} xs={12} className={classes.input}>
                <DocumentComponent />
            </Grid>
            

            {
                field[arrayField.documentType.name] == individualDocuments.Passport.name ? 

                <React.Fragment>
                    <Grid item md={2} xs={12} />

                    <Grid container direction="row" md={5} xs={12}>
                        <Grid item md={12} xs={12}>
                            <Typography variant="h6">{t("kyc-documents.date-label")}</Typography>
                        </Grid>
                        <DatePicker
                            value={field[arrayField.documentDate.name]}
                            handleDateChange={value => { formikProps.setFieldValue(`[${arrayField.name}][${index}][${arrayField.documentDate.name}]`, value); setEdit(true) }}
                            InputProps={{ 
                                readOnly: true,
                                name: `[${arrayField.name}][${index}][${arrayField.doBirth.name}]`,
                                error:
                                    (formikProps.touched[arrayField.name] && formikProps.touched[arrayField.name][index] && formikProps.touched[arrayField.name][index][arrayField.documentDate.name] && formikProps.errors[arrayField.name] && formikProps.errors[arrayField.name][index] && Boolean(formikProps.errors[arrayField.name][index][arrayField.documentDate.name])), 
                                    //|| (isEditStepper() && formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && hasFieldError(props.organizationDetails, beneficialParties.name))
                                
                                helperText:
                                    (formikProps.touched[arrayField.name] && formikProps.touched[arrayField.name][index] && formikProps.touched[arrayField.name][index][arrayField.documentDate.name]  && formikProps.errors[arrayField.name] && formikProps.errors[arrayField.name][index] && formikProps.errors[arrayField.name][index][arrayField.documentDate.name]), 
                                    //|| (formikProps.values[beneficialParties.name] == props.organizationDetails[beneficialParties.name] && getFieldError(props.organizationDetails, beneficialParties.name))
                            }}
                            disablePast={true}
                            minDate={new Date()}
                        />
                    </Grid>
                </React.Fragment>

                :

                <React.Fragment>
                    <Grid item md={2} xs={12} />

                    <Grid item md={5} xs={12} />
                </React.Fragment>
            }
        </React.Fragment>
    )

}
