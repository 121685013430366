import * as Yup from 'yup';
import { fields } from "./stepperModel";
import { eighteen } from '../../../../utils';

const {
    formField: {
        organizationName,
        registrationCountries,
        registrationDate,
        occupations,
        employees,
        interactionCountries,
        activity,
        volume,
        sales,
        information,
        beneficialOwnership,
        beneficialParties,
        owners,
        representatives,
        officeCountry,
        officeAddress,
        phone,
        email,
        website
    }
} = fields;

export default [
    //Identification Step
    Yup.object({
        [organizationName.name]: Yup
            .string()
            .nullable()
            .required(`${organizationName.requiredErrorMsg}`),

        [registrationCountries.name]: Yup
            .number()
            .nullable()
            .required(`${registrationCountries.requiredErrorMsg}`),

        [registrationDate.name]: Yup
            .date()
            .nullable()
            .required(`${registrationDate.requiredErrorMsg}`),
    }),

    //Activity Step
    Yup.object({
        [occupations.name]: Yup
            .array()
            .min(1, `${occupations.requiredErrorMsg}`),

        [employees.name]: Yup
            .string()
            .nullable()
            .required(`${employees.requiredErrorMsg}`),

        [interactionCountries.name]: Yup
            .array()
            .min(1, `${interactionCountries.requiredErrorMsg}`),

        [activity.name]: Yup
            .string()
            .nullable()
            .required(`${activity.requiredErrorMsg}`),
    }),

    //Financial Situation Step
    Yup.object({
        [volume.name]: Yup
            .string()
            .nullable()
            .required(`${volume.requiredErrorMsg}`),

        [sales.name]: Yup
            .string()
            .nullable()
            .required(`${sales.requiredErrorMsg}`),

        [information.name]: Yup
            .string()
            .nullable()
    }),

    //Ownership Structure Step
    Yup.object({
        [beneficialOwnership.name]: Yup
            .number()
            .nullable()
            .required(`${beneficialOwnership.requiredErrorMsg}`),

        [beneficialParties.name]: Yup
            .string()
            .nullable(),

        [owners.name]: Yup
            .array()
            .of(
                Yup.object({
                    [owners.type.name]: Yup
                        .string()
                        .required(`${owners.type.requiredErrorMsg}`),

                    [owners.percentage.name]: Yup
                        .number()
                        .nullable()
                        .required(`${owners.percentage.requiredErrorMsg}`),

                    [owners.firstName.name]: Yup
                        .string()
                        .when(owners.type.name, {
                            is: owners.type.options.individual.name,
                            then: Yup
                                .string()
                                .nullable()
                                .matches('^[a-zA-Z ]+$', `${owners.firstName.matchesErrorMsg}`)
                                .required(`${owners.firstName.requiredErrorMsg}`)
                        }),

                    [owners.fatherName.name]: Yup
                        .string()
                        .when(owners.type.name, {
                            is: owners.type.options.individual.name,
                            then: Yup
                                .string()
                                .nullable()
                                .matches('^[a-zA-Z ]+$', `${owners.fatherName.matchesErrorMsg}`)
                                .required(`${owners.fatherName.requiredErrorMsg}`)
                        }),

                    [owners.lastName.name]: Yup
                        .string()
                        .when(owners.type.name, {
                            is: owners.type.options.individual.name,
                            then: Yup
                                .string()
                                .nullable()
                                .matches('^[a-zA-Z ]+$', `${owners.lastName.matchesErrorMsg}`)
                                .required(`${owners.lastName.requiredErrorMsg}`)
                        }),  

                    [owners.doBirth.name]: Yup
                        .date()
                        .when(owners.type.name, {
                            is: owners.type.options.individual.name,
                            then: Yup
                                .date()
                                .nullable()
                                .required(`${owners.doBirth.requiredErrorMsg}`)
                        }),
                    
                    [owners.nationality.name]: Yup
                        .array()
                        .when(owners.type.name, {
                            is: owners.type.options.individual.name,
                            then: Yup.array().min(1, `${owners.nationality.requiredErrorMsg}`)
                        }),
                
                    [owners.residence.name]: Yup
                        .array()
                        .when(owners.type.name, {
                            is: owners.type.options.individual.name,
                            then: Yup.array().min(1, `${owners.residence.requiredErrorMsg}`)
                        }),

                    [owners.entityName.name]: Yup
                        .string()
                        .when(owners.type.name, {
                            is: owners.type.options.entity.name,
                            then: Yup
                                .string()
                                .nullable()
                                .matches('^[a-zA-Z ]+$', `${owners.entityName.matchesErrorMsg}`)
                                .required(`${owners.entityName.requiredErrorMsg}`)
                        }), 
                    
                    [owners.legalForm.name]: Yup
                        .number()
                        .when(owners.type.name, {
                            is: owners.type.options.entity.name,
                            then: Yup
                                .number()
                                .nullable()
                                .required(`${owners.legalForm.requiredErrorMsg}`)
                        }),

                    [owners.shareholders.name]: Yup
                        .array()
                        .when(owners.type.name, {
                            is: owners.type.options.entity.name,
                            then: Yup
                                .array()
                                .of(
                                    Yup.object({
                                        [owners.type.name]: Yup
                                            .string()
                                            .required(`${owners.type.requiredErrorMsg}`),
                                        [owners.firstName.name]: Yup
                                            .string()
                                            .when(owners.type.name, {
                                                is: owners.type.options.individual.name,
                                                then: Yup
                                                    .string()
                                                    .nullable()
                                                    .matches('^[a-zA-Z ]+$', `${owners.firstName.matchesErrorMsg}`)
                                                    .required(`${owners.firstName.requiredErrorMsg}`)
                                            }), 
                                        [owners.lastName.name]: Yup
                                            .string()
                                            .when(owners.type.name, {
                                                is: owners.type.options.individual.name,
                                                then: Yup
                                                    .string()
                                                    .nullable()
                                                    .matches('^[a-zA-Z ]+$', `${owners.lastName.matchesErrorMsg}`)
                                                    .required(`${owners.lastName.requiredErrorMsg}`)
                                            }), 
                                        [owners.entityName.name]: Yup
                                            .string()
                                            .when(owners.type.name, {
                                                is: owners.type.options.entity.name,
                                                then: Yup
                                                    .string()
                                                    .nullable()
                                                    .matches('^[a-zA-Z ]+$', `${owners.entityName.matchesErrorMsg}`)
                                                    .required(`${owners.entityName.requiredErrorMsg}`)
                                            }), 
                                    })
                                )
                        }),
                    
                    [owners.representatives.name]: Yup
                        .array()
                        .when(owners.type.name, {
                            is: owners.type.options.entity.name,
                            then: Yup
                            .array()
                            .of(
                                Yup.object({
                                    [owners.firstName.name]: Yup
                                        .string()
                                        .nullable()
                                        .matches('^[a-zA-Z ]+$', `${owners.firstName.matchesErrorMsg}`)
                                        .required(`${owners.firstName.requiredErrorMsg}`),
                                    [owners.lastName.name]: Yup
                                        .string()
                                        .nullable()
                                        .matches('^[a-zA-Z ]+$', `${owners.lastName.matchesErrorMsg}`)
                                        .required(`${owners.lastName.requiredErrorMsg}`)
                                })
                            )
                        }),

                    [owners.documentType.name]: Yup
                        .string()
                        .when(owners.type.name, {
                            is: owners.type.options.individual.name,
                            then: Yup
                                .string()
                                .nullable()
                                .required(`${owners.documentType.requiredErrorMsg}`),
                        }),
                    
                    [owners.documentNumber.name]: Yup
                        .string()
                        .when(owners.type.name, {
                            is: owners.type.options.individual.name,
                            then: Yup
                                .string()
                                .nullable()
                                .required(`${owners.documentNumber.requiredErrorMsg}`)
                                .test('passport-test', `${owners.documentNumber.passportMatchesErrorMsg}`,
                                    function(value){
                                        if(this.parent.document_type == "passport"){
                                            const passportRegex = /^(?=.*?[A-Za-z])(?=.*?[0-9]).*$/;
                                            let isValidPassport = passportRegex.test(value);

                                            return isValidPassport
                                        }
                                        return true;
                                    }
                                )
                                .test('id-test', `${owners.documentNumber.idMatchesErrorMsg}`,
                                    function(value){
                                        if(this.parent.document_type == "national_id"){
                                            const idRegex = /^[0-9]+$/;
                                            let isValidId = idRegex.test(value);

                                            return isValidId
                                        }
                                        return true;
                                    }
                                ),
                        }),
                    
                    [owners.documentDate.name]: Yup
                        .date()
                        .when(owners.type.name, {
                            is: owners.type.options.individual.name,
                            then: Yup
                                .date()
                                .nullable()
                                .when('document_type', {
                                    is: "passport",
                                    then: Yup.date().nullable().required(`${owners.documentDate.requiredErrorMsg}`)
                                }),
                        })
                })
            )
    }),

    //Representative Step
    Yup.object({
        [representatives.name]: Yup
            .array()
            .of(
                Yup.object({
                    [representatives.contact.name]: Yup
                        .boolean(),
                    
                    [representatives.isRepresentative.name]: Yup
                        .boolean(),

                    [representatives.firstName.name]: Yup
                        .string()
                        .nullable()
                        .matches('^[a-zA-Z ]+$', `${representatives.firstName.matchesErrorMsg}`)
                        .required(`${representatives.firstName.requiredErrorMsg}`),

                    [representatives.fatherName.name]: Yup
                        .string()
                        .nullable()
                        .matches('^[a-zA-Z ]+$', `${representatives.fatherName.matchesErrorMsg}`)
                        .required(`${representatives.fatherName.requiredErrorMsg}`),

                    [representatives.lastName.name]: Yup
                        .string()
                        .nullable()
                        .matches('^[a-zA-Z ]+$', `${representatives.lastName.matchesErrorMsg}`)
                        .required(`${representatives.lastName.requiredErrorMsg}`), 

                    [representatives.doBirth.name]: Yup
                        .date()
                        .nullable()
                        .max(eighteen(), `${representatives.doBirth.ageErrorMsg}`)
                        .required(`${representatives.doBirth.requiredErrorMsg}`),
                    
                    [representatives.nationality.name]: Yup
                        .array()
                        .min(1, `${representatives.nationality.requiredErrorMsg}`),
                
                    [representatives.residence.name]: Yup
                        .array()
                        .min(1, `${representatives.residence.requiredErrorMsg}`),
                    
                    [representatives.documentType.name]: Yup
                        .string()
                        .nullable()
                        .required(`${representatives.documentType.requiredErrorMsg}`),

                    [representatives.documentNumber.name]: Yup
                        .string()
                        .nullable()
                        .required(`${representatives.documentNumber.requiredErrorMsg}`)
                        .test('passport-test', `${representatives.documentNumber.passportMatchesErrorMsg}`,
                            function(value){
                                if(this.parent.document_type == "passport"){
                                    const passportRegex = /^(?=.*?[A-Za-z])(?=.*?[0-9]).*$/;
                                    let isValidPassport = passportRegex.test(value);

                                    return isValidPassport
                                }
                                return true;
                            }
                        )
                        .test('id-test', `${representatives.documentNumber.idMatchesErrorMsg}`,
                            function(value){
                                if(this.parent.document_type == "national_id"){
                                    const idRegex = /^[0-9]+$/;
                                    let isValidId = idRegex.test(value);

                                    return isValidId
                                }
                                return true;
                            }
                        ),
                    
                    [representatives.documentDate.name]: Yup
                        .date()
                        .nullable()
                        .when('document_type', {
                            is: "passport",
                            then: Yup.date().nullable().required(`${representatives.documentDate.requiredErrorMsg}`)
                        }),
                })
            )
    }),

    //Contact Details Step
    Yup.object({
        [officeCountry.name]: Yup
            .number()
            .nullable()
            .required(`${officeCountry.requiredErrorMsg}`),

        [officeAddress.name]: Yup
            .string()
            .nullable()
            .required(`${officeAddress.requiredErrorMsg}`),
        
        [phone.name]: Yup
            .string()
            .min(8, `${phone.lengthErrorMsg}`)
            .max(8, `${phone.lengthErrorMsg}`)
            .matches('^(([7][01689]|[8][1]|[0][13456789])[0-9]{6}$)', `${phone.matchesErrorMsg}`)
            .nullable()
            .required(`${phone.requiredErrorMsg}`), 

        [email.name]: Yup
            .string()
            .nullable()
            .email()
            .required(`${email.requiredErrorMsg}`), 
        
        [website.name]: Yup
            .string()
            .nullable()
            .url()
    }),

];
