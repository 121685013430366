import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { httpGet } from "../../httpcommon";
import { isArabic, numberWithCommos, toArabicDigits } from "../../utils";
import { LoadingDisplay } from "../common-ui-fit/LoadingDisplay";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, } from "@material-ui/core/styles";
import Filter from "./Filter";
import moment from "moment";
import { useSelector } from "react-redux";
import { getUnreadNotifications } from "../../redux-reducer/centrifuge/centrifugeSlice";
import { useTranslation } from 'react-i18next';
import { apis } from "../../apis/apis";
import { id } from "date-fns/locale";


const P2pOrdersTable = ({platformRate}) => {
  const classes = useStyles();
  const theme = useTheme();
  const unreadNotificationsFromRealtime = useSelector(getUnreadNotifications);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [countAllOrders, setCountAllOrders] = useState(0);
  const [countAllMyOrders, setCountAllMyOrders] = useState(0);

  useEffect(async () => {
    let myOrderResponse = await httpGet("/my-buy-and-sell-orders/count-all");
    setCountAllMyOrders(myOrderResponse.data.total);
  }, []);

  const getRate = (base_currency, order_type) => {
    if(platformRate){
      if(order_type == "buy"){
        if(base_currency == platformRate.base_currency){
          return platformRate.buy_rate;
        }
        return platformRate.sell_rate;
      }
      if(base_currency == platformRate.base_currency){
        return platformRate.sell_rate;
      }
      return platformRate.buy_rate;
    }
  }

  const currencyNumberColumnOptions = {
    customBodyRender: (value, tableMeta, updateValue) => {
      return numberWithCommos(value);
    },
  };

  // Handle Filter Actions
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [countAllFiltered, setCountAllFiltered] = useState(0);
  const [reloadFilterCount, setReloadFilterCount] = useState(0);

  const dateFormate = (date) => {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
  };

  const openFilter = (event) => {
    setIsFilterDialogOpen(event.currentTarget);
  };

  const closeFilter = (event) => {
    setIsFilterDialogOpen(false);
  };

  const updateMinDate = (date) => {
    setMinDate(dateFormate(date));
  };

  const updateMaxDate = (date) => {
    setMaxDate(dateFormate(date));
  };

  const applyFilterOnTable = async (mindate, maxdate) => {
    var res = await httpGet(
      "/my-buy-and-sell-orders?page=" +
        0 +
        "&min_date=" +
        mindate +
        "&max_date=" +
        maxdate
    );

    setSettings({ ...settings, data: res.data });
    if (!mindate && !maxdate) {
      setIsFiltered(false);
      setReloadFilterCount(reloadFilterCount + 1);
    } else {
      setIsFiltered(true);
      setReloadFilterCount(reloadFilterCount + 1);
    }
  };

  useEffect(async () => {
    if (isFiltered) {
      var res = await httpGet(
        "/my-buy-and-sell-orders/filtered-count?" +
          "min_date=" +
          minDate +
          "&max_date=" +
          maxDate
      );
      setCountAllFiltered(res.data.total);
    }
  }, [reloadFilterCount]);

  const withFilterQuery = () =>
    isFiltered ? `&min_date=${minDate}&max_date=${maxDate}` : "";

  const initialColumn = [
    {
      name: "date",
      label: t("homepage.orders-table.date-label"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return isArabic() ? toArabicDigits(value) : value
        }
      }
    },
    {
      name: "order_type",
      label: t("homepage.orders-table.type-label"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == "sell") return <Button color="secondary" style={{backgroundColor: "rgba(37, 218, 197, 0.1)"}}>{t("sell-label")}</Button>;
          return <Button color="secondary" style={{backgroundColor: "rgba(37, 218, 197, 0.1)"}}>{t("buy-label")}</Button>;
        },
      },
    },
    {
      name: "amount",
      label: t("homepage.orders-table.amount-label"),
      options: currencyNumberColumnOptions,
    },
    {
      name: "currency",
      label: t("homepage.orders-table.currency-label"),
    },
    {
      name: "rate",
      label: t("homepage.orders-table.rate-label"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if(tableMeta.rowData[5])
            return t("homepage.orders-table.default-label", {rate: numberWithCommos(getRate(tableMeta.rowData[11], tableMeta.rowData[12]))})
            else return numberWithCommos(value);
        }
      }
    },
    {
      name: "use_platform_rate",
      options: {
        display: false
      }
    },
    {
      name: "approval_status",
      options: {
        display: false
      }
    },
    {
      name: "matching_status",
      options: {
        display: false
      }
    },
    {
      name: "canceled",
      options: {
        display: false
      }
    },
    {
      name: t("homepage.orders-table.status-label"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //Canceled
          if(tableMeta.rowData[8])
            return (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={1}
                className={classes.orderStatus}
              >
                <Grid item xs={12} md={"auto"}>
                  <img src="/images/order-status-completed.svg" />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <Typography>{t("order-status.canceled")}</Typography>
                </Grid>
              </Grid>
            );

          //Matching In Progress
          if(tableMeta.rowData[6] == "APPROVED" && tableMeta.rowData[7] == "UNDER MATCHING")
            return (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={1}
                className={classes.orderStatus}
              >
                <Grid item xs={12} md={"auto"}>
                  <img src="/images/rotating-arrow.svg" />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <Typography>{t("order-status.in-progress")}</Typography>
                </Grid>
              </Grid>
            );

          //Matched
          if(tableMeta.rowData[6] == "APPROVED" && tableMeta.rowData[7] == "MATCHING FINISHED")
          return (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}
              className={classes.orderStatus}
            >
              <Grid item xs={12} md={"auto"}>
                <img src="/images/order-status-ongoing.svg" />
              </Grid>
              <Grid item xs={12} md={"auto"}>
                <Typography>{t("order-status.matched")}</Typography>
              </Grid>
            </Grid>
          );

          //Pending Approval
          if(tableMeta.rowData[6] == "SOURCE OF FUND PROVIDED")
            return (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={1}
                className={classes.orderStatus}
              >
                <Grid item xs={12} md={"auto"}>
                  <img src="/images/order-status-pending.svg" />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <Typography>{t("order-status.pending-approval")}</Typography>
                </Grid>
              </Grid>
            );

            //Deposit Requested
            if(tableMeta.rowData[6] == "DEPOSIT REQUESTED")
              return (
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  spacing={1}
                  className={classes.orderStatus}
                >
                  <Grid item xs={12} md={"auto"}>
                    <img src="/images/order-status-pending.svg" />
                  </Grid>
                  <Grid item xs={12} md={"auto"}>
                    <Typography>{t("order-status.deposit-requested")}</Typography>
                  </Grid>
                </Grid>
              );


          //Disapproved
          if(tableMeta.rowData[6] == "DECLINED")
            return (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={1}
                className={classes.orderStatus}
              >
                <Grid item xs={12} md={"auto"}>
                  <img src="/images/order-status-completed.svg" />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <Typography>{t("order-status.disapproved")}</Typography>
                </Grid>
              </Grid>
            );

          //Completed
          if(tableMeta.rowData[7] == "COMPLETED")
            return (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={1}
                className={classes.orderStatus}
              >
                <Grid item xs={12} md={"auto"}>
                  <img src="/images/order-status-ongoing.svg" />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <Typography>{t("order-status.completed")}</Typography>
                </Grid>
              </Grid>
            );
        }
      }
    },
    {
      name: "masked_id",
      label: t("homepage.orders-table.details-label"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Link
              to={{
                pathname: "/order-details",
                state: { id: value },
              }}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" color="secondary">
                {t("button.show-details")}
              </Button>
            </Link>
          );
        },
      },
    },
    {
      name: "currency_base_ticker",
      options: {
        display: false
      }
    },
    {
      name: "order_type",
      options: {
        display: false
      }
    },
  ];

  const initialSettings = {
    sortOrder: {},
    data: [[t("homepage.orders-table.loading-label")]],
    columns: initialColumn,
  };

  const [settings, setSettings] = useState(initialSettings);

  useEffect(async () => {
    setLoading(true);
    await getData(0);
    setLoading(false);
  }, [unreadNotificationsFromRealtime]);

  const getData = async (page) => {
    let res = await httpGet("/my-buy-and-sell-orders?page=" + page);
    let totalpages = await httpGet("/my-buy-and-sell-orders/count-all");

    var total_pages;

    setCountAllMyOrders(totalpages.data.total);
    total_pages = totalpages.data.total;

    setSettings({
      ...settings,
      data: res.data,
      count: total_pages,
    });
  };

  const sort = async (page, sortOrder) => {
    page = page * 5;
    const filterQuery = withFilterQuery();
    let res = await httpGet(
      "/my-buy-and-sell-orders?page=" +
        page +
        "&sortfield=" +
        sortOrder.name +
        "&sortdirection=" +
        sortOrder.direction +
        filterQuery
    );
    setSettings({
      ...settings,
      data: res.data,
      page: page,
      sortOrder: sortOrder,
      isLoading: false,
    });
  };

  const changePage = async (page, sortOrder) => {
    page = page * 5;
    const filterQuery = withFilterQuery();
    if (Object.keys(sortOrder).length == 0) {
      let res = await httpGet(
        "/my-buy-and-sell-orders?page=" + page + filterQuery
      );
      setSettings({
        ...settings,
        sortOrder: sortOrder,
        data: res.data,
      });
      return;
    }
    let res = await httpGet(
      "/my-buy-and-sell-orders?page=" +
        page +
        "&sortfiled=" +
        sortOrder.name +
        "&sortdirection=" +
        sortOrder.direction +
        filterQuery
    );

    setSettings({
      ...settings,
      sortOrder: sortOrder,
      data: res.data,
    });
  };

  const tableCount = () => {
    if (isFiltered) {
      return countAllFiltered;
    }
    return countAllMyOrders;
  };

  const { data, sortOrder } = settings;
  const options = {
    responsive: "horizontal",
    serverSide: true,
    count: tableCount(),
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    selectableRowsHideCheckboxes: true,
    sortOrder: sortOrder,
    customToolbar: null,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: t("homepage.orders-table.empty-message"),
      }
    },

    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          sort(tableState.page, tableState.sortOrder);
          break;
      }
    },
  };

  if(platformRate)
    return (
      <>
        <Typography variant="h5">{t("homepage.orders-table.title")}</Typography>

        <div>
          {loading ? (
            <LoadingDisplay width="100%" heigth="20rem" />
          ) : (
            <>
                <MUIDataTable
                  data={data}
                  columns={settings.columns}
                  options={options}
                />
              
            </>
          )}
        </div>
      </>
    );
  return <LoadingDisplay />
};
const useStyles = makeStyles((theme) => ({
  typeTagSettings: {
    backgroundColor: theme.palette.secondary.main,
  },
  tabSettings: {
    flexGrow: 1,
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
  },
  orderStatus: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
}));

export default P2pOrdersTable;
