import React, { useEffect, useState } from "react";
import { KeyboardDatePicker, validate } from '@material-ui/pickers';
import { isArabic, toEnglishDigits } from "../../utils";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";

export default function DatePicker({value, handleDateChange, InputProps, maxDate, minDate, disableFuture, disablePast, disabled }) {
    const { t } = useTranslation();

    const [selectedYear, setSelectedYear] = useState(value);
    const [selectedMonth, setSelectedMonth] = useState(value);
    const [selectedDay, setSelectedDay] = useState(value);

    const handleYearChange = (date, v) => { 
        setSelectedYear(date);
        if(selectedMonth)
            setSelectedMonth(date)
        if(selectedDay)
            setSelectedDay(date)
    }

    const handleMonthChange = (date, v) => {
        setSelectedMonth(date);
        setSelectedYear(date);
    };

    const handleChange = (date, value) => {
        setSelectedYear(date);
        setSelectedMonth(date);
        setSelectedDay(date);
        const formattedDate = moment(date).format("YYYY/MM/DD");
        const formattedValue = isArabic() ? toEnglishDigits(formattedDate) : formattedDate;
        handleDateChange(formattedValue);
    }

    return (
        <React.Fragment>
            <Grid item md={4} xs={4}>
                <KeyboardDatePicker
                    fullWidth
                    views={["year"]}
                    label="Year"
                    value={selectedYear}
                    onChange={handleYearChange}
                    inputVariant="outlined"
                    InputProps={InputProps}
                    maxDate={maxDate}
                    minDate={minDate}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    required
                    disabled={disabled}
                    KeyboardButtonProps={{style: { padding: 0 } }}
                />
            </Grid>

            <Grid item md={4} xs={4}>
            <Grid item md={12} xs={12}>
                <KeyboardDatePicker
                    fullWidth
                    label="Month"
                    views={["month"]}
                    format="MMM"
                    value={selectedMonth}
                    initialFocusedDate={selectedYear}
                    onChange={handleMonthChange}
                    inputVariant="outlined"
                    InputProps={InputProps}
                    maxDate={maxDate}
                    minDate={minDate}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    required
                    disabled={disabled}
                    KeyboardButtonProps={{style: { padding: 0 } }}
                />
            </Grid>
            </Grid>

            <Grid item md={4} xs={4}>
                <KeyboardDatePicker
                    fullWidth
                    label="Day"
                    format="DD"
                    value={selectedDay}
                    initialFocusedDate={selectedMonth}
                    onChange={handleChange}
                    maxDate={maxDate}
                    minDate={minDate}
                    inputVariant="outlined"
                    InputProps={InputProps}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    required
                    disabled={disabled}
                    KeyboardButtonProps={{style: { padding: 0 } }} 
                />
            </Grid>

            {
                InputProps.error && InputProps.helperText &&
                <Grid item md={12} xs={12}>
                    <Typography variant="caption" color="error">{InputProps.helperText}</Typography>
                </Grid>
            }
        </React.Fragment>
    )
}






