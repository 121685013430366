import { createSlice } from "@reduxjs/toolkit";

export const orderTypeSlice = createSlice({
  name: "orderType",
  initialState: {
    type: "P2P Order",
  },
  reducers: {
    updateOrderType: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const { updateOrderType } = orderTypeSlice.actions;

export const getOrderType = (state) => state.orderType.type;

export default orderTypeSlice.reducer;
