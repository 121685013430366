import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Chip } from "@material-ui/core";
import DropzoneArea from "./DropzoneArea";
import { useTranslation } from 'react-i18next';
import { getFieldError, hasFieldError, individualDocuments, isEditStepper, isRegisterStepper } from "../../utils";
import DatePicker from "./DatePicker";
import DocumentChip from "./DocumentChip";
import AttachFile from "@material-ui/icons/AttachFile";

const useStyles = makeStyles((theme) => ({
  input: {
      marginTop: "1rem"
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: 600
  },
  titleStyle: {
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  tooltipStyle: {
    fontSize: '15px'
  },
}));

export default function IndividualIdentificationDocument(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const details = props.individualDetails ? props.individualDetails : (props.businessDetails ? props.businessDetails : null)
    const {
        formField: {
            documentType,
            documentDate,
            documentNumber
        }
    } = props;

    const formikProps = props.formikProps;

    const getDocumentLabel = (name) => {
        if(name == individualDocuments.IdFrontSide.name)
            return individualDocuments.IdFrontSide.label;
        if(name == individualDocuments.IdBackSide.name)
            return individualDocuments.IdBackSide.label;
        if(name == individualDocuments.Passport.name)
            return individualDocuments.Passport.label
    }

    const handleDocumentTypeChange = (e) => {
        let document_content = {
            [individualDocuments.IdFrontSide.name]: null,
            [individualDocuments.IdBackSide.name]: null
        }
        if(e.target.value == individualDocuments.Passport.name){
            document_content = {
                [individualDocuments.Passport.name]: null,
            }     
        }
        formikProps.setFieldValue(`document_content`, document_content)
        formikProps.setFieldValue(`[${documentNumber.name}]`, "")
        formikProps.setFieldValue(`[${documentDate.name}]`, null)
        formikProps.setFieldValue(`[${documentType.name}]`, e.target.value)

        props.setEdit(true);
    }

    const handleDeleteUpdatedDocument = (document_name) => {
        formikProps.setFieldValue(`document_content[${document_name}]`, null)
    }

    const DocumentComponent = () => {
        if(isRegisterStepper()){
            return Object.entries(formikProps.values.document_content).map((document) => {
                let [name, value] = document;
                if(value){
                    return (
                        <React.Fragment>
                            <Grid container direction="row" className={classes.titleStyle}>
                                <Grid item md={11} xs={11}>
                                    <Typography variant="h6" gutterBottom className={classes.title}>
                                        {getDocumentLabel(name)}
                                    </Typography>
                                </Grid>
                            </Grid>
            
                            <Chip
                                icon={<AttachFile />}
                                variant='outlined'
                                label={getDocumentLabel(name)}
                                onDelete={() => formikProps.setFieldValue(`document_content[${name}]`, null)}
                            />
            
                        </React.Fragment>
                    )
                }
                return (
                    <React.Fragment>
                        <Grid container direction="row" className={classes.titleStyle}>
                            <Grid item md={11} xs={11}>
                                <Typography variant="h6" gutterBottom className={classes.title}>
                                    {getDocumentLabel(name)}
                                </Typography>
                            </Grid>
                        </Grid>
        
                        <DropzoneArea
                            acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                            handleFileChange={(file) => formikProps.setFieldValue(`document_content[${name}]`, file)}  
                            documentName={name}
                        />
                    </React.Fragment>
                )
            })
        }

        if(isEditStepper()){
            return Object.entries(formikProps.values.document_path).map((document) => {
                let [name, value] = document;
                if(value){
                    return (
                        <React.Fragment>
                            <Grid container direction="row" className={classes.titleStyle}>
                                <Grid item md={11} xs={11}>
                                    <Typography variant="h6" gutterBottom className={classes.title}>
                                        {getDocumentLabel(name)}
                                    </Typography>
                                </Grid>
                            </Grid>
            
                            <DocumentChip
                                label={getDocumentLabel(name)}
                                link={value}
                                status={formikProps.values.document_status} 
                                documentName={name}
                                updatedDocuments={formikProps.values.document_content}
                                handleFileChange={(file) => { formikProps.setFieldValue(`document_content[${name}]`, file); props.setEdit(true); }}
                                handleDeleteFile={handleDeleteUpdatedDocument}
                                rejectionReason={formikProps.values.document_rejection_reason}
                            />
                        </React.Fragment>
                    )
                }
                return (
                    <React.Fragment>
                        <Grid container direction="row" className={classes.titleStyle}>
                            <Grid item md={11} xs={11}>
                                <Typography variant="h6" gutterBottom className={classes.title}>
                                    {getDocumentLabel(name)}
                                </Typography>
                            </Grid>
                        </Grid>
        
                        <DropzoneArea
                            acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                            handleFileChange={(file) => formikProps.setFieldValue(`document_content[${name}]`, file)}  
                            documentName={name}
                        />
                    </React.Fragment>
                )
            })
        }
    }

    return (
        <React.Fragment>
            <Typography variant="h4" style={{ lineHeight: 1.33, textAlign: "center" }}>
                {t("stepper.kyc.step3")}
            </Typography>            

            <Box mt={3} /> 

            <Grid container direction="row" md={12} xs={12} style={{alignItems: "center"}}>
                <Grid item md={5} xs={12} className={classes.input}>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">{documentType.label}</FormLabel>
                        <Box mt={1} />
                        <RadioGroup 
                            row 
                            value={formikProps.values[documentType.name]}
                            name={documentType.name}
                            onChange={handleDocumentTypeChange}
                            onBlur={formikProps.handleBlur(documentType.name)} 
                            error={formikProps.touched[documentType.name] && Boolean(formikProps.errors[documentType.name])}
                            helperText={formikProps.touched[documentType.name] && formikProps.errors[documentType.name]}
                        >
                            <FormControlLabel
                                value={individualDocuments.NationalID.name}
                                control={<Radio required checked={formikProps.values[documentType.name] == individualDocuments.NationalID.name}/>}
                                label={individualDocuments.NationalID.label}
                            /> 
                            <FormControlLabel
                                value={individualDocuments.Passport.name}
                                control={<Radio required checked={formikProps.values[documentType.name] == individualDocuments.Passport.name}/>}
                                label={individualDocuments.Passport.label}
                            /> 
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12} />

                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        name={documentNumber.name}
                        label={documentNumber.label}
                        value={formikProps.values[documentNumber.name]}
                        onChange={(e) => formikProps.setFieldValue(documentNumber.name, e.target.value)}
                        onBlur={formikProps.handleBlur(documentNumber.name)} 
                        error={(formikProps.touched[documentNumber.name] && Boolean(formikProps.errors[documentNumber.name])) || (isEditStepper() && formikProps.values[documentNumber.name] == details[documentNumber.name] && hasFieldError(details, documentNumber.name))}
                        helperText={(formikProps.touched[documentNumber.name] && formikProps.errors[documentNumber.name]) || (formikProps.values[documentNumber.name] == details[documentNumber.name] && getFieldError(details, documentNumber.name))}
                        fullWidth                     
                    />
                </Grid>

                <Grid item md={5} xs={12} className={classes.input}>
                    <DocumentComponent />
                </Grid>
                
                {
                    formikProps.values[documentType.name] == individualDocuments.Passport.name ? 

                    <React.Fragment>
                        <Grid item md={2} xs={12} />

                        <Grid container direction="row" md={5} xs={12}>
                            <Grid item md={12} xs={12}>
                                <Typography variant="h6">{documentDate.label}</Typography>
                            </Grid>
                            <DatePicker 
                                value={formikProps.values[documentDate.name]}
                                handleDateChange={value => { formikProps.setFieldValue(documentDate.name, value); props.setEdit(true);}}
                                disablePast={true}
                                InputProps={{ 
                                    readOnly: true,
                                    name: documentDate.name,
                                    error: (formikProps.touched[documentDate.name] && Boolean(formikProps.errors[documentDate.name])) || (isEditStepper() && formikProps.values[documentDate.name] == details[documentDate.name] && hasFieldError(details, documentDate.name)),  
                                    helperText: (formikProps.touched[documentDate.name] && formikProps.errors[documentDate.name]) || (formikProps.values[documentDate.name] == details[documentDate.name] && getFieldError(details, documentDate.name))
                                }}
                                minDate={new Date()}
                            />
                        </Grid>
                    </React.Fragment>

                    :

                    <React.Fragment>
                        <Grid item md={2} xs={12} />
                        <Grid item md={5} xs={12} />
                    </React.Fragment>
                } 
            </Grid>

        </React.Fragment>
    );
}