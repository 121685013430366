import { Box, Grid, makeStyles, Typography, } from "@material-ui/core";

import React from "react";

import Avatar from '@material-ui/core/Avatar';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MoneyIcon from '@material-ui/icons/Money';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import { isArabic, numberWithCommos, toArabicDigits } from "../../utils";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    title: {
        fontWeight: 500
    },
    contentContainer: {
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
            justifyContent: 'center',
            textAlign: '-webkit-center',
            textAlignLast: 'center'
        },
        position: 'relative'
    },
    cardContent: {
        "& .MuiCardContent-root:last-child" : {
            paddingBottom: '16px'
        },
        marginBottom: '.5rem',
        position: "relative",
        backgroundSize: 'cover',
        backgroundColor: 'rgba(37, 218, 197, 0.05)',
    },

}))

export default function Cards({rate, minSellRate, maxBuyRate, quoteCurrency}) {
    const classes = useStyles()
    const { t } = useTranslation()

    const card = (avatar, title, subtitle) => {
        return (
            <>
                <Card className={classes.cardContent}>
                    <CardContent >
                        <Grid container direction="row" spacing={1} className={classes.contentContainer} >
                            <Grid item xs={12} md={3}>
                                <Avatar className={classes.avatar}>
                                    {avatar}
                                </Avatar>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container direction="column" style={{textAlign: 'start'}} spacing={1}>
                                    <Grid item>
                                        <Typography variant="title" color="primary" className={classes.title}>
                                            {title}
                                        </Typography>
                                    </Grid>
                                
                                    <Grid item>
                                        <Typography variant="subtitle1" color="secondary" className={classes.title} >
                                            {subtitle}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>        
            </>
        )
    }
    
    const maxRateAvatar = () => {
        return (
            <TrendingUpIcon />
        )
    }
    
    const minRateAvatar = () => {
        return (
            <TrendingDownIcon />
        )
    }
    
    const rateAvatar = () => {
        return (
            <MoneyIcon />
        )
    }

    return (
        <>

        <Grid container direction="colum" md={12} xs={12}>
            <Grid item md={12} xs={12}>
                <Box mt={1} />
            </Grid>
            <Grid item md={12} xs={12}>
                {card(rateAvatar(), t("homepage.cards.rate"),  numberWithCommos(rate) + " " + quoteCurrency)}
            </Grid>
            <Grid item md={12} xs={12}>
                {card(maxRateAvatar(), t("homepage.cards.max-rate"), numberWithCommos(maxBuyRate) + " " + quoteCurrency)}
            </Grid>
            <Grid item md={12} xs={12}>
                {card(minRateAvatar(), t("homepage.cards.min-rate"), numberWithCommos(minSellRate) + " " + quoteCurrency)}
            </Grid>
        </Grid>
        
        <Box mt={5} />
        
        </>
    )
}