import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { numberWithCommos, parseNumber } from "../../utils";
import "./matchingBar.css";

export default function MatchingTimeline({orderDetails}) {
    const { t } = useTranslation();

    const isBaseOrder = () => {
        if(orderDetails.base_currency != orderDetails.currency_1)
            return false;
        return true;
    }

    let amounts = [
        {
            name: t("order-details-page.secured-amount-label"),
            value: (orderDetails.amount_fulfilled / orderDetails.amount) * 100,
            color: "green",
            amount: numberWithCommos(parseNumber(orderDetails.amount_fulfilled))
        },
        {
            name: t("order-details-page.matched-amount-label"),
            value: ((orderDetails.amount_matched - orderDetails.amount_fulfilled) / orderDetails.amount) * 100,
            color: "orange",
            amount: numberWithCommos(parseNumber(orderDetails.amount_matched - orderDetails.amount_fulfilled))
        },
        {
            name: t("order-details-page.pending-amount-label"),
            value: ((orderDetails.amount - orderDetails.amount_matched) / orderDetails.amount) * 100,
            color: "rgb(158, 190, 190)",
            amount: numberWithCommos(parseNumber(orderDetails.amount -  orderDetails.amount_matched))
        },
    ];

    let values = amounts.map(function(item, i) {
        if(item.value > 0) {
            return (
                <Box className="value" style={{'color': item.color, 'width': item.value + '%'}}  key={i}>
                    <span>{item.amount}</span>
                </Box>
            )
        }
    }, this);

    let calibrations = amounts.map(function(item, i) {
        if(item.value > 0) {
            return (
                <Box className="graduation" style={{'color': item.color, 'width': item.value + '%'}}  key={i}>
                    <span>|</span>
                </Box>
            )
        }
    }, this);

    let bars = amounts.map(function(item, i) {
        if(item.value > 0) {
            return (
                <Box className="bar" style={{'backgroundColor': item.color, 'width': item.value + '%'}}  key={i}>

                </Box>
            )
        }
    }, this);

    let legends = amounts.map(function(item, i) {

        return (
            <Box className="legend" key={i}>
                <span className="dot" style={{'color': item.color}}>●</span>
                <span className="label">{item.name}</span>
            </Box>
        )

    }, this);

    return (
        <Box className="multicolor-bar">
            <Box className="values">
                {values == ''?'':values}
            </Box>
            <Box className="scale">
                {calibrations == ''?'':calibrations}
            </Box>
            <Box className="bars">
                {bars == ''?'':bars}
            </Box>
            <Box className="legends">
                {legends == ''?'':legends}
            </Box>
        </Box>
    )
}