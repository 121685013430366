import { Avatar, Divider, Grid, MenuItem, Popover, SwipeableDrawer, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from 'react-i18next';
import LanguageSelector from "../common-components/languageSelector";

const StyledDrawer = withStyles((theme) => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.primary.main
        }
    }
}))(SwipeableDrawer);

export default function MobileMenuDrawer ({
    drawerAnchor,
    toggleDrawer,
    classes,
    avatarContent,
    handleLogOut,
    profileDetails
}) {
  
    const { t } = useTranslation();

    return (
        <StyledDrawer
            anchor="left"
            open={drawerAnchor}
            onClose={toggleDrawer()}
            onOpen={toggleDrawer()}
        >
            <MenuItem component="button" href="/profile">
                <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item>
                    <Avatar className={classes.avatarCustomized}>{avatarContent}</Avatar>
                </Grid>
                <Grid item>
                    <Typography variant="body2" className={classes.mobileMenuItem}>{profileDetails.user_email}</Typography>
                </Grid>
                </Grid>
            </MenuItem>

            <Divider />

            <MenuItem className={classes.mobileMenuItem}>
                {t("navbar.preferences-label")}
            </MenuItem>

            <MenuItem className={classes.mobileMenuItem}>
                {t("navbar.new-label")}
            </MenuItem>

            <MenuItem className={classes.mobileMenuItem}>
                {t("navbar.feedback-label")}
            </MenuItem>

            <MenuItem className={classes.mobileMenuItem}>
                {t("navbar.support-label")}
            </MenuItem>

            <MenuItem className={classes.mobileMenuItem}>
                <LanguageSelector />
            </MenuItem>

            <MenuItem onClick={handleLogOut} color="secondary" className={classes.mobileMenuItem}>
                {t("navbar.logout")}
            </MenuItem>
        </StyledDrawer>
    );
};

