import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import commonStyles from "../../../commonStyles";
import { useSelector } from "react-redux";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";
import { getBusinessDetails } from "../../../redux-reducer/business/businessSlice";
import BusinessSummary from "./summary";
import BusinessDetails from "./details";
import EditProfileButton from "../../common-components/EditProfileButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f6f6f6",
    padding: "5rem",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  ...commonStyles,
}));

export default function BusinessProfile() {
  const classes = useStyles();
  const businessDetails = useSelector(getBusinessDetails);

  if(businessDetails)
    return (
        <Grid container direction="row" md={12} xs={12} className={classes.root}>
          <Grid item md={4} xs={12}>
            <BusinessSummary businessDetails={businessDetails} />
          </Grid>

          <Grid item md={1} xs={12} />

          <Grid item md={7} xs={12}>
            <BusinessDetails businessDetails={businessDetails} />
          </Grid>

          <EditProfileButton ongoingOrders={businessDetails.ongoing_orders}/>

        </Grid>   
    )
  return <LoadingDisplay />
}
