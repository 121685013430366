import React from "react";
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { green } from "@material-ui/core/colors";

export const MyTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& input + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
});

export const useStylesCommon = makeStyles((theme) => ({
  detailsSettings: {
    backgroundColor: "#f6f6f6",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },

    paddingTop: "1rem",
  },
  itemSettings: {
    borderRadius: "7px",
    boxShadow: "0 0 16px 0 rgba(92, 92, 92, 0.06)",
    border: "solid 1px #f6f6f6",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  amountFontSettings: {
    fontSize: "2.4rem",
    fontWeight: 700,
    lineHeight: "0",
  },
  flagSettings: {
    borderRadius: "50%",
    padding: "5px"
  },
}));
