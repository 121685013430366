import { Box, Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { httpGet } from "../httpcommon";
import { apis } from "../apis/apis";
import Hero from "../components/landingpage/Hero";
import NavigationBarLandingPage from "../components/landingpage/NavigationBarLandingPage";
import ProductFeatures from "../components/landingpage/ProductFeatures";
import HowItWorks from "../components/landingpage/HowItWorks";
import Partners from "../components/landingpage/Partners";
import LandingPageFooter from "../components/landingpage/LandingPageFooter";
import SocialMediaSection from "../components/landingpage/SocialMediaSection";

export default function LandingPageNew () {

    return (
        <>
            <NavigationBarLandingPage />

            <Hero />

            <ProductFeatures />

            <HowItWorks />

            <Partners />

            <LandingPageFooter />

            <SocialMediaSection />
        </>

    )
}