import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useTranslation } from 'react-i18next';
import { Chip } from "@material-ui/core";
import DropzoneArea from "../../common-components/DropzoneArea";
import commonStyles from "../../../commonStyles";
import AttachFile from "@material-ui/icons/AttachFile";
import DocumentChip from "../../common-components/DocumentChip";
import { hasFieldError, isEditStepper, getFieldError, isRegisterStepper, individualDocuments } from "../../../utils";
import DatePicker from "../../common-components/DatePicker";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";

const useStyles = makeStyles((theme) => ({
    avatarCustomized: {
        height: "7.2rem",
        width: "7.2rem",
        border: "solid 1px",
        borderColor: theme.palette.secondary.main,
        borderRadius: "3px",
    },
    dropzone: {
        fontSize: "0.8rem",
        marginTop: "1.8rem",
    },
    input: {
        marginTop: "1rem"
    },
    title: {
        marginTop: theme.spacing(2),
        fontWeight: 600
    },
    titleStyle: {
        alignItems: 'baseline',
        justifyContent: 'space-between'
    },
    tooltipStyle: {
        fontSize: '15px'
    },
    ...commonStyles,
}));

export default function Identification(props) {
    const formikProps = props.formikProps;

    const {
        formField: {
            businessName,
            registrationPlace,
            registrationNumber,
            registrationDate
        }
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const handleUpdateCertificate = (file, document_name) => {
        formikProps.setFieldValue(`certificate_document_content[${document_name}]`, file)
        props.setEdit(true);
    }

    const handleRmvUpdatedCertificate = (document_name) => {
        formikProps.setFieldValue(`certificate_document_content`, null)
        props.setEdit(true);
    }

    const DocumentComponent = () => {
        if(isRegisterStepper()){
            if(formikProps.values.certificate_document_content){
                return (
                    <Chip
                        icon={<AttachFile />}
                        variant='outlined'
                        label={individualDocuments.BusinessCertificate.label}
                        onDelete={() => formikProps.setFieldValue(`certificate_document_content`, null)}
                    />
                )
            }

            return (
                <DropzoneArea
                    acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                    handleFileChange={(file) => formikProps.setFieldValue(`certificate_document_content`, file)}  
                    documentName={individualDocuments.BusinessCertificate.name}
                />
            )

        }

         if(isEditStepper()){
            if(formikProps.values['certificate_document'] && formikProps.values['certificate_document']['document_path'])
                return (
                    <DocumentChip
                        label={individualDocuments.BusinessCertificate.label}
                        link={formikProps.values['certificate_document']['document_path'][individualDocuments.BusinessCertificate.name]}
                        status={formikProps.values['certificate_document']['document_status']}
                        handleFileChange={handleUpdateCertificate}
                        handleDeleteFile={handleRmvUpdatedCertificate} 
                        documentName={individualDocuments.BusinessCertificate.name}
                        updatedDocuments={formikProps.values.certificate_document_content}
                        rejectionReason={formikProps.values['certificate_document']['document_rejection_reason']}
                    />
                )
            

/*             if(props.updatedDocuments[formikProps.values[documents.name][index].document_type])
                return (
                    <Chip 
                        icon={<AttachFile />}
                        variant='outlined'
                        label={formikProps.values[documents.name][index].document_type}
                        onDelete={(e) => handleRmvUpdatedCertificate(document)}
                    />
                ) */

            return (
                <DropzoneArea
                    acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                    handleFileChange={handleUpdateCertificate}  
                    documentName={individualDocuments.BusinessCertificate.name}
                />
            )
        } 
    }


    return (
        <React.Fragment>
            <Grid container md={12} xs={12} direction="column">
                <Grid item md={12} xs={12}>
                    <Typography variant="h4" style={{ lineHeight: 1.33, textAlign: "center" }}>
                        {t("stepper.kyc.step1")}
                    </Typography>
                </Grid>

                <Box mt={5} /> 
            </Grid>   
            <Grid container direction="row" md={12} xs={12} >
                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        name={businessName.name}
                        label={businessName.label}
                        value={formikProps.values[businessName.name]}
                        onChange={props.setBusinessAttribute(formikProps.setFieldValue)} 
                        onBlur={formikProps.handleBlur(businessName.name)} 
                        error={(formikProps.touched[businessName.name] && Boolean(formikProps.errors[businessName.name])) || (isEditStepper() && formikProps.values[businessName.name] == props.businessDetails[businessName.name] && hasFieldError(props.businessDetails, businessName.name))}
                        helperText={(formikProps.touched[businessName.name] && formikProps.errors[businessName.name]) || (formikProps.values[businessName.name] == props.businessDetails[businessName.name] && getFieldError(props.businessDetails, businessName.name))}
                        fullWidth
                    />
                </Grid>

                <Grid item md={2} xs={12} />
                
                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        name={registrationPlace.name}
                        label={registrationPlace.label}
                        value={formikProps.values[registrationPlace.name]}
                        onChange={props.setBusinessAttribute(formikProps.setFieldValue)} 
                        onBlur={formikProps.handleBlur(registrationPlace.name)} 
                        error={(formikProps.touched[registrationPlace.name] && Boolean(formikProps.errors[registrationPlace.name])) || (isEditStepper() && formikProps.values[registrationPlace.name] == props.businessDetails[registrationPlace.name] && hasFieldError(props.businessDetails, registrationPlace.name))}
                        helperText={(formikProps.touched[registrationPlace.name] && formikProps.errors[registrationPlace.name]) || (formikProps.values[registrationPlace.name] == props.businessDetails[registrationPlace.name] && getFieldError(props.businessDetails, registrationPlace.name))}
                        fullWidth
                    />
                </Grid>

                <Grid container direction="row" md={5} xs={12}>
                    <Grid item md={12} xs={12}>
                        <Typography variant="h6">{t("business-identification-step.date-label")}</Typography>
                    </Grid>
                    <DatePicker 
                        value={formikProps.values[registrationDate.name]}
                        handleDateChange={value => formikProps.setFieldValue(registrationDate.name, value)}
                        disableFuture={true}
                        InputProps={{ 
                            readOnly: true,
                            name: registrationDate.name,
                            error: (formikProps.touched[registrationDate.name] && Boolean(formikProps.errors[registrationDate.name])) || (isEditStepper() && formikProps.values[registrationDate.name] == props.businessDetails[registrationDate.name] && hasFieldError(props.businessDetails, registrationDate.name)),
                            helperText: (formikProps.touched[registrationDate.name] && formikProps.errors[registrationDate.name]) || (formikProps.values[registrationDate.name] == props.businessDetails[registrationDate.name] && getFieldError(props.businessDetails, registrationDate.name))                            
                        }}
                        maxDate={new Date()}
                    />
                </Grid>

                <Grid item md={2} xs={12} />

                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        name={registrationNumber.name}
                        label={registrationNumber.label}
                        value={formikProps.values[registrationNumber.name]}
                        onChange={props.setBusinessAttribute(formikProps.setFieldValue)} 
                        onBlur={formikProps.handleBlur(registrationNumber.name)} 
                        error={(formikProps.touched[registrationNumber.name] && Boolean(formikProps.errors[registrationNumber.name])) || (isEditStepper() && formikProps.values[registrationNumber.name] == props.businessDetails[registrationNumber.name] && hasFieldError(props.businessDetails, registrationNumber.name))}
                        helperText={(formikProps.touched[registrationNumber.name] && formikProps.errors[registrationNumber.name]) || (formikProps.values[registrationNumber.name] == props.businessDetails[registrationNumber.name] && getFieldError(props.businessDetails, registrationNumber.name))}
                        fullWidth
                    />
                </Grid>

                <Grid item md={5} xs={12} className={classes.input}>
                    <Grid container direction="row" className={classes.titleStyle}>
                        <Grid item md={11} xs={11}>
                            <Typography variant="h6" gutterBottom className={classes.title}>
                                {individualDocuments.BusinessCertificate.label}
                            </Typography>
                        </Grid>
                    </Grid>

                    <DocumentComponent />
                </Grid>
            </Grid>       
        </React.Fragment>
    );
}
