import { Box, Grid, Typography, makeStyles, TextField, } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { toEnglishDigits, containsNonNumericLetter, containsMoreThanOneDot, formatNumber, numberWithCommos} from "../../utils";
import BuyandSellSwitch from "./BuyandSellSwitch";
import CurrencySelector from "./CurrencySelector";
import commonStyles from "../../commonStyles";
import RatePart from "./RatePart";
import { useTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/styles";

const CurrencyTextField = withStyles((theme) => ({
  root: {
    "& label": {
      color: theme.palette.primary.main,
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    "& label.Mui-disabled": {
      color: theme.palette.primary.main,
    },

    "& input": {
      color: theme.palette.primary.main,
      fontSize: "1.6rem",
      height: "2rem",
      padding: "0.5rem !important",
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({  
  payBox: {
    padding: "1rem",
    backgroundColor: "rgba(37, 218, 197, 0.05)",
    borderRadius: "4px",
    border: `solid 1px ${theme.palette.secondary.main}`,
  },
  selectSettings: {
    height: "3rem",
    borderRadius: "0px",
    borderBottomRightRadius: "4px",
    borderTopRightRadius: "4px",
  },
  convertedAmountSettings: {
    wordBreak: "break-all",
    fontSize: "3rem"
  },
  flagsEdit: {
    borderRadius: "50%",
  },
  settlementSelect: {
    "& .MuiOutlinedInput-input": {
      padding: "6px 32px 6px 7px"
    }
  },
  noBorder: {
    border: "none"
  },
  ...commonStyles,
}));

function AmountNeededPartForm({
  convertedAmountDisplayed,
  buyOrderRequired,
  setBuyOrderRequired,
  amountNeeded,
  setAmountNeeded,
  platformRate,
  baseCurrency,
  setBaseCurrency,
  quoteCurrency,
  setQuoteCurrency,
  conversionMachine,
  setConvertedAmountDisplayed,
  customRate,
  setCustomRate,
  minRate,
  maxRate,
  rateIncrement,
  probabilityMessage,
  getMinRate,
  getMaxRate,
  usePlatformRate, 
  setUsePlatformRate,
  getRate,
  amountNeededDisplayed,
  setAmountNeededDisplayed,
  setConvertedAmount,
  hasAmountNeededError,
  amountNeededErrorMessage,
  setHasAmountNeededError,
  amountMessage,
  setAmountMessage,
  handleOnBlur,
  getPrecision,
  precisionFollowsBase
}) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  
  const handleBaseCurrencyChangeCurrency = (e) => {
    setConvertedAmountDisplayed(0);
    setConvertedAmount(0);
    setAmountNeededDisplayed(0);
    setAmountNeeded(0);
    setQuoteCurrency(baseCurrency);
    setBaseCurrency(e.target.value);
    setHasAmountNeededError(false)
    setAmountMessage('')
  };

  const getQouteFlag = () => {
    if (platformRate.quote_currency == quoteCurrency) {
      return platformRate.quote_currency_flag;
    }
    return platformRate.base_currency_flag;
  };

  const handleAmountNeeded = (e) => {    
    let newValue = e.target.value
    setHasAmountNeededError(false)
  
    newValue = containsNonNumericLetter(newValue);
    
    if(containsMoreThanOneDot(newValue)) {
      return;
    }

    const formattedNewValue = formatNumber(newValue)
    setAmountNeededDisplayed(formattedNewValue);
    formattedNewValue.replaceAll(',', '');
    let arabicNumeral = new RegExp(`[٠١٢٣٤٥٦٧٨٩]`, 'g');
    if(formattedNewValue.match(arabicNumeral)){
      let arabicFormattedNewValue = toEnglishDigits(formattedNewValue)
      setAmountNeeded(arabicFormattedNewValue.replaceAll(',', ''))
      return;
    }
    setAmountNeeded(formattedNewValue.replaceAll(',', ''))
  };
  
  return (
    <>
      <Grid item md={12} xs={12}>
        <Typography variant="h6">{t("homepage.place-order.want-label")}</Typography>
      </Grid>
      
      <Grid container direction="row" alignItems="center" spacing={1} >
        <Grid item md={5} xs={12}>
          <BuyandSellSwitch
            setBuyOrderRequired={setBuyOrderRequired}
            conversionMachine={conversionMachine}
            amountNeeded={amountNeeded}
            platformRate={platformRate}
          />      
        </Grid>
        <Grid item md={7} xs={12}>
          <Grid container direction="row" alignItems="center" style={{ justifyContent: "right" }} >
            <Grid item md={8} xs={7}>
              <CurrencyTextField 
                variant="outlined"
                value={amountNeededDisplayed}
                onChange={handleAmountNeeded}
                onBlur={handleOnBlur}
                required
                fullWidth
                error={hasAmountNeededError}
                onFocus={(e) => { if(e.target.value == 0) setAmountNeededDisplayed('') }}
              />
            </Grid>
            <Grid item md={4} xs={5}>
              <CurrencySelector
                value={baseCurrency}
                className={classes.selectSettings}
                onChange={handleBaseCurrencyChangeCurrency}
                disabled
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Typography variant="caption" color="primary" >{t("homepage.place-order.precision-message", {precision: getPrecision() + " "  + (precisionFollowsBase ? platformRate.base_currency : platformRate.quote_currency)})}</Typography>
            </Grid>

            {
              hasAmountNeededError &&
            <Grid item md={12} xs={12}>
              <Typography variant="caption" color="error" >{amountNeededErrorMessage}</Typography>
            </Grid>
            }
          </Grid>
        </Grid>
      </Grid>

      <Box mt={2} />

      <Box mt={2} />

      <Grid container direction="column" md={12} xs={12} className={classes.payBox} >
        <Grid item md={12} xs={12}>
          <Typography color="primary" variant="h5" >
            {buyOrderRequired ? 
              usePlatformRate ? t("homepage.place-order.sell-label") + t("homepage.place-order.between-label") : t("homepage.place-order.sell-label") 
              : 
              usePlatformRate ? t("homepage.place-order.buy-label") + t("homepage.place-order.between-label") : t("homepage.place-order.buy-label") 
            }
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid container direction="row" md={12} xs={12}>
            <Grid item md={9} xs={9}>
              <Typography color="primary" style={{paddingTop: ".5rem" ,fontSize: '1.5rem'}}>
              {usePlatformRate && amountMessage ? 
                amountMessage
                : 
                convertedAmountDisplayed
              }
              </Typography>
            </Grid> 
            
            <Grid item md={3} xs={3}>
              <Grid container direction="row" md={12} xs={12} justify="space-between">
                <img src={getQouteFlag()} className={classes.flagsEdit} />
                <Typography variant="h4">{quoteCurrency}</Typography>
              </Grid>
            </Grid>

          </Grid>

          <Box mt={3} />
          
          <Grid item md={12} xs={12}>
            <RatePart 
              customRate={customRate}
              minRate={minRate}
              maxRate={maxRate}
              rateIncrement={rateIncrement}
              setCustomRate={setCustomRate}
              getMinRate={getMinRate}
              getMaxRate={getMaxRate}
              usePlatformRate={usePlatformRate} 
              setUsePlatformRate={setUsePlatformRate}
              probabilityMessage={probabilityMessage}
              platformRate={platformRate}
              getRate={getRate}
              setHasAmountNeededError={setHasAmountNeededError}
              setAmountMessage={setAmountMessage}
            /> 
          </Grid>
        </Grid>        
      </Grid>
    </>
  );
}

export default AmountNeededPartForm;
