import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    partnersContainer: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center",
        padding: '1rem',
        [theme.breakpoints.down("md")]: {
            paddingLeft: '3rem',
            paddingRight: '3rem'
        },
    },
    img: {
        width: "80%",
        height: "60%",
        backgroundColor: "white"
    }

}));

const Partners = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container direction="column" className={classes.partnersContainer} md={12} xs={12} justifyContent="center" alignContent="center">
            <Typography variant="h5" style={{color: "white", textTransform: "uppercase"}} >
                {t("landing-page.section5.title")}
            </Typography>

            <Box mt={5} />

            <Grid container direction="row" md={12} xs={12} justifyContent="center" alignContent="center" >
                <Grid item md={2} xs={12}>
                    <img src="/images/partnerphotos/lia.png" className={classes.img}/>
                </Grid>

                <Grid item  md={2} xs={12}>
                    <img src="/images/partnerphotos/life.png" className={classes.img}/>
                </Grid>

                <Grid item  md={2} xs={12}>
                    <img src="/images/partnerphotos/deloitte.png" className={classes.img}/>
                </Grid>

                <Grid item  md={2} xs={12}>
                    <img src="/images/partnerphotos/arab-bank.png" className={classes.img}/>
                </Grid>

                <Grid item  md={2} xs={12}>
                    <img src="/images/partnerphotos/gin.png" className={classes.img}/>
                </Grid>

                <Grid item  md={2} xs={12}>
                    <img src="/images/partnerphotos/iqeq.jpeg" className={classes.img}/>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default Partners;