import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/styles";

const languageMap = {
  "en": { label: "English", dir: "ltr", active: true },
  "ar": { label: "العربية", dir: "rtl", active: false },
};

const useStyles = makeStyles((theme) => ({
  button: {
    color: "black",
    [theme.breakpoints.down("md")]: {
      color: "white"
    },
    fontWeight: 300
  }
}));

const LanguageSelector = () => {
  const classes = useStyles();

  const language = localStorage.getItem("i18nextLng");

  var selected = "en";

  if(language) {
    if(language == "en" || language == "ar")
      selected = language;
  }
 
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = useState(null);
  
  useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <>
      <Button className={classes.button} onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        {languageMap[selected].label}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <List>
          {Object.keys(languageMap)?.map(item => (
            <ListItem
              button
              key={item}
              onClick={() => {
                window.location.reload(false);
                i18next.changeLanguage(item);
                setMenuAnchor(null);  
              }}
            >
              {languageMap[item].label}
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default LanguageSelector;


