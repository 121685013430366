import { AppBar, Box, Button, Grid, Link, makeStyles, TextField, Typography, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { httpPost } from "../../httpcommon";
import { getUser, setUser } from "../../services/authService";
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme()
  const { t } = useTranslation();

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    const result = await httpPost("/login", data);
    
    if (result.data.success) {
      setUser(result.data);
      if (location.state == undefined) {
        history.push("home");
      } else {
        history.push(location.state.from.pathname);
      }
    } else {
      setErrorMessage(result.data.message);
      setLoading(false);
    }
  };

  return (
    <form className={classes.form} onSubmit={login}>
      <Box mt={2} />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="email"
        id="standard-basic"
        label={t("email-label")}
        name="email"
        autoFocus
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label={t("password-label")}
        type="password"
        id="password"
        autoComplete="current-password"
      />
      {errorMessage ? 
        <Typography variant="subtitle" className={classes.error} >
          {errorMessage}
        </Typography>
    : ''
      }
      <Grid container direction="row" justify="flex-end" alignItems="flex-end">
        <Grid item>
          <Button
            fullWidth
            color="primary"
            disabled={loading}
            href='/forgot-password'
          >
            {t("login-page.forgot-password")}
          </Button>
        </Grid>
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        disabled={loading}
        className={classes.startButton}
      >
        {t("login-page.action-button")}
      </Button>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  startButton: {
    marginTop: theme.spacing(2),
  },

  error: {
    color: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "white",
  },
}));

export default LoginForm;
