import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getUser } from "../services/authService";

export function PrivateOrderRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        let query = new URLSearchParams(location.search);
        let masked_id = query.get("id");

        if (
          getUser() != null &&
          getUser()["onboarded"] &&
          location.search != undefined &&
          masked_id
        ) {
          return (
            <Redirect
              to={{ pathname: "/order-details", state: { id: masked_id } }}
            />
          );
        }
        return getUser() != null &&
          location.state != undefined &&
          location.state.id ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
