import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Link from "@material-ui/core/Link";
import { useTranslation } from 'react-i18next';

const Title = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        {t("login-page.title")}
      </Typography>
      <Box mt={1} />
      <Typography variant="subtitle1" style={{textAlign: "center"}}>
        {t("login-page.subtitle")}{" "}
        <Link href="/registration" variant="subtitle1">
          {t("login-page.register")}
        </Link>
      </Typography>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
    textAlign: "center"
  },
}));

export default Title;
