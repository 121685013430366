import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useStylesCommon } from "../common-ui-fit/commonFields";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { isArabic, numberWithCommos, profileType, toArabicDigits } from "../../utils.js";
import { LoadingDisplay } from "../common-ui-fit/LoadingDisplay";
import CustomizedAmountBox from "./CustomizedAmountBox";
import CustomizedRateBox from "./CustomizedRateBox";
import { useTranslation } from 'react-i18next';
import { organizationApis } from "../../apis/organizationApis";
import ConfirmationDialog from "../common-components/ConfirmationDialog";
import { getUser } from "../../services/authService";
import MatchingTimeline from "./MatchingTimeline";
import moment from "moment";
import CountdownTimer from "react-component-countdown-timer";


const useStyles = makeStyles((theme) => ({
  boxSettings: {
    border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: "rgba(37, 218, 197, 0.05)",
    borderRadius: "4px",
    padding: "0.3rem",
    justifyContent: "center",
  },
  backButtonSettings: {
    textDecoration: "none",
    color: "black",
  },
  cancelButtonSettings: {
    backgroundColor: theme.palette.error.main,
    color: "white",
  },
  boxesMobileSettings: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  detailsMobileSettings: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  boxValuesMobileSettings: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  orderStatusMobileSettings: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  scheduleSettings: {
    padding: "4px",
  },
  headerDetailsSettings: {    
    [theme.breakpoints.down("md")]: {
      marginBottom: "3%",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "3%",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  amountFontSettings: {
    fontSize: "2.3vw",
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      lineHeight: 1,
      fontSize: "1.4rem"
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: "0",
    },
  },
  center: {
    textAlign: "center",
  },
}));

const OrderDetailsSection = ({ orderDetails, handleCancelOrder, matches, platformRate }) => {
  const classes = useStyles();
  const commonClasses = useStylesCommon();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();
  const profile = getUser().profile_type;

  const orderStatus = () => {
    if (orderDetails.canceled) {
      return <Typography color="primary">{t("order-status.canceled")}</Typography>;
    }
    if (orderDetails.approval_status == "APPROVED" && orderDetails.matching_status == "UNDER MATCHING") {
      return <Typography color="primary">{t("order-status.in-progress")}</Typography>;
    }
    if(orderDetails.matching_status == "MATCHING FINISHED" && !orderDetails.is_transaction_post_match_settled){
      if(orderDetails.amount == orderDetails.amount_fulfilled)
        return <Typography color="primary">{t("order-status.secured")}</Typography>;
      else return <Typography color="primary">{t("order-status.matched")}</Typography>;
    }
    if (orderDetails.matching_status == "COMPLETED") {
      return <Typography color="primary">{t("order-status.completed")}</Typography>;
    }
    if (orderDetails.approval_status == "SOURCE OF FUND PROVIDED") {
      return <Typography color="primary">{t("order-status.pending-approval")}</Typography>;
    }
    if (orderDetails.approval_status == "DEPOSIT REQUESTED") {
      return <Typography color="primary">{t("order-status.deposit-requested")}</Typography>;
    }
    if(orderDetails.approval_status == "DECLINED")
      return <Typography color="primary">{t("order-status.disapproved")}</Typography>;
  };

  const formateDate = (date) => {
    date = new Date(date);
    var month = "" + (date.getMonth() + 1);
    var day = "" + date.getDate();
    var year = date.getFullYear();
    var hours = date.getHours() ? "" + date.getHours() : null;
    var minutes = date.getMinutes() ? "" + date.getMinutes() : null;
    var period = "";

    if(hours > 0 && hours < 12) isArabic() ? period = "ص" : period = "AM"
    if(hours > 12 && hours < 24) isArabic() ? period = "م" : period = "PM"

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours && hours.length < 2) hours = "0" + hours;
    if (minutes && minutes.length < 2) minutes = "0" + minutes;

    if(hours && minutes && period)
      var time = hours + ":" + minutes + " " + period

    var date = [day, month, year].join("/")

    return isArabic() ? [toArabicDigits(date), toArabicDigits(time)].join(" - ") : [date, time].join(" - ");
  };

  if (orderDetails) {
    return (
      <>
        <Grid container direction="row" justify="space-between" alignItems="center" className={classes.headerDetailsSettings}>
          <Grid item xs={6} md={6} lg={"auto"}>
            <FormControlLabel
              control={
                <IconButton>
                  {isArabic() ? 
                    <Link to="/home" className={classes.backButtonSettings}>
                      <ArrowForwardIcon />
                    </Link>
                    :
                    <Link to="/home" className={classes.backButtonSettings}>
                      <ArrowBackIcon />
                    </Link>
                  }
                </IconButton>
              }
              label={t("order-details-page.title")}
            />
          </Grid>

          { 
            (
              !orderDetails.canceled && (orderDetails.matching_status != "COMPLETED") && orderDetails.approval_status != "DECLINED" && 
              (!orderDetails.request_money_before_matching || (orderDetails.request_money_before_matching && orderDetails.amount != orderDetails.amount_fulfilled))
            ) ?
              (
                <Grid item style={{textAlign: "end"}} md={6} xs={3} lg={"auto"} > 
                  <Button
                    variant="contained"
                    onClick={handleCancelOrder}
                    className={classes.cancelButtonSettings}
                  >
                    {t("button.cancel")}
                  </Button>
                </Grid>
              ) : <React.Fragment></React.Fragment>
          }
          

        </Grid>

        <Grid container direction="row" justify="center" alignItems="center" spacing={2} className={classes.detailsMobileSettings}>
          <Grid item md={1} />

          <Grid item md={10}>
            <Grid container direction="row" justify="center" spacing={1}>
              
              <Grid item md={4} xs={12} className={classes.boxSettings}>               
                <CustomizedAmountBox orderDetails={orderDetails} />               
              </Grid>
              
              <Grid item>
                <Box mt={2} />
              </Grid>
              
              {
                !orderDetails.use_platform_rate ? (
                  <Grid item md={5} xs={12} className={classes.boxSettings}>
                    <CustomizedRateBox title={t("order-details-page.desired-label")} orderDetails={orderDetails} rate={orderDetails.rate}/>
                  </Grid>
                )
                :
                (
                  (orderDetails.approval_status == "APPROVED" && orderDetails.average_rate && ((orderDetails.matching_status == "MATCHING FINISHED" || orderDetails.matching_status == "COMPLETED") || (orderDetails.canceled && matches.length > 0))) ? (
                    <Grid item md={5} xs={12} className={classes.boxSettings}>
                      <CustomizedRateBox title={t("order-details-page.acquired-rate-label")} orderDetails={orderDetails} rate={orderDetails.average_rate}/>
                    </Grid>
                  ) : <React.Fragment></React.Fragment>
                )
              }
              
            </Grid>
          </Grid>

          <Grid item md={1} />
        </Grid>

        <Grid item className={classes.orderDetailsSettings}>
          <Box mt={1} />
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
            spacing={1}
            className={classes.orderStatusMobileSettings}
          >
            {isDesktop ? (
              <Grid item>
                <img src="/images/tag-icon.svg" />
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12} md={"auto"}>
              <Grid container direction="column">
                <Grid item xs={12} md={"auto"}>
                  <Typography style={{textTransform: "uppercase"}}>{t("order-status.title")}</Typography>
                </Grid>
                <Grid item xs={12} md={"auto"} className={classes.center}>
                  {orderStatus()}
                </Grid>
              </Grid>
            </Grid>

            {isDesktop ? (
              <Grid item>
                <Box ml={2} />
                <img src="/images/vertical-line-dashed.svg" />
                <Box mr={2} />
              </Grid>
            ) : (
              <></>
            )}
            {isDesktop ? (
              <>
                <Box mr={2} />
                <Grid item>
                  <img src="/images/schedule-icon.svg" />
                </Grid>{" "}
              </>
            ) : (
              <></>
            )}

            <Grid item xs={5} md={"auto"}>
              <Grid container direction="column" xs={12}>
                <Grid item xs={12} md={"auto"}>
                  <Typography style={{textTransform: "uppercase"}}>{t("order-details-page.created-on-label")}</Typography>
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <Typography className={classes.center}>
                    {formateDate(orderDetails.created_on)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {orderDetails.matching_status == "COMPLETED" ? (
              <>
                {isDesktop ? (
                  <Grid item xs={1} md={"auto"}>
                    <img src="/images/vertical-line-dashed.svg" />
                    <Box mr={2} />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={3} md={"auto"}>
                  <Grid container direction="column" xs={12} md={"auto"}>
                    <Grid item xs={12} md={"auto"}>
                      <Typography style={{textTransform: "uppercase"}}>{t("order-details-page.closed-on-label")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                      <Typography className={classes.center}>
                        {formateDate(orderDetails.closed_on)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        {
          matches.length > 0 && orderDetails.request_money_before_matching && 
          (!orderDetails.canceled || (orderDetails.canceled && (orderDetails.amount_fulfilled > 0 || orderDetails.amount_matched > 0))) ? (
            <React.Fragment>
              <Box mt={3} />

              <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item md={3} />

                <Grid item md={6}>
                  <Box mt={3} />
                  <Grid container md={12} direction="row" justify="center" alignItems="center" spacing={2}>
                    <MatchingTimeline orderDetails={orderDetails} />
                  </Grid>
                </Grid>

                <Grid item md={3} />
              </Grid>
            </React.Fragment>
          )
          : <React.Fragment></React.Fragment>
        }

        <Box mt={2} />
      </>
    );
  }
  return (
    <Grid
      container
      direction="column"
      className={commonClasses.detailsSettings}
    >
      <Grid item>
        <Box mt={10} />
      </Grid>
      <Grid item>
        <LoadingDisplay heigth={"15rem"} />
      </Grid>
      <Grid item>
        <Box mt={7} />
      </Grid>
      <Grid item>
        <LoadingDisplay heigth={"30rem"} />
      </Grid>
    </Grid>
  );
};

export default OrderDetailsSection;
