import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCountries, updateEmploymentStatus, updateLegalForms, updateOccupationCodes, updateOwnershipBeneficialRights, updateSourceOfFunds, } from "../redux-reducer/data/dataSlice";
import { dataApis } from "../apis/dataApis";
import OrganizationStepper from "../components/signup/organization/Stepper";
import { getUser } from "../services/authService";
import { EMPTY_BUSINESS, EMPTY_INDIVIDUAL, EMPTY_ORGANIZATION, profileType } from "../utils";
import { getOrganizationDetails, updateOrganizationDetails } from "../redux-reducer/organization/organizationSlice";
import { getBusinessDetails, updateBusinessDetails } from "../redux-reducer/business/businessSlice";
import BusinessStepper from "../components/signup/business/Stepper";
import { getIndividualDetails, updateIndividualDetails } from "../redux-reducer/individual/individualSlice";
import IndividualStepper from "../components/signup/individual/Stepper";

export default function SignUp() {
    const profile = getUser().profile_type;
    const dispatch = useDispatch();

    const organizationDetails = useSelector(getOrganizationDetails)
    const individualDetails = useSelector(getIndividualDetails)
    const businessDetails = useSelector(getBusinessDetails)

    useEffect(async () => {

        let countriesResult = await dataApis.getCountries();
        if (countriesResult.data.success) {
            dispatch(updateCountries(countriesResult.data.countries));
        }

        if(profile === profileType.Organization){
            let beneficialRightsResult = await dataApis.getOwnershipBeneficialRights();
            if (beneficialRightsResult.data.success) {
                dispatch(updateOwnershipBeneficialRights(beneficialRightsResult.data.beneficial_right));
            }

            let occupations = await dataApis.getOccupations();
            if(occupations.data.success) {
                dispatch(updateOccupationCodes(occupations.data.occupations))
            }

            let legalForms = await dataApis.getLegalForms();
            if(legalForms.data.success) {
                dispatch(updateLegalForms(legalForms.data.legal_forms))
            }
        }

        if(profile === profileType.Business || profile === profileType.Individual){
            let sourceOfFunds = await dataApis.getSourceOfFunds();
            if(sourceOfFunds.data.success) {
                dispatch(updateSourceOfFunds(sourceOfFunds.data.sources_of_funds))
            }

            let employmentStatus = await dataApis.getEmploymentStatus();
            if(employmentStatus.data.success) {
                dispatch(updateEmploymentStatus(employmentStatus.data.employment_status))
            }
        }
    }, []);

    if(profile === profileType.Organization){
        if(!organizationDetails)
            dispatch(updateOrganizationDetails(EMPTY_ORGANIZATION))
        return (
            <OrganizationStepper />     
        )
    } 

    if(profile === profileType.Business){
        if(!businessDetails)
            dispatch(updateBusinessDetails(EMPTY_BUSINESS))       
        return (
            <BusinessStepper />     
        )
    } 

    if(profile === profileType.Individual){ 
        if(!individualDetails)
            dispatch(updateIndividualDetails(EMPTY_INDIVIDUAL))       
        return (
            <IndividualStepper />     
        )
    } 
}