import { getUser } from "./authService";
import Centrifuge from "centrifuge";
import store from "../redux-config/store";
import {
  updateAccountStatus,
  updateUnreadNotifications,
} from "../redux-reducer/centrifuge/centrifugeSlice";
import { CENTRIFUGO_URL } from "../env";

export function initializeRealtimeNotification() {
  const centrifuge = new Centrifuge(CENTRIFUGO_URL);
  centrifuge.setToken(getUser().c_token);
  centrifuge.on("connect", function (ctx) {
  });
  centrifuge.on("disconnect", function (ctx) {
  });
  centrifuge.subscribe(getUser().email, function (ctx) {
    centrifugeAction(ctx.data);
  });
  
  centrifuge.connect();
}

function centrifugeAction(action) {
  switch (action.type) {
    case "NOTIFICATION_UPDATE":
      store.dispatch(updateUnreadNotifications(action.content));
      break;
    case "ACCOUNT_STATUS_CHANGED":
      store.dispatch(updateAccountStatus(action.content.status));
      store.dispatch(
        updateUnreadNotifications(action.content.unread_notification)
      );
      break;
    default:
      break;
  }
}
