import AppBar from "@material-ui/core/AppBar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  selectedTab: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    fontWeight: 700,

    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  generalTab: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    fontWeight: 700,
    backgroundColor: "rgba(37, 218, 197, 0.1)",
  },
  tabs: {
    "& button": {
      minWidth: 100,
    },
  },
}));

export default function BuyandSellSwitch({
  setBuyOrderRequired,
  amountNeeded,
  conversionMachine,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    conversionMachine(amountNeeded);
    if (newValue == 0) {
      setBuyOrderRequired(true);
    }
    if (newValue == 1) {
      setBuyOrderRequired(false);
    }
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor=""
          textColor="primary"
          className={classes.tabs}
          TabIndicatorProps={{ backgroundColor: "transparent" }}
        >
          <Tab
            style={value == 0 ? { color: "white" } : {}}
            className={value == 0 ? classes.selectedTab : classes.generalTab}
            label={t("buy-label")}
          />

          <Tab
            style={value == 1 ? { color: "white" } : {}}
            className={value == 1 ? classes.selectedTab : classes.generalTab}
            label={t("sell-label")}
          />
        </Tabs>
      </AppBar>
    </div>
  );
}
