import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';

const CustomDialog = withStyles((theme) => ({
    root: {
        '& .MuiDialog-scrollPaper': {
            alignItems: "unset"
        }
    },
}))(Dialog);

export default function TermsDialog({open,onClose, onAgree}) {
    const { t } = useTranslation();

    const [bottom, setBottom] = useState(true);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        setBottom(bottom)
    }

    return (  
        <CustomDialog
            open={open}
            onClose={onClose}
            fullWidth
        >
            <DialogTitle>
                {t("terms-label")}
            </DialogTitle>

            <DialogContent onScroll={handleScroll} >
                <iframe src="https://cedaroxygen.com/general-terms-and-conditions-of-use/" style={{width: "100%", height: "100%"}}/>       
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="default">
                    {t("button.cancel")}
                </Button>
                <Button onClick={onAgree} color="primary" 
                    //disabled={bottom}
                >
                    {t("button.agree")}
                </Button>
            </DialogActions>
        </CustomDialog>
    );
}