import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Typography, Box } from "@material-ui/core";
import { formatDate, isArabic, parseServerDate, toArabicDigits  } from "../../../utils";
import { useTranslation } from 'react-i18next';
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";

import commonStyles from "../../../commonStyles";
import moment from "moment";
import { useSelector } from "react-redux";
import { getCountries, getEmploymentStatus, getSourceOfFunds } from "../../../redux-reducer/data/dataSlice";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2rem"
    },
    center: {
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
    },

  ...commonStyles,
}));

export default function BusinessDetails({businessDetails}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const countries = useSelector(getCountries);
    const employmentStatus = useSelector(getEmploymentStatus);
    const sourceOfFunds = useSelector(getSourceOfFunds);

    const getOptionsById = (options, values) => {
        let result = []
        if(values)
          for(var i=0; i<values.length; i++){
            let filter = options.find((option) => option.id === values[i])
            if(filter)
              result.push(filter)
          }
        return result;
    }

    const getEmploymentStatusById = (value) => {
        if(employmentStatus)
            return employmentStatus.find((status) => status.id == value);
    }

    const getSourceOfFundsById = (values) => {
        return getOptionsById(sourceOfFunds, businessDetails.sources_of_funds).map(option => option.name == "Other" ? businessDetails.other_source_of_funds : (isArabic() ? option.name_arabic : option.name)).join(", ")
    }

    if(countries && employmentStatus && sourceOfFunds)
        return (
            <Paper elevation={0} className={classes.root}>
                <Grid container direction="column" md={12} xs={12}>    
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("business-identification-step.name-label")}
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {businessDetails.name}
                            </Typography>
                        </Grid>
                    </Grid>                              
                                            
                    <Box mt={3} />
            
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("business-identification-step.date-label")}
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {isArabic() ? toArabicDigits(moment(new Date(businessDetails.date_of_registration)).format("DD/MM/yyyy")) : moment(new Date(businessDetails.date_of_registration)).format("DD/MM/yyyy")}
                            </Typography>
                        </Grid>
                    </Grid>                                   

                    <Box mt={3} />
                
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("business-identification-step.place-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {businessDetails.place_of_registration}
                            </Typography>
                        </Grid>
                    </Grid>
            
                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("business-identification-step.number-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {businessDetails.registration_number}
                            </Typography>
                        </Grid>
                    </Grid>
                    
                    <Box mt={3} />
                        
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("labels.name")}
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {businessDetails.first_name + " " + (businessDetails.maiden_name ? businessDetails.maiden_name : "" ) + " " + businessDetails.last_name}
                            </Typography>
                        </Grid>
                    </Grid>                              
                                        
                    <Box mt={3} />
            
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("labels.dob")}
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {isArabic() ? toArabicDigits(moment(new Date(businessDetails.date_of_birth)).format("DD/MM/yyyy")) : moment(new Date(businessDetails.date_of_birth)).format("DD/MM/yyyy")}
                            </Typography>
                        </Grid>
                    </Grid>                                   

                    <Box mt={3} />
                
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("kyc-details-step.phone-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {isArabic() ? toArabicDigits((businessDetails.mobile_phone).toString()) : businessDetails.mobile_phone}
                            </Typography>
                        </Grid>
                    </Grid>
            
                    <Box mt={3} />
        
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("labels.nationality")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {getOptionsById(countries, businessDetails.nationalities).map(option => isArabic() ? option.name_arabic : option.name ).join(", ")}
                            </Typography>
                        </Grid>
                    </Grid>
        
                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("labels.residence")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {getOptionsById(countries, businessDetails.residences).map(option => isArabic() ? option.name_arabic : option.name ).join(", ")} 
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("kyc-details-step.employment-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {
                                    getEmploymentStatusById(businessDetails.employment_status_id).name == "Other" ? 
                                    businessDetails.other_employment_status : getEmploymentStatusById(businessDetails.employment_status_id).name
                                }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box mt={3} />
                
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("kyc-details-step.funds-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                 {getSourceOfFundsById(businessDetails.sources_of_funds)}
                            </Typography>
                        </Grid>
                    </Grid> 
                </Grid>
            </Paper>
        )
    return <LoadingDisplay />
}
