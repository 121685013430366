import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useEffect, useState } from "react";
import { apis } from "../../apis/apis";
import { isArabic, numberWithCommos, toArabicDigits } from "../../utils";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "2rem",
  },
  cardHeader: {
    color: theme.palette.primary.main,
    backgroundColor: "rgb(0, 84, 84,0.16)",
    textAlign: "center",
  },
  cardContent: {
    textAlign: "center",
  },
  hr: {
    borderTop: "0",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: "3px dashed #005454",
  },
  cardAction: {
    backgroundColor: "#25DAC5",
    textAlign: "center",
    justifyContent: "center",
  },
}));

const RateCard = () => {
  const [platformRate, setPlatformRate] = useState(0);
  const [lastTransactionRate, setLastTransactionRate] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    getLiveRate();
  }, []);

  const getLiveRate = async () => {
    let res = await apis.getPublicLiveRate();
    if (res.data.success) {
      setPlatformRate(res.data.platfrom_rate);
      setLastTransactionRate(res.data.platfrom_rate);
    } else {
      setPlatformRate("18,000");
      setLastTransactionRate("18,000");
    }
  };
  const classes = useStyles();
  return (
    <Grid container direction="row" md={12} xs={12}>
      <Grid item md={4} xs={2} />

      <Grid item md={4} xs={8}>
        <Card className={classes.card}>
          <CardHeader title={t("landing-page.rate-card.header")} className={classes.cardHeader} />

          <CardContent className={classes.cardContent}>
            <Grid
              container
              direction="column"
              md={12}
              xs={12}
              alignContent="center"
              spacing={2}
            >
              <Grid item md={12} xs={12}>
                <Typography variant="h5">{t("landing-page.rate-card.platform-rate")}</Typography>
              </Grid>

              <Grid item md={12} xs={12}>
                <Typography variant="h4" color="primary">
                  {
                    platformRate ? 
                      `${numberWithCommos(platformRate)} LBP`
                   : "Loading..."
                  }
                </Typography>
              </Grid>

              <Grid item md={12} xs={12}>
                <hr className={classes.hr}></hr>
              </Grid>

              <Grid item md={12} xs={12}>
                <Grid container direction="row" md={12} xs={12} style={{justifyContent:"center"}}>
                  <Grid item md={"auto"} xs={"auto"}>
                    <Typography variant="h6" style={{ fontWeight: 600, textAlign: "center" }}>
                      {t("landing-page.rate-card.last-transaction")}&nbsp;&nbsp;{" "}
                    </Typography>
                  </Grid>
                  <Grid item md={"auto"} xs={"auto"}>
                    <Typography variant="h6" style={{ fontWeight: 600 }}>
                      {
                        lastTransactionRate ? 
                          `${numberWithCommos(lastTransactionRate)} LBP`
                        : 
                        "Loading..."
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions className={classes.cardAction}>
            <Link href="/login" style={{ textDecoration: "none" }}>
              <Grid container direction="row">
                <Grid item>
                  <Typography style={{ color: "#FFF" }}>
                    {t("button.place-order")}
                  </Typography>
                </Grid>
                <Grid item>
                  <ArrowRightAltIcon style={{ color: "#FFF" }} />
                </Grid>
              </Grid>
            </Link>
          </CardActions>
        </Card>
      </Grid>

      <Grid item md={4} xs={2} />
    </Grid>
  );
};

export default RateCard;
