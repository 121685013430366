import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { theme } from '../../App';
import { useTranslation } from 'react-i18next';
import { isArabic, toArabicDigits } from '../../utils';

const useStyles = makeStyles(theme => ({
    howItWorksContainer: {
        width: '100%',
        padding: '5rem',
        [theme.breakpoints.down("md")]: {
          paddingLeft: 0,
          paddingRight: 0
        },
    },
    howItWorks: {
        [theme.breakpoints.down("md")]: {
            order: 2,
            paddingLeft: "3rem",
            paddingRight: "3rem"

        },
    },
    phoneContainer: {
        height: "50vh", 
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            height: "40vh",
            marginTop: "3rem"
        }
    },
    timeline: {
        [theme.breakpoints.down("md")]: {
            order: 1,
            paddingLeft: '1rem',
            paddingRight: '1rem'
        },
        marginTop: '4rem'
    },
    paper: {
        backgroundColor: '#eee',
        padding: '2rem',
        borderRadius: '10px',
    },
    timelineDot: {
        width: '3rem',
        height: '3rem',
        justifyContent: 'center',
    },
    timelineDotContent: {
        color: 'white', 
        alignSelf: 'center'
    }
})); 

const CustomTimeline = withStyles((theme) => ({
    root: {
        "& li.MuiTimelineItem-missingOppositeContent:before": {
            display: "none"
        },
    }
  }))(Timeline);

const HowItWorks = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [paper1, setPaper1] = useState({
        backgroundColor: '#eee',
        color: '#000',
        boxShadow: 0
    })

    const [paper2, setPaper2] = useState({
        backgroundColor: '#eee',
        color: '#000',
        boxShadow: 0
    })

    const [paper3, setPaper3] = useState({
        backgroundColor: '#eee',
        color: '#000',
        boxShadow: 0
    })

    const [paper4, setPaper4] = useState({
        backgroundColor: '#eee',
        color: '#000',
        boxShadow: '0'
    })



    const handleScroll = (e) => {
        if (window.scrollY > 1000) {
            setPaper1({
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                boxShadow: '0 0 10px 2px #25dac5'
            })
        } else {
            setPaper1({
                backgroundColor: '#eee',
                color: '#000',
                boxShadow: 'unset'
            })
        }

        if(window.scrollY > 1300) {
            setPaper2({
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                boxShadow: '0 0 10px 2px #25dac5'
            })
        } else {
            setPaper2({
                backgroundColor: '#eee',
                color: '#000',
                boxShadow: 'unset'
            })
        }
            
        if(window.scrollY > 1500) {
            setPaper3({
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                boxShadow: '0 0 10px 2px #25dac5'
            })
        } else {
            setPaper3({
                backgroundColor: '#eee',
                color: '#000',
                boxShadow: 'unset'
            })
        }

        if(window.scrollY > 1600) {
            setPaper4({
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                boxShadow: '0 0 10px 2px #25dac5'
            })
        } else {
            setPaper4({
                backgroundColor: '#eee',
                color: '#000',
                boxShadow: 'unset'
            })
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
      }, []);

    return(
        <Grid container direction="row" md={12} xs={12} justify="space-between" className={classes.howItWorksContainer}>
            <Grid item md={6} xs={12} className={classes.howItWorks}>
                <Grid container direction="column" md={12} xs={12} spacing={2}>
                    <Grid item className={classes.phoneContainer}>
                        <img src="/images/phone.png" width="25%"/>
                    </Grid>
                    
                    <Grid item>
                        <Typography variant="h4" color="primary" style={{textTransform: "uppercase"}}>
                            {t("landing-page.section4.title")}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="h5" color="primary">
                            {t("landing-page.section4.subtitle")}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="subtitle" paragraph color="primary">
                            {t("landing-page.section4.body")}
                        </Typography>
                    </Grid>
                    
                </Grid>

            </Grid>

            <Grid item md={6} xs={12} className={classes.timeline}>
                <CustomTimeline>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary" className={classes.timelineDot}>
                                <Typography variant="h4" className={classes.timelineDotContent}>
                                    {isArabic() ? toArabicDigits("1") : 1}
                                </Typography>
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={0} className={classes.paper} style={{backgroundColor: paper1.backgroundColor, boxShadow: paper1.boxShadow}}>
                                <Typography variant="h5" component="h1" style={{color: paper1.color}}>
                                    {isArabic() ? toArabicDigits("1.") : "1."} {t("landing-page.section4.step1.title")}
                                </Typography>
                                <Box mt={2} />
                                <Typography style={{color: paper1.color}}>
                                    {t("landing-page.section4.step1.subtitle")}
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary" className={classes.timelineDot}>
                                <Typography variant="h4" className={classes.timelineDotContent}>
                                    {isArabic() ? toArabicDigits("2") : 2}
                                </Typography>
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={0} className={classes.paper} style={{backgroundColor: paper2.backgroundColor, boxShadow: paper2.boxShadow}}>
                                <Typography variant="h5" component="h1" style={{color: paper2.color}}>
                                    {isArabic() ? toArabicDigits("2.") : "2."} {t("landing-page.section4.step2.title")}
                                </Typography>
                                <Box mt={2} />
                                <Typography style={{color: paper2.color}}>
                                    {t("landing-page.section4.step2.subtitle")}
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary" className={classes.timelineDot}>
                                <Typography variant="h4" className={classes.timelineDotContent}>
                                    {isArabic() ? toArabicDigits("3") : 3}
                                </Typography>
                            </TimelineDot>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={0} className={classes.paper} style={{backgroundColor: paper3.backgroundColor, boxShadow: paper3.boxShadow}}>
                                <Typography variant="h5" component="h1" style={{color: paper3.color}}>
                                    {isArabic() ? toArabicDigits("3.") : "3."} {t("landing-page.section4.step3.title")}
                                </Typography>
                                <Box mt={2} />
                                <Typography style={{color: paper3.color}}>
                                    {t("landing-page.section4.step3.subtitle")}
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary" className={classes.timelineDot}>
                                <Typography variant="h4" className={classes.timelineDotContent}>
                                    {isArabic() ? toArabicDigits("4") : 4}
                                </Typography>
                            </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={0} className={classes.paper} style={{backgroundColor: paper4.backgroundColor, boxShadow: paper4.boxShadow}}>
                                <Typography variant="h5" component="h1" style={{color: paper4.color}}>
                                    {isArabic() ? toArabicDigits("4.") : "4."} {t("landing-page.section4.step4.title")}
                                </Typography>
                                <Box mt={2} />
                                <Typography style={{color: paper4.color}}>
                                    {t("landing-page.section4.step4.subtitle")}
                                    <span style={{fontSize: "10px", color: "red"}}>{t("coming-soon")}</span></Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                </CustomTimeline>
            </Grid>
        </Grid>
    )
}

export default HowItWorks;