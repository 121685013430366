import { Avatar, Divider, Grid, MenuItem, Popover, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from 'react-i18next';
import LanguageSelector from "../common-components/languageSelector";

const PopoverDesktop = ({
  menuId,
  handleMenuClose,
  isMenuOpen,
  anchorEl,
  handleLogOut,
  classes,
  avatarContent,
  profileDetails
}) => {
  const { t } = useTranslation();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component="button" href="/profile">
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Avatar className={classes.avatarCustomized}>{avatarContent}</Avatar>
          </Grid>
          <Grid item>
              <Typography variant="body2" className={classes.desktopMenuItem}>{profileDetails.user_email}</Typography>
          </Grid>
        </Grid>
      </MenuItem>

      <Divider />

      <MenuItem className={classes.desktopMenuItem}>
        {t("navbar.preferences-label")}
      </MenuItem>

      <MenuItem className={classes.desktopMenuItem}>
        {t("navbar.new-label")}
      </MenuItem>

      <MenuItem className={classes.desktopMenuItem}>
        {t("navbar.feedback-label")}
      </MenuItem>

      <MenuItem className={classes.desktopMenuItem}>
        {t("navbar.support-label")}
      </MenuItem>

      <MenuItem className={classes.desktopMenuItem}>
        <LanguageSelector />
      </MenuItem>

      <MenuItem onClick={handleLogOut} color="secondary" className={classes.desktopMenuItem}>
        {t("navbar.logout")}
      </MenuItem>
    </Popover>
  );
};

export default PopoverDesktop;
