import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Box, MenuItem, Grid, Chip, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { numberWithCommos } from "../../utils";
import { Formik } from "formik";
import * as yup from 'yup';
import { AttachFile } from "@material-ui/icons";
import DropzoneArea from "./DropzoneArea";
import DatePicker from "./DatePicker";

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(2),
        fontWeight: 600
    },

    titleStyle: {
        alignItems: 'baseline',
        justifyContent: 'space-between'
    },
}));

export default function DelegateDialog({
    title,
    content,
    leftButtonTitle,
    rightButtonTitle,
    onClickLeftButton,
    onClickRightButton,
    open,
    close,
    profileDelegates,
    setDelegateDetails
}) {

    const classes = useStyles();

    const { t } = useTranslation();

    const validationSchema = yup.object({
        order_delegate: yup
            .string()
            .required()
            .nullable(),
        order_delegate_id: yup
            .number()
            .nullable(),
        new_delegate: yup
            .object({
                first_name: yup
                    .string()
                    .nullable()
                    .matches('^[a-zA-Z]+$', t("validation.matches.name")),
                    //.required(t("validation.required.first-name")),
                last_name: yup
                    .string()
                    .nullable()
                    .matches('^[a-zA-Z]+$', t("validation.matches.name")),
                    //.required(t("validation.required.last-name")),
                delegation_document_content: yup
                    .string()
                    .nullable(),
                    //.required("Delegation Document is required"),
                identification_document_type: yup
                    .string()
                    .nullable(),
                    //.required(t("validation.required.document-type")),
                identification_document_number: yup
                    .string()
                    .nullable()
                    .when(["other_delegate_id", "other_delegate"], {
                        is: (other_delegate_id, other_delegate) => {
                            return (other_delegate == "Other" &&  other_delegate_id == -1)
                        },
                        then: yup
                            .string()
                            .nullable()
                            .required("Document Number is required")
                            .test('passport-test', t("validation.matches.passport-number"),
                                function(value){
                                    if(this.parent.identification_document_type == "passport"){
                                        const passportRegex = /^(?=.*?[A-Za-z])(?=.*?[0-9]).*$/;
                                        let isValidPassport = passportRegex.test(value);

                                        return isValidPassport
                                    }
                                    return true;
                                }
                            )
                            .test('id-test', t("validation.matches.id-number"),
                                function(value){
                                    if(this.parent.identification_document_type == "national_id"){
                                        const idRegex = /^[0-9]+$/;
                                        let isValidId = idRegex.test(value);

                                        return isValidId
                                    }
                                    return true;
                                }
                            )
                    }),
                identification_document_expiry_date: yup
                    .date()
                    .nullable()
                    .when('identification_document_type', {
                        is: "passport",
                        then: yup.date().nullable()
                        //.required(t("validation.required.document-expiry-date"))
                    }),
                identification_document_content: yup
                    .string()
                    .nullable(),
                    //.required("Identification Document is required"),
            })
    });

    const DelegationDocumentComponent = ({delegate, setFieldValue}) => {
        if(delegate.delegation_document_content){
            return (
                <Chip
                    icon={<AttachFile />}
                    variant='outlined'
                    label={"delegation_document"}
                    onDelete={() => setFieldValue("new_delegate.delegation_document_content", null)}
                />
            )
        }

        return (
            <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                handleFileChange={(file) => setFieldValue("new_delegate.delegation_document_content", file)}  
                documentName={"delegation_document"}
            />
        )
    };

    const IdentificationDocumentComponent = ({delegate, setFieldValue}) => {
        if(delegate.identification_document_content){
            return (
                <Chip
                    icon={<AttachFile />}
                    variant='outlined'
                    label={t("documents." + delegate.identification_document_type + ".label")}
                    onDelete={() => setFieldValue("new_delegate.identification_document_content", null)}
                />
            )
        }

        return (
            <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "application/pdf", ".docx", ".pptx"]}
                handleFileChange={(file) => setFieldValue("new_delegate.identification_document_content", file)}  
                documentName={t("documents." + delegate.identification_document_type + ".label")}
            />
        )
    };


    return (
        <Dialog
            open={open}
            onClose={close}
        >
            <DialogTitle>{title}</DialogTitle>
            <Formik
                initialValues={{
                    order_delegate: 'Myself',
                    order_delegate_id: null,
                    new_delegate: {
                        first_name: null,
                        last_name: null,
                        delegation_document_content: null,
                        identification_document_type: "national_id",
                        identification_document_number: null,
                        identification_document_expiry_date: null,
                        identification_document_content: null
                    }
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {setDelegateDetails({...values, order_delegate_id: values.order_delegate_id == -1 ? null : values.order_delegate_id}) ; onClickRightButton()}}
            >
                {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    values,
                    touched,
                    errors
                }) => (
                    <React.Fragment>        
                        <DialogContent>
                            <DialogContentText>
                            {content}
                            </DialogContentText>
                            <Box mt={3} />
                            
                            <Grid container direction="column" md={12} xs={12}>
                                <Grid container direction="row" md={12} xs={12}>
                                    <Grid item md={6} xs={12} style={{paddingRight: '1.7rem'}}>
                                        <TextField
                                            margin="normal"
                                            variant="outlined"
                                            select
                                            required
                                            fullWidth
                                            onChange={handleChange("order_delegate")}
                                            onBlur={handleBlur("order_delegate")}
                                            name="order_delegate"
                                            value={values.order_delegate}
                                            error={touched.order_delegate && Boolean(errors.order_delegate)}
                                            helperText={touched.order_delegate && errors.order_delegate}
                                        >
                                            <MenuItem value="Myself">Myself</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </TextField>
                                    </Grid>
                                
                                    {
                                        values.order_delegate == "Other" && profileDelegates.length > 0 &&
                                        <Grid item md={6} xs={12} style={{paddingLeft: '1.6rem'}}>
                                            <TextField
                                                margin="normal"
                                                variant="outlined"
                                                select
                                                fullWidth
                                                required
                                                label="Select Delegate"
                                                onChange={handleChange("order_delegate_id")}
                                                onBlur={handleBlur("order_delegate")}
                                                name="order_delegate_id"
                                                value={values.order_delegate_id}
                                                error={touched.order_delegate_id && Boolean(errors.order_delegate_id)}
                                                helperText={touched.order_delegate_id && errors.order_delegate_id}
                                            >
                                                {
                                                    profileDelegates.map((delegate) => {
                                                        return (
                                                            <MenuItem value={delegate.id}>{delegate.first_name + " " + delegate.last_name}</MenuItem>
                                                        )}
                                                    )
                                                }
                                                <MenuItem value={-1}>New Delegate</MenuItem>
                                            </TextField>
                                        </Grid>
                                        
                                    }

                                </Grid>
                                

                                {
                                    values.order_delegate == "Other" && (values.order_delegate_id == -1|| profileDelegates.length == 0) &&
                                    <React.Fragment>
                                        <Grid container direction="row" md={12} xs={12}>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    variant="outlined"
                                                    name="new_delegate.first_name"
                                                    value={values.new_delegate.first_name}
                                                    label="First Name"
                                                    onChange={handleChange("new_delegate.first_name")}
                                                    onBlur={handleBlur("new_delegate.first_name")}
                                                    error={touched.new_delegate && touched.new_delegate.first_name && errors.new_delegate && Boolean(errors.new_delegate.first_name)}
                                                    helperText={touched.new_delegate && touched.new_delegate.first_name && errors.new_delegate && errors.new_delegate.first_name}
                                                />
                                            </Grid>
                                            
                                            <Grid item md={6} xs={12} style={{textAlign: 'end'}}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    variant="outlined"
                                                    name="new_delegate.last_name"
                                                    value={values.new_delegate.last_name}
                                                    label="Last Name"
                                                    onChange={handleChange("new_delegate.last_name")}
                                                    onBlur={handleBlur("new_delegate.last_name")}
                                                    error={touched.new_delegate && touched.new_delegate.last_name && errors.new_delegate && Boolean(errors.new_delegate.last_name)}
                                                    helperText={touched.new_delegate && touched.new_delegate.last_name && errors.new_delegate && errors.new_delegate.last_name}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <Typography variant="h6" gutterBottom className={classes.title}>
                                                {t("documents.delegation_document.label")}
                                            </Typography>
                                            <DelegationDocumentComponent delegate={values.new_delegate} setFieldValue={setFieldValue}/>
                                        </Grid>

                                        <Box mt={3} />

                                        <Grid item md={12} xs={12}>
                                            <FormControl required component="fieldset">
                                                <FormLabel component="legend">{t("kyc-documents.type-label")}</FormLabel>
                                                <Box mt={1} />
                                                <RadioGroup 
                                                    row 
                                                    value={values.new_delegate.identification_document_type}
                                                    name="new_delegate.identification_document_type"
                                                    onChange={handleChange("new_delegate.identification_document_type")}
                                                >
                                                    <FormControlLabel
                                                        value="national_id" 
                                                        control={<Radio required checked={values.new_delegate.identification_document_type == "national_id"}/>}
                                                        label={t("documents.national_id.label")}
                                                    /> 
                                                    <FormControlLabel
                                                        value="passport" 
                                                        control={<Radio required checked={values.new_delegate.identification_document_type == "passport"}/>}
                                                        label={t("documents.passport.label")}
                                                    /> 
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid> 

                                        <Grid item md={12} xs={12}>
                                            <Typography variant="h6" gutterBottom className={classes.title}>
                                                {t("documents." + values.new_delegate.identification_document_type + ".label")}
                                            </Typography>
                                            <IdentificationDocumentComponent delegate={values.new_delegate} setFieldValue={setFieldValue}/>
                                        </Grid>

                                        <Box mt={3} />

                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                name="new_delegate.identification_document_number"
                                                label={t("kyc-documents.number-label")}
                                                value={values.new_delegate.identification_document_number}
                                                onChange={handleChange("new_delegate.identification_document_number")} 
                                                onBlur={handleBlur("new_delegate.identification_document_number")} 
                                                error={touched.new_delegate && touched.new_delegate.identification_document_number && errors.new_delegate && Boolean(errors.new_delegate.identification_document_number)}
                                                helperText={touched.new_delegate && touched.new_delegate.identification_document_number && errors.new_delegate && errors.new_delegate.identification_document_number}
                                            />
                                        </Grid>

                                        <Box mt={3} />

                                        {
                                            values.new_delegate.identification_document_type == "passport" &&

                                            <Grid container direction="row" md={12} xs={12}>
                                                <Grid item md={12} xs={12}>
                                                    <Typography variant="h6">{t("kyc-documents.date-label")}</Typography>
                                                </Grid>
                                                <DatePicker
                                                    value={values.new_delegate.identification_document_expiry_date}
                                                    handleDateChange={value => setFieldValue("new_delegate.identification_document_expiry_date", value)}
                                                    InputProps={{ 
                                                        readOnly: true,
                                                        required: true,
                                                        name: "new_delegate.identification_document_expiry_date",
                                                        error: touched.new_delegate && touched.new_delegate.identification_document_expiry_date && errors.new_delegate && Boolean(errors.new_delegate.identification_document_expiry_date),
                                                        helperText: touched.new_delegate && touched.new_delegate.identification_document_expiry_date && errors.new_delegate && errors.new_delegate.identification_document_expiry_date
                                                    }}
                                                    disablePast={true}
                                                    minDate={new Date()}
                                                />
                                            </Grid>
                                        }
                                    </React.Fragment>
                                }
                            </Grid>             
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClickLeftButton} color="primary">
                                {leftButtonTitle}
                            </Button>
                            <Button onClick={handleSubmit} type="submit" color="primary" autoFocus>
                                {rightButtonTitle}
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                )}
            </Formik>
        </Dialog>
    );
}
