import {
  Box,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import MatchActionButton from "./MatchActionButton";
import { ProposedAmount } from "./ProposedAmount";
import { ProposedRate } from "./ProposedRate";
import moment from "moment";
import CountdownTimer from "react-component-countdown-timer";
import "react-component-countdown-timer/lib/styles.css";
import { numberWithCommos, profileType } from "../../utils";
import { getUser } from "../../services/authService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  matchBoxSettings: {
    backgroundColor: "white",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "2rem",
    paddingBottom: "2rem",
    borderRadius: "0.5rem",
  },
  boxesSetting: {
    display: "inherit",
  },
  timeMessage: {
    textAlign: "center",
    color: "red",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "italic",
  }
}));

function Match({ match, orderDetails, openFailedNotification, openSuccessNotification }) {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const profile = getUser().profile_type;
  const { t } = useTranslation();

  const now = moment(new Date());
  const estimated = moment(match.estimation_time);
  const remainingTime = estimated.diff(now, 'seconds');

  const matchStatus = () => {
    if(profile == profileType.Organization && match.match_status == "Pending")
      return t("order-details-page.match-status-message.pending-org")
    if(profile == profileType.Organization && match.match_status == "Canceled")
      return t("order-details-page.match-status-message.canceled")
    if(profile == profileType.Organization && match.match_status == "Rejected")
      return t("order-details-page.match-status-message.rejected-org")
    if((profile == profileType.Individual || profile == profileType.Business) && match.match_status == "Pending")
      return t("order-details-page.match-status-message.pending-ind", {transaction_id: match.transaction_id})
    if((profile == profileType.Individual || profile == profileType.Business) && match.match_status == "Rejected")
      return t("order-details-page.match-status-message.rejected-ind")
  }

  const locateBranch = () => {
    return t("order-details-page.click-here")
  }

  const timeMessage = () => {
    if(match.match_status == "Pending")
      return ( 
        <React.Fragment>
          <Grid item xs={12}>
            <Box mt={2} />
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("order-details-page.remaining-time-label")}</Typography>
          </Grid>       
          <Grid item style={{textAlign: "center"}} >
            <CountdownTimer count={remainingTime > 0 ? remainingTime : 0} direction="right"  color="red" hideDay style={{textAlign: "center", justify: "center"}}/>
          </Grid>      
        </React.Fragment>
      )
  }

  return (
    <>
      <div className={classes.root}>
        <Box
          className={classes.matchBoxSettings}
          style={{
            opacity:  (match.match_status == "Canceled" || match.match_status == "Rejected" || match.match_status == "Done") && "41%" ,
          }}
        >
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item md={2} />

            <Grid item md={8}>
              <Grid container direction="row" justify="center">
                {isDesktop ? (
                  <Grid item className={classes.boxesSetting}>
                    <ProposedAmount match={match} orderDetails={orderDetails} />
                    <Box mr={2} />
                    <ProposedRate match={match} orderDetails={orderDetails} />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Box mt={2} />
                    </Grid>
                    <ProposedAmount match={match} orderDetails={orderDetails} />
                    <Grid item xs={12}>
                      <Box mt={2} />
                    </Grid>

                    <ProposedRate match={match} orderDetails={orderDetails} />
                    <Grid item xs={12}>
                      <Box mt={2} />
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item className={classes.timeMessage}>
                <Grid item xs={12}>
                  <Box mt={2} />
                </Grid>
                <Typography variant="h6" color={match.match_status == "Canceled" || match.match_status == "Rejected" ? "error" : "primary"}>
                  {matchStatus()}
                </Typography>
              </Grid>

              <Grid item className={classes.timeMessage}>
                {timeMessage()}
              </Grid>

                
            </Grid>


            <Grid item xs={12} md={2} lg={2}>
              <MatchActionButton
                match={match}
                orderDetails={orderDetails}
                openFailedNotification={openFailedNotification}
                openSuccessNotification={openSuccessNotification}
              />
            </Grid>
          </Grid>
        </Box>
      </div>

      <Box mb={2} />
    </>
  );
}

export default Match;
