import firebase from 'firebase/app';
import "firebase/messaging"
import {httpPost} from "./httpcommon";

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyAs8JebnS4jY0oxe4xyi43S0L5gFtrQOiE",
    authDomain: "cedarxneo.firebaseapp.com",
    projectId: "cedarxneo",
    storageBucket: "cedarxneo.appspot.com",
    messagingSenderId: "640410244735",
    appId: "1:640410244735:web:f6e932daf67176b6aed14a",
    measurementId: "G-0VWGZ33HMQ"
  });
}

export const askForPermissionToReceiveNotifications = async () => {
    try {
        const messaging = firebase.messaging();
        const token = await messaging.getToken({vapidKey: "BHaCcde7yN2qh-Y7AYlZzM60WfF7NWNm7YRQm9fcJxmnq7LrmmRqlX1NkjlwswzuzPTzinzzV-FHJbmVbiWYVZQ"});
        console.log('FCM TOKEN:', token);

        var data = {
          'token' : token
        }
        const responseInfo = await httpPost(
          "/add-fcm-token" , data
        )
    } catch (error) {
        console.error(error);
    }

}
