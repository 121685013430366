import { IconButton } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

const LogoImage = () => {
  const history = useHistory();
  return (
    <IconButton onClick={() => history.push("/")} style={{ backgroundColor: "transparent" }}>
      <img
        src="/images/cedarxneo-logo/cedarxneo-green-logo.png"
        style={{ width: "17rem" }}
      />
    </IconButton>
  );
};

export default LogoImage;
