import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Typography, Box } from "@material-ui/core";
import { formatDate, isArabic, parseServerDate, toArabicDigits  } from "../../../utils";
import { useTranslation } from 'react-i18next';

import commonStyles from "../../../commonStyles";
import moment from "moment";
import { useSelector } from "react-redux";
import { getCountries } from "../../../redux-reducer/data/dataSlice";
import { LoadingDisplay } from "../../common-ui-fit/LoadingDisplay";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2rem"
    },
    center: {
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
    },

  ...commonStyles,
}));

export default function OrganizationDetails({organizationDetails}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const countries = useSelector(getCountries);

    const getMainRepresentative = () => {
        let representative = organizationDetails.representatives.find((representative) => {
            if(representative.is_main_contact)
                return representative
        })
        return representative;
    }

    const getHOCountryName = () => {
        const country = countries.find((country) => country.id === organizationDetails.head_office_country_id);
        return isArabic() ? country.name_arabic : country.name;
    }

    const getCountryOfRegistrationName = () => {
        const country = countries.find((country) => country.id === organizationDetails.place_and_country_of_registration_id);
        return isArabic() ? country.name_arabic : country.name;
    }


    if(countries)
        return (
            <Paper elevation={0} className={classes.root}>
                <Grid container direction="column" md={12} xs={12}>           
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("company-identification-step.name-label")}
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {organizationDetails.name}
                            </Typography>
                        </Grid>
                    </Grid>                              
                                        
                    <Box mt={3} />
            
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("company-identification-step.date-label")}
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {isArabic() ? toArabicDigits(moment(new Date(organizationDetails.date_of_registration)).format("DD/MM/yyyy")) : moment(new Date(organizationDetails.date_of_registration)).format("DD/MM/yyyy")}
                            </Typography>
                        </Grid>
                    </Grid>                                   

                    <Box mt={3} />
                
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("company-identification-step.country-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {getCountryOfRegistrationName()}
                            </Typography>
                        </Grid>
                    </Grid>
            
                    <Box mt={3} />
        
                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("contact-details-step.email-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {organizationDetails.email}
                            </Typography>
                        </Grid>
                    </Grid>
        
                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("contact-details-step.country-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {getHOCountryName()} 
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("contact-details-step.address-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {organizationDetails.head_office_address}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("representatives-step.representative-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {getMainRepresentative().first_name + " " + getMainRepresentative().last_name}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("contact-details-step.phone-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                            {isArabic() ? toArabicDigits(countries.find((country) => country.code === "LB").phone + organizationDetails.telephone + "+") : countries.find((country) => country.code === "LB").phone + organizationDetails.telephone} 
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box mt={3} />

                    <Grid container direction="row" md={12} xs={12}>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="h6">
                                {t("contact-details-step.website-label")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.center}>
                            <Typography variant="body2">
                                {organizationDetails.website}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        )
    return <LoadingDisplay />
}
