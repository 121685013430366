import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import OrderDetailsSection from "../components/order-details/OrderDetailsSection";
import { NotificationSnakBar } from "../components/common-components/NotificationSnakBar";
import { LoadingDisplay } from "../components/common-ui-fit/LoadingDisplay";
import CancelMessageDialog from "../components/order-details/CancelMessageDialog";
import MatchList from "../components/order-details/MatchList";
import { httpGet, httpPut } from "../httpcommon";
import { useStylesCommon } from "../components/common-ui-fit/commonFields";
import MessageFab from "../components/common-components/MessageFab";
import { useTranslation } from 'react-i18next';
import { getUser } from "../services/authService";
import { numberWithCommos, profileType } from "../utils";
import InfoDialog from "../components/common-components/InfoDialog";
import { apis } from "../apis/apis";
import CountdownTimer from "react-component-countdown-timer";
import moment from "moment";
import { MapWrapper } from "../components/common-components/MapWrapper";
import MapDialog from "../components/common-components/MapDialog";


const useStyles = makeStyles((theme) => ({
  matchingHeaderSettings: {
    padding: "1rem",
  },
  pageSettings: {
    backgroundColor: "#f6f6f6",
    padding: "3rem",
    [theme.breakpoints.up("md")]: {
        paddingLeft: "3rem",
        paddingRight: "3rem",
      },
    [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    },

  },
}));

const OrderDetails = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useStylesCommon();
  const history = useHistory();
  let location = useLocation();
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState();
  const [matches, setMatches] = useState();
  const [error, setError] = useState(false);
  const [openSnakbar, setOpenSnakbar] = useState(false);
  const [snakMessage, setSnakMessage] = useState("");
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [cancelMessage, setCancelMessage] = useState("");
  const [openOrgDialog, setOpenOrgDialog] = useState(false);
  const [platformRate, setPlatformRate] = useState();
  const profile = getUser().profile_type;
  const [remainingTime, setRemainingTime] = useState()

  const [partnerBranches, setPartnerBranches] = useState()
  const [openMapDialog, setOpenMapDialog] = useState(false)

  useEffect(() => {
    (async () => {
      if (location.state.id) {
        let res = await apis.getOrderDetails(location.state.id);
        if (res.data.success) {
          let denormalized_type = res.data.order_type;
          if (res.data.order_type != res.data.normalized_type) {
            denormalized_type = res.data.normalized_type;
            setOrderDetails({
              ...res.data,
              denormalized_type: denormalized_type,
            });
            return;
          }

          setOrderDetails({
            ...res.data,
            denormalized_type: denormalized_type,
          });

          if(!res.data.request_money_before_matching && res.data.matching_status == "MATCHING FINISHED"){
            const now = moment(new Date());
            const estimated = moment(res.data.expiry_time);
            setRemainingTime(estimated.diff(now, 'seconds'));

            let partnerAllResult = await apis.getAllPartnerBranches();
            if(partnerAllResult.data.success)
              setPartnerBranches(partnerAllResult.data.branches)
          }

          if(res.data.request_money_before_matching && ((res.data.matching_status == "MATCHING FINISHED" && res.data.amount == res.data.amount_fulfilled) || res.data.approval_status == "DEPOSIT REQUESTED" )){
            let partnerResult = await apis.getPartnerMainBranches();
            if(partnerResult.data.success)
              setPartnerBranches(partnerResult.data.branches)
          }

        } else {
          history.push("/home");
          console.error(res.data.message);
        }
      }
    })();
  }, [remainingTime, openSnakbar]);

  useEffect(() => {
     (async () => {
      if (orderDetails) {
        try {
          var res;
          if (orderDetails.denormalized_type == "buy") {
            res = await apis.getBuyerMatches(orderDetails.masked_id);
          } else {
            res = await apis.getSellerMatches(orderDetails.masked_id);
          }
          let matchArray = res.data;
          matchArray = matchArray.map((match) => {
            if (orderDetails.denormalized_type != orderDetails.order_type) {
              [match.currency_1, match.currency_2] = [
                match.currency_2,
                match.currency_1,
              ];
            }
            return match;
          });
          setMatches(matchArray);
          setLoading(false);
        } catch (error) {
          console.error(error);
        }
      }
    })(); 
  }, [orderDetails, openSnakbar]);

  const getRateRules = async () => {
    const livePlatformRate = await apis.getLivePlatformRate("USD", "LBP");

    if (livePlatformRate.data.success) {
      setPlatformRate(livePlatformRate.data);
      return;
    }
    setSnakMessage(livePlatformRate.data.message);
    setError(true);
    setOpenSnakbar(true);
  };

  useEffect(() => {
    getRateRules();
  }, []);

  const openSuccessNotification = (message) => {
    setSnakMessage(message);
    setError(false);
    setOpenSnakbar(true);
  };

  const openFailedNotification = (message) => {
    setSnakMessage(message);
    setError(true);
    setOpenSnakbar(true);
  };


  const hasOngoingMatches = () => {
    for(var i = 0; i < matches.length; i++)
      if(matches[i].match_status == "Pending"){
        return true;
      }
    return false;
  }

  const handleExtendOrder = async () => {
    let res = await apis.extendOrderExpiryTime(orderDetails.masked_id);
    if(res.data.success){
      openSuccessNotification(res.data.message);
      return;
    }
    else{
      openFailedNotification(res.data.message);
      return;
    }
  }

  const handleSubmitCancelMessage = async (e) => {
    e.preventDefault();
    let data = {
      id: orderDetails.masked_id,
      reason: e.target.option.value,
      message: e.target.message ? e.target.message.value : null,
    };

    try {
      let res = await httpPut("/cancel-an-order", data);
      if (res.data.success) {
        openSuccessNotification(res.data.message);
        setOpenCancelDialog(false);
        if(orderDetails.request_money_before_matching && orderDetails.approval_status == "APPROVED")
          setOpenOrgDialog(true);
        return;
      }
      openFailedNotification(res.data.message);
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const cancelExpiredOrder = async () => {
    let data = {
      id: orderDetails.masked_id,
      reason: "Other",
      message: "Canceled By Cron",
    };

    try {
      let res = await httpPut("/cancel-an-order", data);
      if (res.data.success) {
        openSuccessNotification("Order has expired and has been canceled");
        return;
      }
      openFailedNotification(res.data.message);
      return;
    } catch (error) {
      console.error(error);
    }
  }

  const handleCancelOrder = () => {
    setOpenCancelDialog(true);
    return;
  };

  const handleCloseOrgDialog = () => {
    setOpenOrgDialog(false)
  }

  const getOrgDialogTitle = () => {
    if(matches.length > 0){
      if(hasOngoingMatches())
        return t("info-dialog.transfer-title");
      return t("info-dialog.transaction-title");
    }
    return t("info-dialog.retrieve-title");
  }

  const getOrgDialogContent = () => {
    if(matches.length > 0){
      if(hasOngoingMatches())
        return t("info-dialog.canceled-transfer-content");
      return t("info-dialog.transaction-content", {transaction_id: orderDetails.transaction_id_post_match});
    }
    return t("info-dialog.retrieve-content", {transaction_id: orderDetails.transaction_id_post_match});
  }

  const handleOpenMap = () => {
    setOpenMapDialog(true)
  }

  const handleCloseMap = () => {
    setOpenMapDialog(false)
  }

  const MapButton = () => {
    if(partnerBranches)
      return (
        <Grid item md={12} xs={12} style={{justifyContent: "center", textAlign: "center"}}>
          <Button color="primary" onClick={handleOpenMap}>
            {t("order-details-page.click-here")}
          </Button>
        </Grid>
      )
    else 
        return <></>
  }

  const AmountDetails = () => {
    if(orderDetails.order_type == "buy"){
      if(orderDetails.use_platform_rate)
        return (
          <React.Fragment>
            <Typography variant="h6" color="primary" style={{textAlign: "center", fontWeight: "bold"}}>
              {t("order-details-page.sell-amount-message", {amount: getActualAmount()})}
            </Typography>
            <Typography variant="h6" color="primary" style={{textAlign: "center", fontWeight: "bold"}}>
              {t("order-details-page.partner-amount-message", {amount: getPartnerAmount()})}
            </Typography>
            <Typography variant="h6" color="error" style={{textAlign: "center" }}>
              {t("order-details-page.rate-fluctuation-message")}
            </Typography>
          </React.Fragment>
      )
      return (
        <Typography variant="h6" color="primary" style={{textAlign: "center", fontWeight: "bold"}}>
            {t("order-details-page.partner-amount-message", {amount: getPartnerAmount()})}
        </Typography>
      )
    }

    if(orderDetails.order_type == "sell")
      return (
        <Typography variant="h6" color="primary" style={{textAlign: "center", fontWeight: "bold"}}>
          {t("order-details-page.partner-amount-message", {amount: numberWithCommos(orderDetails.amount) + orderDetails.base_currency})}
        </Typography>
      )
  }

  const getPartnerAmount = () => {
      if(orderDetails.base_currency == platformRate.base_currency){
        return numberWithCommos(orderDetails.amount * orderDetails.rate) + orderDetails.quote_currency
      }
      return numberWithCommos(orderDetails.amount / orderDetails.rate) + orderDetails.quote_currency
  }

  const getActualAmount = () => {
      if(orderDetails.base_currency == platformRate.base_currency)
        return numberWithCommos(orderDetails.amount * platformRate.buy_rate) + orderDetails.quote_currency
      return numberWithCommos(orderDetails.amount / platformRate.sell_rate) + orderDetails.quote_currency
  }

  const getOrderMessage = () => {
    if((orderDetails.matching_status == "COMPLETED" && !orderDetails.canceled) || orderDetails.approval_status == "SOURCE OF FUND PROVIDED" )
        return <></>
    if(orderDetails.approval_status == "DECLINED")
      return (
        <Grid item md={12} xs={12} style={{justifyContent: "center"}}>
          <Typography variant="h6" color="error" style={{textAlign: "center", fontWeight: "bold"}}>{orderDetails.fund_reason}</Typography>
        </Grid>
      )
    if(orderDetails.request_money_before_matching){
      if(orderDetails.canceled){
        if(orderDetails.approval_status == "DEPOSIT REQUESTED" || orderDetails.is_transaction_post_match_settled)
            return <React.Fragment></React.Fragment>

        if(!orderDetails.is_transaction_post_match_settled){
          if(matches.length > 0){
            if(hasOngoingMatches())
              return (
                <Grid item md={12} xs={12} style={{justifyContent: "center"}}>
                  <Typography variant="h6" color="secondary" style={{textAlign: "center", fontWeight: "bold"}}>{t("info-dialog.canceled-transfer-content")}</Typography>
                </Grid>
              )
            return (
              <React.Fragment>
                <Grid item md={12} xs={12} style={{justifyContent: "center"}}>
                  <Typography variant="h6" color="secondary" style={{textAlign: "center", fontWeight: "bold"}}>{t("info-dialog.transaction-content", {transaction_id: orderDetails.transaction_id_post_match})}</Typography>
                </Grid>
                <MapButton />
              </React.Fragment>
            )
          }
          return (
            <React.Fragment>
              <Grid item md={12} xs={12} style={{justifyContent: "center"}}>
                <Typography variant="h6" color="secondary" style={{textAlign: "center", fontWeight: "bold"}}>{t("info-dialog.retrieve-content", {transaction_id: orderDetails.transaction_id_post_match})}</Typography>
              </Grid>
              <MapButton />
            </React.Fragment>
          )
        }
      }

      if(orderDetails.approval_status == "DEPOSIT REQUESTED")
        return (
          <React.Fragment>
            <Grid item md={12} xs={12} style={{justifyContent: "center"}}>
              <Typography variant="h6" color="secondary" style={{textAlign: "center", fontWeight: "bold"}}>{t("order-details-page.order-deposit", {transaction_id: orderDetails.transaction_id_before_match})}</Typography>
              <AmountDetails />
            </Grid>
            <MapButton />    
          </React.Fragment>
        )

      if(orderDetails.matching_status == "MATCHING FINISHED" && !orderDetails.is_transaction_post_match_settled){
        if(hasOngoingMatches())
          return (
            <Grid item md={12} xs={12} style={{justifyContent: "center"}}>
              <Typography variant="h6" color="secondary" style={{textAlign: "center", fontWeight: "bold"}}>{t("info-dialog.transfer-content")}</Typography>
            </Grid>
          )
        
        return (
          <React.Fragment>
            <Grid item md={12} xs={12} style={{justifyContent: "center"}}>
              <Typography variant="h6" color="secondary" style={{textAlign: "center", fontWeight: "bold"}}>{t("info-dialog.transaction-content", {transaction_id: orderDetails.transaction_id_post_match})}</Typography>
            </Grid>
            <MapButton />
          </React.Fragment>
        )
      }
    }
    else {
      if(orderDetails.matching_status == "MATCHING FINISHED" && !orderDetails.is_transaction_post_match_settled){
        return (
          <React.Fragment>
            <Grid item md={12} xs={12} style={{justifyContent: "center"}}>
              <Typography variant="h6" color="secondary" style={{textAlign: "center", fontWeight: "bold"}}>{t("info-dialog.transaction-content", {transaction_id: orderDetails.transaction_id_post_match})}</Typography>
              <AmountDetails />
            </Grid>
            <MapButton />
          </React.Fragment>
        )
      }
      return <React.Fragment></React.Fragment>
    }

    return (
      <Grid item md={12} xs={12}>
        <Grid container direction="row" md={12} xs={12} spacing={1} style={{justifyContent: "center"}}>
          <Grid item>
            <img src="/images/rotating-arrow.svg" />
          </Grid>
          <Grid item>
            <Typography variant="h5" >{t("order-status.in-progress")}</Typography>
          </Grid>
        </Grid>
      </Grid>
    ) 
  }

  const getOrderAverageRate = () => {
    if(!orderDetails.use_platform_rate && (orderDetails.matching_status == "MATCHING FINISHED" || (orderDetails.canceled && matches.length > 0))){
      return (
        <Grid item md={12} xs={12} style={{justifyContent: "center"}}>
          <Typography variant="h6" style={{textAlign: "center", fontWeight: "bold"}}>{t("order-details-page.acquired-rate-label", {rate: numberWithCommos(orderDetails.average_rate)})}</Typography>
        </Grid>
      )
    }
    return <React.Fragment></React.Fragment>
  }

  if (loading) {
    return (
      <>
        <div className={classes.pageSettings}>
          <Grid
            container
            direction="column"
            className={classes.detailsSettings}
          >
            <Grid item>
              <Box mt={10} />
            </Grid>
            <Grid item>
              <LoadingDisplay heigth={"15rem"} />
            </Grid>
            <Grid item>
              <Box mt={7} />
            </Grid>
            <Grid item>
              <LoadingDisplay heigth={"30rem"} />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }

  if(matches && platformRate)
    return (
      <>
          <Grid container className={classes.pageSettings} justify="space-between" >
              <Grid item md={12} xs={12} className={commonClasses.itemSettings}>
                  <OrderDetailsSection orderDetails={orderDetails} matches={matches} handleCancelOrder={handleCancelOrder} platformRate={platformRate}/>
              </Grid>

              <Grid item md={12} xs={12}>
                <Box mt={3} />
              </Grid>

              {getOrderMessage()}

              {
                !orderDetails.request_money_before_matching && orderDetails.matching_status == "MATCHING FINISHED" && remainingTime > 0 &&
                  <Grid container md={12} xs={12} style={{justifyContent: "center", textAlign: "center"}}>
                    <Grid item md={12} xs={12}>
                      <Box mt={2} />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography>{t("order-details-page.remaining-time-label")}</Typography>
                    </Grid>       
                    <Grid item style={{textAlign: "center"}} >
                      <CountdownTimer 
                        count={remainingTime > 0 ? remainingTime : 0}
                        direction="right"  
                        color="red" 
                        hideDay 
                        onEnd={cancelExpiredOrder}
                      />
                    </Grid>      

                  {
                    remainingTime < 1200 && remainingTime > 0 && !orderDetails.extension_requested &&
                    <React.Fragment>
                      <Grid item md={12} xs={12}>
                        <Box mt={3} />
                      </Grid>
                    
                      <Grid item md={12} xs={12}>
                        <Button variant="contained" color="primary" onClick={handleExtendOrder}>
                          {t("button.time-extend")} 
                        </Button>
                      </Grid>
                    </React.Fragment>
                  }
                  </Grid>
              }

              <Grid item md={12} xs={12}>
                <Box mt={3} />
              </Grid>

              {getOrderAverageRate()}

              <Grid item md={12} xs={12}>
                <Box mt={3} />
              </Grid>            
          </Grid>

          <MessageFab />

          <NotificationSnakBar
            openSnakbar={openSnakbar}
            setOpenSnakbar={setOpenSnakbar}
            snakMessage={snakMessage}
            error={error}
          />

          <CancelMessageDialog
            handleSubmitCancelMessage={handleSubmitCancelMessage}
            openCancelDialog={openCancelDialog}
            setOpenCancelDialog={setOpenCancelDialog}
            setCancelMessage={setCancelMessage}
            cancelMessage={cancelMessage}
          />

          <InfoDialog
            open={openOrgDialog}
            onClose={handleCloseOrgDialog}
            title={getOrgDialogTitle()}
            subtitle={getOrgDialogContent()}
          />

          <MapDialog 
            open={openMapDialog}
            onClose={handleCloseMap}
            partnerBranches={partnerBranches}
          />
      </>
    );
  return <LoadingDisplay />
};

export default OrderDetails;
