import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from "react-redux";
import { getCountries, getOccupationCodes } from "../../../redux-reducer/data/dataSlice";
import { MenuItem, Tooltip } from "@material-ui/core";
import { countryToFlag, hasFieldError, getFieldError, isArabic, isEditStepper, toArabicDigits } from "../../../utils";
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
    input: {
        marginTop: "1rem"
    },
    inputContainer: {
        alignItems: "center"
    }
}));

export default function Activity(props) {
    const formikProps = props.formikProps;

    const {
        formField: {
            occupations,
            employees,
            interactionCountries,
            activity
        }
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const countries = useSelector(getCountries);

    const occupationCodes = useSelector(getOccupationCodes);

    const numberOfEmployees = [
        "0 – 19",
        "20 – 49",
        "50 – 99",
        "100 – 199",
        "200 – 499",
        "500 – 999",
        "1000+"
    ]
   
    return (
        <React.Fragment>
            <Typography variant="h4" style={{ lineHeight: 1.33, textAlign: "center" }}>
                {t("stepper.kyb.step2")}
            </Typography>
                
            <Box mt={5} /> 
            
            <Grid container direction="row" md={12} xs={12} >
                <Grid container direction="row" md={5} xs={12} className={classes.inputContainer}>
                    <Grid item md={11} xs={11} className={classes.input}>
                        <Autocomplete
                            multiple
                            options={occupationCodes}
                            getOptionLabel={(option) => isArabic() ? option.name_arabic : option.name}
                            filterSelectedOptions
                            value={props.getOptionById(occupationCodes, formikProps.values[occupations.name])}
                            onChange={props.setMultipleOptionsAttribute(formikProps.setFieldValue, occupations.name)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    name={occupations.name}
                                    label={occupations.label}
                                    onBlur={formikProps.handleBlur(occupations.name)} 
                                    error={(formikProps.touched[occupations.name] && Boolean(formikProps.errors[occupations.name])) || (isEditStepper() && JSON.stringify(formikProps.values[occupations.name]) == JSON.stringify(props.organizationDetails[occupations.name]) && hasFieldError(props.organizationDetails, occupations.name))}
                                    helperText={(formikProps.touched[occupations.name] && formikProps.errors[occupations.name]) || (JSON.stringify(formikProps.values[occupations.name]) == JSON.stringify(props.organizationDetails[occupations.name]) && getFieldError(props.organizationDetails, occupations.name))}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1} xs={1} className={classes.input} style={{textAlign: "end"}}>
                        <Tooltip title={t("activity-step.occupation-desc")}>
                            <HelpIcon color="secondary" fontSize= "small" />
                        </Tooltip>
                    </Grid>
                </Grid>
                

                <Grid item md={2} xs={12} />

                <Grid container direction="row" md={5} xs={12} className={classes.inputContainer}>
                    <Grid item md={11} xs={11} className={classes.input}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            select
                            name={employees.name}
                            label={employees.label}
                            value={formikProps.values[employees.name]}
                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                            onBlur={formikProps.handleBlur(employees.name)} 
                            error={(formikProps.touched[employees.name] && Boolean(formikProps.errors[employees.name])) || (isEditStepper() && formikProps.values[employees.name] == props.organizationDetails[employees.name] && hasFieldError(props.organizationDetails, employees.name))}
                            helperText={(formikProps.touched[employees.name] && formikProps.errors[employees.name]) || (formikProps.values[employees.name] == props.organizationDetails[employees.name] && getFieldError(props.organizationDetails, employees.name))}
                        >
                            {
                                numberOfEmployees.map((number) => {
                                    return (
                                        <MenuItem value={number}>{isArabic() ? toArabicDigits(number) : number}</MenuItem>
                                    )}
                                )
                            }
                        </TextField>
                    </Grid>
                    <Grid item md={1} xs={1} />
                </Grid>
                         
                <Grid container direction="row" md={5} xs={12} className={classes.inputContainer}>
                    <Grid item md={11} xs={11} className={classes.input}>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            options={countries}
                            value={props.getOptionById(countries, formikProps.values[interactionCountries.name])}
                            onChange={props.setMultipleOptionsAttribute(formikProps.setFieldValue, interactionCountries.name)}
                            getOptionLabel={
                                (option) => isArabic() ? countryToFlag(option.code) + " " + option.name_arabic : countryToFlag(option.code) + " " + option.name 
                            }
                            renderOption={(option) => (
                                <React.Fragment>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                        style={{marginRight: '1rem'}}
                                    />
                                    {isArabic() ? option.name_arabic : option.name} 
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    name={interactionCountries.name}
                                    label={interactionCountries.label}
                                    onBlur={formikProps.handleBlur(interactionCountries.name)} 
                                    error={(formikProps.touched[interactionCountries.name] && Boolean(formikProps.errors[interactionCountries.name])) || (isEditStepper() && JSON.stringify(formikProps.values[interactionCountries.name]) == JSON.stringify(props.organizationDetails[interactionCountries.name]) && hasFieldError(props.organizationDetails, interactionCountries.name))}
                                    helperText={(formikProps.touched[interactionCountries.name] && formikProps.errors[interactionCountries.name]) || (JSON.stringify(formikProps.values[interactionCountries.name]) == JSON.stringify(props.organizationDetails[interactionCountries.name]) && getFieldError(props.organizationDetails, interactionCountries.name))}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1} xs={1} className={classes.input} style={{textAlign: "end"}}>
                        <Tooltip title={t("activity-step.countries-desc")}>
                            <HelpIcon color="secondary" fontSize= "small" />
                        </Tooltip>
                    </Grid>
                </Grid>

                <Grid item md={2} xs={12} />

                <Grid container direction="row" md={5} xs={12} className={classes.inputContainer}>
                    <Grid item md={11} xs={11} className={classes.input}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            name={activity.name}
                            label={activity.label}
                            value={formikProps.values[activity.name]}
                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                            onBlur={formikProps.handleBlur(activity.name)} 
                            error={(formikProps.touched[activity.name] && Boolean(formikProps.errors[activity.name])) || (isEditStepper() && formikProps.values[activity.name] == props.organizationDetails[activity.name] && hasFieldError(props.organizationDetails, activity.name))}
                            helperText={(formikProps.touched[activity.name] && formikProps.errors[activity.name]) || (formikProps.values[activity.name] == props.organizationDetails[activity.name] && getFieldError(props.organizationDetails, activity.name))}
                        />
                    </Grid>
                    <Grid item md={1} xs={1} className={classes.input} style={{textAlign: "end"}}>
                        <Tooltip title={t("activity-step.activity-desc")}>
                            <HelpIcon color="secondary" fontSize= "small" />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>       
        </React.Fragment>
    );
}
