import React from "react";
import { useSelector } from "react-redux";
import { LoadingDisplay } from "../components/common-ui-fit/LoadingDisplay";
import { getProfileType } from "../redux-reducer/profileType/profileTypeSlice" 
import { profileType } from "../utils";
import OrganizationHomepage from "../components/homepage/organization/OrganizationHomepage";
import BusinessHomepage from "../components/homepage/business/BusinessHomepage";
import IndividualHomepage from "../components/homepage/individual/IndividualHomepage";
import { makeStyles } from "@material-ui/core";

export default function Homepage() {
  const profile = useSelector(getProfileType);
  const classes = useStyles();

  if(!profile)
    return <LoadingDisplay />

  if(profile == profileType.Organization)
    return <OrganizationHomepage classes={classes} /> 
  
  if(profile == profileType.Business)
    return <BusinessHomepage classes={classes} />
  
    if(profile == profileType.Individual)
    return <IndividualHomepage classes={classes} /> 
}

const useStyles = makeStyles((theme) => ({
  homepageSettings: {
    backgroundColor: "#f6f6f6",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },

    paddingTop: "3rem",
  },
  itemSettings: {
    borderRadius: "7px",
    boxShadow: "0 0 16px 0 rgba(92, 92, 92, 0.06)",
    border: "solid 1px #f6f6f6",
    backgroundColor: "white",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    paddingTop: "1.6rem",
    paddingBottom: "1.6rem",
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
