import React, { useEffect } from "react";
import { LoadingDisplay } from "../components/common-ui-fit/LoadingDisplay";
import { profileType } from "../utils";
import OrganizationStepper from "../components/signup/organization/Stepper";
import { getUser } from "../services/authService";
import BusinessStepper from "../components/signup/business/Stepper";
import IndividualStepper from "../components/signup/individual/Stepper";

export default function EditProfile() {
    const profile = getUser().profile_type;

    if(!profile)
        return <LoadingDisplay />

    if(profile == profileType.Organization){
        return <OrganizationStepper /> 
    }

    if(profile == profileType.Business){
        return <BusinessStepper /> 
    }

    if(profile == profileType.Individual){
        return <IndividualStepper /> 
    }


}
