import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    featuresContainer: {
      width: '100%',
      justifyContent: 'center',
      padding: '5rem',
      [theme.breakpoints.down("md")]: {
        paddingLeft: '3rem',
        paddingRight: '3rem'
      },
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 84, 84, 0.6)',
    },
    featuresTitle: {
      textAlign: 'center',
      [theme.breakpoints.down("md")]: {
        fontSize: '1.5rem'
      },
    },
    featureContainer: {
        marginBottom: '2rem'
    },
    featureDesc: {
        textAlign: 'center',
        padding: '1rem',
        [theme.breakpoints.down("md")]: {
            padding: 0
        },
    },
    featureTitle: {
        fontWeight: 700
    },
    featureImgContainer: {
        textAlign: "center"
    },
    featureImage: {
        [theme.breakpoints.down('md')]: {
            width: "70%"
        },
        "&:hover": {
            animation: "$pulse 1s infinite",
        }
    },
    "@keyframes pulse": {
        "0%": { 
            transform: "scale(1)"
        },
        "50%": { 
            transform: "scale(1.1)"
        },
        "100%": { 
            transform: "scale(1)" 
        }
    },
    ctaContainer: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center",
        padding: '2rem',
        [theme.breakpoints.down("md")]: {
            paddingLeft: '3rem',
            paddingRight: '3rem'
        },
    },
    ctaTitle: {
        color: 'white',
        fontWeight: 700
    }
}));

const ProductFeature = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
        <Grid container className={classes.featuresContainer}>
            <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                color="#fff"
            >
                <Typography variant="h4" color="primary" align='center' className={classes.Featurestitle}>
                    {t("landing-page.section2.title")}
                </Typography>

                <Box mt={5} />

                <Grid container direction="row" md={12} xs={12} justify="space-between">
                    <Grid item md={3} xs={12} className={classes.featureContainer}>
                        <Grid  container direction="column" md={12} xs={12} spacing={1} alignItems="center">
                            <Grid item className={classes.featureImgContainer}>
                                <img src="/images/landingpage/rate.png" className={classes.featureImage}/>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant="h6" color="primary" className={classes.featureTitle}>
                                    {t("landing-page.section2.feature1.title")}
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant="subtitle" paragraph color="primary" className={classes.featureDesc}>
                                    {t("landing-page.section2.feature1.subtitle")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={3} xs={12} className={classes.featureContainer}>
                        <Grid  container direction="column" md={12} xs={12} spacing={1} alignItems="center">
                            <Grid item className={classes.featureImgContainer}>
                                <img src="/images/landingpage/matching.png" className={classes.featureImage}/>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant="h6" color="primary" className={classes.featureTitle}>
                                    {t("landing-page.section2.feature2.title")}
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant="subtitle" paragraph color="primary" className={classes.featureDesc}>
                                    {t("landing-page.section2.feature2.subtitle")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={3} xs={12} className={classes.featureContainer}>
                        <Grid  container direction="column" md={12} xs={12} spacing={1} alignItems="center">
                            <Grid item className={classes.featureImgContainer}>
                                <img src="/images/landingpage/safe.png" className={classes.featureImage}/>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant="h6" color="primary" className={classes.featureTitle}>
                                    {t("landing-page.section2.feature3.title")}
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant="subtitle" paragraph color="primary" className={classes.featureDesc}>
                                    {t("landing-page.section2.feature3.subtitle")}      
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={3} xs={12}>
                        <Grid  container direction="column" md={12} xs={12} spacing={1} alignItems="center">
                            <Grid item className={classes.featureImgContainer}>
                                <img src="/images/landingpage/delivery.png" className={classes.featureImage}/>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant="h6" color="primary" className={classes.featureTitle}>
                                    {t("landing-page.section2.feature4.title")}
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant="subtitle" paragraph color="primary" className={classes.featureDesc}>
                                    {t("landing-page.section2.feature4.subtitle")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Grid>

        <Grid container className={classes.ctaContainer} direction="column" md={12} xs={12} justifyContent="center" alignContent="center">
            <Grid item md={12} xs={12}>
                <Typography variant="h6" className={classes.ctaTitle}>
                    {t("landing-page.section3.title")}
                </Typography>
            </Grid>

            <Grid item>
                <Box mt={5} />
            </Grid>

            <Grid item  md={12} xs={12}>
                <Button color="secondary" variant="contained" href='/home'>
                    {t("button.place-order")} 
                </Button>
            </Grid>

        </Grid>
        </>
    )
}

export default ProductFeature;