import {makeStyles} from "@material-ui/core/styles";

export default {
  inputLabel: {
    color: "#d8d8d8",
    fontSize: "1rem",
  },
  subtitle1: {
    fontSize: "1rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.14,
    color: "#757575",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  whitecolor: {
    color: "#FFFFFF",
  },
  linkDecoration: {
    textDecoration: "underline",
  },
  numbertextfield:{
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
  },
 
  },
  bold:{
    fontWeight: 700
  },
  semibold:{
    fontWeight:600
  },
  medium:{
    fontWeight:500
  },
  regular:{
    fontWeight:400
  },
  mobileVersionFontH1:{
   
    fontSize: "1.7rem",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "30px",
    color: "#FFFFFF",
  
},
mobileVersionsubtitle2: {
  fontSize: "0.9rem",
  fontWeight: 400,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1,
  color: "#FFFFFF",
},
  
};

export const globalClasses = makeStyles((theme)=>({
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  success:{
    backgroundColor:theme.palette.success.main,
    color: "white"
  }
}))