import React from "react";
import { Grid,  makeStyles, Typography } from "@material-ui/core";
import { isArabic, numberWithCommos, parseNumber, toArabicDigits } from "../../utils.js";
import { useStylesCommon } from "../common-ui-fit/commonFields";

const useStyles = makeStyles((theme) => ({
    amountFontSettings: {
      fontSize: "2.4rem",
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        lineHeight: 1,
        fontSize: "1.4rem"
      },
      [theme.breakpoints.up("md")]: {
        lineHeight: "0",
      },
    },
}));

export default function CustomizedRateBox ({ title, orderDetails, rate}) {
    const classes = useStyles();
    const commonClasses = useStylesCommon();

    return (
        <> 
            <Typography variant="h6" color="secondary">
                {title}
            </Typography>

            <Grid container direction="row" alignItems="center" spacing={1} style={{justifyContent: "center", textAlign: "center"}}>
                <Grid item md={4} xs={12}>
                    <Typography className={classes.amountFontSettings}>
                        {numberWithCommos(parseNumber(rate))}
                    </Typography>
                </Grid>
                
                <Grid item md={8} xs={12}>
                    <Grid container direction="row" style={{justifyContent: "center"}}>
                        <img
                            src={orderDetails.platform_base_currency_flag}
                            className={commonClasses.flagSettings}
                        />
                
                        <Typography variant="h5" style={{alignSelf: "center"}}>
                            {orderDetails.currency_1}
                        </Typography>
                    
                        <Typography variant="h5" style={{alignSelf: "center"}}>-</Typography>
                    
                        <Typography variant="h5" style={{alignSelf: "center"}}>
                            {orderDetails.currency_2}
                        </Typography>
                    
                        <img
                            src={orderDetails.platform_quote_currency_flag}
                            className={commonClasses.flagSettings}
                        />
                    </Grid>
                </Grid>    
            </Grid>
        </>
    )
}