import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import commonStyles from "./commonStyles";
import NavigationBarWithNoteBar from "./components/NavigationBar/NavigationBarWithNoteBar";
import MiddleWareRoute from "./routes/MiddleWareRoute";
import ResetPassword from "./pages/ResetPassword.js";
import EmailRegistration from "./components/signup/EmailRegistration";
import EmailVerified from "./components/signup/EmailVerified";
import Homepage from "./pages/Homepage";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import ForgotPassword from "./pages/ForgotPassword";
import OrderDetails from "./pages/OrderDetails";
import { PrivateOrderRoute } from "./routes/PrivateOrderRoute";
import { PrivateRoute } from "./routes/PrivateRoute";
import { isArabic } from "./utils";
import SignUp from "./pages/SignUp";
import EditProfile from "./pages/EditProfile";
import BoB from "./pages/BoB";

export const colors = {
  primary: {
    main: "#005454",
  },
  secondary: {
    main: "#25dac5",
  },
  neutral: {
    main: "#D1DADD",
  },
  black: {
    main: "#0B162B",
  },
  error: {
    main: "#ED2B44",
  },
  success: {
    main: "#2BED63",
  },
  warning: {
    main: "#EDBC2B",
  },
  info: {
    main: "#0088FF",
  },
  white: {
    main: "#FFFFFF",
  },
};

const globalWeight = {
  bold: 700,
  semibold: 600,
  medium: 500,
  regular: 400,
};

const defaultTheme = createMuiTheme()

export const theme = createMuiTheme({
  direction: isArabic() ? "rtl" : "ltr",
  palette: colors,
  typography: {
    fontFamily: "Montserrat",
    h6: {
      fontSize: "1rem",
      fontWeight: globalWeight.medium,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.71,
    },
    h5: {
      fontSize: "1.3rem",
      fontWeight: globalWeight.semibold,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1rem'
      }
    },
    h4: {
      fontSize: "2rem",
      fontWeight: globalWeight.bold,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.5rem'
      }
    },
    h3: {
      fontSize: "3rem",
      fontWeight: globalWeight.bold,
      lineHeight: 1.33,
      fontStretch: "normal",
      letterSpacing: "normal",
      color: "black",
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '2rem'
      }
    },
    h2: {
      fontSize: "4rem",
      fontWeight: globalWeight.bold,
      lineHeight: 1.25,
      fontStretch: "normal",
      letterSpacing: "normal",
      color: "black",
    },
    h1: {
      fontSize: "4.5rem",
      fontWeight: globalWeight.semibold,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "77px",
      color: "#FFFFFF",
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
    },
    subtitle2: {
      fontSize: "1.57rem",
      fontWeight: globalWeight.regular,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.636,
      color: "#FFFFFF",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiInputLabel: {
      // Name of the rule
      root: {
        // Some CSS
        ...commonStyles.inputLabel,
      },
    },
    MuiButton: {
      // Name of the rule

      label: {
        fontSize: "1rem",
        fontWeight: globalWeight.bold,
        textTransform: "none",
      },
      containedSecondary: {
        color: "white",
      },
    },
    MuiDropzoneArea: {
      root: {
        minHeight: "0",
        height: "5rem",
      },
      textContainer: {
        textAlign: "center",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "center",
      },
      icon: {
        width: "30px",
        marginTop: "0.5rem",
        color: colors.secondary.main,
      },
    },

    MuiMenuItem: {
      root: {
        "&:hover": {
          backgroundColor: colors.secondary.main,
        },
        "&.Mui-selected": {
          backgroundColor: colors.secondary.main,
        },
      },
    },
    MuiSelect: {
      outlined: {
        borderColor: colors.secondary.main,
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "red",
      },
    },

    MuiTextField: {
      root: {
        "& label.Mui-focused": {
          color: colors.secondary.main,
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.error.main,
          },

          "&.Mui-focused fieldset": {
            borderColor: colors.secondary.main,
            backgroundColor: "rgba(37, 218, 197, 0.05)",
          },
        },
      },
    },
    MuiFormControl: {
      root: {
        "& label.Mui-focused": {
          color: colors.secondary.main,
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.error.main,
          },

          "&.Mui-focused fieldset": {
            borderColor: colors.secondary.main,
            backgroundColor: "rgba(37, 218, 197, 0.05)",
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        "& label.Mui-focused": {
          color: colors.secondary.main,
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.error.main,
          },

          "&.Mui-focused fieldset": {
            borderColor: colors.secondary.main,
            backgroundColor: "rgba(37, 218, 197, 0.05)",
          },
        },
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: "0",
      },
    },
    MuiAccordion: {
      root: {
        "&.Mui-disabled": {
          opacity: "50%",
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        width: "100%",
      },
    },
    MuiAvatar: {
      img: {
        objectFit: "contain"
      }
    },
    MuiStepIcon: {
      root: {
        '&$active': {
          color: colors.secondary.main,
        },
        '&$completed': {
          color: colors.secondary.main,
        },
      }
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div dir={isArabic() ? "rtl" : "ltr"}>
        <Router>
          <Route path="/" exact>
            <LandingPage />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/registration" exact>
            <EmailRegistration />
          </Route>
          <Route path="/public/register/:id/:activationkey" exact>
            <SignUp />
          </Route>
          <Route path="/public/resetpassword/:id/:activationkey" exact>
            <ResetPassword />
          </Route>
          <Route path="/verification/:id/:activationkey" exact>
            <EmailVerified />
          </Route>
          <Route path="/redirect" exact>
            <MiddleWareRoute />
          </Route>
          <PrivateRoute path="/home" exact>
            <NavigationBarWithNoteBar />
            <Homepage />
          </PrivateRoute>
          <PrivateRoute path="/profile" exact>
            <NavigationBarWithNoteBar />
            <Profile />
          </PrivateRoute>
          <PrivateRoute path="/profile/edit" exact>
            <NavigationBarWithNoteBar />
            <EditProfile />
          </PrivateRoute>
          <PrivateOrderRoute path="/order-details" exact>
            <NavigationBarWithNoteBar />
            <OrderDetails />
          </PrivateOrderRoute>
          <Route path="/bob" exact>
            <BoB />
          </Route>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
