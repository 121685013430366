import React, { useState } from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { colors } from "../../App";

const useStyles = makeStyles((theme) => ({
  cancelIcon: {
    color: colors.error.main,
    fontSize: 50,
  },
  canceledButton: {
    backgroundColor: colors.error.main,
    color: "black",
  },
  doneIcon: {
    color: colors.success.main,
    fontSize: 50,
  },
  grayIcon: {
    color: colors.neutral.main,
    fontSize: 50,
    "&:hover": {
      cursor: "pointer",
    },
  },
  centerText: {
    textAlign: "center",
  },
}));

function MatchActionButton({ match, orderDetails, openFailedNotification, openSuccessNotification, }) {
  const [openDialog, setOpenDialog] = useState(false);

  const classes = useStyles();
  
  if (match.match_status == "Canceled")
    return (
      <>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.centerText}
        >
          <Grid item>
              <CancelIcon className={classes.cancelIcon} />
          </Grid>
        </Grid>
      </>
    );

  if (match.match_status == "Done")
    return (
      <>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.centerText}
        >
          <Grid item>
              <CheckCircleOutlineIcon className={classes.doneIcon} />
          </Grid>
        </Grid>
      </>
    );
  return <></>;
}

export default MatchActionButton;
