import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useTranslation } from 'react-i18next';

export default function CancelMessageDialog({
  handleSubmitCancelMessage,
  openCancelDialog,
  setOpenCancelDialog,
  setCancelMessage,
  cancelMessage
}) {
  const { t } = useTranslation();
  const radioValuesLabel = {
    option_1: t("cancel-dialog.option1"),
    option_2: t("cancel-dialog.option2"),
    option_3: t("cancel-dialog.option3"),
    option_4: t("cancel-dialog.option4"),
  };

  const radioValues = {
    option_1: "Market rate has changed",
    option_2: "Time constraint",
    option_3: "My need was fulfilled outside the platform",
    option_4: "Other",
  };

  const [option, setOption] = useState(radioValues.option_1);

  const handleClose = () => {
    setOpenCancelDialog(false);
  };

  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };

  const handleCancelButton = () => {
    setCancelMessage(null);
    setOpenCancelDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openCancelDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        style={{padding: "1rem"}}
      >
        <DialogTitle id="form-dialog-title">
          {t("cancel-dialog.title")}
        </DialogTitle>

        <form onSubmit={handleSubmitCancelMessage}>
          <RadioGroup
            aria-label="option"
            name="option"
            value={option}
            onChange={handleOptionChange}
            style={{ padding: "1rem" }}
          >
            <FormControlLabel
              value={radioValues.option_1}
              control={<Radio />}
              label={radioValuesLabel.option_1}
            />
            <FormControlLabel
              value={radioValues.option_2}
              control={<Radio />}
              label={radioValuesLabel.option_2}
            />
            <FormControlLabel
              value={radioValues.option_3}
              control={<Radio />}
              label={radioValuesLabel.option_3}
            />
            <FormControlLabel
              value={radioValues.option_4}
              control={<Radio />}
              label={radioValuesLabel.option_4}
            />
          </RadioGroup>
          {option === radioValues.option_4 ? (
            <DialogContent>
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={10}
                variant="outlined"
                fullWidth
                onChange={(e)=>setCancelMessage(e.target.value)}
                name="message"
                required
                inputProps={{
                  maxlength: 300,
                }}
                helperText={`${cancelMessage.length}/300`}
              />
            </DialogContent>
          ) : (
            <></>
          )}
          <DialogActions>
            <Button onClick={handleCancelButton} color="primary">
              {t("button.cancel")}
            </Button>
            <Button type="submit" color="primary">
              {t("button.submit")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
