import i18n from "./i18nextConf";
import moment from "moment";

export const eighteen = () => {
  const today = new Date()
  const currentYear = today.getFullYear()
  let currentMonth = "" + (today.getMonth() + 1)

  if(currentMonth.length < 2)
      currentMonth = '0' + currentMonth

  let currentDay = "" + today.getDate()

  if(currentDay.length < 2)
      currentDay = '0' + currentDay

  return [currentYear - 18, currentMonth, currentDay].join("-")
}

const withArabic = (f) => {
  const result = f();
  if(isArabic())
    return toArabicDigits(result);
  return result;
}

export const numberWithCommos = (number) => { 
  return withArabic(() => {
    if (number == null) {
      return number;
    }
     
    var values = number.toString().split(".");
    return (
      values[0].replace(/.(?=(?:.{3})+$)/g, "$&,") +
      (values.length == 2 ? "." + values[1] : "")
    );
  })
};

export const checkCurrency = (id) => {
  const currencies = {
    1: "USD",
    2: "LBP",
  };
  return currencies[id];
};

export const currencies = {
  USD: 1,
  LBP: 2,
};

function parseDateNumberComponent(numberComponentString) {
  if (numberComponentString.startsWith("0")) {
    return parseInt(numberComponentString.substring(1, 2));
  }
  return parseInt(numberComponentString);
}

export function parseServerDate(dateString) {
  try {
    let dateSplits = dateString.split(" ");
    const dayMonthYear = dateSplits[0].split("-");
    const year = parseInt(dayMonthYear[0]);
    const month = parseDateNumberComponent(dayMonthYear[1]);
    const day = parseDateNumberComponent(dayMonthYear[2]);
    const hourMinuteSeconds = dateSplits[1].split(":");
    const hours = parseDateNumberComponent(hourMinuteSeconds[0]);
    const minutes = parseDateNumberComponent(hourMinuteSeconds[1]);
    const seconds = parseDateNumberComponent(hourMinuteSeconds[2]);
    return new Date(year, month - 1, day, hours, minutes, seconds, 0);
  } catch (e) {
    return new Date();
  }
}

export function formatDate(d) {
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
}

export function formateTime(d) {
  let hours = d.getHours(),
    minutes = d.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let time = `${hours}:${minutes} ${ampm}`;
  return time;
}

export function getDifferenceDays(d) {
  let difference_in_time = new Date().getTime() - d.getTime(),
    difference_in_day = Math.round(difference_in_time / (1000 * 3600 * 24));
  if (difference_in_day < 1) return `Today at ${formateTime(d)}`;
  if (difference_in_day >= 1 && difference_in_day < 2)
    return `Yesterday at ${formateTime(d)}`;
  if (difference_in_day >= 2 && difference_in_day < 3)
    return `Two days ago at ${formateTime(d)}`;
  if (difference_in_day >= 3 && difference_in_day < 4)
    return `Three days ago at ${formateTime(d)}`;
  if (difference_in_day >= 4 && difference_in_day < 5)
    return `Four days ago at ${formateTime(d)}`;
  if (difference_in_day >= 5 && difference_in_day < 6)
    return `Five days ago at ${formateTime(d)}`;
  if (difference_in_day >= 6 && difference_in_day < 7)
    return `Six days ago at ${formateTime(d)}`;
  if (difference_in_day >= 7 && difference_in_day < 8)
    return `Week ago at ${formateTime(d)}`;
  return `${formatDate(d)} at ${formateTime(d)}`;
}

export function resizeFlagImage(country, size) {
  country = country.substring(0, 2);
  return `https://www.countryflags.io/${country}/flat/${size}.png`;
}

const limitPercentage = 2; 

export function upperLimitRate(rate) {
  return ((1 + limitPercentage / 100) * rate).toFixed(2);
}

export function lowerLimitRate(rate) {
  return ((1 - limitPercentage / 100) * rate).toFixed(2);
}

export function checkCustomRateLimit(value, platformRate) {
  if (
    value > lowerLimitRate(platformRate) &&
    value < upperLimitRate(platformRate)
  ) {
    return true;
  }
  return false;
}

export const profileStatus = {
  Verified: "Profile Verified",
  Pending: "Verification Pending",
  Guest: "Guest Mode",
  SystemStopped: "System Stopped",
  AccountDeactivated: "Account Deactivated",
  Rejected: "Rejected"
};

export const isAccountApproved = (profile) => {
  if ( profile && profile.account_status?.status == "Approved" )
    return true;
  else return false;
};

export const isSystemStopped = (accountStatus) => {
  if( accountStatus && ( accountStatus.type === profileStatus.Guest ||  accountStatus.type === profileStatus.SystemStopped) )
    return true;
  else return false;
}

export const toArabicDigits = (number) => {
  if(!number) return null;
  var id = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
  return number.replace(/[0-9]/g, function(w) {
     return id[+w]
  });
}

export const toEnglishDigits = (number) => {
  var arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

  if(typeof number === 'string')
  {
    for(var i=0; i<10; i++)
    {
      number = number.replace(arabicNumbers[i], i);
    }
  }
  return number;
}

export const rtl = () => {
  if(localStorage.getItem("i18nextLng") == "ar")
      document.body.dir = "rtl";
}

export const isArabic = () => {
  if(localStorage.getItem("i18nextLng") == "ar")
      return true;
  return false;
}

export const cityName = (city) => {
  return isArabic() ? city.name_arabic : city.name;
}

export const countryName = (country) => {
  return isArabic() ? country.name_arabic : country.name;
}

function generateRateBrackets(rate, rateLimitPercentage) {
  let brackets = []
  let numberOfIntervals = 10;
  let bracketJump = rateLimitPercentage / numberOfIntervals;
  let currentMax = rate;
  let currentMaxProbability = 50;
  
  let currentMin, currentMinProbability;

  for (let i = 0; i < numberOfIntervals; i++) {
  	currentMin = rate * (1-(i+1)*bracketJump)
    currentMinProbability = currentMaxProbability - 5;
    brackets = [{minRate: currentMin, maxRate: currentMax, minBuyProbability: currentMinProbability, maxBuyProbability: currentMaxProbability, minSellProbability: (100-currentMaxProbability), maxSellProbability: (100-currentMinProbability)}].concat(brackets)
    currentMax = currentMin; 
    currentMaxProbability = currentMinProbability;
  }
   
  currentMin = rate;
  currentMinProbability = 50;

  for (let i = 0; i < numberOfIntervals; i++) {
  	currentMax = rate * (1+(i+1)*bracketJump)
    currentMaxProbability = currentMinProbability + 5;
    brackets.push({minRate: currentMin, maxRate: currentMax, minBuyProbability: currentMinProbability, maxBuyProbability: currentMaxProbability, minSellProbability: (100-currentMaxProbability), maxSellProbability: (100-currentMinProbability)})
    currentMin = currentMax; 
    currentMinProbability = currentMaxProbability;
  }

  return brackets;
}

function generateProbabilityMessage(minProbability, maxProbability) {
	if(minProbability < 50) {
      maxProbability = isArabic() ? toArabicDigits(maxProbability.toString()) : maxProbability;
      return i18n.t('probability-message.less', { maxProbability: maxProbability })
    }
    minProbability = isArabic() ? toArabicDigits(minProbability.toString()) : minProbability;
    return i18n.t('probability-message.more', { minProbability: minProbability })
}

export function getProbabilityFor(platformRate, userRate, rateLimitPercentage, isBuy) {

	if(userRate === platformRate) {
    let probability= isArabic() ? toArabicDigits("50") : 50 
    return i18n.t('probability-message.middle', { probability: probability })
    }
	const brackets = generateRateBrackets(platformRate, rateLimitPercentage);
    
  	const bracket = brackets.find((bracket, idx) => {
    	return (bracket.minRate <= userRate && bracket.maxRate>=userRate) || (idx === 19 && userRate>=bracket.maxRate) || (idx === 0 && userRate<=bracket.minRate);
  	});

    if(isBuy) { 
    	return generateProbabilityMessage(bracket.minBuyProbability, bracket.maxBuyProbability) 
    	
    }
    return generateProbabilityMessage(bracket.minSellProbability, bracket.maxSellProbability) 
}

export const documentStatus = {
  PendingApproval: i18n.t("document-status.pending-approval"),
  Rejected: i18n.t("document-status.rejected"),
}

export const profileType = {
  Organization: "Organization",
  Individual: "Individual",
  Business: "Business Enterprise"
}

export const organizationRequiredDocuments = [
  "certificate_of_register",
  "general_assembly",
  "board_of_directors",
  "commercial_circular_or_authority_matrix",
  "general_attestation",
  "company_bylaws"
]

export const businessRequiredDocuments = [
  "passport",
  "national_id",
  "certificate_of_registration"
]

export const individualDocuments = {
  Passport: {
    name: "Passport",
    label: i18n.t("documents.passport.label")
  },
  IdFrontSide: {
    name: "id_front_side",
    label: i18n.t("documents.id_front_side.label")
  },
  IdBackSide: {
    name: "id_back_side",
    label: i18n.t("documents.id_back_side.label")
  },
  NationalID: {
    name: "National ID",
    label: i18n.t("documents.national_id.label")
  },
  Delegation: {
    name: "Delegation",
    label: i18n.t("documents.delegation_document.label")
  },
  BusinessCertificate : {
    name: "Registration Certificate",
    label: i18n.t("documents.certificate_of_registration.label")
  }
}

export const EMPTY_ORGANIZATION = {   
  name: null,
  place_and_country_of_registration_id: null,
  date_of_registration: null,
  occupations: [],
  detailed_activity: null,
  countries_of_business_interactions: [],
  number_of_employees: null,
  expected_annual_volume_IN_OUT_in_USD: null,
  annual_sales_in_USD: null,
  financial_situation_additional_information: null,
  owners: [
    {
      type: "individual",
      first_name: null,
      last_name: null,
      father_name: null,
      date_of_birth: null,
      percentage_of_ownership: 0.1,
      nationality: [],
      residence: [],
      document_type: individualDocuments.NationalID.name,
      document_number: null,
      document_expiry_date: null,
      document_content: {
        [individualDocuments.IdFrontSide.name]: null,
        [individualDocuments.IdBackSide.name]: null
      },
    }
  ],
  beneficial_right_ownership_id: null,
  beneficial_right_parties: null,
  representatives: [
    {
      first_name: null,
      last_name: null,
      father_name: null,
      date_of_birth: null,
      nationality: [],
      residence: [],
      is_main_contact: true,
      is_representative: true,
      document_type: individualDocuments.NationalID.name,
      document_number: null,
      document_expiry_date: null,
      document_content: {
        [individualDocuments.IdFrontSide.name]: null,
        [individualDocuments.IdBackSide.name]: null
      },
      delegation_document_content: null
    }
  ],
  head_office_country_id: null,
  head_office_address: null,
  telephone: null,
  email: null,
  website: null,
  signature: null,
  signature_date: null,
  documents: []
}

export function editable(profile){
  if(profile.account_status.status === "Rejected" || profile.account_status.status === "Missing Documents")
    return true;
  return false;
}

export const EMPTY_BUSINESS = {   
  name: null,
  place_of_registration: null,
  date_of_registration: null,
  registration_number: null,
  gender: "Male",
  first_name: null,
  last_name: null,
  father_name: null,
  maiden_name: null,
  date_of_birth: null,
  employment_status_id: null,
  other_employment_status: null,
  other_source_of_funds: null,
  mobile_phone: null,
  signature: null,
  signature_date: null,
  nationalities: [],
  residences: [],
  sources_of_funds: [],
  document_type: individualDocuments.NationalID.name,
  document_number: null,
  document_expiry_date: null,
  document_content: {
    [individualDocuments.IdFrontSide.name]: null,
    [individualDocuments.IdBackSide.name]: null
  },
  certificate_document_content: null
}

export const EMPTY_INDIVIDUAL = {
  gender: "Male",
  first_name: null,
  father_name: null,
  last_name: null,
  maiden_name: null,
  date_of_birth: null,
  employment_status_id: null,
  other_employment_status: null,
  other_source_of_funds: null,
  mobile_phone: null,
  signature: null,
  signature_date: null,
  nationalities: [],
  residences: [],
  sources_of_funds: [],
  document_type: individualDocuments.NationalID.name,
  document_number: null,
  document_expiry_date: null,
  document_content: {
    [individualDocuments.IdFrontSide.name]: null,
    [individualDocuments.IdBackSide.name]: null
  },
}

export const containsMoreThanOneDot = (value) => {
  return value.split(".").length >= 3;
};

export const containsDot = (value) => {
  return value.split(".").length >= 2;
};

export const containsNonNumericLetter = (value) => {
  let nonNumeric = new RegExp(`[^0123456789٠١٢٣٤٥٦٧٨٩.]`, 'g');

  value = value.replace(nonNumeric, '');

  return value;
}

export const formatNumber = (value) => {
  const dotSplit = value.split(".")
  const beforeDot = dotSplit[0]
  let addCommaCounter = 3
  let newValue = ""
  for(let i=beforeDot.length-1;i>=0;i--) {
    newValue = value.charAt(i) + newValue;
    addCommaCounter--;
    if(addCommaCounter == 0 && i>0) {
     newValue = "," + newValue;
      addCommaCounter=3
    }
  }
  return dotSplit.length == 2 ? newValue + "." + dotSplit[1] : newValue   
}

export const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : '';
}

export const hasFieldError = (profileDetails, fieldName) => {
  return profileDetails.profile_fields_status[fieldName]?.status == profileStatus.Rejected;
}

export const hasStepError = (step, profileDetails) => {
  for(var i=0; i<step.fields.length; i++){
    if(profileDetails?.profile_fields_status[step.fields[i]]?.status === profileStatus.Rejected)
      return true;
  }
  return false;
}

export const getFieldError = (profileDetails, fieldName) => {
  if(isEditStepper() && hasFieldError(profileDetails, fieldName))
    return profileDetails.profile_fields_status[fieldName]?.note
  return null;
}

export const getAvatar = (profileDetails, type) => {
  if(profileDetails){
    if(type === profileType.Organization || type === profileType.Business)
      return profileDetails?.name?.substring(0,1);
    if(type === profileType.Individual)
      if(profileDetails.maiden_name)
        return profileDetails?.first_name?.substring(0,1) + profileDetails?.maiden_name?.substring(0,1)
      return profileDetails?.first_name?.substring(0,1) + profileDetails?.last_name?.substring(0,1)
  }
}

export const getUsername = (profileDetails, profile) => {
  if(profile === profileType.Organization || profile === profileType.Business)
    return profileDetails.name;
  if(profile === profileType.Individual)
    if(profileDetails.maiden_name){
      if(profileDetails.last_name)
        return profileDetails.first_name + " " + profileDetails.maiden_name + " " + profileDetails.last_name
      return profileDetails.first_name + " " + profileDetails.maiden_name
    }
    else return profileDetails.first_name + " " + profileDetails.last_name
}

export const isEditStepper = () => {
  return window.location.pathname.split("/").includes("edit");
}

export const isRegisterStepper = () => {
  return window.location.pathname.split("/").includes("register");
}

export const parseNumber = (number) => {
  return parseFloat(number.toFixed(2))
}
