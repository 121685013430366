import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useTranslation } from 'react-i18next';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import { getCountries } from "../../../redux-reducer/data/dataSlice";
import { countryToFlag, getFieldError, hasFieldError, isArabic, isEditStepper, toArabicDigits } from "../../../utils";
import { InputAdornment } from "@material-ui/core"; 

const useStyles = makeStyles((theme) => ({
    input: {
        marginTop: "1rem"
    },
}));

export default function ContactDetails(props) {
    const formikProps = props.formikProps;

    const {
        formField: {
            officeCountry,
            officeAddress,
            phone,
            email,
            website
        }
    } = props;
    
    const classes = useStyles();
    const { t } = useTranslation();

    const countries = useSelector(getCountries);

    const handleDateChange = (date, value) => {
        props.setOrganizationDetails({
            ...props.organizationDetails,
            signature_date: value
        })
        props.setEdit(true);
    };

    return (
        <React.Fragment>
            <Typography variant="h4" style={{ lineHeight: 1.33, textAlign: "center" }}>
            {t("stepper.kyb.step6")}
            </Typography>                    

            <Box mt={5} /> 

            <Grid container direction="row" md={12} xs={12} >
                <Grid item md={5} xs={12} className={classes.input}>
                    <Autocomplete
                        options={countries}
                        value={countries.find((country) => country.id === formikProps.values[officeCountry.name])}
                        onChange={props.setSingleOptionAttribute(formikProps.setFieldValue, officeCountry.name)}
                        getOptionLabel={
                            (option) => isArabic() ? countryToFlag(option.code) + " " + option.name_arabic : countryToFlag(option.code) + " " + option.name 
                        }
                        renderOption={(option) => (
                            <React.Fragment>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                    style={{marginRight: '1rem'}}
                                />
                                {isArabic() ? option.name_arabic : option.name} 
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                name={officeCountry.name}
                                label={officeCountry.label}
                                onBlur={formikProps.handleBlur(officeCountry.name)} 
                                error={(formikProps.touched[officeCountry.name] && Boolean(formikProps.errors[officeCountry.name])) || (isEditStepper() && JSON.stringify(formikProps.values[officeCountry.name]) == JSON.stringify(props.organizationDetails[officeCountry.name]) && hasFieldError(props.organizationDetails, officeCountry.name))}
                                helperText={(formikProps.touched[officeCountry.name] && formikProps.errors[officeCountry.name]) || (JSON.stringify(formikProps.values[officeCountry.name]) == JSON.stringify(props.organizationDetails[officeCountry.name]) && getFieldError(props.organizationDetails, officeCountry.name))}
                            />
                        )}
                    />
                </Grid>

                <Grid item md={2} xs={12} />

                
                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        name={officeAddress.name}
                        label={officeAddress.label}
                        value={formikProps.values[officeAddress.name]}
                        onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                        onBlur={formikProps.handleBlur(officeAddress.name)} 
                        error={(formikProps.touched[officeAddress.name] && Boolean(formikProps.errors[officeAddress.name])) || (isEditStepper() && formikProps.values[officeAddress.name] == props.organizationDetails[officeAddress.name] && hasFieldError(props.organizationDetails, officeAddress.name))}
                        helperText={(formikProps.touched[officeAddress.name] && formikProps.errors[officeAddress.name]) || (formikProps.values[officeAddress.name] == props.organizationDetails[officeAddress.name] && getFieldError(props.organizationDetails, officeAddress.name))}
                    />
                </Grid>
                
                <Grid container md={5} xs={12} className={classes.input} direction="row">
                    <Grid item md={2} xs={2}>
                        <Autocomplete
                            options={countries.find((country) => country.code === "LB")}
                            value={countries.find((country) => country.code === "LB")}
                            disabled
                            getOptionLabel={
                                (option) => countryToFlag(option.code) 
                            }
                            renderOption={(option) => (
                                <React.Fragment>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                        style={{marginRight: '1rem'}}
                                    />
                                    { isArabic() ? option.name_arabic : option.name } 
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={10} xs={10}>
                        <TextField
                            variant="outlined"
                            name={phone.name}
                            label={phone.label}
                            value={formikProps.values[phone.name]}
                            onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                            onBlur={formikProps.handleBlur(phone.name)} 
                            error={(formikProps.touched[phone.name] && Boolean(formikProps.errors[phone.name])) || (isEditStepper() && formikProps.values[phone.name] == props.organizationDetails[phone.name] && hasFieldError(props.organizationDetails, phone.name))}
                            helperText={(formikProps.touched[phone.name] && formikProps.errors[phone.name]) || (formikProps.values[phone.name] == props.organizationDetails[phone.name] && getFieldError(props.organizationDetails, phone.name))}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" type="text" disableTypography>{isArabic() ? toArabicDigits("+" + countries.find((country) => country.code === "LB").phone) : "+" + countries.find((country) => country.code === "LB").phone}</InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item md={2} xs={12} />

                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        name={email.name}
                        label={email.label}
                        value={formikProps.values[email.name]}
                        onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                        onBlur={formikProps.handleBlur(email.name)} 
                        error={(formikProps.touched[email.name] && Boolean(formikProps.errors[email.name])) || (isEditStepper() && formikProps.values[email.name] == props.organizationDetails[email.name] && hasFieldError(props.organizationDetails, email.name))}
                        helperText={(formikProps.touched[email.name] && formikProps.errors[email.name]) || (formikProps.values[email.name] == props.organizationDetails[email.name] && getFieldError(props.organizationDetails, email.name))}
                        fullWidth
                    />
                </Grid>

                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        name={website.name}
                        label={website.label}
                        value={formikProps.values[website.name]}
                        onChange={props.setOrganizationAttribute(formikProps.setFieldValue)} 
                        onBlur={formikProps.handleBlur(website.name)} 
                        error={(formikProps.touched[website.name] && Boolean(formikProps.errors[website.name])) || (isEditStepper() && formikProps.values[website.name] == props.organizationDetails[website.name] && hasFieldError(props.organizationDetails, website.name))}
                        helperText={(formikProps.touched[website.name] && formikProps.errors[website.name]) || (formikProps.values[website.name] == props.organizationDetails[website.name] && getFieldError(props.organizationDetails, website.name))}
                        fullWidth
                    />
                </Grid>

{/*                 <Grid item md={2} xs={12} />

                <Grid item md={5} xs={12} className={classes.input}>
                    <TextField
                        variant="outlined"
                        required
                        name="signature"
                        label={t("labels.signature")}
                        value={props.organizationDetails.signature}
                        onChange={props.setOrganizationAttribute}
                        fullWidth
                    />
                </Grid>

                <Grid item md={5} xs={12}>
                    <KeyboardDatePicker
                        fullWidth
                        variant="dialog"
                        format="yyyy/MM/dd"
                        margin="normal"
                        label={t("labels.signature-date")}
                        value={moment(new Date()).format("yyyy/MM/DD")}
                        inputVariant="outlined"
                        InputProps={{ readOnly: true }}
                        disabled
                    />
                </Grid> */}
            </Grid>       
        </React.Fragment>
    );
}
